import {StackLayout} from "@progress/kendo-react-layout";
import {useLayoutEffect, useState} from "react";
import UserGrid from "./UsersGrid";
import useFetch from "../../../tools/UseFetch";
import config from "../../../config/config.json";

const Users = props => {

    const menuSize = props.menuSize
    const [refSize, setRefSize] = useState({width:0, height:0});

    const { data:actual_user, isPending:actual_user_isPending, error:actual_user_error} = useFetch(config.API_URL + 'user/get_own_user', 1)


    const handleSize = () => {
        // debugger
        let width = window.innerWidth - 10;
        const height = window.innerHeight;
        setRefSize({
            // width: ref.current.offsetWidth,
            // height: ref.current.offsetHeight
            width: width,
            height: height
        })
        console.log(refSize.width, width, window.innerWidth)
    }

    useLayoutEffect(() => {
        handleSize()
        window.addEventListener("resize", handleSize);
        return () => window.removeEventListener("resize", handleSize);
    }, []);


    return(
        <>


                <StackLayout
                    style={{
                        height: "calc(100vh - 55px)",
                        width: "100%"
                    }}
                    orientation={"vertical"}
                    gap={0}
                >

                    {actual_user && <UserGrid size={refSize} menuSize={menuSize} actual_user={actual_user} dataType={"all"}></UserGrid>}

                </StackLayout>



        </>
    )
}

export default Users