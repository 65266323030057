import Notes from "./Notes";
import {Dialog, DialogActionsBar, Window, WindowActionsBar} from "@progress/kendo-react-dialogs";

const NotesInDialog = props => {
    return (
        <>

            <Window title="Notes"
                    initialHeight={1200}
                    initialWidth={750}
                    modal={true}
                    onClose={props.close}
            >

                <Notes orientation={"vertical"} notesFor={props.notesFor} />

            </Window>

        </>
    );
}

export default NotesInDialog