import {filterBy, process} from "@progress/kendo-data-query";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {Grid, GridToolbar} from "@progress/kendo-react-grid";
import config from "../../config/config.json";
import {cloneElement, useEffect, useState} from "react";
import EditClient from "./EditClient";
import APICall from "../../tools/APICall";
import PartnerEditor from "../editComponents/PartnerEditor";
import useFetch from "../../tools/UseFetch";
import DropDownFilter from "../filterComponents/DropDownFilter";
import EditCommandCell from "../editComponents/EditCommandCell";
import CustomTextCell from "../editComponents/CustomTextCell";
import useClientStore from "../../stores/ClientStore";
import DropDownEditor from "../editComponents/DropDownEditor";
import PriceEditor from "../editComponents/PriceEditor";
import CommandCell from "../tableComponents/CommandCell";
import useCashflowStore from "../../stores/CashflowStore";
import Confirm from "../general/Confirm";
import FileDialog from "../files/FileDialog";
import useOwnRightsStore from "../../stores/OwnRightsStore";
import {ToolbarItem, ToolbarSeparator} from "@progress/kendo-react-buttons";
import ProjectsGrid from "../projects/ProjectsGrid";
import TableDetailProjectsGrid from "./TableDetailProjectsGrid";
import useProjectStore from "../../stores/ProjectStore";
import DetailsCell from "../tableComponents/DetailsCell";
import NotesInDialog from "../notes/NotesInDialog";


const ActionCell = (props) => {
    return(
        <>
        </>
    )
}


const initialDataState = {
    take: 50,
    skip: 0,
    pageSize:50,
};


const ProjectsGridInClients = props => {

    console.log("... props ... ", props, props.dataItem.ID)

    return(

        <>
            <div className="ClientProjectsTable">
                <TableDetailProjectsGrid clientID={props.dataItem.ID} />
            </div>
        </>
    )
}


const ClientsGrid = (props) => {

    const { data:partners, isPending, error} = useFetch(config.API_URL + 'partner/', 1)
    const { data:units, isPending:units_isPending, error:units_error} = useFetch(config.API_URL + 'lists/unit/', 1)

    const isSmallHeight = window.innerHeight < 600

    const haveRight = useOwnRightsStore(state => state.haveRight)


    const data = useClientStore(state => state.clients)
    const setData = useClientStore(state => state.setClients)
    const clients = useClientStore(state => state.clientsOriginal)
    const discardNew = useClientStore(state => state.discardNew)
    const saveNew = useClientStore(state => state.saveNew)
    const saveItem = useClientStore(state => state.saveItem)
    const selectedClient = useClientStore(state => state.selectedClient)
    const setSelected = useClientStore(state => state.selectClient)

    const setSelectedProject = useProjectStore(state => state.selectItem)

    const addNewClient = useClientStore(state => state.addNew)
    const refreshData = useClientStore(state => state.refreshData)
    const deleteItem = useClientStore(state => state.deleteItem)


    const refSize = props.size
    const menuSize = props.menuSize

    const toggleDisplayParent = props.toggleDisplayParent

    const [dataState, setDataState] = useState(initialDataState);

    const [openForm, setOpenForm] = useState(false);
    const [editItem, setEditItem] = useState(null);


    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [deletedItem, setDeletedItem] = useState(null);

    const [showFilesDialog, setShowFilesDialog] = useState(false)
    const [showFilesFor, setShowFilesFor] = useState(null);


    let columnDefWidth = refSize.width * 0.1
    if (columnDefWidth < 120) columnDefWidth = 120

    // const [selectedCount, setSelectedCount] = useState(0)

    const SumFooter = (props) => {
        const field = props.field || "";

        const filteredData = dataState.filter ? filterBy(data, dataState.filter) : data;
        const total = filteredData.reduce((acc, current) => acc + current[field], 0);

        // const total = process(data, dataState).data.reduce((acc, current) => acc + current[field], 0)
        const formatter = new Intl.NumberFormat('en-US', { useGrouping: true });
        return (
            <td colSpan={props.colSpan} style={props.style} className={"right"}>
                {formatter.format(total)}
            </td>
        );
    };


    // obarvuje ID vybraneho radku
    const useCustomCell = props => (
        <CustomTextCell
            {...props}
            selected={selectedClient}
        />
    );


    const usePriceCell = props => (
        <PriceEditor
            {...props}
            // redStyle={"redSolid"}
            // greenStyle={"greenSolid"}
        />
    );

    const usePartnerEditor = props => (
        <DropDownEditor
            {...props}
            listData={partners}
        />
    );

    const PartnerFilter = (props) => (
        <DropDownFilter
            {...props}
            data={partners}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );


    const useUnitEditor = props => (
        <DropDownEditor
            {...props}
            listData={units}
            editable={true}
        />
    );

    const UnitFilter = (props) => (
        <DropDownFilter
            {...props}
            data={units}
            filterable={true}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );





    const discard = () => {
        discardNew()
    };




    const rowClick = (props) => {
        console.log("row click - " + props.dataItem.ID)
        console.log(process(clients, dataState))
        setSelected(props.dataItem)
        setSelectedProject(null)
        // setSelectedClientName(props.dataItem.Name)
        if (isSmallHeight) toggleDisplayParent()
    }



    //
    // const updateFilterInfo = e => {
    //     props.setSelectedCount(process(data, dataState).data.length)
    // }

    // useEffect(() => {
    //     setSelectedCount(process(data, dataState).data.length)
    // },[dataState])



    const MyEditCommandCell = (props) => (
        <CommandCell
            {...props}
            edit={enterEdit}
            copy={enterCopy}
            delete={deleteRow}
            showFiles={false}
            showDelete={haveRight("Clients_Del")}
            showCopy={haveRight("Clients_Edit")}
            filesCount={props.dataItem.FilesCount}
        />
    );

    const MyDetailsCell = (props) => (
        <DetailsCell
            {...props}
            showFiles={showFiles}
            filesCount={props.dataItem.FilesCount}
            showNotes={showNotes}
            notesCount={props.dataItem.NotesCount}
        />
    );


    // ************************************** EDIT *************************************


    const rowDblClick = props => {
        enterEdit(props.dataItem)
    }

    const enterEdit = (dataItem) => {
        console.log("item edit")

        setEditItem(dataItem)
        setOpenForm(true)
    };


    const handleCancelEdit = (dataItem) => {

        setOpenForm(false);

    };



    const handleSubmit = (dataItem) => {
        //debugger
        if (dataItem.ID === undefined || dataItem.ID === null) {
            saveNew(dataItem)
        }
        else {
            saveItem(dataItem)
        }
        setOpenForm(false);
    };


    // ************************************** NEW *************************************

    const editNew = () => {
        const newItem = {};
        setEditItem(newItem)
        setOpenForm(true)
    };

    // ************************************** COPY *************************************


    const enterCopy = (dataItem) => {
        console.log("copy edit")

        const newItem = {
            ...dataItem,
            ID: null,
            UID: null,
        }

        setEditItem(newItem)
        setOpenForm(true)

        console.log("edit copy ... ", openForm, editItem)
    };



    // ************************************** Delete *************************************

    const deleteRow = (dataItem) => {
        setDeletedItem(dataItem)
        setShowConfirmDelete(true)
    };

    const deleteRowAction = () => {
        deleteItem(deletedItem)
        setShowConfirmDelete(false)
    };

    const cancelDelete = () => {
        setShowConfirmDelete(false)
    };


    // ************************************** Files *************************************

    const showFiles = (dataItem) => {
        // debugger
        setShowFilesFor(dataItem)
        setShowFilesDialog(true)
    };

    const closeFiles = () => {
        refreshData()
        setShowFilesDialog(false)
    };


    // ********************************* Master-detail *********************************

    const expandChange = (event) => {
        console.log("event value - ", event)
        event.dataItem.expanded = event.value;
        setSelected(event.dataItem)
    };

    // ************************************** Notes *************************************

    const [showNotesFor, setShowNotesFor] = useState(null);
    const showNotes = (dataItem) => {
        // debugger
        setShowNotesFor(dataItem)
    };

    const closeNotes = () => {
        refreshData()
        setShowNotesFor(null)
    };

    // ************************************** GRID *************************************


    const [pageSizeValue, setPageSizeValue] = useState(50);

    const pageChange = (event) => {

        // const targetEvent = event.syntheticEvent;
        // const take = targetEvent.value === "All" ? process(data, dataState).length : event.page.take;
        //
        // console.log("pagechange", targetEvent, event)
        //
        // if (targetEvent.value) {
        //     setPageSizeValue(targetEvent.value);
        // }

        setPageSizeValue(event.page.take)

        setDataState({
            ...dataState,
            skip: event.page.skip,
            take: event.page.take
        });
    };

    return(
        <>

            {partners && units && <Grid
                // pageable={true}
                sortable={true}
                filterable={true}
                //scrollable={"virtual"}
                resizable={true}
                reorderable={true}
                //rowHeight={30}
                // navigatable={true}
                // onItemChange={itemChange}
                //onFilterChange={updateFilterInfo}

                // rowRender={rowRender}
                //editField="inEdit"
                // onItemChange={itemChange}
                size={"small"}

                style={{
                    height: "100%", // (refSize.height - 150) / 2,//"500px",
                    minHeight: 20,
                    maxHeight: refSize.height - 70,
                    width: refSize.width - menuSize,
                }}
                data={process(data, dataState)}{...dataState}

                total={process(data, dataState).length}
                pageable={{
                    buttonCount: 4,
                    pageSizes: [10, 50, 100, 500],
                    pageSizeValue: pageSizeValue,
                }}
                onPageChange={pageChange}

                onDataStateChange={(e) => setDataState(e.dataState)}
                onRowClick={rowClick}
                onRowDoubleClick={rowDblClick}

                detail={ProjectsGridInClients}
                expandField="expanded"
                onExpandChange={expandChange}

            >


                <GridToolbar>

                    {haveRight("Clients_Edit") && <button
                        title="Export to Excel"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={editNew}
                    >
                        Novy klient
                    </button>}

                    {/*<ToolbarSeparator />*/}
                    {/*<ToolbarItem>zobrazeno: {selectedCount} z celkem {data.length}</ToolbarItem>*/}

                </GridToolbar>

                {/*<Column field="expanded" minResizableWidth={100} title="Projects" width={250} locked/>*/}

                <Column cell={useCustomCell} field="ID" title="ID" width="60px" filterable={false} editable={false} locked />
                {/*<Column field="action" minResizableWidth={160}*/}
                {/*        cell={MyEditCommandCell}*/}
                {/*    // columnMenu={ActionCell}*/}
                {/*        filterable={false}*/}
                {/*        sortable={false}*/}
                {/*    //  headerCell={ActionCell}*/}
                {/*        width="160"*/}
                {/*        locked={true}*/}

                {/*    // filterCell={ActionCell}*/}
                {/*/>*/}

                {/*<Column cell={myID} field="ID" title="ID" width="80px" footerCell={myFooterCell} filterable={true}/>*/}
                {/*<Column cell={myID} field="ID" title="ID" width="80px" filterable={true} locked/>*/}


                <Column field="Name" minResizableWidth={100} title="Název" width={250} locked/>
                <Column field="ICO" minResizableWidth={100} title="IČ" width={130}/>
                <Column field="DIC" minResizableWidth={100} title="DIČ" width={130}/>


                {/*<Column field="Status" minResizableWidth={100} title="Status" width={columnDefWidth}/>*/}

                <Column field="Address" minResizableWidth={100} title="Adresa" width={340}/>

                <Column field="PartnerID" minResizableWidth={100} title="Partner" cell={usePartnerEditor}
                        width={200} filterCell={PartnerFilter} />
                <Column field="UnitID" minResizableWidth={100} title="Středisko" cell={useUnitEditor}
                        width={160} filterCell={UnitFilter} />


                <Column field="LastMonth" minResizableWidth={40} title="Minulý měsíc" cell={PriceEditor} width={100} editable={false} footerCell={SumFooter}/>
                <Column field="ToInvoice" minResizableWidth={40} title="k fakturaci" cell={PriceEditor} width={100} editable={false} filter={"numeric"} footerCell={SumFooter} />
                <Column field="PlannedCashFlow" minResizableWidth={40} title="Planned in CZK" cell={PriceEditor} width={100} editable={false} filter={"numeric"} />

                <Column field="CashFlow" minResizableWidth={40} cell={PriceEditor} title="bilance CZK" width={120} editable={false} filter={"numeric"} footerCell={SumFooter} />
                {haveRight("View_In_Values") && <Column field="CashFlowWithTMSIn" minResizableWidth={40} cell={PriceEditor} title="bilance CZK In"
                         width={120} editable={false} filter={"numeric"} footerCell={SumFooter}/>}
                {haveRight("View_Out_Values") && <Column field="CashFlowWithTMSOut" minResizableWidth={40} cell={PriceEditor} title="bilance CZK Out"
                         width={120} editable={false} filter={"numeric"} footerCell={SumFooter}/>}


                <Column field="Code" minResizableWidth={50} title="Kód" width={100}/>
                <Column field="Category" minResizableWidth={50} title="Kategorie" width={150}/>

                <Column field="Phone" minResizableWidth={100} title="phone" width={200}/>
                <Column field="Email" minResizableWidth={100} title="e-mail" width={200} resizable={true}/>

                <Column field="Representative" minResizableWidth={100} title="Zástupce" width={200}/>

                <Column field="Note" minResizableWidth={100} title="Poznámka" width={300}/>

                {/*<Column field="Tags" minResizableWidth={100} title="Tagy" width={200}/>*/}

                <Column field="details" minResizableWidth={120}
                        cell={MyDetailsCell}
                        filterable={false}
                        headerCell={ActionCell}
                        width="120"
                        resizable={true}
                        locked
                />

                <Column field="action" minResizableWidth={100}
                        cell={MyEditCommandCell}
                    // columnMenu={ActionCell}
                        filterable={false}
                        headerCell={ActionCell}
                        width="100"
                        resizable={true}
                        locked
                    // filterCell={ActionCell}
                />

            </Grid>}

            {openForm && haveRight("Clients_Edit") && <EditClient
                partners={partners}
                units={units}
                cancelEdit={handleCancelEdit}
                onSubmit={handleSubmit}
                item={editItem}
            />}

            {showConfirmDelete && <Confirm
                action={deleteRowAction}
                cancel={cancelDelete}
                text={"delete item?"}
            />}


            {showFilesDialog && <FileDialog
                close={closeFiles}
                text={"delete item?"}
                filesFor={showFilesFor}
            />}

            {showNotesFor && <NotesInDialog
                close={closeNotes}
                notesFor={showNotesFor}
            />}

        </>
    )
}

export default ClientsGrid