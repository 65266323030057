import * as React from "react";
import {Dialog, DialogActionsBar, Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import {TextBox, NumericTextBox, TextArea} from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {Error, Hint, Label} from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import FormDropDown from "../formEditComponents/FormDropDown";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import {isNumeric} from "@progress/kendo-data-query/dist/npm/utils";
import {DateInput, DatePicker} from "@progress/kendo-react-dateinputs";
import DropDownEditor from "../editComponents/DropDownEditor";
import FormTextBoxField from "../formEditComponents/FormTextBoxField";
import FormTextAreaField from "../formEditComponents/FormTextAreaField";
import FormNumBoxField from "../formEditComponents/FormNumBoxField";
import FormDateField from "../formEditComponents/FormDateField";
import NumBoxField from "../formEditComponents/FormNumBoxField";
import useOwnRightsStore from "../../stores/OwnRightsStore";



const minValueValidator = value => value >= 0 ? "" : "The value must be 0 or higher";

const comparisonDate = new Date().getDate() > 5 ? new Date(new Date().getFullYear(), new Date().getMonth(), 1) : new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);

const mandatoryFieldValidator = value => value != null ? "" : "mandatory field"


const useProjectEditor = props => (
    <FormDropDown
        {...props}
        textField={"ClientProjectName"}
    />
);




const EditExpense = props => {

    const {
        cancelEdit,
        onSubmit,
        item,
        projects,
        authors,
        expenseTypes,
        currencies,
        editFor,
        selectedClient,
        selectedProject,
        actualUser,
        ...other
    } = props;

    // item.UnitID = units.find((c) => c.ID === item.UnitID)
    // item.PartnerID = partners.find((c) => c.ID === item.PartnerID)


    const haveRight = useOwnRightsStore(state => state.haveRight)

    const unlockedDateFieldValidator = value => {
        if (value != null) {

            if (value >= comparisonDate || haveRight("TMS_Edit_Locked")) {
                return "";
            } else {
                return "Timesheet for this date is locked";
            }

        } else {
            return "mandatory field";
        }
    }


    var projectsData = projects

    switch (editFor) {
        case "client":
            projectsData = projects.filter(project => project.ClientID === selectedClient.ID)
            break
        case "project":
            projectsData = projects.filter(project => project.ID === selectedProject.ID)
            break
        default:

    }

    const convertDateToUTC = (date) => {

        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        const utcDate = new Date(Date.UTC(year, month, day));

        return utcDate;
    };



    const handleSubmit = dataItem => {
         //debugger
        dataItem.Date = convertDateToUTC(dataItem.Date)
        if (!isNumeric(dataItem.ProjectID)) dataItem.ProjectID = dataItem.ProjectID.ID
        if (!isNumeric(dataItem.AuthorID)) dataItem.AuthorID = dataItem.AuthorID.ID
        if (!isNumeric(dataItem.ExpenseTypeID)) dataItem.ExpenseTypeID = dataItem.ExpenseTypeID.ID
        if (!isNumeric(dataItem.CurrencyID)) dataItem.CurrencyID = dataItem.CurrencyID.ID
        onSubmit(dataItem)
    }

    var locked = false

    var title = ``
    if (item.ID === null) {
        title = "Copy Expense"
    }
    else if (item.ID === undefined) {
        title = "Create new Expense"
    }
    else {
        title = `Edit Expense: ${item.ID}`
        if ((item.Date < comparisonDate && !haveRight("TMS_Edit_Locked")) || (item.AuthorID !== actualUser.ID && !haveRight("TMS_Fill_For_Others"))) {
            title += ` - LOCKED`
            locked = true
        }
    }


    // const { data:partners, isPending, error} = useFetch(config.API_URL + 'partner/', 1)

    return <Form initialValues={item} onSubmit={handleSubmit} render={renderProps =>

        <Window title={title} onClose={cancelEdit}
                initialHeight={800}
                initialWidth={600}
                modal={true}
        >
            <div className="editForm">
                <FormElement horizontal={true}>



                    {projectsData && <FieldWrapper><Field disabled={locked} name={"ProjectID"} label={"Project"} textField={"Name"} dataItemKey={"id"} component={useProjectEditor} listData={projectsData} validator={mandatoryFieldValidator} /></FieldWrapper>}
                    {authors && <FieldWrapper><Field name={"AuthorID"} label={"Autor"} textField={"Name"} dataItemKey={"id"} component={FormDropDown} disabled={locked || !haveRight("TMS_Fill_For_Others")} listData={authors} validator={mandatoryFieldValidator} /></FieldWrapper>}
                    {expenseTypes && <FieldWrapper><Field disabled={locked} name={"ExpenseTypeID"} label={"Typ"} textField={"Name"} dataItemKey={"id"} component={FormDropDown} listData={expenseTypes} validator={mandatoryFieldValidator} /></FieldWrapper>}


                    <FieldWrapper><Field disabled={locked} name={"Code"} component={FormTextBoxField}  label={"Code"} /></FieldWrapper>
                    <FieldWrapper><Field disabled={locked} name={"Category"} component={FormTextBoxField}  label={"Category"} /></FieldWrapper>

                    <FieldWrapper><Field disabled={locked} name={"Date"} component={FormDateField}  label={"Date"} validator={unlockedDateFieldValidator} /></FieldWrapper>

                    <FieldWrapper><Field disabled={locked} name={"Desc"} component={FormTextAreaField}  label={"Popis"} /></FieldWrapper>

                    <FieldWrapper><Field disabled={locked} name={"Price"} component={FormNumBoxField}  label={"Cena"} /></FieldWrapper>
                    {currencies && <FieldWrapper><Field disabled={locked} name={"CurrencyID"} label={"Mena"} textField={"Name"} dataItemKey={"id"} component={FormDropDown} listData={currencies} validator={mandatoryFieldValidator} /></FieldWrapper>}


                    {/*<FieldWrapper><Field name={"Tags"} component={FormTextBoxField}  label={"Tagy"} /></FieldWrapper>*/}

                    <FieldWrapper><Field disabled={locked} name={"InvoiceNum"} component={FormTextBoxField}  label={"Invoice Num"} /></FieldWrapper>




                    {/*<FieldWrapper>*/}
                    {/*    <Field name={"UnitPrice"} component={NonNegativeNumericInput} label={"Price"} validator={minValueValidator} />*/}
                    {/*</FieldWrapper>*/}
                    {/*<FieldWrapper>*/}
                    {/*    <Field name={"UnitsInStock"} component={NonNegativeNumericInput} label={"In stock"} validator={minValueValidator} />*/}
                    {/*</FieldWrapper>*/}
                </FormElement>
            </div>

            <WindowActionsBar layout="start">
                <Button type={"submit"} themeColor={"primary"} disabled={locked || !renderProps.allowSubmit} onClick={renderProps.onSubmit} icon="save" >
                    Update
                </Button>
                <Button onClick={cancelEdit} icon="cancel" >
                    Cancel
                </Button>
            </WindowActionsBar>

        </Window>} {...other} />;
};
export default EditExpense;