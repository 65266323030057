import {process} from "@progress/kendo-data-query";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {Grid, GridToolbar} from "@progress/kendo-react-grid";
import {useEffect, useState} from "react";
import CommandCell from "../../tableComponents/CommandCell";
import EditUnit from "./EditUnit";
import useUnitStore from "../../../stores/UnitStore";
import ColorCell from "../../tableComponents/ColorCell";

const EDIT_FIELD = "inEdit";

const ActionCell = (props) => {
    return(
        <>
            {/*<Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"*/}
            {/*        size="small"*/}
            {/*        rounded="small"*/}
            {/*        onClick={() => props.enterEdit(props.dataItem)}*/}
            {/*>Novy klient</Button>*/}
            {/*<span className={"table-header-action"}>Nový klient</span>*/}
            {/*<span className={"table-header-action"}>aaa</span>*/}
        </>
    )
}

const initialDataState = {
    // take: 50,
    skip: 0,
    pageSize:100,
};





const UnitGrid = (props) => {

    // const refresh = props.refresh
    // const {selectedClient} = useClientStore((state) => ({selectedClient: state.selectedClient}))
    // const {selectedProject} = useProjectStore((state) => ({selectedProject: state.selectedItem}))
    // const selectedProject = props.selectedProject

    const refSize = props.size
    const menuSize = props.menuSize
    const dataType = props.dataType || "all"

    const exportData = props.setData

    const [dataState, setDataState] = useState(initialDataState);
    const [refresh, setRefresh] = useState(Date.now())


    let columnDefWidth = 100
    // let columnDefWidth = refSize.width * 0.1
    // if (columnDefWidth < 120) columnDefWidth = 120


    // let apiURL;
    // if (selectedProject) apiURL = config.API_URL + 'cashflow/project/' + selectedProject.ID
    // else if (selectedClient) apiURL = config.API_URL + 'cashflow/client/' + selectedClient.ID
    // else apiURL = config.API_URL + 'cashflow/'

    // const { data:cashflow, isPending, error} = useFetch(apiURL, refresh)

    const data = useUnitStore(state => state.storeData)
    const addNew = useUnitStore(state => state.addNew)
    const setData = useUnitStore(state => state.setStoreData)
    const saveNew = useUnitStore(state => state.saveNew)
    const saveItem = useUnitStore(state => state.saveItem)

    const refreshData = useUnitStore(state => state.refreshData)
    const deleteItem = useUnitStore(state => state.deleteItem)

    const loadAll = useUnitStore(state => state.loadAll)



    // const { data:currency, isPending:currency_isPending, error:currency_error} = useFetch(config.API_URL + 'lists/currency/', refresh)
    // const { data:units, isPending:units_isPending, error:units_error} = useFetch(config.API_URL + 'lists/unit/', refresh)
    // const { data:projects, isPending:projects_isPending, error:projects_error} = useFetch(config.API_URL + 'project/', refresh)
    // const { data:cfType, isPending:cfType_isPending, error:ccfType_error} = useFetch(config.API_URL + 'lists/cashflow_type/', refresh)
    // const { data:cfStatus, isPending:cfStatus_isPending, error:cfStatus_error} = useFetch(config.API_URL + 'lists/cashflow_status/', refresh)
    // //const { data:clients, isPending:clients_isPending, error:clients_error} = useFetch(config.API_URL + 'client/', refresh)
    // const clients = useClientStore(state => state.clients)
    // const projects = useProjectStore(state => state.storeData)

    const [openForm, setOpenForm] = useState(false);
    const [editItem, setEditItem] = useState(null);

    // const [chartVisible, setChartVisible] = useState(false);

    // const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    // const [deletedItem, setDeletedItem] = useState(null);

    // const [showFilesDialog, setShowFilesDialog] = useState(false)
    // const [showFilesFor, setShowFilesFor] = useState(null);

    // const [filteredData, setFilteredData] = useState()

    // const PriceFooter = (props) => {
    //     const field = props.field || "";
    //     const total = process(data, dataState).data.reduce((acc, current) => acc + current[field], 0)
    //     const formatter = new Intl.NumberFormat('cs-CZ', { useGrouping: true });
    //     return (
    //         <td
    //             style={props.style} // this applies styles that lock the column at a specific position
    //             className={props.className + " right"} // this adds classes needed for locked columns
    //             colSpan={props.colSpan}
    //             role={"gridcell"}
    //             aria-colindex={props.ariaColumnIndex}
    //             aria-selected={props.isSelected}
    //         >
    //             {formatter.format(total)}
    //         </td>
    //     );
    // };

    const CountFooter = (props) => {
        const field = props.field || "";
        const total = process(data, dataState).data.length
        return (
            <td
                style={props.style} // this applies styles that lock the column at a specific position
                className={props.className} // this adds classes needed for locked columns
                colSpan={props.colSpan}
                role={"gridcell"}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
            >
                {total} záznamů
            </td>
        );
    };


    // const [data, setData] = useState(cashflow)



    useEffect(() => {
        loadAll()
    },[])


    //region editors and filters
    //
    // const useCurrencyEditor = props => (
    //     <DropDownEditor
    //         {...props}
    //         listData={currency}
    //         editable={true}
    //     />
    // );
    //
    // const CurrencyFilter = (props) => (
    //     <DropDownFilter
    //         {...props}
    //         data={currency}
    //         filterable={false}
    //         defaultItem={{ID: 0, Name: "All"}}
    //     />
    // );


    //
    // const StatusFilter = (props) => (
    //     <DropDownFilter
    //         {...props}
    //         data={cfStatus}
    //         filterable={false}
    //         defaultItem={{ID: 0, Name: "All"}}
    //     />
    // );
    //
    // const useCashflowTypeEditor = props => (
    //     <DropDownEditor
    //         {...props}
    //         listData={cfType}
    //         editable={true}
    //     />
    // );

    // const TypeFilter = (props) => (
    //     <DropDownFilter
    //         {...props}
    //         data={cfType}
    //         filterable={false}
    //         defaultItem={{ID: 0, Name: "All"}}
    //     />
    // );
    //
    // const usePriceEditor = props => (
    //     <PriceEditor
    //         {...props}
    //         editable={true}
    //     />
    // );



    //
    // const useClientEditor = props => (
    //     <DropDownEditor
    //         {...props}
    //         listData={clients}
    //         editable={true}
    //         filterable={true}
    //     />
    // );

    // const ClientFilter = (props) => (
    //     <DropDownFilter
    //         {...props}
    //         data={clients}
    //         filterable={true}
    //         defaultItem={{ID: 0, Name: "All"}}
    //     />
    // );


    // const projectColumns = [
    //     {
    //         field: "ID",
    //         header: "ID",
    //         width: "100px",
    //     },
    //     {
    //         field: "Name",
    //         header: "Name",
    //         width: "300px",
    //     },
    //     {
    //         field: "Tags",
    //         header: "Tags",
    //         width: "300px",
    //     },
    // ];
    // const useProjectEditor = props => (
    //     <DropDownEditor
    //         {...props}
    //         listData={projects}
    //         // columns={projectColumns}
    //         textField={"ClientProjectName"}
    //         editable={true}
    //         filterable={true}
    //     />
    // );

    // const ProjectFilter = (props) => (
    //     <DropDownFilter
    //         {...props}
    //         data={projects}
    //         textField={"ClientProjectName"}
    //         filterable={true}
    //         defaultItem={{ID: 0, Name: "All"}}
    //     />
    // );

    // const useColorCodedDateEditor = props => (
    //     <ColorCodedDateEditor
    //         {...props}
    //         colorPast = ""
    //         colorFuture = ""
    //     />
    // );

    // const useColorCodedDateEditor1 = props => (
    //     <ColorCodedDateEditor
    //         {...props}
    //         colorPast = "#DD0000"
    //         colorFuture = ""
    //     />
    // );
    //
    // const useUnitEditor = props => (
    //     <DropDownEditor
    //         {...props}
    //         listData={units}
    //         editable={true}
    //     />
    // );
    //
    // const UnitFilter = (props) => (
    //     <DropDownFilter
    //         {...props}
    //         data={units}
    //         filterable={true}
    //         defaultItem={{ID: 0, Name: "All"}}
    //     />
    // );

    //endregion


    //region edit functions

    const MyEditCommandCell = (props) => (
        <CommandCell
            {...props}
            edit={enterEdit}
            copy={enterCopy}
            showFiles={false}
            filesCount={props.dataItem.FilesCount}
        />
    );



    //
    // const handleCancelEdit = () => {
    //     setOpenForm(false);
    // };
    //
    // const cancel = (dataItem) => {
    //
    //     const originalItem = cashflow.find(
    //         (p) => p.ID === dataItem.ID
    //     );
    //     const newData = data.map((item) =>
    //         item.ID === originalItem.ID ? originalItem : item
    //     );
    //     setData(newData);
    //
    // };
    //
    // const discard = () => {
    //     discardNew()
    // };
    //
    // const update = dataItem => {
    //
    //     saveItem(dataItem)
    //     // APICall('POST', config.API_URL + 'client/' + dataItem.ID, dataItem)
    //     closeEdit(dataItem)
    //
    // }
    //
    // const add = (dataItem) => {
    //
    //     // APICall('PUT', config.API_URL + 'client/', dataItem)
    //
    //     closeEdit(dataItem)
    //     saveNew()
    //
    // };

    // const closeEdit = dataItem => {
    //     setData(
    //         data.map((item) =>
    //             item.ID === dataItem.ID
    //                 ? {
    //                     ...item,
    //                     inEdit: false,
    //                 }
    //                 : item
    //         )
    //     )
    // }



    //endregion

    //
    // const itemEdit = (dataItem) => {
    //     //debugger
    //     console.log("item edit")
    //     setData(
    //         data.map((item) =>
    //             item.ID === dataItem.ID
    //                 ? {
    //                     ...item,
    //                     inEdit: true,
    //                 }
    //                 : item
    //         )
    //     )
    //     //setEditID(dataItem.ProductID);
    // };

    const itemChange = (event) => {
        const inEditID = event.dataItem.ID;
        const field = event.field || "";
        const newData = data.map((item) =>
            item.ID === inEditID
                ? {
                    ...item,
                    [field]: event.value,
                }
                : item
        );
        setData(newData);
    };

    // const exitEdit = (dataItem) => {
    //     const newData = data.map((item) => ({
    //         ...item,
    //         [EDIT_FIELD]: undefined,
    //     }));
    //     setData(newData);
    //     //TODO doplnit ulozeni dat
    //     // APICall('POST', config.API_URL + 'price/', dataItem)
    //     setRefresh(Date.now())
    // };
    //
    // const customCellRender = (td, props) => (
    //     <CellEditCellRender
    //         originalProps={props}
    //         td={td}
    //         enterEdit={enterEdit}
    //         exitEdit={exitEdit}
    //         editField={EDIT_FIELD}
    //     />
    // );
    // const customRowRender = (tr, props) => (
    //     <CellEditRowRender
    //         originalProps={props}
    //         tr={tr}
    //         exitEdit={exitEdit}
    //         editField={EDIT_FIELD}
    //     />
    // );
    //
    // const toggleChart = () => {
    //     setChartVisible(!chartVisible);
    // };



    // ************************************** EDIT *************************************

    const rowDblClick = props => {
        enterEdit(props.dataItem)
    }

    const enterEdit = (dataItem) => {
        console.log("item edit")

        setEditItem(dataItem)
        setOpenForm(true)
    };

    const handleCancelEdit = (dataItem) => {

        setOpenForm(false);

    };



    const handleSubmit = (dataItem) => {
        //debugger
        if (dataItem.ID === undefined || dataItem.ID === null) {
            saveNew(dataItem)
        }
        else {
            saveItem(dataItem)
        }
        setOpenForm(false);
    };


    // ************************************** NEW *************************************

    const editNew = () => {
        const newItem = addNew //{};
        setEditItem(newItem)
        setOpenForm(true)
    };


    // ************************************** COPY *************************************


    const enterCopy = (dataItem) => {
        console.log("copy edit")

        const newItem = {
            ...dataItem,
            ID: null,
            UID: null,
        }

        setEditItem(newItem)
        setOpenForm(true)

        console.log("edit copy ... ", openForm, editItem)
    };


    // ************************************** Delete *************************************
    //
    // const deleteRow = (dataItem) => {
    //     setDeletedItem(dataItem)
    //     setShowConfirmDelete(true)
    // };
    //
    // const deleteRowAction = () => {
    //     deleteItem(deletedItem)
    //     setShowConfirmDelete(false)
    // };
    //
    // const cancelDelete = () => {
    //     setShowConfirmDelete(false)
    // };


    // ************************************** Files *************************************

    // const showFiles = (dataItem) => {
    //     setShowFilesFor(dataItem)
    //     setShowFilesDialog(true)
    // };
    //
    // const closeFiles = () => {
    //     refreshData()
    //     setShowFilesDialog(false)
    // };


    // ************************************** GRID *************************************




    return(
        <>

            {data &&


                <Grid
                    //pageable={true}
                    sortable={true}
                    filterable={true}
                    scrollable={"scrollable"} //virtual
                    resizable={true}
                    reorderable={true}
                    rowHeight={40}
                    onItemChange={itemChange}
                    // cellRender={customCellRender}
                    // rowRender={customRowRender}

                    editField={EDIT_FIELD}

                    style={{
                        height: "100%", //"calc(100% - 55px)", // (refSize.height - 150) / 2,//"500px",
                        minHeight: 20,
                        //maxHeight: 300, // refSize.height - 70,
                        width: refSize.width - menuSize,
                    }}
                    data={process(data, dataState)}{...dataState}

                    //onPageChange={pageChange}
                    onDataStateChange={(e) => setDataState(e.dataState)}
                    onRowDoubleClick={rowDblClick}

                >

                    <GridToolbar>

                        <button
                            title="Export to Excel"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={editNew}
                        >
                            Novy zaznam
                        </button>


                    </GridToolbar>

                    <Column field="ID" title="ID" width="80px" filterable={false} editable={false} locked />


                    <Column field="Name" minResizableWidth={80} title="Name" width={150} editable={true} locked />
                    <Column field="ShortName" minResizableWidth={80} title="Short Name" width={300} editable={true} />
                    <Column field="Color" minResizableWidth={80} title="Color" cell={ColorCell} width={300} editable={true} />

                    <Column field="Tags" minResizableWidth={120} title="Tagy" width={columnDefWidth}/>

                    <Column field="action" minResizableWidth={180}
                            cell={MyEditCommandCell}
                        // columnMenu={ActionCell}
                            filterable={false}
                            headerCell={ActionCell}
                            width="180"
                            resizable={true}
                            locked
                        // filterCell={ActionCell}
                    />

                </Grid>

            }



            {openForm && <EditUnit
                cancelEdit={handleCancelEdit}
                onSubmit={handleSubmit}
                item={editItem}
            />}


            {/*{isPending && <Waiting />}*/}

            {/*<div className="list-footer"></div>*/}


            {/*{openForm && <EditClient*/}
            {/*    cancelEdit={handleCancelEdit}*/}
            {/*    onSubmit={handleSubmit}*/}
            {/*    item={editItem}*/}
            {/*/>}*/}

        </>
    )
}

export default UnitGrid