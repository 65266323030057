import {create} from "zustand";
import axios from "axios";
import config from "../config/config.json";

const TaskStore = (set, get) => ({

    selectedItem: null,
    tasks: [],
    // expenseOriginal: [],
    isLoading: false,
    error: null,
    inAddMode: false,

    ClientID: 0,
    ProjectID: 0,
    UID: null,


    selectItem: (task) => set((state) => ({
        selectedItem: task
    })),

    setTasks: (newTasks) => {
        // debugger
        set({tasks: newTasks})
    },

    setFilter: (newFilter) => {
        set({filter: newFilter})
    },

    loadAll: async () => {
        // debugger

        if (get().ClientID != null || get().ProjectID != null) set({tasks:[]})
        set({ isLoading: true, error: null, ClientID: null, ProjectID: null, UID: null, inAddMode: false });

        try {
            const response = await axios.get(config.API_URL + 'task/', {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            const newData = data.map(item => ({...item, Taken: new Date(item.Taken), DueDate: new Date(item.DueDate)}))
            set({ tasks: newData, isLoading: false });

        } catch (error) {
            set({ error: error.message, isLoading: false });
        }
    },


    loadForClient: async (clientID) => {
        // debugger

        if (get().ClientID !== clientID) set({tasks:[]})
        set({ isLoading: true, error: null, ClientID: clientID, ProjectID: null, UID: null, inAddMode: false });

        try {
            const response = await axios.get(config.API_URL + 'task/client/' + clientID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            const newData = data.map(item => ({...item, Taken: new Date(item.Taken), DueDate: new Date(item.DueDate)}))
            set({ tasks: newData, isLoading: false });

        } catch (error) {
            set({ error: error.message, isLoading: false });
        }
    },


    loadForProject: async (projectID) => {
        // debugger

        if (get().ProjectID !== projectID) set({tasks:[]})
        set({ isLoading: true, error: null, ClientID: null, ProjectID: projectID, UID: null, inAddMode: false });

        try {
            const response = await axios.get(config.API_URL + 'task/project/' + projectID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            const newData = data.map(item => ({...item, Taken: new Date(item.Taken), DueDate: new Date(item.DueDate)}))
            set({ tasks: newData, isLoading: false });

        } catch (error) {
            set({ error: error.message, isLoading: false });
        }
    },


    loadForUID: async (UID) => {
        // debugger

        if (get().UID !== UID) set({tasks:[]})
        set({ isLoading: true, error: null, ClientID: null, ProjectID: null, UID: UID, inAddMode: false });

        try {
            const response = await axios.get(config.API_URL + 'task/uid/' + UID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            const newData = data.map(item => ({...item, Taken: new Date(item.Taken), DueDate: new Date(item.DueDate)}))
            set({ tasks: newData, isLoading: false });

        } catch (error) {
            set({ error: error.message, isLoading: false });
        }
    },


    addNew: () => {
        var newDataItem = {};
        // if (get().ProjectID !== 0) {
        newDataItem = {...newDataItem, ProjectID: get().ProjectID, ClientID: get().ClientID}
        // }

        return newDataItem
    },

    discardNew: () => {
        // debugger
        if (get().inAddMode) {

            set({inAddMode: false})

            const newData = [...get().expense];
            newData.splice(0, 1);

            set({tms: newData})

        }
    },

    saveNew: async (dataItem) => {
        // debugger

            try {
                const response = await axios.put(config.API_URL + 'task/', dataItem, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
                get().refreshData()
            } catch (error) {
                set({ error: error.message, loading: false });
            }

    },

    saveItem: async (dataItem) => {

        try {
            const response = await axios.post(config.API_URL + 'task/' + dataItem.ID, dataItem, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },

    deleteItem: async (dataItem) => {

        try {
            const response = await axios.delete(config.API_URL + 'task/' + dataItem.ID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            //set((state) => state.loadCashflowForClient(state.ClientID))
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },


    refreshData: () => {
        if (get().UID != null) set((state) => state.loadForUID(state.UID))
        else if (get().ProjectID > 0) set((state) => state.loadForProject(state.ProjectID))
        else if (get().ClientID > 0) set((state) => state.loadForClient(state.ClientID))
        else set((state) => state.loadAll())
    }

})

const useTaskStore = create(TaskStore)

export default useTaskStore