import * as React from "react";
import {Dialog, DialogActionsBar, Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import {TextBox, NumericTextBox, TextArea} from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {Error, Hint, Label} from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import FormDropDown from "../formEditComponents/FormDropDown";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import {isNumeric} from "@progress/kendo-data-query/dist/npm/utils";
import {DateInput, DatePicker} from "@progress/kendo-react-dateinputs";
import DropDownEditor from "../editComponents/DropDownEditor";
import FormTextBoxField from "../formEditComponents/FormTextBoxField";
import FormTextAreaField from "../formEditComponents/FormTextAreaField";
import FormNumBoxField from "../formEditComponents/FormNumBoxField";
import FormDateField from "../formEditComponents/FormDateField";
import {useState} from "react";
import axios from "axios";

const minValueValidator = value => value >= 0 ? "" : "The value must be 0 or higher";


const mandatoryFieldValidator = value => value != null ? "" : "mandatory field"


const useProjectEditor = props => (
    <FormDropDown
        {...props}
        textField={"ClientProjectName"}
    />
);


const EditCashflow = props => {

    const icons = {
        '.pdf':'k-i-pdf',
        '.doc':'k-i-doc',
        '.docx':'k-i-doc',
        '.xls':'k-i-xls',
        '.xlsx':'k-i-doc',
        '.txt':'k-i-txt',
        '.csv':'k-i-csv',
        '.exe':'k-i-exe',
        '.psd':'k-i-psd',
        '.ppt':'k-i-ppt',
        '.pptx':'k-i-ppt',
        '.avi':'k-i-film',
        '.mpg':'k-i-film',
        '.mp4':'k-i-film',
        '.mpeg':'k-i-film',
        '.jpg':'k-i-image',
        '.jpeg':'k-i-image',
        '.png':'k-i-image',
        '.bmp':'k-i-image',
        '.tiff':'k-i-image',
    }

    const {
        cancelEdit,
        onSubmit,
        item,
        cfTypes,
        cfStatuses,
        counterParties,
        projects,
        currencies,
        units,
        ...other
    } = props;

    // item.UnitID = units.find((c) => c.ID === item.UnitID)
    // item.PartnerID = partners.find((c) => c.ID === item.PartnerID)

    const [refNum, setRefNum] = useState(0)
    const {data:files, isPending, error} = useFetch(config.API_URL + 'file/' + item.UID, refNum)

    const handleSubmit = dataItem => {
         debugger
        if (!isNumeric(dataItem.CashflowTypeID)) dataItem.CashflowTypeID = dataItem.CashflowTypeID.ID
        if (!isNumeric(dataItem.CashflowStatusID)) dataItem.CashflowStatusID = dataItem.CashflowStatusID.ID
        if (dataItem.CounterPartyID != null && !isNumeric(dataItem.CounterPartyID)) dataItem.CounterPartyID = dataItem.CounterPartyID.ID
        if (dataItem.ProjectID != null && !isNumeric(dataItem.ProjectID)) dataItem.ProjectID = dataItem.ProjectID.ID
        if (!isNumeric(dataItem.UnitID)) dataItem.UnitID = dataItem.UnitID.ID
        if (!isNumeric(dataItem.CurrencyID)) dataItem.CurrencyID = dataItem.CurrencyID.ID
        onSubmit(dataItem)
    }

    var title = `Edit cashflow: ${item.ID}`
    if (item.ID === null) {
        title = "Copy cashflow"
    }

    if (item.ID === undefined) {
        title = "Create new cashflow"
    }


    const  removeFile  = async file => {
        try {
            const response = await axios.delete(config.API_URL + 'file/' + file.ID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            //set((state) => state.loadCashflowForClient(state.ClientID))
            setRefNum(refNum + 1)
        } catch (error) {

        }
    }

    // const { data:partners, isPending, error} = useFetch(config.API_URL + 'partner/', 1)

    return <Form initialValues={item} onSubmit={handleSubmit} render={renderProps =>

        <Window title={title} onClose={cancelEdit}
                initialHeight={850}
                initialWidth={600}
                modal={true}
        >
            <div className="editForm">
                <FormElement horizontal={true}>


                    {cfTypes &&
                        <FieldWrapper><Field name={"CashflowTypeID"} label={"Typ"} textField={"Name"} dataItemKey={"id"}
                                             component={FormDropDown} listData={cfTypes}
                                             validator={mandatoryFieldValidator}/></FieldWrapper>}
                    {cfStatuses && <FieldWrapper><Field name={"CashflowStatusID"} label={"Status"} textField={"Name"}
                                                        dataItemKey={"id"} component={FormDropDown}
                                                        listData={cfStatuses}
                                                        validator={mandatoryFieldValidator}/></FieldWrapper>}
                    {counterParties && <FieldWrapper><Field name={"CounterPartyID"} label={"Partner"} textField={"Name"}
                                                            dataItemKey={"id"} component={FormDropDown}
                                                            listData={counterParties}
                                                            validator={mandatoryFieldValidator}/></FieldWrapper>}
                    {projects &&
                        <FieldWrapper><Field name={"ProjectID"} label={"Project"} textField={"Name"} dataItemKey={"id"}
                                             component={useProjectEditor} listData={projects}/></FieldWrapper>}
                    {units &&
                        <FieldWrapper><Field name={"UnitID"} label={"Stredisko"} textField={"Name"} dataItemKey={"id"}
                                             component={FormDropDown} listData={units}
                                             validator={mandatoryFieldValidator}/></FieldWrapper>}


                    <FieldWrapper><Field name={"Number"} component={FormTextBoxField} label={"Cislo"}/></FieldWrapper>
                    <FieldWrapper><Field name={"Text"} component={FormTextBoxField} label={"Text"}/></FieldWrapper>
                    <FieldWrapper><Field name={"Note"} component={FormTextAreaField} label={"Poznamka"}/></FieldWrapper>
                    <FieldWrapper><Field name={"Price"} component={FormNumBoxField} label={"Cena"}/></FieldWrapper>
                    <FieldWrapper><Field name={"PriceVat"} component={FormNumBoxField}
                                         label={"Cena s DPH"}/></FieldWrapper>

                    {currencies &&
                        <FieldWrapper><Field name={"CurrencyID"} label={"Mena"} textField={"Name"} dataItemKey={"id"}
                                             component={FormDropDown} listData={currencies}
                                             validator={mandatoryFieldValidator}/></FieldWrapper>}

                    <FieldWrapper><Field name={"Date"} component={FormDateField} label={"Vystaveni"}
                                         validator={mandatoryFieldValidator}/></FieldWrapper>
                    <FieldWrapper><Field name={"Maturity"} component={FormDateField} label={"Splatnost"}
                                         validator={mandatoryFieldValidator}/></FieldWrapper>

                    <FieldWrapper><Field name={"Tags"} component={FormTextBoxField} label={"Tagy"}/></FieldWrapper>


                    {/*<FieldWrapper>*/}
                    {/*    <Field name={"UnitPrice"} component={NonNegativeNumericInput} label={"Price"} validator={minValueValidator} />*/}
                    {/*</FieldWrapper>*/}
                    {/*<FieldWrapper>*/}
                    {/*    <Field name={"UnitsInStock"} component={NonNegativeNumericInput} label={"In stock"} validator={minValueValidator} />*/}
                    {/*</FieldWrapper>*/}
                </FormElement>
            </div>

            <div className="file-list" style={{height: 'calc(100% - 750px)'}}>
                {files && <>

                    {files.map(f => (
                        <div key={f.ID} className="file-row">
                            <a className="file-row-a" href={config.FILE_URL + f.RelPath} target="_blank"><span
                                className={"k-icon " + (icons[f.Type.toLowerCase()] || "k-i-file")}
                                style={{fontSize: 50 + 'px'}}/>
                                <span className="file-row-name">{f.Name}</span>
                                <span
                                    className="file-row-info">{new Date(f.CreatedAt).toLocaleString('cz-CZ')}, {f.Size} B</span></a>
                            {/*<span className="file-row-remove">*/}

                            {/*    <span className="k-icon k-i-x-circle red" style={{fontSize: 25 + 'px'}}*/}
                            {/*          onClick={() => removeFile(f)}/>*/}

                            {/*</span>*/}

                        </div>
                    ))}

                </>}
            </div>

            <WindowActionsBar layout="start">
                <Button type={"submit"} themeColor={"primary"} disabled={!renderProps.allowSubmit}
                        onClick={renderProps.onSubmit} icon="save">
                    Update
                </Button>
                <Button onClick={cancelEdit} icon="cancel">
                    Cancel
                </Button>
            </WindowActionsBar>

        </Window>} {...other} />;
};
export default EditCashflow;