import {Button} from "@progress/kendo-react-buttons";

const ProductClientInGrid = props => {

    const pc = props.pc

    return (

        <>
            <div onDoubleClick={props.edit}>
                <div className="product-client-ingrig" style={{backgroundColor: pc.StatusColor}}>

                    <span className="product-client-ingrig-productname" style={{backgroundColor: pc.ProductStatusColor}}>{pc.Name}</span>
                    {(pc.ProductStatusName.toLowerCase() !== "active") && <span className="product-client-ingrig-productstatus"
                                                                  >{pc.ProductStatusName}</span>}
                    {/*<span className="product-client-ingrig-statusName">{pc.StatusName}</span>*/}
                    <span className="product-client-ingrig-note">{pc.Note}</span>
                    {/*<span className="product-client-ingrig-statusName">{pc.StatusName}</span>*/}
                    {pc.Pricing && <span className="product-client-ingrig-pricing">{pc.Pricing}</span>}

                    <span className="product-client-ingrig-files">

                        {pc.UID && <Button themeColor={"base"} fillMode={"flat"}
                                 className1="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
                                 onClick={props.showFiles}>
                            <span className="k-icon k-i-clip"/>
                            {pc.FilesCount > 0 &&
                                <span className={"files-count"} style={{fontWeight: "normal", color: "red"}}>{pc.FilesCount}</span>}
                        </Button>}

                    </span>


                </div>
            </div>
        </>
    );
}

export default ProductClientInGrid