import {TileLayout} from "@progress/kendo-react-layout";
import {useState} from "react";
import ProjectDetailInfo from "./ProjectDetailInfo";
import useClientStore from "../../stores/ClientStore";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import useProjectStore from "../../stores/ProjectStore";
import ProjectDetailGraph from "./ProjectDetailGraph";
import ProjectDetailSummary from "./ProjectDetailSummary";

const styles = {
    fontSize: 24,
    textAlign: "center",
    margin: "auto",
    userSelect: "none",
};



const ProjectDetail = props => {

    const {selectedClient} = useClientStore((state) => ({selectedClient: state.selectedClient}))
    const {selectedProject} = useProjectStore((state) => ({selectedProject: state.selectedItem}))

    const { data:summaryData, isPending:summaryData_IsPending, error:summaryData_error} = useFetch(config.API_URL + 'tms/project_summary/' + selectedProject.ID, 0)
    const { data:chartData, isPending, error} = useFetch(config.API_URL + 'tms/project_graph/' + selectedProject.ID, 0)
    //const { data:statuses, statuses_isPending, statuses_error} = useFetch(config.API_URL + 'lists/project_status', 0)
    // const { data:projects, isPending:projects_IsPending, error:projects_error} = useFetch(config.API_URL + 'project/forClient/' + selectedClient.ID, 0)


    const tiles = [
        {
            header: "Detail projektu",
            body: <>{<ProjectDetailInfo project={selectedProject}/>}</>,
            resizable: false,
            reorderable: false,
        },

        {
            header: "Přehled",
            body: <>{summaryData && <ProjectDetailSummary data={summaryData}/>}</>,
            resizable: false,
            reorderable: false,
        },
        {
            header: "graf",
            body: <>{chartData && <ProjectDetailGraph data={chartData} projects={selectedProject}/>}</>,
            resizable: false,
            reorderable: false,
        },

    ];

    const [data, setData] = useState([
        {
            col: 1,
            colSpan: 2,
            rowSpan: 10,
        },
        {
            col: 3,
            colSpan: 2,
            rowSpan: 10,
        },
        {
            col: 5,
            colSpan: 6,
            rowSpan: 10,
        },

    ]);

    const handleReposition = (e) => {
        setData(e.value);
        console.log(e.value);
    };

    return(
        <>

            <div className="client-detail">

                <TileLayout
                    columns={10}
                    // rows={10}
                    items={tiles}
                    positions={data}
                    onReposition={handleReposition}
                />

            </div>


        </>
    )
}

export default ProjectDetail