import InvoiceRecapGrid from "./InvoiceRecapGrid";
import {StackLayout} from "@progress/kendo-react-layout";
import InvoiceItemInGrid from "./InvoiceItemInGrid";
import InvoiceItems from "./IvoiceItems";

const InvoiceRecap = props => {


    const tags = props.tags
    const invoiceNum = props.invoiceNum
    const includeNoNB = props.includeNoNB
    const setAsInvoiced = props.setAsInvoiced
    const createCashFlow = props.createCashFlow
    const period = props.period
    const periodFrom = props.periodFrom
    const periodTo = props.periodTo





    return(
        <>

            <div className="invoice-dialog-recap">

                {/*<StackLayout*/}
                {/*    style={{*/}
                {/*        height: "100%",*/}
                {/*    }}*/}
                {/*    orientation={"vertical"}*/}
                {/*    gap={0}*/}
                {/*    */}
                {/*>*/}

                    <div className="invoice-dialog-recap-recap">

                        {/*<div className="invoice-dialog-recap-recap-row">*/}
                        {/*    <span className="invoice-dialog-recap-recap-row-caption">Tags:</span>*/}
                        {/*    <span className="invoice-dialog-recap-recap-row-value">{tags}</span>*/}
                        {/*</div>*/}

                        <div className="invoice-dialog-recap-recap-row">
                            <span className="invoice-dialog-recap-recap-row-caption">Inv. Num:</span>
                            <span className="invoice-dialog-recap-recap-row-value">{invoiceNum}</span>
                        </div>

                        <div className="invoice-dialog-recap-recap-row">
                            <span className="invoice-dialog-recap-recap-row-caption">Non-billable:</span>
                            <span className="invoice-dialog-recap-recap-row-value">{includeNoNB.text}</span>
                        </div>

                        <div className="invoice-dialog-recap-recap-row">
                            <span className="invoice-dialog-recap-recap-row-caption">Set as Invoiced:</span>
                            <span className="invoice-dialog-recap-recap-row-value">{setAsInvoiced.text}</span>
                        </div>

                        <div className="invoice-dialog-recap-recap-row">
                            <span className="invoice-dialog-recap-recap-row-caption">Create Cashflow:</span>
                            <span className="invoice-dialog-recap-recap-row-value">{createCashFlow.text}</span>
                        </div>

                        <div className="invoice-dialog-recap-recap-row">
                            <span className="invoice-dialog-recap-recap-row-caption">Period:</span>
                            <span className="invoice-dialog-recap-recap-row-value">{period.text}</span>
                        </div>

                    </div>

                    {/*<InvoiceRecapGrid {...props} />*/}
                    <InvoiceItems {...props} />

                {/*</StackLayout>*/}

            </div>

        </>
    )
}

export default InvoiceRecap