import {process} from "@progress/kendo-data-query";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {Grid, GridToolbar} from "@progress/kendo-react-grid";
import {useEffect, useRef, useState} from "react";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import DropDownEditor from "../editComponents/DropDownEditor";
import {CellEditCellRender, CellEditRowRender} from "../gridRenders/CellEditRenders";
import useClientStore from "../../stores/ClientStore";
import useCashflowStore from "../../stores/CashflowStore";
import DropDownFilter from "../filterComponents/DropDownFilter";
import PriceEditor from "../editComponents/PriceEditor";
import {Window} from "@progress/kendo-react-dialogs";
import {StackLayout} from "@progress/kendo-react-layout";
import CashflowGraph from "./CashflowGraph";
import EditCashflow from "./EditCashflow";
import EditCommandCell from "../editComponents/EditCommandCell";
import CommandCell from "../tableComponents/CommandCell";
import Confirm from "../general/Confirm";
import ColorCodedDateEditor from "../editComponents/ColorCodedDateEditor";
import FileDialog from "../files/FileDialog";
import useProjectStore from "../../stores/ProjectStore";
import Waiting from "../waiting/Waiting";
import useTmsStore from "../../stores/TmsStore";
import DummyUpload from "./DummyUpload";
import {ToolbarSeparator, ToolbarSpacer} from "@progress/kendo-react-buttons";
import NotesInDialog from "../notes/NotesInDialog";
import DetailsCell from "../tableComponents/DetailsCell";

const EDIT_FIELD = "inEdit";

const ActionCell = (props) => {
    return(
        <>
            {/*<Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"*/}
            {/*        size="small"*/}
            {/*        rounded="small"*/}
            {/*        onClick={() => props.enterEdit(props.dataItem)}*/}
            {/*>Novy klient</Button>*/}
            {/*<span className={"table-header-action"}>Nový klient</span>*/}
            {/*<span className={"table-header-action"}>aaa</span>*/}
        </>
    )
}

const initialDataState = {
    sort:[
        {dir: "asc", field: "Maturity" }
        ],
};


const initialDataStateWithFilter = {
    sort:[
        {dir: "asc", field: "Maturity" }
    ],
    filter: {
        logic: 'and',
        filters: [
            { field: 'CashflowStatusID', operator: 'eq', value: 1 }
        ]
    }
};





const CashFlowGrid = (props) => {

    // const refresh = props.refresh
    const {selectedClient} = useClientStore((state) => ({selectedClient: state.selectedClient}))
    const {selectedProject} = useProjectStore((state) => ({selectedProject: state.selectedItem}))
    // const selectedProject = props.selectedProject

    const refSize = props.size
    const menuSize = props.menuSize
    const dataType = props.dataType || "all"

    console.log("cashflow grid size", refSize, menuSize)

    const exportData = props.setData

    const initDataState = (dataType === "all") ? initialDataStateWithFilter : initialDataState

    const [dataState, setDataState] = useState(initDataState);
    const [refresh, setRefresh] = useState(Date.now())


    let columnDefWidth = 100
    // let columnDefWidth = refSize.width * 0.1
    // if (columnDefWidth < 120) columnDefWidth = 120


    // let apiURL;
    // if (selectedProject) apiURL = config.API_URL + 'cashflow/project/' + selectedProject.ID
    // else if (selectedClient) apiURL = config.API_URL + 'cashflow/client/' + selectedClient.ID
    // else apiURL = config.API_URL + 'cashflow/'

    // const { data:cashflow, isPending, error} = useFetch(apiURL, refresh)

    const data = useCashflowStore(state => state.cashflow)
    const isLoading = useCashflowStore(state => state.isLoading)
    const setData = useCashflowStore(state => state.setCashflow)
    const loadCashflowForClient = useCashflowStore(state => state.loadCashflowForClient)
    const loadCashflowForProject = useCashflowStore(state => state.loadCashflowForProject)
    const loadCashflowAll = useCashflowStore(state => state.loadCashflowAll)



    const cashflow = useCashflowStore(state => state.cashflowOriginal)
    const addNew = useCashflowStore(state => state.addNew)
    const discardNew = useCashflowStore(state => state.discardNew)
    const saveNew = useCashflowStore(state => state.saveNew)
    const saveItem = useCashflowStore(state => state.saveItem)
    const deleteItem = useCashflowStore(state => state.deleteItem)
    const refreshData = useCashflowStore(state => state.refreshData)



    const { data:currency, isPending:currency_isPending, error:currency_error} = useFetch(config.API_URL + 'lists/currency/', refresh)
    const { data:units, isPending:units_isPending, error:units_error} = useFetch(config.API_URL + 'lists/unit/', refresh)
    const { data:projects, isPending:projects_isPending, error:projects_error} = useFetch(config.API_URL + 'project/for_lists', refresh)
    const { data:cfType, isPending:cfType_isPending, error:ccfType_error} = useFetch(config.API_URL + 'lists/cashflow_type/', refresh)
    const { data:cfStatus, isPending:cfStatus_isPending, error:cfStatus_error} = useFetch(config.API_URL + 'lists/cashflow_status/', refresh)
    //const { data:clients, isPending:clients_isPending, error:clients_error} = useFetch(config.API_URL + 'client/', refresh)
    const clients = useClientStore(state => state.clients)
    // const projects = useProjectStore(state => state.storeData)

    const [openForm, setOpenForm] = useState(false);
    const [editItem, setEditItem] = useState(null);

    const [chartVisible, setChartVisible] = useState(false);

    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [deletedItem, setDeletedItem] = useState(null);

    const [showFilesDialog, setShowFilesDialog] = useState(false)
    const [showFilesFor, setShowFilesFor] = useState(null);

    // const [filteredData, setFilteredData] = useState()

    const PriceFooter = (props) => {
        const field = props.field || "";
        const total = process(data, dataState).data.reduce((acc, current) => acc + current[field], 0)
        const formatter = new Intl.NumberFormat('cs-CZ', { useGrouping: true });
        return (
            <td
                style={props.style} // this applies styles that lock the column at a specific position
                className={props.className + " right"} // this adds classes needed for locked columns
                colSpan={props.colSpan}
                role={"gridcell"}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
            >
                {formatter.format(total)}
            </td>
        );
    };

    const CountFooter = (props) => {
        const field = props.field || "";
        const total = process(data, dataState).data.length
        return (
            <td
                style={props.style} // this applies styles that lock the column at a specific position
                className={props.className} // this adds classes needed for locked columns
                colSpan={props.colSpan}
                role={"gridcell"}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
            >
                {total} záznamů
            </td>
        );
    };


    // const [data, setData] = useState(cashflow)



    useEffect(() => {
        switch (dataType) {
            case "client":
                loadCashflowForClient(selectedClient.ID)
                break
            case "project":
                loadCashflowForProject(selectedProject.ID)
                break
            default:
                loadCashflowAll()
        }
    },[selectedClient, selectedProject])

    useEffect(() => {
        if (exportData) exportData(process(data, dataState))
    },[dataState, data])


    // const gridRef = useRef(null);
    //
    // useEffect(() => {
    //     console.log("filter use effect")
    //     if (gridRef.current) {
    //         console.log("filter use effect inner")
    //         gridRef.current.filter([
    //             { field: 'CashflowStatusID', operator: 'eq', value: '1' }
    //         ]);
    //     }
    // }, []);


    //region editors and filters

    const useCurrencyEditor = props => (
        <DropDownEditor
            {...props}
            listData={currency}
            editable={true}
        />
    );

    const CurrencyFilter = (props) => (
        <DropDownFilter
            {...props}
            data={currency}
            filterable={false}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );

    const useCashflowStatusEditor = props => (
        <DropDownEditor
            {...props}
            listData={cfStatus}
            editable={true}
        />
    );

    const StatusFilter = (props) => {
        console.log("datastate ... ", dataState)
        return(
        <DropDownFilter
            {...props}
            data={cfStatus}
            filterable={false}
            defaultItem={{ID: 0, Name: "All"}}
        />
    )};

    const useCashflowTypeEditor = props => (
        <DropDownEditor
            {...props}
            listData={cfType}
            editable={true}
        />
    );

    const TypeFilter = (props) => (
        <DropDownFilter
            {...props}
            data={cfType}
            filterable={false}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );

    const usePriceEditor = props => (
        <PriceEditor
            {...props}
            editable={true}
        />
    );




    const useClientEditor = props => (
        <DropDownEditor
            {...props}
            listData={clients}
            editable={true}
            filterable={true}
        />
    );

    const ClientFilter = (props) => (
        <DropDownFilter
            {...props}
            data={clients}
            filterable={true}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );


    const projectColumns = [
        {
            field: "ID",
            header: "ID",
            width: "100px",
        },
        {
            field: "Name",
            header: "Name",
            width: "300px",
        },
        {
            field: "Tags",
            header: "Tags",
            width: "300px",
        },
    ];
    const useProjectEditor = props => (
        <DropDownEditor
            {...props}
            listData={projects}
            // columns={projectColumns}
            textField={"ClientProjectName"}
            editable={true}
            filterable={true}
        />
    );

    const ProjectFilter = (props) => (
        <DropDownFilter
            {...props}
            data={projects}
            textField={"ClientProjectName"}
            filterable={true}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );

    const useColorCodedDateEditor = props => (
        <ColorCodedDateEditor
            {...props}
            colorPast = ""
            colorFuture = ""
        />
    );

    const useColorCodedDateEditor1 = props => (
        <ColorCodedDateEditor
            {...props}
            colorPast = "#DD0000"
            colorFuture = ""
        />
    );

    const useUnitEditor = props => (
        <DropDownEditor
            {...props}
            listData={units}
            editable={true}
        />
    );

    const UnitFilter = (props) => (
        <DropDownFilter
            {...props}
            data={units}
            filterable={true}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );

    //endregion


    //region edit functions

    const MyEditCommandCell = (props) => (
        <CommandCell
            {...props}
            edit={enterEdit}
            copy={enterCopy}
            delete={deleteRow}
            showFiles={false}
            filesCount={props.dataItem.FilesCount}
        />
    );

    const MyDetailsCell = (props) => (
        <DetailsCell
            {...props}
            showFiles={showFiles}
            filesCount={props.dataItem.FilesCount}
            showNotes={showNotes}
            notesCount={props.dataItem.NotesCount}
        />
    );



    //
    // const handleCancelEdit = () => {
    //     setOpenForm(false);
    // };

    const cancel = (dataItem) => {

        const originalItem = cashflow.find(
            (p) => p.ID === dataItem.ID
        );
        const newData = data.map((item) =>
            item.ID === originalItem.ID ? originalItem : item
        );
        setData(newData);

    };

    const discard = () => {
        discardNew()
    };

    const update = dataItem => {

        saveItem(dataItem)
        // APICall('POST', config.API_URL + 'client/' + dataItem.ID, dataItem)
        closeEdit(dataItem)

    }

    const add = (dataItem) => {

        // APICall('PUT', config.API_URL + 'client/', dataItem)

        closeEdit(dataItem)
        saveNew()

    };

    const closeEdit = dataItem => {
        setData(
            data.map((item) =>
                item.ID === dataItem.ID
                    ? {
                        ...item,
                        inEdit: false,
                    }
                    : item
            )
        )
    }



    //endregion


    const itemEdit = (dataItem) => {
        //debugger
        console.log("item edit")
        setData(
            data.map((item) =>
                item.ID === dataItem.ID
                    ? {
                        ...item,
                        inEdit: true,
                    }
                    : item
            )
        )
        //setEditID(dataItem.ProductID);
    };

    const itemChange = (event) => {
        const inEditID = event.dataItem.ID;
        const field = event.field || "";
        const newData = data.map((item) =>
            item.ID === inEditID
                ? {
                    ...item,
                    [field]: event.value,
                }
                : item
        );
        setData(newData);
    };

    const exitEdit = (dataItem) => {
        const newData = data.map((item) => ({
            ...item,
            [EDIT_FIELD]: undefined,
        }));
        setData(newData);
        //TODO doplnit ulozeni dat
        // APICall('POST', config.API_URL + 'price/', dataItem)
        setRefresh(Date.now())
    };

    const customCellRender = (td, props) => (
        <CellEditCellRender
            originalProps={props}
            td={td}
            enterEdit={enterEdit}
            exitEdit={exitEdit}
            editField={EDIT_FIELD}
        />
    );
    const customRowRender = (tr, props) => (
        <CellEditRowRender
            originalProps={props}
            tr={tr}
            exitEdit={exitEdit}
            editField={EDIT_FIELD}
        />
    );

    const toggleChart = () => {
        setChartVisible(!chartVisible);
    };



    // ************************************** EDIT *************************************

    const rowDblClick = props => {
        console.log(dataState)
        enterEdit(props.dataItem)
    }

    const enterEdit = (dataItem) => {
        console.log("item edit")

        setEditItem(dataItem)
        setOpenForm(true)
    };

    const handleCancelEdit = (dataItem) => {

        setOpenForm(false);

    };



    const handleSubmit = (dataItem) => {
        //debugger
        if (dataItem.ID === undefined || dataItem.ID === null) {
            saveNew(dataItem)
        }
        else {
            saveItem(dataItem)
        }
        setOpenForm(false);
    };


    // ************************************** NEW *************************************

    const editNew = () => {
        const newItem = addNew //{};
        setEditItem(newItem)
        setOpenForm(true)
    };


    // ************************************** COPY *************************************


    const enterCopy = (dataItem) => {
        console.log("copy edit")

        const newItem = {
            ...dataItem,
            ID: null,
            UID: null,
        }

        setEditItem(newItem)
        setOpenForm(true)

        console.log("edit copy ... ", openForm, editItem)
    };


    // ************************************** Delete *************************************

    const deleteRow = (dataItem) => {
        setDeletedItem(dataItem)
        setShowConfirmDelete(true)
    };

    const deleteRowAction = () => {
        deleteItem(deletedItem)
        setShowConfirmDelete(false)
    };

    const cancelDelete = () => {
        setShowConfirmDelete(false)
    };


    // ************************************** Files *************************************

    const showFiles = (dataItem) => {
        setShowFilesFor(dataItem)
        setShowFilesDialog(true)
    };

    const closeFiles = () => {
        refreshData()
        setShowFilesDialog(false)
    };


    // ************************************** Notes *************************************

    const [showNotesFor, setShowNotesFor] = useState(null);
    const showNotes = (dataItem) => {
        // debugger
        setShowNotesFor(dataItem)
    };

    const closeNotes = () => {
        refreshData()
        setShowNotesFor(null)
    };

    // ************************************** GRID *************************************




    return(
        <>

            {data && currency && clients && cfStatus && cfType && projects && units &&


                <Grid
                    //pageable={true}
                    // ref={gridRef}

                    sortable={true}
                    filterable={true}
                    scrollable={"scrollable"} //virtual
                    resizable={true}
                    reorderable={true}
                    //rowHeight={40}
                    onItemChange={itemChange}
                    // cellRender={customCellRender}
                    // rowRender={customRowRender}
                    filter={dataState.filter}

                    editField={EDIT_FIELD}
                    size={"small"}

                    style={{
                        height: "100%", //"calc(100% - 55px)", // (refSize.height - 150) / 2,//"500px",
                        minHeight: 20,
                        //maxHeight: 300, // refSize.height - 70,
                        width: refSize.width - menuSize,
                    }}
                    data={process(data, dataState)}{...dataState}

                    //onPageChange={pageChange}
                    onDataStateChange={(e) => setDataState(e.dataState)}
                    onRowDoubleClick={rowDblClick}

                >

                    <GridToolbar>

                        <button
                            title="Export to Excel"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={editNew}
                        >
                            Novy zaznam
                        </button>

                        <button
                            title="Export to Excel"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={toggleChart}
                        >
                            graf
                        </button>

                        {/*<ToolbarSpacer />*/}
                        <ToolbarSeparator />

                        <DummyUpload refreshData={refreshData} />

                    </GridToolbar>

                    <Column field="ID" title="ID" width="80px" filterable={false} editable={false} locked />



                    <Column field="CashflowStatusID" minResizableWidth={80} title="Status" cell={useCashflowStatusEditor} width={150} filterCell={StatusFilter} locked />
                    <Column field="CashflowTypeID" minResizableWidth={80} title="Typ" cell={useCashflowTypeEditor} width={150} filterCell={TypeFilter} locked />



                    <Column field="CounterPartyID" minResizableWidth={120} title="Protistrana" cell={useClientEditor} footerCell={CountFooter}
                            width={200} filterCell={ClientFilter} locked />

                    <Column field="ProjectID" minResizableWidth={120} title="Projekt" cell={useProjectEditor}
                            width={200} filterCell={ProjectFilter} />

                    <Column field="UnitID" minResizableWidth={120} title="Středisko" cell={useUnitEditor}
                            width={200} filterCell={UnitFilter} />

                    <Column field="Number" minResizableWidth={80} title="Cislo" width={150} editable={true} />
                    <Column field="Text" minResizableWidth={80} title="Text" width={300} editable={true} />

                    <Column field="Date" minResizableWidth={100} title="Datum" width={120} cell={useColorCodedDateEditor}
                            filter="date"
                            format="{0:dd.MM.yyyy}"
                            editor="date"
                        //cell={DateCell}
                    />

                    <Column field="Maturity" minResizableWidth={100} title="Splatnost" width={120} cell={useColorCodedDateEditor1}
                            filter="date"
                            format="{0:dd.MM.yyyy}"
                            editor="date"
                        //cell={DateCell}
                    />

                    <Column title={"Original"}>
                        <Column field="Price" minResizableWidth={80} title="Price" width={columnDefWidth} cell={PriceEditor} filterable={false} />
                        <Column field="PriceVat" minResizableWidth={80} title="Price VAT" width={columnDefWidth} cell={PriceEditor} editor={"numeric"} filterable={false} />
                        <Column field="CurrencyID" minResizableWidth={80} title="Měna" cell={useCurrencyEditor} width={columnDefWidth} filterCell={CurrencyFilter}/>
                    </Column>

                    <Column title={"In CZK"} >
                        <Column field="PriceCZK" minResizableWidth={80} title="Price" width={columnDefWidth} editable={false} cell={PriceEditor} filterable={false} footerCell={PriceFooter} />
                        <Column field="PriceCZKVAT" minResizableWidth={80} title="Price VAT" width={columnDefWidth} editable={false} cell={PriceEditor} filterable={false} footerCell={PriceFooter}  />
                        <Column field="CZKVAT" minResizableWidth={80} title="VAT" width={columnDefWidth} editable={false} cell={PriceEditor} filterable={false} footerCell={PriceFooter}  />
                    </Column>



                    <Column field="Note" minResizableWidth={80} title="Note" width={300} editable={true} />
                    <Column field="Tags" minResizableWidth={120} title="Tagy" width={columnDefWidth}/>

                    <Column field="details" minResizableWidth={120}
                            cell={MyDetailsCell}
                            filterable={false}
                            headerCell={ActionCell}
                            width="120"
                            resizable={true}
                            locked
                    />

                    <Column field="action" minResizableWidth={100}
                            cell={MyEditCommandCell}
                        // columnMenu={ActionCell}
                            filterable={false}
                            headerCell={ActionCell}
                            width="100"
                            resizable={true}
                            locked
                        // filterCell={ActionCell}
                    />

                </Grid>

            }

            {chartVisible && <Window title={"Graf"} onClose={toggleChart} initialHeight={1350} initialWidth={2048}>

                <StackLayout
                    style={{
                        height: "100%",
                    }}
                    orientation={"vertical"}
                    gap={0}
                >

                    <>

                        {data && <CashflowGraph data={process(data, dataState)}/>}

                    </>

                </StackLayout>

                {/*{chartData && <CashflowGraph data={chartData}/>}*/}
            </Window>}

            {openForm && <EditCashflow
                cfTypes={cfType}
                cfStatuses={cfStatus}
                counterParties={clients}
                projects={projects}
                currencies={currency}
                units={units}
                cancelEdit={handleCancelEdit}
                onSubmit={handleSubmit}
                item={editItem}
            />}


            {showConfirmDelete && <Confirm
                action={deleteRowAction}
                cancel={cancelDelete}
                text={"delete item?"}
            />}


            {showFilesDialog && <FileDialog
                close={closeFiles}
                text={"delete item?"}
                filesFor={showFilesFor}
            />}

            {showNotesFor && <NotesInDialog
                close={closeNotes}
                notesFor={showNotesFor}
            />}

            {isLoading && <Waiting />}

            {/*<div className="list-footer"></div>*/}


            {/*{openForm && <EditClient*/}
            {/*    cancelEdit={handleCancelEdit}*/}
            {/*    onSubmit={handleSubmit}*/}
            {/*    item={editItem}*/}
            {/*/>}*/}

        </>
    )
}

export default CashFlowGrid