import {Button} from "@progress/kendo-react-buttons";
import * as React from "react";

const Header = (props) => {

    const handleClick = props.handleClick
    const section = props.section

    return (
        <>

            <div className="custom-toolbar">

                <span className="k-icon k-i-menu app-name-icon" onClick={handleClick}></span>
                <span className="app-name" onClick={handleClick}>HSBN Billing</span>
                <span className="app-name-section">{section}</span>

            </div>


        </>
    )
}

export default Header