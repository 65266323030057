import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import {Button} from "@progress/kendo-react-buttons";
import {useState} from "react";
import {Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import Invoice from "../invoice/Invoice";
import useClientStore from "../../stores/ClientStore";
import * as React from "react";

const ProjectDetailSummary = props => {

    const data = props.data
    const {selectedClient} = useClientStore((state) => ({selectedClient: state.selectedClient}))

    const [viewInvoiceDialog, setViewInvoiceDialog] = useState(false)
    const toggleDialog = () => {
        setViewInvoiceDialog(!viewInvoiceDialog);
    };

    const getTimeString = time => {
        return (time ? Math.floor(time / 60).toString().padStart(2, '0') + ':' + (time % 60).toString().padStart(2, '0') : 0)
    }

    const getPriceString = price => {
        const formatter = new Intl.NumberFormat('cs-CZ', { useGrouping: true });
        return formatter.format(Math.floor(price))
    }

    return(
        <>
            <div className="client-detail-summary">

                {viewInvoiceDialog && <Invoice toggleDialog={toggleDialog} dataType={"project"} />}

                <div>
                    <h2>Celkem vykázáno</h2>
                    <span>{getTimeString(data.AllTheTimeH)} hodin</span>
                    <span>{getPriceString(data.AllTheTime)} ,- CZK</span>
                </div>

                <div>
                    <h2>Tento měsíc</h2>
                    <span>{getTimeString(data.ThisMonthH)}  hodin</span>
                    <span>{getPriceString(data.ThisMonth)} ,- CZK</span>
                </div>

                <div>
                    <h2>Minulý měsíc</h2>
                    <span>{getTimeString(data.LastMonthH)}  hodin</span>
                    <span>{getPriceString(data.LastMonth)} ,- CZK</span>
                </div>

                <div  className="client-detail-summary-highlight">
                    <h2>K fakturaci</h2>
                    <span>{getTimeString(data.ToInvoiceH)}  hodin</span>
                    <span>{getPriceString(data.ToInvoice)} ,- CZK</span>

                    {/*<div  className="client-detail-summary-button">*/}
                    {/*    <Button themeColor={"error"} size={"large"} disabled={data.ToInvoice <= 0}*/}
                    {/*    onClick={toggleDialog}*/}
                    {/*    >*/}
                    {/*        Fakturovat*/}
                    {/*    </Button>*/}
                    {/*</div>*/}

                </div>



            </div>



        </>
    )
}

export default ProjectDetailSummary