import axios from "axios";
import config from "../../config/config.json";
import useOwnRightsStore from "../../stores/OwnRightsStore";
import {useState} from "react";
import useFetch from "../../tools/UseFetch";
import {Button, ButtonGroup, Toolbar, ToolbarSeparator} from "@progress/kendo-react-buttons";
import NoteInGrid from "../notes/NoteInGrid";
import EditNote from "../notes/EditNote";
import CheckListInGrid from "./CheckListInGrid";
import EditCheckList from "./EditCheckList";

const Checklists = props => {

    const saveNew = dataItem => {
        axios.put(config.API_URL + 'chcklist/' + props.forItem.UID, dataItem, {
            headers: {'x-api-key': sessionStorage.getItem("apiKEY")}
        })
            .then((response) => {
                console.log(response);
                setRefNum(new Date())
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const saveItem = dataItem => {
        axios.post(config.API_URL + 'chcklist/' + dataItem.ID, dataItem, {
            headers: {'x-api-key': sessionStorage.getItem("apiKEY")}
        })
            .then((response) => {
                console.log(response);
                setRefNum(new Date())
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const deleteChckList = dataItem => {
        axios.delete(config.API_URL + 'chcklist/' + dataItem.ID, {
            headers: {'x-api-key': sessionStorage.getItem("apiKEY")}
        })
            .then((response) => {
                console.log(response);
                setRefNum(new Date())
            })
            .catch((error) => {
                console.error(error);
            });
    };


    const haveRight = useOwnRightsStore(state => state.haveRight)

    const [refNum, setRefNum] = useState(new Date())
    const [editItem, setEditItem] = useState(null);

    const [viewCompleted, setViewCompleted] = useState(true)

    const {data:checklists, isPending, error} = useFetch(config.API_URL + 'chcklist/' + props.forItem.UID, refNum)


    const updateData = () => {
        setRefNum(new Date())
    }


// ************************************** EDIT *************************************


    const createNew = () => {
        setEditItem({})
    }

    const enterEdit = (dataItem) => {
        console.log("note edit")
        setEditItem(dataItem)
    };

    const handleCancelEdit = (dataItem) => {
        setEditItem(null)
    };

    const handleSubmit = (dataItem) => {
        //debugger
        if (dataItem.ID === undefined || dataItem.ID === null) {
            saveNew(dataItem)
        }
        else {
            saveItem(dataItem)
        }
        setEditItem(null);
        // setRefNum(refNum + 1)
    };

    return (
        <div className={"details-page"}>

            {/*<StackLayout*/}
            {/*    // style={{*/}
            {/*    //     height: "100%",*/}
            {/*    //     width: "100%",*/}
            {/*    // }}*/}
            {/*    orientation={"vertical"}*/}
            {/*    gap={0}*/}
            {/*>*/}
            <div className="chcklist-header">
                <Toolbar>

                    <Button onClick={createNew}>Add check list</Button>
                    <ToolbarSeparator />

                    {/*View completed:*/}
                    {/*<ButtonGroup>*/}
                    {/*    <Button togglable={true} selected={viewCompleted} onClick={() => setViewCompleted(!viewCompleted)}><span className="k-icon k-i-check-outline k-icon-md"/></Button>*/}
                    {/*    /!*<Button togglable={true} selected={viewManual} onClick={() => setViewManual(!viewManual)}><span className="k-icon k-i-edit k-icon-sm"/></Button>*!/*/}
                    {/*</ButtonGroup>*/}

                </Toolbar>
            </div>

            <div className="chcklist-list-outter">
                {checklists && checklists.length > 0 && <div className="chcklist-list">
                    {checklists.map(n => (
                        <CheckListInGrid key={n.ID} edit={enterEdit} delete={deleteChckList} checklist={n} update={updateData} refNum={refNum}/>
                    ))}
                </div>}

                {editItem && haveRight("Projects_Edit") && <EditCheckList
                    cancelEdit={handleCancelEdit}
                    onSubmit={handleSubmit}
                    item={editItem}
                />}

            </div>
            {/*</StackLayout>*/}

        </div>
    );
}

export default Checklists