import {
    Button,
    ButtonGroup,
    Toolbar,
    ToolbarItem,
    ToolbarSeparator,
    ToolbarSpacer
} from "@progress/kendo-react-buttons";
import useClientStore from "../../stores/ClientStore";
import useProjectStore from "../../stores/ProjectStore";
import useTaskStore from "../../stores/TaskStore";

const TaskDetailSelector = (props) => {

    // const project = props.project
    const selectedTask= useTaskStore(state => state.selectedItem)

    // debugger

    const clientName = props.clientName
    const selectedDetail = props.selectedDetail
    const setSelectedDetail = props.setSelectedDetail
    const toggleDisplayParent = props.toggleDisplayParent

    const enabled = selectedTask != null
    const SelectedTaskName = (enabled ? selectedTask.Subject : "")

    return(
        <>
            <div className="detail-selector-outer">

                <span className="detail-selector-buttons">
                    <Toolbar>
                        <ToolbarItem style={{minWidth: 0}}>
                            <span className="detail-selector-caption" onClick={toggleDisplayParent}>{SelectedTaskName}</span>
                        </ToolbarItem>

                        <ToolbarSeparator />
                        <ToolbarItem>
                            <ButtonGroup disabled={!enabled}>
                                <Button onClick={() => setSelectedDetail(0)}
                                        themeColor={(selectedDetail === 0) ? "primary" : "base"}
                                >Info</Button>
                                <Button onClick={() => setSelectedDetail(12)}
                                        themeColor={(selectedDetail === 12) ? "primary" : "base"}
                                >Dokumenty</Button>
                                <Button onClick={() => setSelectedDetail(13)}
                                        themeColor={(selectedDetail === 13) ? "primary" : "base"}
                                >Checklists</Button>
                                <Button onClick={() => setSelectedDetail(14)}
                                        themeColor={(selectedDetail === 14) ? "primary" : "base"}
                                >Notes</Button>
                            </ButtonGroup>
                        </ToolbarItem>
                        <ToolbarSeparator />

                        <ToolbarSpacer />
                        <ToolbarItem>

                            <span className="detail-selector-caption" onClick={toggleDisplayParent}>{clientName}</span>
                        </ToolbarItem>
                    </Toolbar>
                </span>

                {/*<span className="detail-selector-caption">*/}
                {/*    {clientName}*/}
                {/*</span>*/}

            </div>
        </>
    )
}

export default TaskDetailSelector