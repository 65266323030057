import {Fragment} from "react";

const NoteInGrid = props => {

    const note = props.note
    const bg_color = note.Color1 || props.bg_color || "#efefef"
    const border_color = note.Color2 || props.border_color ||  "#c2c2c2"

    const dateTaken = new Date(note.Taken)

    return (
        <div className="note-in-grid">
            <span className="note-in-grid-date">{dateTaken.toLocaleString('cs-CZ')}</span>
            <span className="note-in-grid-author">{note.Author.Name}</span>

            <div className="note-in-grid-content" style={{backgroundColor: bg_color, borderColor: border_color}}>

                <div className="note-in-grid-content-callout1"
                     style={{backgroundColor: bg_color, borderColor: border_color}}></div>
                <div className="note-in-grid-content-callout2"
                     style={{backgroundColor: bg_color, borderColor: border_color}}></div>

                {/*<div className="note-in-grid-content-callout"></div>*/}
                <span className="note-in-grid-subject">{note.Subject}</span>
                {/*<span className="note-in-grid-text">{note.Text}</span>*/}

                <span className="note-in-grid-text">
                    {note.Text.split('\n').map((text, index) => (
                      <Fragment key={`${text}-${index}`}>
                          {text}
                          <br/>
                      </Fragment>
                    ))}
                </span>


                {note.Type === 1 &&
                    <span className="note-in-grid-edit" onClick={() => props.edit(note)}><span
                    className="k-icon k-i-edit"/></span>
                }

            </div>

        </div>
    );
}

export default NoteInGrid