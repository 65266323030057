import {Fragment, useEffect, useState} from "react";
import {Checkbox, TextBox} from "@progress/kendo-react-inputs";
import {Label} from "@progress/kendo-react-labels";
import axios from "axios";
import config from "../../config/config.json";
import {Button} from "@progress/kendo-react-buttons";
import Confirm from "../general/Confirm";
import {process} from "@progress/kendo-data-query";


const ChckBoxStatusItem = props => {

    const item = props.item
    const color = item.Done ? "#02c004" : "rgba(152,152,152,0.47)"

    return (
        <div className="chcklist-in-grid-header-status-item" style={{backgroundColor: color}}></div>
    )
}

const NewItem = props => {

    const [text, setText] = useState("")

    const changeText = e => {
        setText(e.target.value)
    }

    const AddItem = (event) => {
        event.preventDefault();
        props.addItem(text)
        setText("")
    }

    return(
        <>
            <div className="chcklist-list-in-grid-newitem">
                <form onSubmit={AddItem}>
                    <TextBox value={text} onChange={changeText}/>
                    <Button disabled={text.length <= 0} type="submit">Add</Button>
                </form>
            </div>

        </>
    )
}

const CheckListItem = props => {

    const item = props.item || {}
    console.log("item - ", item.Text, item.Done)
    const [checked, setChecked] = useState(item.Done)

    useEffect(() => {
        setChecked(item.Done)
    }, [item.Done]);

    const changeChecked = () => {
        console.log(item, checked)
        props.updateItemStatus(item, !checked)
        setChecked(!checked)
    }

    return(
        <>
            <div className="chcklist-list-in-grid-items-item">
                <span className="chcklist-list-in-grid-items-item-chckbox">
                    <Checkbox className={"checklist-checkbox"} id={"ch" + item.ID} checked={checked} onClick={changeChecked} >
                    </Checkbox>
                </span>
                <span className={"chcklist-list-in-grid-items-item-text" + (checked ? "-done" : "")}>
                    {item.Text}
                </span>
                <span className="chcklist-list-in-grid-items-item-icons">
                    {/*<span className="k-icon k-i-edit k-icon-md"/>*/}
                    <span className="k-icon k-i-delete k-icon-md" onClick={() => props.deleteItem(item)}/>
                </span>

            </div>
        </>
    )
}


const CheckListInGrid = props => {

    const checklist = props.checklist
    const items = checklist.Items

    console.log("items - ", items)

    const [viewItems, setViewItems] = useState(false)

    const updateItemStatus = (dataItem, status) => {
        dataItem.Done = status;
        axios.post(config.API_URL + 'chcklist/item/' + dataItem.ID, dataItem, {
            headers: {'x-api-key': sessionStorage.getItem("apiKEY")}
        })
            .then((response) => {
                console.log(response);
                props.update()
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const addItem = (text) => {
        const dataItem = {CheckListID: checklist.ID, Text: text, Done: false}

        axios.put(config.API_URL + 'chcklist/item/', dataItem, {
            headers: {'x-api-key': sessionStorage.getItem("apiKEY")}
        })
            .then((response) => {
                console.log(response);
                props.update()
            })
            .catch((error) => {
                console.error(error);
            });
    }


    const deleteItem = (dataItem) => {

        axios.delete(config.API_URL + 'chcklist/item/' + dataItem.ID, {
            headers: {'x-api-key': sessionStorage.getItem("apiKEY")}
        })
            .then((response) => {
                console.log(response);
                props.update()
            })
            .catch((error) => {
                console.error(error);
            });
    }

    // ************************************** Delete chcklist *************************************

    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    const deleteRow = () => {
        // setDeletedItem(checklist)
        setShowConfirmDelete(true)
    };

    const deleteRowAction = () => {
        props.delete(checklist)
        setShowConfirmDelete(false)
    };

    const cancelDelete = () => {
        setShowConfirmDelete(false)
    };

    return (
        <>

            <div className="chcklist-in-grid">
                <div className="chcklist-in-grid-header">
                    <span className="chcklist-in-grid-header-caption">{checklist.Subject}</span>

                    <span className="chcklist-in-grid-header-actionicons">
                        <span className="k-icon k-i-edit k-icon-md" onClick={() => props.edit(checklist)} />
                        <span className="k-icon k-i-delete k-icon-md" onClick={deleteRow}/>
                    </span>

                    <span className="chcklist-in-grid-header-colapsicon" onClick={() => setViewItems(!viewItems)}>
                        {!viewItems && <span className="k-icon k-i-arrow-chevron-down k-icon-xl"/>}
                        {viewItems && <span className="k-icon k-i-arrow-chevron-up k-icon-xl"/>}
                    </span>

                    {/*<span className="chcklist-in-grid-header-status">*/}

                    {items && items.length > 0 &&

                        <>
                            <div className="chcklist-in-grid-header-status-info">
                                ({items.filter(item => item.Done).length}/{items.length})
                            </div>

                            <div className="chcklist-in-grid-header-status">

                                {[...items].sort((a, b) => (a.Done === b.Done) ? 0 : a.Done ? -1 : 1).map(n => (
                                    <ChckBoxStatusItem item={n}/>
                                ))}
                            </div>
                        </>
                    }

                    {/*</span>*/}
                </div>


                {viewItems &&
                    <div className="chcklist-list-in-grid-items">
                        <NewItem addItem={addItem}/>
                        {items.map(n => (
                            <CheckListItem id={n.ID} item={n} updateItemStatus={updateItemStatus} deleteItem={deleteItem}/>
                        ))}
                    </div>
                }

                {showConfirmDelete && <Confirm
                    action={deleteRowAction}
                    cancel={cancelDelete}
                    text={"delete item?"}
                />}

            </div>

        </>



    );
}

export default CheckListInGrid