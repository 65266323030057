import {DropDownList} from "@progress/kendo-react-dropdowns";
import {MaskedTextBox} from "@progress/kendo-react-inputs";

function convertTimeStringToMinutes(timeString) {
    // debugger
    var totalMinutes = 0
    var parts = timeString.split(":");
    var hours = parseInt(parts[0], 10);
    var minutes = parseInt(parts[1], 10);
    totalMinutes = (hours * 60) + minutes;
    if (isNaN(totalMinutes)) {totalMinutes = 0;}
    return totalMinutes;
}

const DurationEditor = props => {

    const rules = {
        5: /[0-5]/,
        2: /[0-2]/,
    }

    const listData = props.listData
    const hideZeros = props.hideZeros || true



    //debugger
    const handleChange = (e) => {
        //debugger
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.ID,
            });
        }
    };

    const setHours = (e) => {
        let minutes = convertTimeStringToMinutes(e.target.value)
        console.log(minutes)
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: minutes,
            });
        }
    }

    // debugger
    const { dataItem } = props;
    const field = props.field || "";
    const minutes = dataItem[field] === null ? 0 : dataItem[field];

    const dataValue = Math.floor(minutes / 60).toString().padStart(2, '0') + ':' + (minutes % 60).toString().padStart(2, '0')

    const colorZeros = hideZeros && minutes === 0 ? "silver" : ""

    return(
        <td className={"right"} style={{color: colorZeros}}>
            {dataItem.inEdit ? (

                <MaskedTextBox
                    style={{
                        width: "100%",
                    }}
                    fillMode={"solid"}
                    rounded={"small"}
                    mask={"20:50"}
                    rules={rules}
                    defaultValue={"0"}
                    value={dataValue}
                    onChange={setHours}
                    // placeholder="email"
                    //label={'e-mail'} //value={email} handleChange={setEmail}

                />

                // <DropDownList
                //     style={{
                //         width: "100%",
                //     }}
                //     onChange={handleChange}
                //     value={listData.find((c) => c.ID === dataValue)}
                //     data={listData}
                //     textField="Name"
                // />
            ) : (
                dataValue
            )}
        </td>
    )

}

export default DurationEditor
