import useClientStore from "../../stores/ClientStore";

const CashflowGraphTooltipRow = props => {


    const item = props.item
    const index = props.key

    const client = useClientStore(state => state.clients).filter(client => (client.ID === item.CounterPartyID))[0]

    const formatter = new Intl.NumberFormat('cs-CZ', { useGrouping: true });

    const color = (item.PriceCZKVAT > 0) ? "green" : "red"

    return(
        <>
            <div className={"Tooltip-row"} key={index}>
                <span className={"Tooltip-row-number"}>[{item.Number}]</span>
                <span className={"Tooltip-row-client"}>{client.Name}</span>
                <span className="Tooltip-row-text">{item.Text}</span>
                <span className={"Tooltip-row-price " + color}>{formatter.format(item.PriceCZKVAT)},-</span>
            </div>
        </>
    )
}

export default CashflowGraphTooltipRow
