import {DropDownList} from "@progress/kendo-react-dropdowns";
import {MaskedTextBox, NumericTextBox} from "@progress/kendo-react-inputs";

function convertTimeStringToMinutes(timeString) {
    // debugger
    var totalMinutes = 0
    var parts = timeString.split(":");
    var hours = parseInt(parts[0], 10);
    var minutes = parseInt(parts[1], 10);
    totalMinutes = (hours * 60) + minutes;
    if (isNaN(totalMinutes)) {totalMinutes = 0;}
    return totalMinutes;
}

const ColorCodedDateEditor = props => {


    // debugger
    const { dataItem } = props;
    const field = props.field || "";
    const value = dataItem[field] === null ? 0 : dataItem[field];
    const formatter = new Intl.DateTimeFormat('cs-CZ');

    const currentDate = new Date

    const addStyle = (value < currentDate ? props.colorPast : props.colorFuture)

    return(
        <td
            style={{...props.style, color:addStyle }} // this applies styles that lock the column at a specific position
            className={props.className} // this adds classes needed for locked columns
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
        >
            {
                formatter.format(value)
            }
        </td>
    )

}

export default ColorCodedDateEditor
