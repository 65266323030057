import {useTableKeyboardNavigation} from "@progress/kendo-react-data-tools";
import {GRID_COL_INDEX_ATTRIBUTE} from "@progress/kendo-react-grid";
import {Button} from "@progress/kendo-react-buttons";

const ColorCell = (props) => {
    //debugger
    const { dataItem } = props;
    const navigationAttributes = useTableKeyboardNavigation(props.id);

    // let styles = {...props.style}
    // if (props.selected && props.dataItem.ID === props.selected.ID) {styles = {...props.style, border: "1px solid red", backgroundColor: "rgb(243,221,239)"}}

    const field = props.field || "";
    const value = dataItem[field] === null ? 0 : dataItem[field];
    // const colorStyle = "; background-color:" + color + "!important; " : ""


    return (
        <td
            style={{...props.style, backgroundColor:value }} // this applies styles that lock the column at a specific position
            className={props.className + " right"} // this adds classes needed for locked columns
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            {...{
                [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
            }}
            {...navigationAttributes}
            align={"right"}
        >

            {value}

        </td>
    );
};

export default ColorCell