import {TileLayout} from "@progress/kendo-react-layout";
import {useState} from "react";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import useClientStore from "../../stores/ClientStore";
import HomeSummary from "./HomeSummary";
import ClientDetailGraph from "../clients/ClientDetailGraph";
import HomeGraph from "./HomeGraph";
import QuickLinks from "./QuickLinks";

const styles = {
    fontSize: 24,
    textAlign: "center",
    margin: "auto",
    userSelect: "none",
};



const Home = props => {

    const {selectedClient} = useClientStore((state) => ({selectedClient: state.selectedClient}))

    const { data:summaryData, isPending:summaryData_IsPending, error:summaryData_error} = useFetch(config.API_URL + 'home/summary/', 0)
    const { data:chartData, isPending, error} = useFetch(config.API_URL + 'home/graph/', 0)
    const { data:projects, isPending:projects_IsPending, error:projects_error} = useFetch(config.API_URL + 'project/forClient/', 0)

    const tiles = [
        {
            header: "quick links",
            body: <><QuickLinks /></>,
            resizable: true,
            reorderable: true,
        },

        // {
        //     header: "Přehled",
        //     body: <>{summaryData && <HomeSummary data={summaryData}/>}</>,
        //     resizable: true,
        //     reorderable: true,
        // },
        // {
        //     header: "graf",
        //     body: <>{chartData && <HomeGraph data={chartData} projects={projects}/>}</>,
        //     resizable: true,
        //     reorderable: true,
        // },

    ];

    const [data, setData] = useState([
        {
            col: 3,
            colSpan: 6,
            rowSpan: 10,
        },
        {
            col: 1,
            colSpan: 2,
            rowSpan: 10,
        },
        {
            col: 1,
            colSpan: 8,
            rowSpan: 20,
        },

    ]);
    const handleReposition = (e) => {
        setData(e.value);
        console.log(e.value);
    };

    return(
        <>

            {<TileLayout
                columns={10}
                // rows={10}
                items={tiles}
                positions={data}
                onReposition={handleReposition}
            />}



        </>
    )
}

export default Home