import {DropDownList} from "@progress/kendo-react-dropdowns";
import {Button} from "@progress/kendo-react-buttons";
import {filterBy} from "@progress/kendo-data-query";
import {useState} from "react";

const DropDownFilter = props => {

    const textField = props.textField || "Name"
    const filterable = props.filterable || false

    // console.log("dropdown filter: ", props)

    let hasValue = (value) => Boolean(value && value !== props.defaultItem);
    // debugger
    const onChange = (event) => {
        hasValue = hasValue(event.target.value);
        props.onChange({
            value: hasValue ? event.target.value.ID : "",
            operator: hasValue ? "eq" : "",
            syntheticEvent: event.syntheticEvent,
        });
    };


    const onClearButtonClick = (event) => {
        event.preventDefault();
        props.onChange({
            value: "",
            operator: "",
            syntheticEvent: event,
        });
    };

    const [data, setData] = useState(props.data.slice());

    const filterData = (filter) => {
        const data = props.data.slice();
        return filterBy(data, filter);
    };
    const filterChange = (event) => {
        setData(filterData(event.filter));
    };

    return (
        <>

            <div className="k-filtercell">
                <DropDownList
                    data={data}
                    onChange={onChange}
                    value={props.data.find((c) => c.ID === props.value) || props.defaultItem}
                    defaultItem={props.defaultItem}
                    textField={textField}
                    filterable={filterable}
                    onFilterChange={filterChange}
                    //dataItemKey="ID"
                />
                <Button
                    title="Clear"

                    themeColor={hasValue(props.value) ? "error" : "base" }
                    disabled={!hasValue(props.value)}
                    onClick={onClearButtonClick}
                    icon="filter-clear"
                />
            </div>

        </>
    )
}

export default DropDownFilter