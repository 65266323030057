const InvoiceItemInGrid = props => {

    const item = props.item;
    const reportType = props.reportType;
    const showPrice = props.showPrice;
    const showHours = props.showHours;

    console.log("item - ", item);

    const TimeSpentRight = reportType.id === 1 ? 80 : 0

    return(
        <>
            <span className="invoice-item-in-grid">
                <span className="invoice-item-in-grid-firstline">
                    <span className="invoice-item-in-grid-date">
                      {
                          new Intl.DateTimeFormat('cs-CZ', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit'
                          }).format(new Date(item.Date))
                      }
                    </span>
                    <span className="invoice-item-in-grid-author">{item.AuthorShort}</span>

                    <span className="invoice-item-in-grid-text">{item.Text}</span>

                    <span className="invoice-item-in-grid-timespent">
                      {
                          item.TimeSpent ? Math.floor(item.TimeSpent / 60) + ":" + (item.TimeSpent % 60).toString().padStart(2, '0') + " h" : ""
                      }
                    </span>
                    {(reportType.id === 1 || showPrice === "yes") && <span
                        className="invoice-item-in-grid-price">{item.Price.toLocaleString()} {item.Currency}</span>}
                </span>

                {/*<span className="invoice-item-in-grid-project">Project: {item.ProjectName}</span>*/}



            </span>

        </>
    )
}

export default InvoiceItemInGrid