import {useEffect, useLayoutEffect, useRef, useState} from "react";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import {Button, Toolbar, ToolbarItem, ToolbarSpacer} from "@progress/kendo-react-buttons";
import ClientsGrid from "./ClientsGrid";
import DetailSelector from "./DetailSelector";
import TmsGrid from "../tms/TmsGrid";
import {Splitter, StackLayout} from "@progress/kendo-react-layout";
import ClientDetail from "./ClientDetail";
import ProjectsGrid from "../projects/ProjectsGrid";
import ProjectsGridInClient from "../projects/ProjectsGridInClient";
import PriceGrid from "../price/PriceGrid";
import Cashflow from "../cashflow/Cashflow";
import CashFlowGrid from "../cashflow/CashFlowGrid";
import useClientStore from "../../stores/ClientStore";
import FileInDetail from "../files/FileInDetail";
import useOwnRightsStore from "../../stores/OwnRightsStore";
import Price from "../price/Price";
import Expenses from "../expenses/Expenses";
import TaskGrid from "../tasks/TasksGrid";
import ExpensesGrid from "../expenses/ExpensesGrid";
import ClientDetailSection from "./ClientDetailSection";
import useProjectStore from "../../stores/ProjectStore";
import ProjectDetailSection from "../projects/ProjectDetailSection";


const Clients = (props) => {

    const {selectedProject, setSelectedProject} = useProjectStore((state) => ({selectedProject: state.selectedItem, setSelectedProject: state.selectItem}))

    const {selectedClient, setSelectedClient} = useClientStore((state) => ({selectedClient: state.selectedClient, setSelectedClient: state.selectClient}))
    const clients = useClientStore(state => state.clients)
    const setClients = useClientStore(state => state.setClients)
    const loadClients = useClientStore(state => state.loadClients)

    const rights = useOwnRightsStore(state => state.storeData)
    const haveRight = useOwnRightsStore(state => state.haveRight)

    const addNewClient = useClientStore(state => state.addNew)
    const discardNewClient = useClientStore(state => state.discardNew)


    const maPravoNaClienty = haveRight("Clients_Read")

    console.log(props.size)
    const menuSize = props.menuSize
    //console.log("menu size - ", menuSize)

    const refresh = props.refresh
    const [refSize, setRefSize] = useState({width:0, height:0});
    // const [selectedClient, setSelectedClient] = useState()
    const [selectedClientName, setSelectedClientName] = useState()
    const [selectedDetail, setSelectedDetail] = useState()
    const [displayClientsGrid, setDisplayClientsGrid] = useState(true)

    const [selectedCount, setSelectedCount] = useState(0)

    const [panes, setPanes] = useState([
        {
            size: "50%",
            min: "0",
            collapsible: true,
        },
        {
            min: "50px",
            collapsible: false,
            // scrollable: false,
        },
    ]);

    const onChange = (event) => {
        setPanes(event.newState);
    };

    const toggleDisplayClientsGrid = () => {
        setDisplayClientsGrid(!displayClientsGrid)
    }

    const handleSize = () => {
        // debugger
        let width = window.innerWidth - 10;
        const height = window.innerHeight;
        setRefSize({
            // width: ref.current.offsetWidth,
            // height: ref.current.offsetHeight
            width: width,
            height: height
        })
        console.log(refSize.width, width, window.innerWidth)
    }

    useLayoutEffect(() => {
        handleSize()
        window.addEventListener("resize", handleSize);
        return () => window.removeEventListener("resize", handleSize);
    }, []);

    // const { data:clients, isPending, error} = useFetch(config.API_URL + 'client/', refresh)
    // const [data, setData] = useState(clients)

    useEffect(() => {
        loadClients()
        setSelectedClient(null)
        setSelectedProject(null)
    },[])

    // useEffect(() => {
    //     setData(clients)
    // },[clients])

    useEffect(() => {
        const tmpSelectedDetail = selectedDetail
        setSelectedDetail(0)
        setSelectedDetail(tmpSelectedDetail)
    },[selectedClient])


    const [editID, setEditID] = useState(null);

    // const [filtered, setFiltered] = useState(0)
    // const [openForm, setOpenForm] = useState(false);
    // const [editItem, setEditItem] = useState({ProductID: 1,});
    // const [data, setData] = useState(users);


    const addNew = () => {
        // const newDataItem = {
        //     inEdit: true,
        // };
        // setData([newDataItem, ...data]);
        addNewClient()
    };

    const addCashFlow = () => {
        console.log("add cashflow")
        //debugger

    }

    //TODO: Presunout overeni prav primo do store nebo do modulu
    // console.log("have right ... ", haveRight("Clients_Read"))
    // console.log("have right ... ", rights.some(right => right["Key"] === "Clients_Read"))

    return(
        <>
            {/*<div className="outer-table" ref={ref}>*/}
                {clients && <>

                    <Splitter
                        style={{
                            height: "calc(100vh - 55px)",
                        }}
                        panes={panes}
                        orientation={"vertical"}
                        onChange={onChange}


                    >

                        <StackLayout
                            style={{
                                height: "100%",
                            }}
                            orientation={"vertical"}
                            gap={0}
                        >

                            <ClientsGrid clients={clients} data={clients} setData={setClients} size={refSize} menuSize={menuSize}
                                         selectedClient={selectedClient}
                                         setSelected={setSelectedClient}
                                         setSelectedClientName={setSelectedClientName}
                                         toggleDisplayParent={toggleDisplayClientsGrid}
                                         setSelectedCount={setSelectedCount}
                            />
                        </StackLayout>


                        {!selectedProject && <ClientDetailSection menuSize={menuSize} size={refSize} selectedDetail={selectedDetail} setSelectedDetail={setSelectedDetail} />}
                        {selectedProject && <ProjectDetailSection menuSize={menuSize} size={refSize} selectedDetail={selectedDetail} setSelectedDetail={setSelectedDetail} />}


                    </Splitter>

                </>}

        </>
    )
}

export default Clients