import CashFlowGrid from "./CashFlowGrid";
import {StackLayout} from "@progress/kendo-react-layout";
import {useLayoutEffect, useState} from "react";
import CashflowGraph from "./CashflowGraph";
import useCashflowStore from "../../stores/CashflowStore";
import {Window} from "@progress/kendo-react-dialogs";

const Cashflow = props => {

    const menuSize = props.menuSize
    const [refSize, setRefSize] = useState({width:0, height:0});

    const [chartData, setChartData] = useState(null)
    const [chartVisible, setChartVisible] = useState(false);


    const handleSize = () => {
        // debugger
        let width = window.innerWidth - 10;
        const height = window.innerHeight;
        setRefSize({
            // width: ref.current.offsetWidth,
            // height: ref.current.offsetHeight
            width: width,
            height: height
        })
        console.log(refSize.width, width, window.innerWidth)
    }

    useLayoutEffect(() => {
        handleSize()
        window.addEventListener("resize", handleSize);
        return () => window.removeEventListener("resize", handleSize);
    }, []);

    const [panes, setPanes] = useState([
        {
            size: "50%",
            min: "0",
            collapsible: true,
        },
        {
            // min: "50px",
            collapsible: false,
        },
    ]);


    const addNew = () => {
        // const newDataItem = {
        //     inEdit: true,
        // };
        // setData([newDataItem, ...data]);
        // addNewClient()
    };

    const addNewCashflow = useCashflowStore(state => state.addNew)

    const onChange = (event) => {
        setPanes(event.newState);
    };

    const toggleChart = () => {
        setChartVisible(!chartVisible);
    };

    return(
        <>


            {/*<Splitter*/}
            {/*    style={{*/}
            {/*        height: "calc(100vh - 55px)",*/}
            {/*    }}*/}
            {/*    panes={panes}*/}
            {/*    orientation={"vertical"}*/}
            {/*    onChange={onChange}*/}
            {/*>*/}

                <StackLayout
                    style={{
                        height: "calc(100vh - 55px)",
                    }}
                    orientation={"vertical"}
                    gap={0}
                >
                    {/*<div className="list-header">*/}

                    {/*    <Toolbar>*/}
                    {/*        <ToolbarItem>*/}

                    {/*            <Button onClick={addNewCashflow}*/}
                    {/*                    themeColor={"base"}*/}
                    {/*            >Nový záznam</Button>*/}


                    {/*        </ToolbarItem>*/}
                    {/*        <ToolbarSpacer />*/}

                    {/*        <ToolbarItem>*/}

                    {/*            <Button onClick={toggleChart}*/}
                    {/*                    themeColor={"base"}*/}
                    {/*            >Graf</Button>*/}

                    {/*        </ToolbarItem>*/}
                    {/*    </Toolbar>*/}

                    {/*</div>*/}
                    <CashFlowGrid size={refSize} menuSize={menuSize} dataType={"all"} setData={setChartData}></CashFlowGrid>

                </StackLayout>

            {chartVisible && <Window title={"Graf"} onClose={toggleChart} initialHeight={1350} initialWidth={2048}>

                    <StackLayout
                        style={{
                            height: "100%",
                        }}
                        orientation={"vertical"}
                        gap={0}
                    >

                        <>

                            {chartData && <CashflowGraph data={chartData}/>}

                        </>

                    </StackLayout>

                {/*{chartData && <CashflowGraph data={chartData}/>}*/}
            </Window>}

            {/*    <StackLayout*/}
            {/*        style={{*/}
            {/*            height: "100%",*/}
            {/*        }}*/}
            {/*        orientation={"vertical"}*/}
            {/*        gap={0}*/}
            {/*    >*/}

            {/*        <>*/}

            {/*            {chartData && <CashflowGraph data={chartData}/>}*/}

            {/*        </>*/}

            {/*    </StackLayout>*/}

            {/*</Splitter>*/}


        </>
    )
}

export default Cashflow