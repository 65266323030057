import {process} from "@progress/kendo-data-query";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {Grid, GridToolbar} from "@progress/kendo-react-grid";
import {useEffect, useState} from "react";
import CommandCell from "../../tableComponents/CommandCell";
import useUserStore from "../../../stores/UserStore";
import DropDownEditor from "../../editComponents/DropDownEditor";
import DropDownFilter from "../../filterComponents/DropDownFilter";
import useFetch from "../../../tools/UseFetch";
import config from "../../../config/config.json";
import EditGroup from "./EditGroup";
import useGroupStore from "../../../stores/GroupStore";
import EditGroups from "../users/EditGroups";
import EditRights from "./EditRights";
import GroupCommandCell from "../../tableComponents/GroupCommandCell";

const EDIT_FIELD = "inEdit";

const ActionCell = (props) => {
    return(
        <>

        </>
    )
}

const initialDataState = {
    // take: 50,
    skip: 0,
    pageSize:100,
};





const GroupsGrid = (props) => {


    const refSize = props.size
    const menuSize = props.menuSize
    const dataType = props.dataType || "all"

    const exportData = props.setData

    const [dataState, setDataState] = useState(initialDataState);
    const [refresh, setRefresh] = useState(Date.now())


    let columnDefWidth = 100


    const data = useGroupStore(state => state.storeData)
    const addNew = useGroupStore(state => state.addNew)
    const setData = useGroupStore(state => state.setStoreData)
    const saveNew = useGroupStore(state => state.saveNew)
    const saveItem = useGroupStore(state => state.saveItem)

    const refreshData = useGroupStore(state => state.refreshData)
    const deleteItem = useGroupStore(state => state.deleteItem)

    const loadAll = useGroupStore(state => state.loadAll)


    const [openForm, setOpenForm] = useState(false);
    const [editItem, setEditItem] = useState(null);

    const { data:partners, isPending, error} = useFetch(config.API_URL + 'partner/', 1)


    const CountFooter = (props) => {
        const field = props.field || "";
        const total = process(data, dataState).data.length
        return (
            <td
                style={props.style} // this applies styles that lock the column at a specific position
                className={props.className} // this adds classes needed for locked columns
                colSpan={props.colSpan}
                role={"gridcell"}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
            >
                {total} záznamů
            </td>
        );
    };


    // const [data, setData] = useState(cashflow)



    useEffect(() => {
        loadAll()
    },[])



    const MyEditCommandCell = (props) => (
        <GroupCommandCell
            {...props}
            edit={enterEdit}
            copy={enterCopy}
            rights={editRights}
            // rightsCount={props.dataItem.RightsCount}
        />
    );

    const usePartnerEditor = props => (
        <DropDownEditor
            {...props}
            listData={partners}
        />
    );

    const PartnerFilter = (props) => (
        <DropDownFilter
            {...props}
            data={partners}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );


    const itemChange = (event) => {
        const inEditID = event.dataItem.ID;
        const field = event.field || "";
        const newData = data.map((item) =>
            item.ID === inEditID
                ? {
                    ...item,
                    [field]: event.value,
                }
                : item
        );
        setData(newData);
    };


    // *********************************** HELPER DATA **********************************

    const [openRightsForm, setOpenRightsForm] = useState(false);


    // *********************************** EDIT RIGHTS **********************************

    const editRights = dataItem => {
        setEditItem(dataItem)
        setOpenRightsForm(true)
    }

    const cancelEditRights = e => {
        setOpenRightsForm(false)
    }

    const submitEditRights = e => {
        setOpenRightsForm(false)
        console.log("before data refresh")
        refreshData()
    }



    // ************************************** EDIT *************************************

    const rowDblClick = props => {
        enterEdit(props.dataItem)
    }

    const enterEdit = (dataItem) => {
        console.log("item edit")

        setEditItem(dataItem)
        setOpenForm(true)
    };

    const handleCancelEdit = (dataItem) => {

        setOpenForm(false);

    };



    const handleSubmit = (dataItem) => {
        // debugger
        if (dataItem.ID === undefined || dataItem.ID === null) {
            saveNew(dataItem)
        }
        else {
            saveItem(dataItem)
        }
        setOpenForm(false);
    };


    // ************************************** NEW *************************************

    const editNew = () => {
        const newItem = addNew //{};
        setEditItem(newItem)
        setOpenForm(true)
    };


    // ************************************** COPY *************************************


    const enterCopy = (dataItem) => {
        console.log("copy edit")

        const newItem = {
            ...dataItem,
            ID: null,
            UID: null,
        }

        setEditItem(newItem)
        setOpenForm(true)

        console.log("edit copy ... ", openForm, editItem)
    };


    // ************************************** Delete *************************************
    //
    // const deleteRow = (dataItem) => {
    //     setDeletedItem(dataItem)
    //     setShowConfirmDelete(true)
    // };
    //
    // const deleteRowAction = () => {
    //     deleteItem(deletedItem)
    //     setShowConfirmDelete(false)
    // };
    //
    // const cancelDelete = () => {
    //     setShowConfirmDelete(false)
    // };


    // ************************************** Files *************************************

    // const showFiles = (dataItem) => {
    //     setShowFilesFor(dataItem)
    //     setShowFilesDialog(true)
    // };
    //
    // const closeFiles = () => {
    //     refreshData()
    //     setShowFilesDialog(false)
    // };


    // ************************************** GRID *************************************




    return(
        <>

            {data && partners &&


                <Grid
                    //pageable={true}
                    sortable={true}
                    filterable={true}
                    scrollable={"scrollable"} //virtual
                    resizable={true}
                    reorderable={true}
                    rowHeight={40}
                    onItemChange={itemChange}

                    editField={EDIT_FIELD}

                    style={{
                        height: "100%", //"calc(100% - 55px)", // (refSize.height - 150) / 2,//"500px",
                        minHeight: 20,
                        //maxHeight: 300, // refSize.height - 70,
                        width: refSize.width - menuSize,
                    }}
                    data={process(data, dataState)}{...dataState}

                    //onPageChange={pageChange}
                    onDataStateChange={(e) => setDataState(e.dataState)}
                    onRowDoubleClick={rowDblClick}

                >

                    <GridToolbar>

                        <button
                            title="Export to Excel"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={editNew}
                        >
                            Novy zaznam
                        </button>


                    </GridToolbar>

                    <Column field="ID" title="ID" width="80px" filterable={false} editable={false} locked />


                    <Column field="Name" minResizableWidth={80} title="Name" width={150} editable={true} locked />
                    <Column field="ShortName" minResizableWidth={80} title="Short Name" width={300} editable={true} />
                    <Column field="Tags" minResizableWidth={80} title="Tags" width={300} editable={true} />


                    <Column field="action" minResizableWidth={180}
                            cell={MyEditCommandCell}
                        // columnMenu={ActionCell}
                            filterable={false}
                            headerCell={ActionCell}
                            width="180"
                            resizable={true}
                            locked
                        // filterCell={ActionCell}
                    />

                </Grid>

            }



            {openForm && <EditGroup
                partners={partners}
                cancelEdit={handleCancelEdit}
                onSubmit={handleSubmit}
                item={editItem}
            />}


            {openRightsForm && <EditRights
                partners={partners}
                onCancel={cancelEditRights}
                onSubmit={submitEditRights}
                item={editItem}
            />}


        </>
    )
}

export default GroupsGrid