import {process} from "@progress/kendo-data-query";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {Grid, GridToolbar} from "@progress/kendo-react-grid";
import {useEffect, useState} from "react";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import Waiting from "../waiting/Waiting";
import EditCommandCell from "../editComponents/EditCommandCell";
import APICall from "../../tools/APICall";
import CurrencyEditor from "../editComponents/CurrencyEditor";
import DropDownFilter from "../filterComponents/DropDownFilter";
import DropDownEditor from "../editComponents/DropDownEditor";
import DurationEditor from "../editComponents/DurationEditor";
import useClientStore from "../../stores/ClientStore";
import DateFilter from "../filterComponents/DateFilter";
import useCashflowStore from "../../stores/CashflowStore";
import useTmsStore from "../../stores/TmsStore";
import useCurrencyStore from "../../stores/CurrencyStore";
import useProjectStore from "../../stores/ProjectStore";
import CommandCell from "../tableComponents/CommandCell";
import Confirm from "../general/Confirm";
import FileDialog from "../files/FileDialog";
import EditTms from "./EditTms";
import ImportDialog from "./ImportDialog";




const initialDataState = {
    // take: 50,
    skip: 0,
    //pageSize:100,
};


const ImportGrid = (props) => {

    const importID = props.importID

    const isSmallHeight = window.innerHeight < 600

    // const refresh = props.refresh
    const {selectedClient} = useClientStore((state) => ({selectedClient: state.selectedClient}))
    const {selectedProject} = useProjectStore((state) => ({selectedProject: state.selectedItem}))

    const refSize = props.size
    const menuSize = props.menuSize
    const dataType = props.dataType || "all"


    const [dataState, setDataState] = useState(initialDataState);
    const [refresh, setRefresh] = useState(Date.now())


    let columnDefWidth = 100
    // let columnDefWidth = refSize.width * 0.1
    // if (columnDefWidth < 120) columnDefWidth = 120

    const { data:data, isPending, error} = useFetch(config.API_URL + 'tms/get_import/' + importID, refresh)

    const SumFooter = (props) => {
        const field = props.field || "";
        const total = process(data, dataState).data.reduce((acc, current) => acc + current[field], 0)
        const formatter = new Intl.NumberFormat('en-US', { useGrouping: true });
        return (
            <td colSpan={props.colSpan} style={props.style} className={"right"}>
                {formatter.format(total)}
            </td>
        );
    };

    const DurationFooter = (props) => {
        const field = props.field || "";
        const total = process(data, dataState).data.reduce((acc, current) => acc + current[field], 0)
        const dataValue = Math.floor(total / 60).toString().padStart(2, '0') + ':' + (total % 60).toString().padStart(2, '0')
        return (
            <td colSpan={props.colSpan} style={props.style} className={"right"}>
                {dataValue}
            </td>
        );
    };

    const CountFooter = (props) => {
        const field = props.field || "";
        const total = process(data, dataState).data.length
        return (
            <td
                style={props.style} // this applies styles that lock the column at a specific position
                className={props.className} // this adds classes needed for locked columns
                colSpan={props.colSpan}
                role={"gridcell"}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
            >
                {total} záznamů
            </td>
        );
    };



    const PriceFooter = (props) => {
        const field = props.field || "";
        const total = process(data, dataState).data.reduce((acc, current) => acc + current[field], 0)
        const formatter = new Intl.NumberFormat('cs-CZ', { useGrouping: true });
        return (
            <td
                style={props.style} // this applies styles that lock the column at a specific position
                className={props.className + " right"} // this adds classes needed for locked columns
                colSpan={props.colSpan}
                role={"gridcell"}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
            >
                {formatter.format(total)}
            </td>
        );
    };


    //region editors and filters


    const useDurationEditor = props => (
        <DurationEditor
            {...props}
        />
    );

    const useDateFilter = (props) => (
        <DateFilter
            {...props}
            defaultItem={{ID: 0, Name: "All"}}
            type={"past"}
            dataState={dataState}
            setDataState={setDataState}
        />
    );




    //endregion


    // ************************************** GRID *************************************




    return(
        <>

            {data &&


                <Grid
                //pageable={true}
                sortable={true}
                filterable={true}
                //scrollable={"virtual"} //virtual, scrollable
                resizable={true}
                reorderable={true}
                rowHeight={45}
                // onItemChange={itemChange}

                editField="inEdit"


                style={{
                    height: "100%", //"calc(100% - 55px)", // (refSize.height - 150) / 2,//"500px",
                    minHeight: 20,
                    //maxHeight: 300, // refSize.height - 70,
                    width: props.width //"1190px" // refSize.width - menuSize,
                }}
                data={process(data, dataState)}{...dataState}

                //onPageChange={pageChange}
                onDataStateChange={(e) => setDataState(e.dataState)}


                >


                    <Column field="ID" title="ID" width="80px" filterable={true} editable={false} />

                    <Column field="IssueID" minResizableWidth={120} title="Issue ID" width={180}/>
                    <Column field="IssueSummary" minResizableWidth={120} title="Issue" width={220}/>
                    <Column field="Date" minResizableWidth={100} title="Datum" width={200}
                            filter="date"
                            format="{0:dd.MM.yyyy}"
                            editor="date"
                        //cell={DateCell}
                            filterCell={useDateFilter}
                    />
                    <Column field="Author" minResizableWidth={120} title="Autor" width={120}/>
                    <Column field="Type" minResizableWidth={120} title="Typ" width={120}/>
                    <Column field="Comment" minResizableWidth={120} title="Text" width={350}/>
                    <Column field="SpentTime" cell={DurationEditor} minResizableWidth={120} title="Trvání In" width={120} filter={"numeric"} footerCell={DurationFooter}/>


                </Grid>

            }




            {false && <Waiting />}

                {/*<div className="list-footer"></div>*/}


                {/*{openForm && <EditClient*/}
                {/*    cancelEdit={handleCancelEdit}*/}
                {/*    onSubmit={handleSubmit}*/}
                {/*    item={editItem}*/}
                {/*/>}*/}

        </>
    )
}

export default ImportGrid