import {Input, TextBox} from "@progress/kendo-react-inputs";
import {DatePicker} from "@progress/kendo-react-dateinputs";
import FormTextBoxField from "../formEditComponents/FormTextBoxField";
import {Field, FieldWrapper, Form, FormElement} from "@progress/kendo-react-form";
import * as React from "react";
import {Error, Label} from "@progress/kendo-react-labels";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import FormDropDown from "../formEditComponents/FormDropDown";
import FormTextAreaField from "../formEditComponents/FormTextAreaField";
import FormNumBoxField from "../formEditComponents/FormNumBoxField";
import FormDateField from "../formEditComponents/FormDateField";
import {Button} from "@progress/kendo-react-buttons";
import {isNumeric} from "@progress/kendo-data-query/dist/npm/utils";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import {useRef, useState} from "react";
import useClientStore from "../../stores/ClientStore";
import useCashflowStore from "../../stores/CashflowStore";
import useTmsStore from "../../stores/TmsStore";
import TmsDateHelp from "./TmsDateHelp";


const mandatoryFieldValidator = value => value != null ? "" : "mandatory field"


const invoicedData = [{Name:"yes", ID:1}, {Name:"no", ID:0}]


const useProjectFilter = props => (
    <FormDropDown
        {...props}
        textField={"ClientProjectName"}
        defaultItem={{ClientProjectName:"all", ID:null}}
    />
);

const useFilter = props => (
    <FormDropDown
        {...props}
        defaultItem={{Name:"all", ID:null}}
    />
);

const TmsLoadFilter = props => {

    const {
        cancelEdit,
        onSubmit,
        item,
        cfTypes,
        cfStatuses,
        counterParties,
        // projects,
        currencies,
        units,
        ...other
    } = props;

    const [refresh, setRefresh] = useState(Date.now())

    const { data:workTypes, isPending:workType_isPending, error:workType_error} = useFetch(config.API_URL + 'lists/worktype/', refresh)
    const { data:authors, isPending:authors_isPending, error:authors_error} = useFetch(config.API_URL + 'user/authors/', refresh)
    const { data:partners, isPending:partners_isPending, error:partners_error} = useFetch(config.API_URL + 'partner/', refresh)
    const { data:projects, isPending:projects_isPending, error:projects_error} = useFetch(config.API_URL + 'project/for_lists', refresh)
    const clients = useClientStore(state => state.clients)

    const filter = useTmsStore(state => state.filter)
    const setFilter = useTmsStore(state => state.setFilter)
    const refreshData = useTmsStore(state => state.refreshData)

    const convertDateToUTC = (date) => {

        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        const utcDate = new Date(Date.UTC(year, month, day));

        return utcDate;
    };


    const handleSubmit = newFilter => {
        // debugger

        if (newFilter.ProjectID != null && !isNumeric(newFilter.ProjectID)) newFilter.ProjectID = newFilter.ProjectID.ID
        if (newFilter.ClientID != null && !isNumeric(newFilter.ClientID)) newFilter.ClientID = newFilter.ClientID.ID
        if (newFilter.Author != null && !isNumeric(newFilter.Author)) newFilter.Author = newFilter.Author.ID
        if (newFilter.Partner != null && !isNumeric(newFilter.Partner)) newFilter.Partner = newFilter.Partner.ID
        if (newFilter.Invoiced != null && !isNumeric(newFilter.Invoiced)) newFilter.Invoiced = newFilter.Invoiced.ID

        if (newFilter.DateFrom != null) newFilter.DateFrom = convertDateToUTC(newFilter.DateFrom)
        if (newFilter.DateTo != null) newFilter.DateTo = convertDateToUTC(newFilter.DateTo)

        setFilter(newFilter)
        refreshData()
    }



    return(
        <>


            <div className="tms-load-filter">

                <span className="tms-load-filter-caption">pre-Load <br />filter</span>

                <Form initialValues={filter} onSubmit={handleSubmit} render={renderProps => {

                    return(
                    <div className="tms-load-filter-filter">

                        <div className="tms-load-filter-filter-column">
                            <FormElement horizontal={true}>

                                {authors && <FieldWrapper><Field name={"Author"} label={"Autor"} textField={"Name"} dataItemKey={"id"} component={useFilter} listData={authors} /></FieldWrapper>}
                                {/*<FieldWrapper><Field name={"Author"} component={FormTextBoxField}  label={"Author"} /></FieldWrapper>*/}
                                {/*<FieldWrapper><Field name={"DateRange"} component={FormTextBoxField}  label={"Date range"} /></FieldWrapper>*/}
                                {partners && <FieldWrapper><Field name={"Partner"} label={"Partner"} textField={"Name"} dataItemKey={"id"} component={useFilter} listData={partners} /></FieldWrapper>}

                            </FormElement>
                        </div>

                        <div className="tms-load-filter-filter-column">
                            <FormElement horizontal={true}>

                                <FieldWrapper>
                                    <Field name={"DateFrom"} component={FormDateField}  label={"Date from"} />
                                    {/*<Button iconClass={"k-icon k-i-star"} onClick={showDateHelp}></Button>*/}
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Field name={"DateTo"} component={FormDateField}  label={"Date to"} />
                                    {/*<Button iconClass={"k-icon k-i-star"} onClick={showDateHelp}></Button>*/}
                                </FieldWrapper>

                            </FormElement>
                        </div>

                        <div className="tms-load-filter-filter-column">
                            <FormElement horizontal={true}>

                                {clients && <FieldWrapper><Field name={"ClientID"} label={"Client"} textField={"Name"} dataItemKey={"id"} component={useFilter} listData={clients} /></FieldWrapper>}
                                {/*<FieldWrapper><Field name={"ClientID"} component={FormTextBoxField}  label={"Client"} /></FieldWrapper>*/}
                                {projects && <FieldWrapper><Field name={"ProjectID"} label={"Project"} textField={"Project"} dataItemKey={"id"} component={useProjectFilter} listData={projects} /></FieldWrapper>}


                            </FormElement>
                        </div>

                        <div className="tms-load-filter-filter-column">
                            <FormElement horizontal={true}>

                                {invoicedData && <FieldWrapper><Field name={"Invoiced"} label={"Invoiced"} textField={"Name"} dataItemKey={"value"} component={useFilter} listData={invoicedData} /></FieldWrapper>}
                                {/*<FieldWrapper><Field name={"Invoiced"} component={FormTextBoxField}  label={"Invoiced"} /></FieldWrapper>*/}
                                <FieldWrapper><Field name={"InvoiceNum"} component={FormTextBoxField}  label={"Inv. Num."} /></FieldWrapper>

                            </FormElement>
                        </div>

                        <div className="tms-load-filter-filter-column-button">
                            <FormElement horizontal={true}>
                                <Button type={"submit"} themeColor={"primary"} disabled={!renderProps.allowSubmit} onClick={renderProps.onSubmit} icon="save" style={{height: "70px"}} >
                                    Load Data
                                </Button>
                            </FormElement>
                        </div>

                    </div>)}} {...other} />



            </div>




        </>
    )
}

export default TmsLoadFilter