import {
    Chart,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartSeries,
    ChartSeriesItem, ChartSeriesItemTooltip
} from "@progress/kendo-react-charts";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import 'hammerjs'
import {groupBy, process} from '@progress/kendo-data-query';

const ProjectDetailGraph = props => {

    // const { data, isPending, error} = useFetch(config.API_URL + 'tms/graph/1', 0)
    const data = props.data
    const projects = props.projects

    const series = groupBy(data, [
        {
            field: "PrName",
        },
    ]);

    console.log(series)
    const mapSeries = (item) => (
        <ChartSeriesItem
            data={item.items}
            name={item.value}
            field="Value"
            categoryField="Date"
            type="area"
            stack={true}
            line={{
                style: "smooth",
            }}
        >
            <ChartSeriesItemTooltip visible={true} render={(point) => tooltipTemplate(point, item.value)} />
        </ChartSeriesItem>
    );

    console.log('aaa - ', series, series.map(mapSeries))

    const tooltipTemplate = (point, id) => {
        // console.log('point - ', point)
        // console.log('id - ', id)
        return (
            <div>
                <div>Hodnota: {point.point.value}</div>
                {/*<div>Projekt: <b>{projects.find(prj => prj.ID === id).Name}</b></div>*/}
                <div>Projekt: <b>{point.point.series.name}</b></div>
            </div>
        );
    };



    return(
        <>

            {data && <Chart>
                <ChartCategoryAxis>
                    <ChartCategoryAxisItem type={"date"} maxDivisions={10}>

                    </ChartCategoryAxisItem>
                </ChartCategoryAxis>
                {/*<ChartSeries>*/}
                {/*    {[...new Set(data.map(item => item.ProjectID))].map(id => (*/}
                {/*        <ChartSeriesItem key={id} type={"area"} stack={true}*/}
                {/*                         data={data}  //{data.filter(item => item.ProjectID === id)}*/}
                {/*                         line={{*/}
                {/*                             style: "normal",*/}
                {/*                         }}*/}
                {/*                         field="Value">*/}
                {/*            <ChartSeriesItemTooltip visible={true} render={(point) => tooltipTemplate(point, id)} />*/}
                {/*        </ChartSeriesItem>*/}
                {/*    ))}*/}

                {/*</ChartSeries>*/}
                <ChartSeries>
                    {series.map(mapSeries)}
                </ChartSeries>
            </Chart>}

        </>
    )
}

export default ProjectDetailGraph