import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Button} from "@progress/kendo-react-buttons";

const Confirm = props => {
    return(
        <>


            <Dialog title={"Please confirm"} onClose={props.cancel}
                    height={250}
                    width={400}
            >
                <p
                    style={{
                        margin: "25px",
                        textAlign: "center",
                    }}
                >
                    {props.text}
                </p>
                <DialogActionsBar>

                    <Button
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                        onClick={props.action}
                        themeColor={"error"}
                        style={{height:"40px"}}
                    >
                        Yes
                    </Button>

                    <Button
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                        onClick={props.cancel}
                        themeColor={"success"}
                        style={{height:"40px"}}
                    >
                        No
                    </Button>

                </DialogActionsBar>
            </Dialog>



        </>
    )
}

export default Confirm