import React, {useEffect, useState} from 'react';
import './App.scss';
import { HashRouter, Routes, Route } from 'react-router-dom';
import DrawerRouterContainer from "./components/DrawerRouterContainer";
import Home from "./components/home/Home";
import Clients from "./components/clients/Clients";
import Projects from "./components/projects/Projects";
import Timesheet from "./components/tms/Tms";
import AuditTrail from "./components/audittrail/AuditTrail";
import Users from "./components/admin/users/Users";
import Rights from "./components/admin/Rights";
import Settings from "./components/admin/Settings";
import Groups from "./components/admin/groups/Groups";
import Login from "./components/login/Login";
import Cashflow from "./components/cashflow/Cashflow";
import Unit from "./components/lists/unit/Unit";
import Currency from "./components/lists/currency/Currency";
import Partner from "./components/lists/partner/Partner";
import CFStatus from "./components/lists/CFStatus/CFStatus";
import CFType from "./components/lists/CFType/CFType";
import Price from "./components/price/Price";
import BasePriceContainer from "./components/price/BasePriceContainer";
import ProjectStatus from "./components/lists/projectStatus/ProjectStatus";
import Expenses from "./components/expenses/Expenses";
import ExpenseType from "./components/lists/expenseType/ExpenseType";
import WorkType from "./components/lists/workType/WorkType";
import Tasks from "./components/tasks/Tasks";
import TaskStatus from "./components/lists/taskStatus/TaskStatus";
import TaskPriority from "./components/lists/taskPriority/TaskPriority";
import useOwnRightsStore from "./stores/OwnRightsStore";
import LoginHistory from "./components/loginHistory/LoginHistory";
import ProductStatus from "./components/lists/productStatus/ProductStatus";
import ProductClientStatus from "./components/lists/productClientStatus/ProductClientStatus";
import Product from "./components/products/Product";

function App() {

    const [menuSize, setMenuSize] = useState(0)

    const [userAPIKey, setUserAPIKey] = useState(sessionStorage.getItem("apiKEY"))

    return (

      <>

        {!userAPIKey && <>

          <Login setUserApiKey={setUserAPIKey} />

        </>}

        {userAPIKey &&
          <React.Fragment>
            <HashRouter>
              <DrawerRouterContainer menuSize={menuSize} setMenuSize={setMenuSize}>
                <Routes>
                    <Route exact={true} path="/" element={<Home/>}/>
                    <Route exact={true} path="/clients" element={<Clients menuSize={menuSize}/>}/>
                    <Route exact={true} path="/projects" element={<Projects menuSize={menuSize}/>}/>
                    <Route exact={true} path="/timesheet" element={<Timesheet menuSize={menuSize}/>}/>
                    <Route exact={true} path="/expenses" element={<Expenses menuSize={menuSize}/>}/>
                    <Route exact={true} path="/cashflow" element={<Cashflow menuSize={menuSize}/>}/>
                    <Route exact={true} path="/tasks" element={<Tasks menuSize={menuSize}/>}/>
                    <Route exact={true} path="/audittrail" element={<AuditTrail/>}/>
                    <Route exact={true} path="/users" element={<Users menuSize={menuSize}/>}/>
                    <Route exact={true} path="/groups" element={<Groups menuSize={menuSize}/>}/>
                    <Route exact={true} path="/baseprices" element={<BasePriceContainer menuSize={menuSize} />}/>
                    <Route exact={true} path="/rights" element={<Rights menuSize={menuSize}/>}/>
                    <Route exact={true} path="/settings" element={<Settings menuSize={menuSize}/>}/>
                    <Route exact={true} path="/units" element={<Unit menuSize={menuSize}/>}/>
                    <Route exact={true} path="/currency" element={<Currency menuSize={menuSize}/>}/>
                    <Route exact={true} path="/partners" element={<Partner menuSize={menuSize} />}/>
                    <Route exact={true} path="/cfstatus" element={<CFStatus menuSize={menuSize} />}/>
                    <Route exact={true} path="/cftype" element={<CFType menuSize={menuSize} />}/>
                    <Route exact={true} path="/expense_type" element={<ExpenseType menuSize={menuSize} />}/>
                    <Route exact={true} path="/work_type" element={<WorkType menuSize={menuSize} />}/>
                    <Route exact={true} path="/task_status" element={<TaskStatus menuSize={menuSize} />}/>
                    <Route exact={true} path="/task_priority" element={<TaskPriority menuSize={menuSize} />}/>
                    <Route exact={true} path="/project_status" element={<ProjectStatus menuSize={menuSize} />}/>
                    <Route exact={true} path="/login_history" element={<LoginHistory menuSize={menuSize} />}/>
                    <Route exact={true} path="/products" element={<Product menuSize={menuSize} />}/>
                    <Route exact={true} path="/product_status" element={<ProductStatus menuSize={menuSize} />}/>
                    <Route exact={true} path="/product_client_status" element={<ProductClientStatus menuSize={menuSize} />}/>
                </Routes>
              </DrawerRouterContainer>
            </HashRouter>
          </React.Fragment>
        }
      </>

    );
}

export default App;
