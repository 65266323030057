import {DropDownList} from "@progress/kendo-react-dropdowns";
import {Button} from "@progress/kendo-react-buttons";
import {filterBy} from "@progress/kendo-data-query";
import {useState} from "react";
import {DatePicker} from "@progress/kendo-react-dateinputs";



const DateFilter = props => {

    const textField = "Name"
    const filterable = props.filterable || false
    const field = props.field

    let currentFilters = { ...props.dataState.filter }

    let actualFrom, actualTo
    if (currentFilters.filters) {
        actualFrom = currentFilters.filters.find(function (f) {
            return f.field === field && f.operator === "gte"
        })
        actualTo = currentFilters.filters.find(function (f) {
            return f.field === field && f.operator === "lte"
        })
    }
    const now = new Date()

    actualFrom = actualFrom ? actualFrom.value : now
    actualTo = actualTo ? actualTo.value : now

    console.log("Actuals /// ", actualFrom, actualTo)

    // debugger

    let DateData = []
    const filterType = props.type
    const currentDate = new Date()

    const convertDateToUTC = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();
        const utcDate = new Date(Date.UTC(year, month, day));
        return utcDate;
    };

    switch (filterType) {
        case "past":

            const ThisMonthS = convertDateToUTC(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
            const ThisMonthE = convertDateToUTC(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));

            const LastMonthS = convertDateToUTC(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
            const LastMonthE = convertDateToUTC(new Date(currentDate.getFullYear(), currentDate.getMonth(), 0));

            const ThisAndLastMonthS = convertDateToUTC(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
            const ThisAndLastMonthE = convertDateToUTC(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));

            const ThisYearS = convertDateToUTC(new Date(currentDate.getFullYear(), 0, 1));
            const ThisYearE = convertDateToUTC(new Date(currentDate.getFullYear(), 11, 31));

            const LastYearS = convertDateToUTC(new Date(currentDate.getFullYear() -1, 0, 1));
            const LastYearE = convertDateToUTC(new Date(currentDate.getFullYear() -1, 11, 31));

            console.log("nastavene /// ", ThisYearS)

            DateData = [
                {ID:2, Name: "This month", From:ThisMonthS, To:ThisMonthE},
                {ID:1, Name: "Last month", From:LastMonthS, To:LastMonthE},
                {ID:5, Name: "This & Last month", From:ThisAndLastMonthS, To:ThisAndLastMonthE},
                {ID:3, Name: "This year", From:ThisYearS, To:ThisYearE},
                {ID:4, Name: "Last year", From:LastYearS, To:LastYearE}
            ]
            break

        case "future":
            const f_ThisMonthS = convertDateToUTC(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
            const f_ThisMonthE = convertDateToUTC(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));

            const f_NextMonthS = convertDateToUTC(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
            const f_NextMonthE = convertDateToUTC(new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0));

            const f_ThisYearS = convertDateToUTC(new Date(currentDate.getFullYear(), 0, 1));
            const f_ThisYearE = convertDateToUTC(new Date(currentDate.getFullYear(), 11, 31));

            const f_NextYearS = convertDateToUTC(new Date(currentDate.getFullYear() +1, 0, 1));
            const f_NextYearE = convertDateToUTC(new Date(currentDate.getFullYear() +1, 11, 31));


            DateData = [
                {ID:2, Name: "This month", From:f_ThisMonthS, To:f_ThisMonthE},
                {ID:1, Name: "Last month", From:f_NextMonthS, To:f_NextMonthE},
                {ID:3, Name: "This year", From:f_ThisYearS, To:f_ThisYearE},
                {ID:4, Name: "Last year", From:f_NextYearS, To:f_NextYearE}
            ]
            break

        default:
            DateData = props.data

    }


    let hasValue = (value) => Boolean(value && value !== props.defaultItem);
    // debugger
    const onChange = (event) => {

        hasValue = hasValue(event.target.value);

        let currentFilters = { ...props.dataState.filter }

        //TODO: Dodelat pro datum do

        // const dateTo = new Date(event.target.value.To).toLocaleString()
        // const dateFrom = new Date(event.target.value.From).toLocaleString()

        const dateTo = event.target.value.To
        const dateFrom = event.target.value.From


        // Filter from
        if (currentFilters.filters) {
            let newFilter = currentFilters.filters.filter((filter) => {
                return !(
                    filter.field === field // && // Smaze vsechny filtery na dane pole
                    // filter.operator === "gte"
                );
            });
            currentFilters.filters = newFilter;
            currentFilters.filters.push({
                field: field,
                operator: "gte",
                value: dateFrom,
            });
            currentFilters.filters.push({
                field: field,
                operator: "lte",
                value: dateTo,
            });
        } else {
            currentFilters.filters = [];
            currentFilters.logic = "and";
            currentFilters.filters.push({
                field: field,
                operator: "gte",
                value: dateFrom,
            });
            currentFilters.filters.push({
                field: field,
                operator: "lte",
                value: dateTo,
            });
        }




        props.setDataState({...props.dataState, filter: currentFilters});
        console.log(props.dataState)

        //TODO: https://www.telerik.com/kendo-react-ui/components/knowledge-base/grid-date-ragne-filter/

    };


    const onClearButtonClick = (event) => {
        let currentFilters = { ...props.dataState.filter };
        let newFilter = currentFilters.filters.filter((filter) => {
            return filter.field !== field;
        });
        currentFilters.filters = newFilter;
        props.setDataState({...props.dataState, filter: currentFilters });
    };

    const [data, setData] = useState(DateData.slice());
    //
    // const filterData = (filter) => {
    //     const data = DateData.slice();
    //     return filterBy(data, filter);
    // };
    // const filterChange = (event) => {
    //     setData(filterData(event.filter));
    // };

    return (
        <>

            <div className="k-filtercell">
                <DropDownList
                    data={data}
                    onChange={onChange}
                    value={DateData.find((c) => c.From.getTime() === actualFrom.getTime() && c.To.getTime() === actualTo.getTime()) || props.defaultItem}
                    // defaultItem={props.defaultItem}
                    textField={"Name"}
                    filterable={filterable}
                    // onFilterChange={filterChange}
                    //dataItemKey="ID"
                />

                <Button
                    title="Clear"

                    themeColor={hasValue(props.value) ? "error" : "base" }
                    disabled={!hasValue(props.value)}
                    onClick={onClearButtonClick}
                    icon="filter-clear"
                />
            </div>

        </>
    )
}

export default DateFilter