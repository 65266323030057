import "./Waiting.css"

const Waiting = () => {

    return (
        <>

        <div className="waiting">
            <span>

                <div className="lds-roller">
                    <div></div><div></div>
                    <div></div><div></div><div></div>

                </div>

            </span>
        </div>

        </>
    )

}

export default Waiting