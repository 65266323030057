import {filterBy, process} from "@progress/kendo-data-query";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {Grid, GridToolbar} from "@progress/kendo-react-grid";
import {useEffect, useState} from "react";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import Waiting from "../waiting/Waiting";
import DropDownFilter from "../filterComponents/DropDownFilter";
import DropDownEditor from "../editComponents/DropDownEditor";
import useClientStore from "../../stores/ClientStore";
import DateFilter from "../filterComponents/DateFilter";
import useProjectStore from "../../stores/ProjectStore";




const initialDataState = {
    take: 100,
    skip: 0,
    //pageSize:100,
    sort:[
        {dir: "desc", field: "Date" }
    ],
};


const LoginHistoryGrid = (props) => {

    const isSmallHeight = window.innerHeight < 600

    // const refresh = props.refresh
    // const {selectedClient} = useClientStore((state) => ({selectedClient: state.selectedClient}))
    // const {selectedProject} = useProjectStore((state) => ({selectedProject: state.selectedItem}))

    const refSize = props.size
    const menuSize = props.menuSize
    const dataType = props.dataType || "all"

    console.log("expense grid datatype - ", props)


    const [dataState, setDataState] = useState(initialDataState);
    const [refresh, setRefresh] = useState(Date.now())

    const lh = props.data
    const data = lh.map(item => ({...item, Date: new Date(item.Date)}))

    const { data:authors, isPending:authors_isPending, error:authors_error} = useFetch(config.API_URL + 'user/authors/', refresh)
    // const { data:projects, isPending:projects_isPending, error:projects_error} = useFetch(config.API_URL + 'project/', refresh)
    const { data:actual_user, isPending:actual_user_isPending, error:actual_user_error} = useFetch(config.API_URL + 'user/get_own_user', 1)


    const CountFooter = (props) => {
        const field = props.field || "";

        const filteredData = dataState.filter ? filterBy(data, dataState.filter) : data;
        const total = filteredData.length

        return (
            <td
                style={props.style} // this applies styles that lock the column at a specific position
                className={props.className} // this adds classes needed for locked columns
                colSpan={props.colSpan}
                role={"gridcell"}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
            >
                {total} záznamů
            </td>
        );
    };

    // useEffect(() => {
    //     if (data) {
    //         data = data.map(item => ({...item, Date: new Date(item.Date)}))
    //     }
    // }, [data]);

    const useAuthorEditor = props => (
        <DropDownEditor
            {...props}
            listData={authors}
        />
    );

    const AuthorFilter = (props) => (
        <DropDownFilter
            {...props}
            data={authors}
            filterable={true}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );

    const useDateFilter = (props) => (
        <DateFilter
            {...props}
            defaultItem={{ID: 0, Name: "All"}}
            type={"past"}
            dataState={dataState}
            setDataState={setDataState}
        />
    );




    //endregion


    //region edit functions



    //endregion


    // ************************************** GRID *************************************


    // const [page, setPage] = useState(initialDataState);
    const [pageSizeValue, setPageSizeValue] = useState(10);

    const pageChange = (event) => {

        const targetEvent = event.syntheticEvent;
        const take = targetEvent.value === "All" ? process(data, dataState).length : event.page.take;

        if (targetEvent.value) {
            setPageSizeValue(targetEvent.value);
        }

        setDataState({
            ...dataState,
            skip: event.page.skip,
            take: event.page.take
        });
    };

    return(
        <>

            {data && authors &&


                <Grid
                //pageable={true}
                sortable={true}
                filterable={true}
                //scrollable={"virtual"} //virtual, scrollable
                resizable={true}
                reorderable={true}
                //rowHeight={45}
                // onItemChange={itemChange}

                editField="inEdit"
                size={"small"}


                style={{
                    height: "100%", //"calc(100% - 55px)", // (refSize.height - 150) / 2,//"500px",
                    minHeight: 20,
                    //maxHeight: 300, // refSize.height - 70,
                    width: refSize.width - menuSize,
                }}
                data={process(data, dataState)}{...dataState}

                total={process(data, dataState).length}
                pageable={{
                    buttonCount: 4,
                    pageSizes: [10, 50, 100, 500],
                    pageSizeValue: pageSizeValue,
                }}
                onPageChange={pageChange}

                //onPageChange={pageChange}
                onDataStateChange={(e) => setDataState(e.dataState)}

                >


                    <Column field="ID" title="ID" width="80px" filterable={true} editable={false} />

                    <Column field="UserID" minResizableWidth={120} title="User" cell={useAuthorEditor}
                            filterCell={AuthorFilter} width={200} editable={true} locked />

                    <Column field="Date" minResizableWidth={100} title="Datum" width={150}
                            filter="date"
                            format="{0:dd.MM.yyyy HH:mm:ss}"
                            editor="date"
                            //cell={DateCell}
                            filterCell={useDateFilter}
                    />

                    <Column field="UserName" minResizableWidth={120} title="UName" width={120}/>
                    {/*<Column field="Pass" minResizableWidth={120} title="Faktura" width={120}/>*/}
                    <Column field="IP" minResizableWidth={120} title="IP" width={120}/>
                    <Column field="OtherInfo" minResizableWidth={120} title="Other info" width={900}/>

                </Grid>

            }






                {/*<div className="list-footer"></div>*/}


                {/*{openForm && <EditClient*/}
                {/*    cancelEdit={handleCancelEdit}*/}
                {/*    onSubmit={handleSubmit}*/}
                {/*    item={editItem}*/}
                {/*/>}*/}

        </>
    )
}

export default LoginHistoryGrid