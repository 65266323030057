import {Splitter, StackLayout} from "@progress/kendo-react-layout";
import {useLayoutEffect, useState} from "react";
import {Button, Toolbar, ToolbarItem, ToolbarSpacer} from "@progress/kendo-react-buttons";
import useCashflowStore from "../../stores/CashflowStore";
import TmsGrid from "./TmsGrid";
import TmsLoadFilter from "./TmsLoadFilter";


const Tms = props => {

    const menuSize = props.menuSize
    const [refSize, setRefSize] = useState({width:0, height:0});

    const [chartData, setChartData] = useState()

    console.log("tms main size", refSize, menuSize)


    const handleSize = () => {
        // debugger
        let width = window.innerWidth - 10;
        const height = window.innerHeight;
        setRefSize({
            // width: ref.current.offsetWidth,
            // height: ref.current.offsetHeight
            width: width,
            height: height
        })
        console.log("tms size - ", refSize.width, width, window.innerWidth)
    }

    useLayoutEffect(() => {
        handleSize()
        window.addEventListener("resize", handleSize);
        return () => window.removeEventListener("resize", handleSize);
    }, []);

    const [panes, setPanes] = useState([
        {
            size: "50%",
            min: "0",
            collapsible: true,
        },
        {
            // min: "50px",
            collapsible: false,
        },
    ]);


    const addNew = () => {
        // const newDataItem = {
        //     inEdit: true,
        // };
        // setData([newDataItem, ...data]);
        // addNewClient()
    };

    const addNewCashflow = useCashflowStore(state => state.addNew)

    const onChange = (event) => {
        setPanes(event.newState);
    };

    return(
        <>


            {/*<Splitter*/}
            {/*    style={{*/}
            {/*        height: "calc(100vh - 55px)",*/}
            {/*    }}*/}
            {/*    panes={panes}*/}
            {/*    orientation={"vertical"}*/}
            {/*    onChange={onChange}*/}
            {/*>*/}

                <StackLayout
                    style={{
                        height: "calc(100vh - 55px)",
                    }}
                    orientation={"vertical"}
                    gap={0}
                >
                    {/*<div className="list-header">*/}

                    {/*    <Toolbar>*/}
                    {/*        <ToolbarItem>*/}
                    {/*            /!*Zobrazeno {0} z {0}*!/*/}
                    {/*        </ToolbarItem>*/}
                    {/*        <ToolbarSpacer />*/}
                    {/*        <ToolbarItem>*/}

                    {/*            <Button onClick={addNewCashflow}*/}
                    {/*                    themeColor={"base"}*/}
                    {/*            >Nový záznam</Button>*/}


                    {/*        </ToolbarItem>*/}
                    {/*        <ToolbarItem>*/}

                    {/*        </ToolbarItem>*/}
                    {/*    </Toolbar>*/}

                    {/*</div>*/}

                    <TmsLoadFilter />

                    <TmsGrid size={refSize} menuSize={menuSize} dataType={"all"} setData={setChartData}></TmsGrid>

                </StackLayout>
                <></>

                {/*<StackLayout*/}
                {/*    style={{*/}
                {/*        height: "100%",*/}
                {/*    }}*/}
                {/*    orientation={"vertical"}*/}
                {/*    gap={0}*/}
                {/*>*/}

                {/*    <>*/}

                {/*        {chartData && <CashflowGraph data={chartData}/>}*/}

                {/*    </>*/}

                {/*</StackLayout>*/}

            {/*</Splitter>*/}


        </>
    )
}

export default Tms