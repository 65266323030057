import {process} from "@progress/kendo-data-query";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {Grid, GridToolbar} from "@progress/kendo-react-grid";
import config from "../../config/config.json";
import {cloneElement, useEffect, useState} from "react";
import APICall from "../../tools/APICall";
import PartnerEditor from "../editComponents/PartnerEditor";
import useFetch from "../../tools/UseFetch";
import DropDownFilter from "../filterComponents/DropDownFilter";
import EditCommandCell from "../editComponents/EditCommandCell";
import ClientEditor from "../editComponents/ClientEditor";
import CommandCell from "../tableComponents/CommandCell";
import useCashflowStore from "../../stores/CashflowStore";
import useClientStore from "../../stores/ClientStore";
import useProjectStore from "../../stores/ProjectStore";
import EditClient from "../clients/EditClient";
import Confirm from "../general/Confirm";
import FileDialog from "../files/FileDialog";
import DropDownEditor from "../editComponents/DropDownEditor";
import EditProject from "./EditProject";
import CustomTextCell from "../editComponents/CustomTextCell";
import PriceEditor from "../editComponents/PriceEditor";
import useOwnRightsStore from "../../stores/OwnRightsStore";
import {ToolbarItem, ToolbarSeparator} from "@progress/kendo-react-buttons";
import DetailsCell from "../tableComponents/DetailsCell";
import NotesInDialog from "../notes/NotesInDialog";


const ActionCell = (props) => {
    return(
        <>
            {/*<Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"*/}
            {/*        size="small"*/}
            {/*        rounded="small"*/}
            {/*        onClick={() => props.enterEdit(props.dataItem)}*/}
            {/*>Novy klient</Button>*/}
            {/*<span className={"table-header-action"}>Nový klient</span>*/}
            {/*<span className={"table-header-action"}>aaa</span>*/}
        </>
    )
}

const initialDataState = {
    take: 50,
    skip: 0,
    pageSize:50,
};

const ProjectsGrid = (props) => {

    const {selectedClient} = useClientStore((state) => ({selectedClient: state.selectedClient}))

    const {selectedProject, setSelectedProject} = useProjectStore((state) => ({selectedProject: state.selectedItem, setSelectedProject: state.selectItem}))

    const refSize = props.size  || {"width" : window.innerWidth - 10, "height" : window.innerHeight}
    const menuSize = props.menuSize
    const dataType = props.dataType || "all"

    console.log("menusize a reffsize", menuSize, refSize)

    const haveRight = useOwnRightsStore(state => state.haveRight)



    const data = useProjectStore(state => state.storeData).filter(project => (!selectedClient || project.ClientID === selectedClient.ID))

    // const [data, setData] = useState(data1)

    const addNew = useProjectStore(state => state.addNew)
    // const setData = useProjectStore(state => state.setStoreData)
    // const projects = useProjectStore(state => state.clientsOriginal)
    // const discardNew = useProjectStore(state => state.discardNew)
    const saveNew = useProjectStore(state => state.saveNew)
    const saveItem = useProjectStore(state => state.saveItem)
    // const selectedProject = useProjectStore(state => state.selectedItem)
    const setSelected = useProjectStore(state => state.selectItem)
    // const addNewClient = useProjectStore(state => state.addNew)
    const refreshData = useProjectStore(state => state.refreshData)
    const deleteItem = useProjectStore(state => state.deleteItem)

    const loadForClient = useProjectStore(state => state.loadForClient)
    const loadAll = useProjectStore(state => state.loadAll)



    const { data:partners, isPending, error} = useFetch(config.API_URL + 'partner/', 1)
    const { data:units, isPending:units_isPending, error:units_error} = useFetch(config.API_URL + 'lists/unit/', 1)
    const { data:projectStatus, isPending:projectStatus_isPending, error:cprojectStatus_error} = useFetch(config.API_URL + 'lists/project_status/', 1)
    const clients = useClientStore(state => state.clients)


    const isSmallHeight = window.innerHeight < 600

    // const projects = props.projects

    // const selected = props.selected
    // const setSelected = props.setSelected
    // const setSelectedProjectName = props.setSelectedProjectName
    const toggleDisplayParent = props.toggleDisplayParent || null

    const [dataState, setDataState] = useState(initialDataState);

    let columnDefWidth = refSize.width * 0.1
    if (columnDefWidth < 120) columnDefWidth = 120

    // const [selectedCount, setSelectedCount] = useState(0)


    // useEffect(() => {
    //     setSelectedProject(null)
    // },[])

    useEffect(() => {
        switch (dataType) {
            case "client":
                //loadForClient(selectedClient.ID)
                loadAll()
                // setData(data1.filter(project => project.ClientID === selectedClient.ID))
                break
            default:
                loadAll()
        }
    },[selectedClient])


    const useCustomCell = props => (
        <CustomTextCell
            {...props}
            selected={(dataType == "all") ? selectedProject : null}
        />
    );


    const useClientEditor = props => (
        <ClientEditor
            {...props}
            clients={clients}
        />
    );

    const useLimitEditor = props => (
        <PriceEditor
            {...props}
            neutralStyle={""}
            redStyle={" "}
            greenStyle={" "}
        />
    );


    const usePartnerEditor = props => (
        <DropDownEditor
            {...props}
            listData={partners}
        />
    );

    const PartnerFilter = (props) => (
        <DropDownFilter
            {...props}
            data={partners}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );

    const useUnitEditor = props => (
        <DropDownEditor
            {...props}
            listData={units}
            editable={true}
        />
    );

    const UnitFilter = (props) => (
        <DropDownFilter
            {...props}
            data={units}
            filterable={true}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );

    const ClientFilter = (props) => (
        <DropDownFilter
            {...props}
            data={clients}
            filterable={true}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );


    const useProjectStatusEditor = props => (
        <DropDownEditor
            {...props}
            listData={projectStatus}
            editable={true}
        />
    );

    const StatusFilter = (props) => {
        console.log("datastate ... ", dataState)
        return(
            <DropDownFilter
                {...props}
                data={projectStatus}
                filterable={false}
                defaultItem={{ID: 0, Name: "All"}}
            />
        )};

    const rowClick = (props) => {
        console.log("project row click - " + props.dataItem.ID)
        console.log(props.dataItem)
        // debugger
        if (dataType === "all") setSelected(props.dataItem)
        // setSelectedProjectName(props.dataItem.Name)
        if (isSmallHeight) toggleDisplayParent()
        console.log(selectedProject)

        // this.setState({
        //     selectedRowId: props.dataItem.id
        // });

    }

    // useEffect(() => {
    //     setSelectedCount(process(data, dataState).data.length)
    // },[dataState])

    const MyEditCommandCell = (props) => (
        <CommandCell
            {...props}
            edit={enterEdit}
            copy={enterCopy}
            delete={deleteRow}
            showFiles={false}
            showDelete={haveRight("Projects_Del")}
            showCopy={haveRight("Projects_Edit")}
            filesCount={props.dataItem.FilesCount}
        />
    );

    const MyDetailsCell = (props) => (
        <DetailsCell
            {...props}
            showFiles={showFiles}
            filesCount={props.dataItem.FilesCount}
            showNotes={showNotes}
            notesCount={props.dataItem.NotesCount}
        />
    );


    // ********************************* helper data  *********************************

    const [openForm, setOpenForm] = useState(false);
    const [editItem, setEditItem] = useState(null);


    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [deletedItem, setDeletedItem] = useState(null);

    const [showFilesDialog, setShowFilesDialog] = useState(false)
    const [showFilesFor, setShowFilesFor] = useState(null);


// ************************************** EDIT *************************************


    const rowDblClick = props => {
        enterEdit(props.dataItem)
    }

    const enterEdit = (dataItem) => {
        console.log("item edit")

        setEditItem(dataItem)
        setOpenForm(true)
    };


    const handleCancelEdit = (dataItem) => {

        setOpenForm(false);

    };



    const handleSubmit = (dataItem) => {
        //debugger
        if (dataItem.ID === undefined || dataItem.ID === null) {
            saveNew(dataItem)
        }
        else {
            saveItem(dataItem)
        }
        setOpenForm(false);
    };


    // ************************************** NEW *************************************

    // const editNew = () => {
    //     const newItem = {};
    //     setEditItem(newItem)
    //     setOpenForm(true)
    // };

    const editNew = () => {
        const newItem = addNew //{};
        setEditItem(newItem)
        setOpenForm(true)
    };

    // ************************************** COPY *************************************


    const enterCopy = (dataItem) => {
        console.log("copy edit")

        const newItem = {
            ...dataItem,
            ID: null,
            UID: null,
        }

        setEditItem(newItem)
        setOpenForm(true)

        console.log("edit copy ... ", openForm, editItem)
    };



    // ************************************** Delete *************************************

    const deleteRow = (dataItem) => {
        setDeletedItem(dataItem)
        setShowConfirmDelete(true)
    };

    const deleteRowAction = () => {
        deleteItem(deletedItem)
        setShowConfirmDelete(false)
    };

    const cancelDelete = () => {
        setShowConfirmDelete(false)
    };


    // ************************************** Files *************************************

    const showFiles = (dataItem) => {
        // debugger
        setShowFilesFor(dataItem)
        setShowFilesDialog(true)
    };

    const closeFiles = () => {
        refreshData()
        setShowFilesDialog(false)
    };

    // ************************************** Notes *************************************

    const [showNotesFor, setShowNotesFor] = useState(null);
    const showNotes = (dataItem) => {
        // debugger
        setShowNotesFor(dataItem)
    };

    const closeNotes = () => {
        refreshData()
        setShowNotesFor(null)
    };


    // ************************************** GRID *************************************

    // const [page, setPage] = useState(initialDataState);
    const [pageSizeValue, setPageSizeValue] = useState(50);

    const pageChange = (event) => {

        // const targetEvent = event.syntheticEvent;
        // const take = targetEvent.value === "All" ? process(data, dataState).length : event.page.take;
        //
        // if (targetEvent.value) {
        //     setPageSizeValue(targetEvent.value);
        // }

        setPageSizeValue(event.page.take)

        setDataState({
            ...dataState,
            skip: event.page.skip,
            take: event.page.take
        });
    };

    return(
        <>
            {

                console.log(menuSize)

            }
            {partners && clients && units && projectStatus && <Grid
                // pageable={true}
                sortable={true}
                filterable={true}
                //scrollable={"virtual"}
                resizable={true}
                reorderable={true}
                //rowHeight={50}
                // onItemChange={itemChange}
                // rowRender={rowRender}

                editField="inEdit"
                size={"small"}

                style={{
                    height: "100%", // (refSize.height - 150) / 2,//"500px",
                    minHeight: 20,
                    maxHeight: refSize.height - 70,
                    width: refSize.width - menuSize,
                }}
                data={process(data, dataState)}{...dataState}

                total={process(data, dataState).length}
                pageable={{
                    buttonCount: 4,
                    pageSizes: [10, 50, 100, 500],
                    pageSizeValue: pageSizeValue,
                }}
                onPageChange={pageChange}

                onDataStateChange={(e) => setDataState(e.dataState)}
                onRowClick={rowClick}
                onRowDoubleClick={rowDblClick}
            >

                <GridToolbar>

                    {haveRight("Projects_Edit") && <button
                        title="Export to Excel"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={editNew}
                    >
                        Novy projekt
                    </button>}

                    {/*<ToolbarSeparator />*/}
                    {/*<ToolbarItem>zobrazeno: {selectedCount} z celkem {data.length}</ToolbarItem>*/}

                </GridToolbar>

                <Column cell={useCustomCell} field="ID" title="ID" width="60px" filterable={false} editable={false} locked />


                {/*<Column cell={myID} field="ID" title="ID" width="80px" footerCell={myFooterCell} filterable={true}/>*/}
                {/*<Column cell={myID} field="ID" title="ID" width="80px" filterable={true} locked/>*/}

                <Column field="ClientID" minResizableWidth={120} title="Klient" cell={useClientEditor}
                        width={columnDefWidth} filterCell={ClientFilter} locked />

                <Column field="Name" minResizableWidth={200} title="Název" width={columnDefWidth} locked/>
                <Column field="OfferNum" minResizableWidth={130} title="Nabidka" width={130} />
                <Column field="ProjectStatusID" minResizableWidth={80} title="Status" cell={useProjectStatusEditor} width={150} filterCell={StatusFilter} />


                <Column field="FileNum" minResizableWidth={200} title="Spis" width={120}/>


                {/*<Column field="PartnerID" minResizableWidth={100} title="Partner" cell={usePartnerEditor}*/}
                {/*        width={200} filterCell={PartnerFilter} />*/}
                {/*<Column field="UnitID" minResizableWidth={100} title="Středisko" cell={useUnitEditor}*/}
                {/*        width={200} filterCell={UnitFilter} />*/}


                {/*<Column field="PartnerID" minResizableWidth={120} title="Partner" cell={usePartnerEditor}*/}
                {/*        width={columnDefWidth} filterCell={PartnerFilter} />*/}

                <Column field="LastMonth" minResizableWidth={40} title="Minulý měsíc" cell={PriceEditor} width={100} editable={false} />
                <Column field="ToInvoice" minResizableWidth={40} title="k fakturaci" cell={PriceEditor} width={100} editable={false} filter={"numeric"} />


                <Column field="CashFlow" minResizableWidth={40} cell={PriceEditor} title="bilance CZK" width={120} editable={false} filter={"numeric"} />
                {haveRight("View_In_Values") && <Column field="CashFlowWithTMSIn" minResizableWidth={40} cell={PriceEditor} title="bilance CZK In"
                         width={120} editable={false} filter={"numeric"}/>}
                {haveRight("View_Out_Values") && <Column field="CashFlowWithTMSOut" minResizableWidth={40} cell={PriceEditor} title="bilance CZK Out"
                         width={120} editable={false} filter={"numeric"}/>}

                <Column field="ProjectLimit" minResizableWidth={40} cell={useLimitEditor} title="Limit" width={120} editable={false} filter={"numeric"} />

                <Column field="Code" minResizableWidth={120} title="Kód" width={120}/>
                <Column field="Category" minResizableWidth={120} title="Kategorie" width={120}/>
                <Column field="Note" minResizableWidth={100} title="Poznámka" width={300}/>
                {/*<Column field="Tags" minResizableWidth={120} title="Tagy" width={columnDefWidth}/>*/}


                {/*<Column field="action" minResizableWidth={180}*/}
                {/*        cell={MyEditCommandCell}*/}
                {/*    // columnMenu={ActionCell}*/}
                {/*        filterable={false}*/}
                {/*        headerCell={ActionCell}*/}
                {/*        width="180"*/}
                {/*        resizable={true}*/}
                {/*        locked*/}
                {/*    // filterCell={ActionCell}*/}
                {/*/>*/}

                <Column field="details" minResizableWidth={120}
                        cell={MyDetailsCell}
                        filterable={false}
                        headerCell={ActionCell}
                        width="120"
                        resizable={true}
                        locked
                />

                <Column field="action" minResizableWidth={100}
                        cell={MyEditCommandCell}
                    // columnMenu={ActionCell}
                        filterable={false}
                        headerCell={ActionCell}
                        width="100"
                        resizable={true}
                        locked
                    // filterCell={ActionCell}
                />

            </Grid>}

            {openForm && haveRight("Projects_Edit") && <EditProject
                partners={partners}
                units={units}
                clients={clients}
                projectStatuses={projectStatus}
                cancelEdit={handleCancelEdit}
                onSubmit={handleSubmit}
                item={editItem}
            />}

            {showConfirmDelete && <Confirm
                action={deleteRowAction}
                cancel={cancelDelete}
                text={"delete item?"}
            />}


            {showFilesDialog && <FileDialog
                close={closeFiles}
                text={"delete item?"}
                filesFor={showFilesFor}
            />}

            {showNotesFor && <NotesInDialog
                close={closeNotes}
                notesFor={showNotesFor}
            />}

        </>
    )
}

export default ProjectsGrid