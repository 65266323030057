import {create} from "zustand";
import axios from "axios";
import config from "../config/config.json";

const TmsStore = (set, get) => ({


    tms: [],
    tmsOriginal: [],
    isLoading: false,
    error: null,
    inAddMode: false,
    ClientID: 0,
    ProjectID: 0,

    filter: {
        Author: null,
        Partner: null,
        DateRange: null,
        DateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        DateTo: null,
        ClientID: null,
        ProjectID: null,
        Invoiced: null,
        InvoiceNum: null,
    },

    setTms: (newTms) => {
        // debugger
        set({tms: newTms})
        console.log(newTms)
    },

    setFilter: (newFilter) => {
        set({filter: newFilter})
    },

    loadTmsAll: async () => {
        // debugger

        if (get().ClientID != null || get().ProjectID != null) set({tms:[]})
        set({ isLoading: true, error: null, ClientID: null, ProjectID: null, inAddMode: false });

        try {
            const response = await axios.post(config.API_URL + 'tms/load_filter/', get().filter, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            const newData = data.map(item => ({...item, Date: new Date(item.Date)}))
            set({ tms: newData, isLoading: false });
            set((state) => ({tmsOriginal: state.tms}))

        } catch (error) {
            set({ error: error.message, isLoading: false });
        }
    },


    loadTmsForClient: async (clientID) => {
        // debugger

        if (get().ClientID !== clientID) set({tms:[]})
        set({ isLoading: true, error: null, ClientID: clientID, ProjectID: null, inAddMode: false });

        try {
            const response = await axios.get(config.API_URL + 'tms/forClient/' + clientID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            const newData = data.map(item => ({...item, Date: new Date(item.Date)}))
            set({ tms: newData, isLoading: false });
            set((state) => ({tmsOriginal: state.tms}))

        } catch (error) {
            set({ error: error.message, isLoading: false });
        }
    },


    loadTmsForProject: async (projectID) => {
        // debugger

        if (get().ProjectID !== projectID) set({tms:[]})
        set({ isLoading: true, error: null, ClientID: null, ProjectID: projectID , inAddMode: false });

        try {
            const response = await axios.get(config.API_URL + 'tms/forProject/' + projectID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            const newData = data.map(item => ({...item, Date: new Date(item.Date)}))
            set({ tms: newData, isLoading: false });
            set((state) => ({tmsOriginal: state.tms}))

        } catch (error) {
            set({ error: error.message, isLoading: false });
        }
    },


    addNew: () => {
        var newDataItem = {
            // inEdit: true,
        };
        console.log("add new tms", newDataItem)
        if (get().ProjectID !== 0 && get().ProjectID != null) {
            newDataItem = {...newDataItem, ProjectID: get().ProjectID}
        }
        // set((state) => ({tms: [newDataItem, ...state.tms]}))
        // set({inAddMode: true})
        return newDataItem
    },

    discardNew: () => {
        // debugger
        if (get().inAddMode) {

            set({inAddMode: false})

            const newData = [...get().tms];
            newData.splice(0, 1);

            set({tms: newData})

        }
    },

    saveNew: async (dataItem) => {
        // debugger
        //     dataItem.date = dataItem.date.toISOString().split('T')[0]
        try {
            const response = await axios.put(config.API_URL + 'tms/', dataItem, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },

    saveItem: async (dataItem) => {
        // debugger
        // dataItem.date = dataItem.date.toISOString().split('T')[0]
        try {
            const response = await axios.post(config.API_URL + 'tms/' + dataItem.ID, dataItem, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },

    deleteItem: async (dataItem) => {

        try {
            const response = await axios.delete(config.API_URL + 'tms/' + dataItem.ID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            //set((state) => state.loadCashflowForClient(state.ClientID))
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },


    confirmImport: async (importID, blnDelete) => {

        try {
            const response = await axios.get(config.API_URL + (blnDelete ? 'tms/confirm_import_delete/' : 'tms/confirm_import/') + importID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },

    recalculatePrice: async () => {

        let data;

        if (get().ProjectID > 0) data={Type:"project", ProjectID: get().ProjectID}
        else if (get().ClientID > 0) data={Type:"client", ClientID: get().ClientID}
        else  data={Type:"all"}

        try {
            const response = await axios.post(config.API_URL + 'tms/recalculate_price/', data, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },


    recalculatePriceUnInvoiced: async () => {

        let data;

        if (get().ProjectID > 0) data={Type:"project", ProjectID: get().ProjectID}
        else if (get().ClientID > 0) data={Type:"client", ClientID: get().ClientID}
        else  data={Type:"all"}

        try {
            const response = await axios.post(config.API_URL + 'tms/recalculate_price_uninvoiced/', data, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },

    refreshData: () => {
        if (get().ProjectID > 0) set((state) => state.loadTmsForProject(state.ProjectID))
        else if (get().ClientID > 0) set((state) => state.loadTmsForClient(state.ClientID))
        else set((state) => state.loadTmsAll())
    }

})

const useTmsStore = create(TmsStore)

export default useTmsStore