import {
    Button,
    ButtonGroup,
    Toolbar,
    ToolbarItem,
    ToolbarSeparator,
    ToolbarSpacer
} from "@progress/kendo-react-buttons";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import useClientStore from "../../stores/ClientStore";
import useCashflowStore from "../../stores/CashflowStore";
import useOwnRightsStore from "../../stores/OwnRightsStore";

const DetailSelector = (props) => {

    const haveRight = useOwnRightsStore(state => state.haveRight)

    const addNewCashflow = useCashflowStore(state => state.addNew)


    const ViewTypes = [
        {
            text: "Vše",
            id: 0,
        },
        {
            text: "Interní",
            id: 1,
        },
        {
            text: "Externí",
            id: 2,
        },
    ];

    const selectedClient = useClientStore(state => state.selectedClient)

    const selectedDetail = props.selectedDetail
    const setSelectedDetail = props.setSelectedDetail
    const toggleDisplayParent = props.toggleDisplayParent

    const enabled = selectedClient != null
    const SelectedClientname = (enabled ? selectedClient.Name : "")

    return(
        <>
            <div className="detail-selector-outer">

                <span className="detail-selector-buttons">
                    <Toolbar>
                        <ToolbarItem style={{minWidth: 0}}>

                            <span className="detail-selector-caption" onClick={toggleDisplayParent}>{SelectedClientname}</span>
                        </ToolbarItem>
                        <ToolbarSeparator />
                        <ToolbarItem>
                            <ButtonGroup disabled={!enabled}>
                                <Button onClick={() => setSelectedDetail(0)}
                                        themeColor={(selectedDetail === 0) ? "primary" : "base"}
                                >Přehled</Button>
                                {/*<Button onClick={() => setSelectedDetail(10)}*/}
                                {/*        themeColor={(selectedDetail === 10) ? "primary" : "base"}*/}
                                {/*>Poznámky</Button>*/}
                                {/*<Button onClick={() => setSelectedDetail(11)}*/}
                                {/*        themeColor={(selectedDetail === 11) ? "primary" : "base"}*/}
                                {/*>Deník</Button>*/}
                                <Button onClick={() => setSelectedDetail(12)}
                                        themeColor={(selectedDetail === 12) ? "primary" : "base"}
                                >Dokumenty</Button>
                                <Button onClick={() => setSelectedDetail(13)}
                                        themeColor={(selectedDetail === 13) ? "primary" : "base"}
                                >Tasks</Button>
                            </ButtonGroup>
                        </ToolbarItem>
                        <ToolbarSeparator />
                        <ToolbarItem>
                            <ButtonGroup disabled={!enabled}>
                                <Button onClick={() => setSelectedDetail(2)}
                                        themeColor={(selectedDetail === 2) ? "primary" : "base"}
                                >Projekty</Button>
                                {/*<Button onClick={() => setSelectedDetail(21)}*/}
                                {/*        themeColor={(selectedDetail === 21) ? "primary" : "base"}*/}
                                {/*>Produkty</Button>*/}
                                {/*<Button onClick={() => setSelectedDetail(22)}*/}
                                {/*        themeColor={(selectedDetail === 22) ? "primary" : "base"}*/}
                                {/*>Helpdesk</Button>*/}
                            </ButtonGroup>
                        </ToolbarItem>
                        <ToolbarSeparator />
                        <ToolbarItem>
                            <ButtonGroup disabled={!enabled}>

                                <Button onClick={() => setSelectedDetail(1)}
                                        themeColor={(selectedDetail === 1) ? "primary" : "base"}
                                        disabled={!haveRight("Prices_Client_Edit")}
                                >Ceny</Button>

                                <Button onClick={() => setSelectedDetail(3)}
                                        themeColor={(selectedDetail === 3) ? "primary" : "base"}
                                >Výkazy</Button>
                                <Button onClick={() => setSelectedDetail(4)}
                                        themeColor={(selectedDetail === 4) ? "primary" : "base"}
                                >Náklady</Button>
                                <Button onClick={() => setSelectedDetail(5)}
                                        themeColor={(selectedDetail === 5) ? "primary" : "base"}
                                >Cashflow</Button>
                            </ButtonGroup>
                        </ToolbarItem>
                        <ToolbarSeparator />
                        {/*<ToolbarItem>*/}

                        {/*      <DropDownList*/}
                        {/*          data={ViewTypes}*/}
                        {/*          textField="text"*/}
                        {/*          dataItemKey="id"*/}
                        {/*          // value={state.value}*/}
                        {/*          // onChange={handleChange}*/}
                        {/*          style={{*/}
                        {/*              width: "150px",*/}
                        {/*          }}*/}
                        {/*      />*/}

                        {/*</ToolbarItem>*/}

                        <ToolbarSpacer />

                        {/*<ToolbarItem>*/}

                        {/*    {selectedDetail === 2 && <Button onClick={() => setSelectedDetail(1)} themeColor={"success"}>Nový projekt</Button>}*/}
                        {/*    {selectedDetail === 3 && <Button onClick={() => setSelectedDetail(1)}>Nový výkaz</Button>}*/}
                        {/*    {selectedDetail === 4 && <Button onClick={() => setSelectedDetail(1)}>Nový náklad</Button>}*/}
                        {/*    {selectedDetail === 5 && <Button onClick={addNewCashflow}>Nový cashflow záznam</Button>}*/}


                        {/*</ToolbarItem>*/}
                    </Toolbar>
                </span>

                {/*<span className="detail-selector-caption">*/}
                {/*    {clientName}*/}
                {/*</span>*/}

            </div>
        </>
    )
}

export default DetailSelector