import * as React from "react";
import {Dialog, DialogActionsBar, Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import {TextBox, NumericTextBox, TextArea} from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {Error, Hint, Label} from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import FormDropDown from "../formEditComponents/FormDropDown";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import {isDate, isNumeric} from "@progress/kendo-data-query/dist/npm/utils";
import {DateInput, DatePicker} from "@progress/kendo-react-dateinputs";
import DropDownEditor from "../editComponents/DropDownEditor";
import FormTextBoxField from "../formEditComponents/FormTextBoxField";
import FormTextAreaField from "../formEditComponents/FormTextAreaField";
import FormNumBoxField from "../formEditComponents/FormNumBoxField";
import FormDateField from "../formEditComponents/FormDateField";
import NumBoxField from "../formEditComponents/FormNumBoxField";
import TimeField from "../formEditComponents/FormTimeField";
import useOwnRightsStore from "../../stores/OwnRightsStore";


const minValueValidator = value => value >= 0 ? "" : "The value must be 0 or higher";


const comparisonDate = new Date().getDate() > 5 ? new Date(new Date().getFullYear(), new Date().getMonth(), 1) : new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);

const mandatoryFieldValidator = value => value != null ? "" : "mandatory field"

const useProjectEditor = props => (
    <FormDropDown
        {...props}
        textField={"ClientProjectName"}
    />
);



const EditTms = props => {

    const {
        cancelEdit,
        onSubmit,
        item,
        projects,
        authors,
        workTypes,
        editFor,
        selectedClient,
        selectedProject,
        actualUser,
        ...other
    } = props;

    // item.UnitID = units.find((c) => c.ID === item.UnitID)
    // item.PartnerID = partners.find((c) => c.ID === item.PartnerID)

    const originalItem = item

    const haveRight = useOwnRightsStore(state => state.haveRight)

    const unlockedDateFieldValidator = value => {
        if (value != null) {

            if (value >= comparisonDate || haveRight("TMS_Edit_Locked")) {
                return "";
            } else {
                return "Timesheet for this date is locked";
            }

        } else {
            return "mandatory field";
        }
    }

    item.TimeSpentIn = isDate(item.TimeSpentIn) ? item.TimeSpentIn : new Date(0,0,0, Math.floor(item.TimeSpentIn / 60), item.TimeSpentIn % 60)
    item.TimeSpentOut = isDate(item.TimeSpentOut) ? item.TimeSpentOut : new Date(0,0,0, Math.floor(item.TimeSpentOut / 60), item.TimeSpentOut % 60)

    var projectsData = projects



    switch (editFor) {
        case "client":
            projectsData = projects.filter(project => project.ClientID === selectedClient.ID)
            break
        case "project":
            projectsData = projects.filter(project => project.ID === selectedProject.ID)
            break
        default:

    }


    console.log("projectsData", projectsData, editFor, selectedClient, selectedProject)
    console.log("editFor", editFor)
    console.log("SelectedClient", selectedClient)
    console.log("projectsProject", selectedProject)
    console.log("projects", projects)


    const convertDateToUTC = (date) => {

        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        const utcDate = new Date(Date.UTC(year, month, day));

        return utcDate;
    };

    const handleSubmit = dataItem => {
        debugger

        // if (!haveRight("View_Out_Values")) dataItem.TimeSpentOut = dataItem.TimeSpentIn

        dataItem.Date = convertDateToUTC(dataItem.Date)
        if (isDate(dataItem.TimeSpentIn)) dataItem.TimeSpentIn = 60 * dataItem.TimeSpentIn.getHours() + dataItem.TimeSpentIn.getMinutes()


        if (!isNumeric(dataItem.ProjectID)) dataItem.ProjectID = dataItem.ProjectID.ID
        if (!isNumeric(dataItem.AuthorID)) dataItem.AuthorID = dataItem.AuthorID.ID

        if (dataItem.WorkTypeID !== null && dataItem.WorkTypeID !== undefined) {
            if (!isNumeric(dataItem.WorkTypeID)) dataItem.WorkTypeID = dataItem.WorkTypeID.ID
        }
        else {
            dataItem.WorkTypeID = 1
        }

        console.log("dataItem.AuthorOutID - ", dataItem.AuthorOutID)
        if (dataItem.AuthorOutID !== null && dataItem.AuthorOutID !== undefined) {
            if (!isNumeric(dataItem.AuthorOutID)) dataItem.AuthorOutID = dataItem.AuthorOutID.ID
            if (dataItem.AuthorOutID === 0) dataItem.AuthorOutID = null
        }

        // debugger
        // if (!isDate(dataItem.TimeSpentOut) || (dataItem.TimeSpentOut.getHours() + dataItem.TimeSpentOut.getMinutes()) === 0) {
        //     dataItem.TimeSpentOut = dataItem.TimeSpentIn
        // }
        // else {
        //     if (isDate(dataItem.TimeSpentOut)) dataItem.TimeSpentOut = 60 * dataItem.TimeSpentOut.getHours() + dataItem.TimeSpentOut.getMinutes()
        // }

        if (isDate(dataItem.TimeSpentOut)) dataItem.TimeSpentOut = 60 * dataItem.TimeSpentOut.getHours() + dataItem.TimeSpentOut.getMinutes()
        console.log("dataItem.AuthorOutID 2 - ", dataItem.AuthorOutID)


        onSubmit(dataItem)
    }

    const handleClose = dataItem => {
        // item.TimeSpentIn = originalItem.TimeSpentIn
        // item.TimeSpentOut = originalItem.TimeSpentOut
        cancelEdit()
    }

    var locked = false

    var title = ``
    if (item.ID === null) {
        title = "Copy TMS"
    }
    else if (item.ID === undefined) {
        title = "Create new TMS"
    }
    else {
        // console.log("a2", item.AuthorID, actual_user.ID, haveRight("TMS_Fill_For_Others"))
        title = `Edit TMS: ${item.ID}`
        if ((item.Date < comparisonDate && !haveRight("TMS_Edit_Locked")) || (item.AuthorID !== actualUser.ID && !haveRight("TMS_Fill_For_Others"))) {
            title += ` - LOCKED`
            locked = true
        }
    }


    // const { data:partners, isPending, error} = useFetch(config.API_URL + 'partner/', 1)

    return <Form initialValues={item} onSubmit={handleSubmit} render={renderProps =>

        <Window title={title} onClose={handleClose}
                initialHeight={800}
                initialWidth={600}
                modal={true}
        >
            <div className="editForm">
                <FormElement disabled={locked} horizontal={true}>



                    {projectsData && <FieldWrapper><Field disabled={locked} name={"ProjectID"} label={"Project"} textField={"Name"} dataItemKey={"id"} component={useProjectEditor} listData={projectsData} validator={mandatoryFieldValidator}  /></FieldWrapper>}
                    {authors && <FieldWrapper><Field name={"AuthorID"} label={"Autor"} textField={"Name"} dataItemKey={"id"} component={FormDropDown} disabled={locked || !haveRight("TMS_Fill_For_Others")} listData={authors} validator={mandatoryFieldValidator} /></FieldWrapper>}
                    {haveRight("View_Out_Values") && workTypes && <FieldWrapper><Field disabled={locked} name={"WorkTypeID"} label={"Type"} textField={"Name"} dataItemKey={"id"} component={FormDropDown} listData={workTypes} validator={mandatoryFieldValidator} /></FieldWrapper>}


                    {/*<FieldWrapper><Field name={"Code"} component={FormTextBoxField}  label={"Code"} /></FieldWrapper>*/}
                    {/*<FieldWrapper><Field name={"Category"} component={FormTextBoxField}  label={"Category"} /></FieldWrapper>*/}

                    <FieldWrapper><Field disabled={locked} name={"Date"} component={FormDateField}  label={"Date"} validator={unlockedDateFieldValidator} /></FieldWrapper>

                    <FieldWrapper><Field disabled={locked} name={"TimeSpentIn"} component={TimeField} steps={{"hour":1, "minute":5}} label={"Trvani"} /></FieldWrapper>


                    <FieldWrapper><Field disabled={locked} name={"Note"} component={FormTextAreaField}  label={"Text"} /></FieldWrapper>
                    {/*<FieldWrapper><Field name={"Tags"} component={FormTextBoxField}  label={"Tagy"} /></FieldWrapper>*/}

                    {/*<FieldWrapper><Field name={"InvoiceNum"} component={FormTextBoxField}  label={"InvoiceNum"} /></FieldWrapper>*/}



                    <br /><br />
                    {haveRight("View_Out_Values") && authors && <FieldWrapper><Field disabled={locked} name={"AuthorOutID"} label={"Autor out"} textField={"Name"} dataItemKey={"id"} defaultItem={{ID:0, Name:"none"}} component={FormDropDown} listData={authors} /></FieldWrapper>}
                    {haveRight("View_Out_Values") && <FieldWrapper><Field disabled={locked} name={"TimeSpentOut"} component={TimeField} steps={{"hour":1, "minute":5}} label={"Trvani Out"} /></FieldWrapper>}
                    {haveRight("View_Out_Values") && <FieldWrapper><Field disabled={locked} name={"NoteOut"} component={FormTextAreaField}  label={"Text out"} /></FieldWrapper>}

                    {/*<FieldWrapper>*/}
                    {/*    <Field name={"UnitPrice"} component={NonNegativeNumericInput} label={"Price"} validator={minValueValidator} />*/}
                    {/*</FieldWrapper>*/}
                    {/*<FieldWrapper>*/}
                    {/*    <Field name={"UnitsInStock"} component={NonNegativeNumericInput} label={"In stock"} validator={minValueValidator} />*/}
                    {/*</FieldWrapper>*/}
                </FormElement>
            </div>

            <WindowActionsBar layout="start">
                <Button type={"submit"} themeColor={"primary"} disabled={locked || !renderProps.allowSubmit} onClick={renderProps.onSubmit} icon="save" >
                    Update
                </Button>
                <Button onClick={handleClose} icon="cancel" >
                    Cancel
                </Button>
            </WindowActionsBar>

        </Window>} {...other} />;
};
export default EditTms;