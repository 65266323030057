import {StackLayout} from "@progress/kendo-react-layout";
import {useLayoutEffect, useState} from "react";
import ProductClientStatusGrid from "./ProductClientStatusGrid";

const ProductClientStatus = props => {

    const menuSize = props.menuSize
    const [refSize, setRefSize] = useState({width:0, height:0});


    const handleSize = () => {
        // debugger
        let width = window.innerWidth - 10;
        const height = window.innerHeight;
        setRefSize({
            // width: ref.current.offsetWidth,
            // height: ref.current.offsetHeight
            width: width,
            height: height
        })
        console.log(refSize.width, width, window.innerWidth)
    }

    useLayoutEffect(() => {
        handleSize()
        window.addEventListener("resize", handleSize);
        return () => window.removeEventListener("resize", handleSize);
    }, []);


    return(
        <>


                <StackLayout
                    style={{
                        height: "calc(100vh - 55px)",
                    }}
                    orientation={"vertical"}
                    gap={0}
                >

                    <ProductClientStatusGrid size={refSize} menuSize={menuSize} dataType={"all"}></ProductClientStatusGrid>

                </StackLayout>

        </>
    )
}

export default ProductClientStatus