import {process} from "@progress/kendo-data-query";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {Grid} from "@progress/kendo-react-grid";
import {useEffect, useState} from "react";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import Waiting from "../waiting/Waiting";
import DropDownEditor from "../editComponents/DropDownEditor";
import {CellEditCellRender, CellEditRowRender} from "../gridRenders/CellEditRenders";
import APICall from "../../tools/APICall";
import CommandCell from "../tableComponents/CommandCell";
import useOwnRightsStore from "../../stores/OwnRightsStore";
import EditProject from "../projects/EditProject";
import EditPrice from "./EditPrice";
import usePriceStore from "../../stores/PriceStore";

const EDIT_FIELD = "inEdit";

const ActionCell = (props) => {
    return(
        <>
            {/*<Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"*/}
            {/*        size="small"*/}
            {/*        rounded="small"*/}
            {/*        onClick={() => props.enterEdit(props.dataItem)}*/}
            {/*>Novy klient</Button>*/}
            {/*<span className={"table-header-action"}>Nový klient</span>*/}
            {/*<span className={"table-header-action"}>aaa</span>*/}
        </>
    )
}

const initialDataState = {
    // take: 50,
    skip: 0,
    pageSize:100,
};


const PriceGrid = (props) => {

    // const refresh = props.refresh
    const selectedClient = props.selectedClient
    const selectedProject = props.selectedProject

    const dataType = props.dataType || "all"

    const refSize = props.size
    const menuSize = props.menuSize

    const [dataState, setDataState] = useState(initialDataState);
    const [refresh, setRefresh] = useState(Date.now())


    let columnDefWidth = 100
    // let columnDefWidth = refSize.width * 0.1
    // if (columnDefWidth < 120) columnDefWidth = 120


    // let apiURL;
    // if (selectedProject) apiURL = config.API_URL + 'project/prices/' + selectedProject.ID
    // else apiURL = config.API_URL + 'client/prices/' + selectedClient.ID

    // const { data:priceView, isPending, error} = useFetch(apiURL, refresh)
    const { data:currency, isPending:currency_isPending, error:currency_error} = useFetch(config.API_URL + 'lists/currency/', refresh)

    const priceView = usePriceStore(state => state.userPrice)

    const saveOrAdd = usePriceStore(state => state.saveOrAddItem)
    const refreshData = usePriceStore(state => state.refresh)
    const loadForClient = usePriceStore(state => state.loadForClient)
    const loadForProject = usePriceStore(state => state.loadForProject)
    const loadBase = usePriceStore(state => state.loadBase)

    const haveRight = useOwnRightsStore(state => state.haveRight)





    const [originalData, setOriginalData] = useState(priceView)
    const [data, setData] = useState(priceView)

    const [changes, setChanges] = useState(false)



    // useEffect(() => {
    //     switch (dataType) {
    //         case "client":
    //             loadForClient(selectedClient.ID)
    //             break
    //         case "project":
    //             loadForProject(selectedProject.ID)
    //             break
    //         default:
    //             loadBase()
    //     }
    //
    //     // console.log("projectMasterPrice - ", projectMasterPrice)
    //
    // },[selectedClient, selectedProject])


    console.log("price view - ", priceView)

    const useCurrencyEditor = props => (
        <DropDownEditor
            {...props}
            listData={currency}
        />
    );


    const itemChange = (event) => {
        let field = event.field || "";
        event.dataItem[field] = event.value;
        let newData = data.map((item) => {
            if (item.UserID === event.dataItem.UserID) {
                item[field] = event.value;
            }
            return item;
        });
        setData(newData);
        setChanges(true);
    };


    const exitEdit = (dataItem) => {
        const newData = data.map((item) => ({
            ...item,
            [EDIT_FIELD]: undefined,
        }));
        setData(newData);
        //TODO doplnit ulozeni dat
        APICall('POST', config.API_URL + 'price/', dataItem)
        setRefresh(Date.now())
    };

    const customCellRender = (td, props) => (
        <CellEditCellRender
            originalProps={props}
            td={td}
            enterEdit={enterEdit}
            exitEdit={exitEdit}
            editField={EDIT_FIELD}
        />
    );
    const customRowRender = (tr, props) => (
        <CellEditRowRender
            originalProps={props}
            tr={tr}
            exitEdit={exitEdit}
            editField={EDIT_FIELD}
        />
    );



    const MyEditCommandCell = (props) => (
        <CommandCell
            {...props}
            edit={enterEdit}
            delete={deleteRow}
            showDelete={haveRight("Projects_Del")}
            showCopy={false}
            filesCount={props.dataItem.FilesCount}
        />
    );

    // ********************************* helper data  *********************************

    const [openForm, setOpenForm] = useState(false);
    const [editItem, setEditItem] = useState(null);


    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [deletedItem, setDeletedItem] = useState(null);

    const [showFilesDialog, setShowFilesDialog] = useState(false)
    const [showFilesFor, setShowFilesFor] = useState(null);

    // ************************************** EDIT *************************************


    const rowDblClick = props => {
        enterEdit(props.dataItem)
    }

    const enterEdit = (dataItem) => {
        console.log("item edit")

        setEditItem(dataItem)
        setOpenForm(true)
    };


    const handleCancelEdit = (dataItem) => {
        setOpenForm(false);
    };

    const handleSubmit = (dataItem) => {
        saveOrAdd(dataItem)
        setOpenForm(false);
    };

    // ************************************** Delete *************************************

    const deleteRow = (dataItem) => {
        setDeletedItem(dataItem)
        setShowConfirmDelete(true)
    };

    return(


        <>
            {data && currency &&


                <Grid
                    //pageable={true}
                    sortable={true}
                    filterable={true}
                    scrollable={"scrollable"} //virtual
                    resizable={true}
                    reorderable={true}
                    rowHeight={40}
                    onItemChange={itemChange}
                    cellRender={customCellRender}
                    rowRender={customRowRender}

                    editField={EDIT_FIELD}

                    style={{
                        height: "100%", //"calc(100% - 55px)", // (refSize.height - 150) / 2,//"500px",
                        minHeight: 20,
                        //maxHeight: 300, // refSize.height - 70,
                        width: refSize.width - menuSize,
                    }}
                    data={process(priceView, dataState)}{...dataState}

                    onRowDoubleClick={rowDblClick}

                    //onPageChange={pageChange}
                    onDataStateChange={(e) => setDataState(e.dataState)}
                    >

                        <Column field="UserID" title="UserID" width="80px" filterable={false} editable={false} />
                        <Column field="Name" minResizableWidth={80} title="Jméno" width={300} locked editable={false} />

                        <Column title={"Individuální"}>
                            <Column field="PriceIn" minResizableWidth={80} title="Cena In" width={columnDefWidth} editor={"numeric"} filterable={false} />
                            <Column field="CurrencyInID" minResizableWidth={80} title="Měna In" cell={useCurrencyEditor} width={columnDefWidth} filterable={false}/>
                            <Column field="PriceOut" minResizableWidth={80} title="Cena Out" width={columnDefWidth} editor={"numeric"} filterable={false} />
                            <Column field="CurrencyOutID" minResizableWidth={80} title="Měna Out" cell={useCurrencyEditor} width={columnDefWidth} filterable={false}/>
                        </Column>

                    {/*<Column field="action" minResizableWidth={180}*/}
                    {/*        cell={MyEditCommandCell}*/}
                    {/*    // columnMenu={ActionCell}*/}
                    {/*        filterable={false}*/}
                    {/*        headerCell={ActionCell}*/}
                    {/*        width="180"*/}
                    {/*        resizable={true}*/}
                    {/*        locked*/}
                    {/*    // filterCell={ActionCell}*/}
                    {/*/>*/}

                        <Column title={"Použije se ..."}>
                            <Column field="PriceInEffective" minResizableWidth={80} title="Cena In" width={columnDefWidth} editable={false} filterable={false}/>
                            <Column field="CurrencyInIDEffective" minResizableWidth={80} title="Měna In" cell={useCurrencyEditor} editable={false} width={columnDefWidth} filterable={false}/>
                            <Column field="PriceOutEffective" minResizableWidth={80} title="Cena Out" width={columnDefWidth}  editable={false} filterable={false} />
                            <Column field="CurrencyOutIDEffective" minResizableWidth={80} title="Měna Out" cell={useCurrencyEditor} editable={false} width={columnDefWidth} filterable={false}/>
                        </Column>

                </Grid>

            }


            {openForm && haveRight("Projects_Edit") && <EditPrice
                cancelEdit={handleCancelEdit}
                onSubmit={handleSubmit}
                currency={currency}
                item={editItem}
            />}

            {/*{isPending && <Waiting />}*/}

                {/*<div className="list-footer"></div>*/}


                {/*{openForm && <EditClient*/}
                {/*    cancelEdit={handleCancelEdit}*/}
                {/*    onSubmit={handleSubmit}*/}
                {/*    item={editItem}*/}
                {/*/>}*/}

        </>
    )
}

export default PriceGrid