import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import {useEffect, useState} from "react";
import NoteInGrid from "./NoteInGrid";
import {Button, ButtonGroup, Toolbar, ToolbarSeparator} from "@progress/kendo-react-buttons";
import EditProject from "../projects/EditProject";
import useOwnRightsStore from "../../stores/OwnRightsStore";
import axios from "axios";
import EditNote from "./EditNote";
import {StackLayout} from "@progress/kendo-react-layout";
import * as notes from "@progress/kendo-data-query/dist/npm/transducers";
import {Pair as note} from "yaml/types";





const Notes = props => {


    // const saveNew = dataItem => {
    //     try {
    //         const response = axios.put(config.API_URL + 'note/' + props.notesFor.UID, dataItem, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
    //     } catch (error) {
    //     }
    // };

    const saveNew = dataItem => {
        axios.put(config.API_URL + 'note/' + props.notesFor.UID, dataItem, {
            headers: {'x-api-key': sessionStorage.getItem("apiKEY")}
        })
            .then((response) => {
                // Zde můžeme provést tvou akci pro úspěšný výsledek.
                console.log(response);
                setRefNum(new Date())
            })
            .catch((error) => {
                // Zde můžeme provést tvou akci pro chybovou odpověď.
                console.error(error);
            });
    };

    const saveItem = dataItem => {
        axios.post(config.API_URL + 'note/' + dataItem.ID, dataItem, {
            headers: {'x-api-key': sessionStorage.getItem("apiKEY")}
        })
            .then((response) => {
                // Zde můžeme provést tvou akci pro úspěšný výsledek.
                console.log(response);
                setRefNum(new Date())
            })
            .catch((error) => {
                // Zde můžeme provést tvou akci pro chybovou odpověď.
                console.error(error);
            });
    };


    const haveRight = useOwnRightsStore(state => state.haveRight)

    const [refNum, setRefNum] = useState(new Date())
    const [editItem, setEditItem] = useState(null);

    const [viewSystem, setViewSystem] = useState(false)
    const [viewManual, setViewManual] = useState(true)

    const {data:notes, isPending, error} = useFetch(config.API_URL + 'note/' + props.notesFor.UID, refNum)

    const [notesFiltered, setNotesFiltered] = useState(notes)

    //create useEffect
    useEffect(() => {
        if (notes) {
            setNotesFiltered(notes.filter(note => (viewSystem && note.Type === 0) || (viewManual && note.Type === 1)))
        }
    }, [notes, viewSystem, viewManual])


// ************************************** EDIT *************************************


    const createNew = () => {
        setEditItem({Type: 1})
    }

    const enterEdit = (dataItem) => {
        console.log("note edit")
        setEditItem(dataItem)
    };

    const handleCancelEdit = (dataItem) => {
        setEditItem(null)
    };

    const handleSubmit = (dataItem) => {
        //debugger
        if (dataItem.ID === undefined || dataItem.ID === null) {
            saveNew(dataItem)
        }
        else {
            saveItem(dataItem)
        }
        setEditItem(null);
        // setRefNum(refNum + 1)
    };

    return (
        <div className={"details-page"}>

            {/*<StackLayout*/}
            {/*    // style={{*/}
            {/*    //     height: "100%",*/}
            {/*    //     width: "100%",*/}
            {/*    // }}*/}
            {/*    orientation={"vertical"}*/}
            {/*    gap={0}*/}
            {/*>*/}
                <div className="notes-header">
                    <Toolbar>

                        <Button onClick={createNew}>Add Note</Button>
                        <ToolbarSeparator />

                        View:
                        <ButtonGroup>
                            <Button togglable={true} selected={viewSystem} onClick={() => setViewSystem(!viewSystem)}><span className="k-icon k-i-gear k-icon-sm"/></Button>
                            <Button togglable={true} selected={viewManual} onClick={() => setViewManual(!viewManual)}><span className="k-icon k-i-edit k-icon-sm"/></Button>
                        </ButtonGroup>

                    </Toolbar>
                </div>

                <div className="notes-list-outter">
                    {notesFiltered && notesFiltered.length > 0 && <div className="notes-list">
                        {notesFiltered.map(n => (
                            <NoteInGrid edit={enterEdit} note={n}/>
                        ))}
                    </div>}

                    {editItem && haveRight("Projects_Edit") && <EditNote
                        cancelEdit={handleCancelEdit}
                        onSubmit={handleSubmit}
                        item={editItem}
                    />}

                </div>
            {/*</StackLayout>*/}

        </div>
    );
}

export default Notes