import {useEffect, useState} from "react";
import config from "../../config/config.json";
import "./Login.css"
import {InputSeparator, TextBox} from "@progress/kendo-react-inputs";
import {Button} from "@progress/kendo-react-buttons";
import useClientStore from "../../stores/ClientStore";
import useOwnRightsStore from "../../stores/OwnRightsStore";
import * as React from "react";

const Login = props => {

    const [email, setEmail] = useState("")
    const [pass, setPass] = useState("")
    const [database, setDatabase] = useState("HSBN")

    const [error, setError] = useState("")

    const setUserAPiKey = props.setUserApiKey

    const loadRights = useOwnRightsStore(state => state.loadAll)
    const rights = useOwnRightsStore(state => state.storeData)

    const [viewPass, setViewPass] = useState(false)

    const toggleViewPass = () => {
        setViewPass(!viewPass)
    }



    const POSTData = (url, data) => {

        fetch( url, {
            method: 'POST',
            headers: {"content-type": "application/json"},
            body: JSON.stringify(data)
        }).then(r => {
            //debugger
            console.log('ted')
            console.log(r)
            return r.json()
        }).then(data => {
            //debugger
            console.log('ted jdou data')
            console.log(data)
            sessionStorage.setItem("apiKEY", data.ApiKEY)
            setUserAPiKey(sessionStorage.getItem("apiKEY"))
            setError(data.Error)
            loadRights()
            if (data.err === undefined) {

            }
            else {

            }
        })



    }

    const login = (e) => {

        //debugger
        e.preventDefault()

        let loginData = {
            uname: email,
            pass: pass,
            db: database
        };

        setError("")

        POSTData(config.API_URL + 'user/login', loginData)
    }

    return(
        <>

            <div className="login-page">

                <form onSubmit={login}>
                    <div className="login">

                        <div className="section">

                            <div className="login-version-info">v {config.APP_VERSION}</div>

                            <h2>
                                {/*<img src={config.IMG_URL + "logo_small2.png"} className="login-logo" alt="logo"/>*/}
                                HSBN Login
                            </h2>

                            <span className="login-space-1"></span>

                            <div>Database:</div>
                            <TextBox
                                size={"medium"}
                                fillMode={"solid"}
                                rounded={"small"}
                                color={"red"}
                                value={database}
                                disabled={true}
                                onChange={(e) => setDatabase(e.target.value)}
                                // placeholder="email"
                                label={'e-mail'} //value={email} handleChange={setEmail}
                                style={{
                                    width: "90%",
                                    marginBottom: 10,
                                    marginTop: 3,
                                    padding: 3,
                                    // color: "blue",
                                    background: "#DDDDDD",
                                }}
                            />

                            <div className="login-separator"></div>

                            <div>user:</div>
                            <TextBox
                                size={"medium"}
                                fillMode={"solid"}
                                rounded={"small"}
                                color={"red"}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                // placeholder="email"
                                label={'e-mail'} //value={email} handleChange={setEmail}
                                style={{
                                    width: "90%",
                                    marginBottom: 10,
                                    marginTop: 3,
                                    padding: 3,
                                    // color: "blue",
                                    background: "#DDDDDD",
                                }}
                            />


                            <div>password:</div>
                            <TextBox
                                size={"medium"}
                                fillMode={"solid"}
                                rounded={"small"}
                                type={viewPass ? "text" : "password"}
                                value={pass}
                                onChange={(e) => setPass(e.target.value)}
                                label={'e-mail'} //value={email} handleChange={setEmail}
                                style={{
                                    width: "90%",
                                    marginBottom: 10,
                                    marginTop: 3,
                                    padding: 3,
                                    background: "#DDDDDD",
                                }}
                                suffix={() => (
                                    <>

                                        <InputSeparator />
                                        <Button
                                            onClick={toggleViewPass}
                                            fillMode={"flat"}
                                            icon={"eye"}
                                        >
                                            {/*<span className="k-icon k-icon-md k-i-eye"></span>*/}
                                        </Button>

                                    </>
                                )}
                            />
                            {/*<TextBox type="password" label={'password'} value={pass} handleChange={setPass} desc=""/>*/}

                            <span className="login-space-2"></span>

                            <Button
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                onClick={login}
                                size="large"
                                themeColor="secondary"
                            >
                                Login
                            </Button>


                        </div>

                        {error && <div className="loginError">{error}</div>}



                    </div>


                </form>

            </div>

        </>
    )
}

export default Login