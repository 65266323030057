import {Input, TextBox} from "@progress/kendo-react-inputs";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {DatePicker, DateTimePicker} from "@progress/kendo-react-dateinputs";

const InvoiceBasicParams = props => {



    const tags = props.tags
    const setTags = props.setTags
    const invoiceNum = props.invoiceNum
    const setInvoiceNum = props.setInvoiceNum

    const includeNoNB = props.includeNoNB
    const setIncludeNoNB = props.setIncludeNoNB
    const nonBillableValues = props.nonBillableValues

    const include = props.include
    const setInclude = props.setInclude
    const includeValues = props.includeValues

    const yesNoValues = props.yesNoValues
    const setAsInvoiced = props.setAsInvoiced
    const setSetAsInvoiced = props.setSetAsInvoiced
    const createCashFlow = props.createCashFlow
    const setCreateCashFlow = props.setCreateCashFlow

    const reportType = props.reportType
    const setReportType = props.setReportType
    const reportTypeValues = props.reportTypeValues

    const invNumError = props.invNumError
    const setInvNumError = props.setInvNumError

    const period = props.period
    const setPeriod = props.setPeriod
    const periodItems = props.periodItems

    const selectedAuthor = props.selectedAuthor
    const setSelectedAuthor = props.setSelectedAuthor
    const authors = props.authors

    const periodFrom = props.periodFrom
    const setPeriodFrom = props.setPeriodFrom
    const periodTo = props.periodTo
    const setPeriodTo = props.setPeriodTo


    console.log("include no nb", includeNoNB)

    // debugger


    const updateInvNum = (e) => {
        // e.preventDefault();
        setInvoiceNum(e.target.value);
        setInvNumError(false)
    }

    return(


        <>

            <div className="invoice-dialog-textbox">
                <h2>Číslo Fa:</h2>
                <TextBox
                    value={invoiceNum}
                    onChange={updateInvNum}
                />
                {invNumError &&
                    <div className="invoice-dialog-textbox-invNumError">Toto číslo faktury už existuje</div>}
            </div>

            {/*<div className="invoice-dialog-textbox">*/}
            {/*    <h2>Doplnit tagy:</h2>*/}
            {/*    <TextBox*/}
            {/*        value={tags}*/}
            {/*        onChange={(e) => setTags(e.target.value)}*/}
            {/*    />*/}
            {/*</div>*/}

            <div className="invoice-dialog-textbox">
                <h2>Označit jako fakturované:</h2>
                <DropDownList
                    data={yesNoValues}
                    textField="text"
                    dataItemKey="id"
                    value={setAsInvoiced}
                    onChange={(e) => setSetAsInvoiced(e.value)}
                />
            </div>

            <div className="invoice-dialog-textbox">
                <h2>Vytvořit Cashflow:</h2>
                <DropDownList
                    data={yesNoValues}
                    textField="text"
                    dataItemKey="id"
                    value={createCashFlow}
                    onChange={(e) => setCreateCashFlow(e.value)}
                    disabled={true}
                />
            </div>

            <br/>

            <div className="invoice-dialog-textbox">
                <h2>Zahrnout:</h2>
                <DropDownList
                    data={includeValues}
                    textField="text"
                    dataItemKey="id"
                    value={include}
                    onChange={(e) => setInclude(e.value)}
                />
            </div>

            <div className="invoice-dialog-textbox">
                <h2>Non-billable:</h2>
                <DropDownList
                    data={nonBillableValues}
                    textField="text"
                    dataItemKey="id"
                    value={includeNoNB}
                    onChange={(e) => setIncludeNoNB(e.value)}
                />
            </div>

            <div className="invoice-dialog-textbox">
                <h2>Advokát:</h2>
                <DropDownList
                    // value={1}
                    defaultItem={{Name: "All", ID:0}}
                    data={authors}
                    textField="Name"
                    dataItemKey="ID"
                    value={selectedAuthor}
                    onChange={(e) => setSelectedAuthor(e.value)}
                />
            </div>

            <div className="invoice-dialog-textbox">
                <h2>Období:</h2>
                <DropDownList
                    // value={1}

                    data={periodItems}
                    textField="text"
                    dataItemKey="id"
                    value={period}
                    onChange={(e) => setPeriod(e.value)}
                />
            </div>

            {period.id === 5 && <div className="invoice-dialog-customrange">
                <div className="invoice-dialog-textbox">
                    <h2>Období od:</h2>
                    <DatePicker format={"dd.MM.yyyy"}
                                value={periodFrom}
                                onChange={(e) => setPeriodFrom(e.target.value)}
                    />
                </div>

                <div className="invoice-dialog-textbox">
                    <h2>Období do:</h2>
                    <DatePicker format={"dd.MM.yyyy"}
                                value={periodTo}
                                onChange={(e) => setPeriodTo(e.target.value)}
                    />
                </div>
            </div>}


            <div className="invoice-dialog-textbox">
                <h2>Report Type:</h2>
                <DropDownList
                    // value={1}
                    data={reportTypeValues}
                    textField="text"
                    dataItemKey="id"
                    value={reportType}
                    onChange={(e) => setReportType(e.value)}
                />
            </div>


        </>
    )
}

export default InvoiceBasicParams