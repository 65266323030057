import invoice from "./Invoice";

const InvoiceSums = (props) => {

    const invoices = props.invoices
    const reportType = props.reportType
    const showPrice = props.showPrice;
    const showHours = props.showHours;

    console.log("invoice sums - ", invoices)

    let sums = {};
    invoices.forEach(item => {
        if (!sums[item.Currency]) {
            sums[item.Currency] = 0;
        }
        sums[item.Currency] += item.Price;
    });

    let totalMinutes = 0;
    invoices.forEach(item => {
        totalMinutes += item.TimeSpent;
    });

    let hours = Math.floor(totalMinutes / 60);
    let minutes = (totalMinutes % 60).toString().padStart(2, '0');

    // let ids = {};
    //
    // invoices.forEach(invoice => {
    //     if (!ids[invoice.ItemType]) {
    //         ids[invoice.ItemType] = [];
    //     }
    //     ids[invoice.ItemType].push(invoice.ID);
    // });
    //
    // for (let itemType in ids) {
    //     ids[itemType] = ids[itemType].join(', ');
    // }
    //
    // props.setInvoiceIds(ids);

    debugger

    // const itemsExpenses = invoices.filter(item => item.ItemType === 'material');
    // const itemsTMS = invoices.filter(item => item.ItemType === 'prace');
    //
    // const idsExpenses = itemsExpenses.map(item => item.ID).join(", ");
    // const idsTMS = itemsTMS.map(item => item.ID).join(", ");
    //
    // props.setInvoiceIds(idsTMS, idsExpenses)

    return (
        <>
            <div>
                {showHours !== "no" && <div>Total hours: {hours + ':' + minutes}</div>}
                {(reportType.id !== 3 || showPrice === "yes")&& <>
                {Object.entries(sums).map(([currency, sum]) => (
                    <div key={currency}>{`Total in ${currency}: ${sum.toLocaleString()}`}</div>
                ))}
                </>}
            </div>
        </>
    );
}

export default InvoiceSums;