const InvoiceSetItems = (props) => {


    const invoices = props.invoices

    const itemsExpenses = invoices.filter(item => item.ItemType === 'material');
    const itemsTMS = invoices.filter(item => item.ItemType === 'prace');

    const idsExpenses = itemsExpenses.map(item => item.ID).join(", ");
    const idsTMS = itemsTMS.map(item => item.ID).join(", ");

    // const ids = {"material": idsType1, "prace": idsType2}

    // const ids = invoices.map(item => item.ID).join(", ");
    props.setInvoiceIds(idsTMS, idsExpenses)


    console.log("Invoice Items - ", props.invoices)

    return(
        <></>
    )

}

export default InvoiceSetItems