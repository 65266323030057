import {process} from "@progress/kendo-data-query";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {Grid, GridToolbar} from "@progress/kendo-react-grid";
import {useEffect, useState} from "react";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import Waiting from "../waiting/Waiting";
import EditCommandCell from "../editComponents/EditCommandCell";
import APICall from "../../tools/APICall";
import CurrencyEditor from "../editComponents/CurrencyEditor";
import DropDownFilter from "../filterComponents/DropDownFilter";
import DropDownEditor from "../editComponents/DropDownEditor";
import DurationEditor from "../editComponents/DurationEditor";
import useClientStore from "../../stores/ClientStore";
import DateFilter from "../filterComponents/DateFilter";
import useCashflowStore from "../../stores/CashflowStore";
import useTmsStore from "../../stores/TmsStore";
import useCurrencyStore from "../../stores/CurrencyStore";
import useProjectStore from "../../stores/ProjectStore";
import CommandCell from "../tableComponents/CommandCell";
import Confirm from "../general/Confirm";
import FileDialog from "../files/FileDialog";
import useExpenseStore from "../../stores/ExpenseStore";
import EditTask from "./EditTask";
import useTaskStore from "../../stores/TaskStore";
import CustomTextCell from "../editComponents/CustomTextCell";
import ColorCodedDateEditor from "../editComponents/ColorCodedDateEditor";
import DetailsCell from "../tableComponents/DetailsCell";
import NotesInDialog from "../notes/NotesInDialog";



const ActionCell = (props) => {
    return(
        <>
            {/*<Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"*/}
            {/*        size="small"*/}
            {/*        rounded="small"*/}
            {/*        onClick={() => props.enterEdit(props.dataItem)}*/}
            {/*>Novy klient</Button>*/}
            {/*<span className={"table-header-action"}>Nový klient</span>*/}
            {/*<span className={"table-header-action"}>aaa</span>*/}
        </>
    )
}

const initialDataState = {
    //take: 300,
    skip: 0,
    //pageSize:100,
};


const TaskGrid = (props) => {

    const isSmallHeight = window.innerHeight < 600

    // const refresh = props.refresh
    const {selectedClient} = useClientStore((state) => ({selectedClient: state.selectedClient}))
    const {selectedProject} = useProjectStore((state) => ({selectedProject: state.selectedItem}))

    const {selectedTask} = useTaskStore((state) => ({selectedTask: state.selectedItem}))

    const refSize = props.size
    const menuSize = props.menuSize
    const dataType = props.dataType || "all"

    console.log("expense grid datatype - ", props)


    const [dataState, setDataState] = useState(initialDataState);
    const [refresh, setRefresh] = useState(Date.now())


    let columnDefWidth = 100
    // let columnDefWidth = refSize.width * 0.1
    // if (columnDefWidth < 120) columnDefWidth = 120

    const toggleDisplayParent = props.toggleDisplayParent

    useEffect(() => {
        // debugger
        switch (dataType) {
            case "client":
                loadForClient(selectedClient.ID)
                break
            case "project":
                loadForProject(selectedProject.ID)
                break
            default:
                loadAll()
        }
    },[selectedClient, selectedProject])

    //
    // let apiURL, apiProjectURL;
    // switch (dataType) {
    //     case "client":
    //         apiURL = config.API_URL + 'tms/forClient/' + selectedClient.ID
    //         apiProjectURL = config.API_URL + 'project/forClient/' + selectedClient.ID
    //         break
    //     case "project":
    //         apiURL = config.API_URL + 'tms/forProject/' + selectedProject.ID
    //         apiProjectURL = config.API_URL + 'project/forProject/' + selectedProject.ID
    //         break
    //     default:
    //         apiURL = config.API_URL + 'tms/'
    //         apiProjectURL = config.API_URL + 'project/'
    // }


    const data = useTaskStore(state => state.tasks)
    const isLoading = useTaskStore(state => state.isLoading)
    const setData = useTaskStore(state => state.setTasks)
    const loadForClient = useTaskStore(state => state.loadForClient)
    const loadForProject = useTaskStore(state => state.loadForProject)
    const loadAll = useTaskStore(state => state.loadAll)

    const addNew = useTaskStore(state => state.addNew)
    const saveNew = useTaskStore(state => state.saveNew)
    const saveItem = useTaskStore(state => state.saveItem)
    const setSelected = useTaskStore(state => state.selectItem)
    const deleteItem = useTaskStore(state => state.deleteItem)
    const refreshData = useTaskStore(state => state.refreshData)

    const { data:currency, isPending:currency_isPending, error:currency_error} = useFetch(config.API_URL + 'lists/currency/', refresh)
    const { data:taskStatus, isPending:taskStatus_isPending, error:taskStatus_error} = useFetch(config.API_URL + 'lists/task_status/', refresh)
    const { data:priorities, isPending:priorities_isPending, error:priorities_error} = useFetch(config.API_URL + 'lists/task_priority/', refresh)


    // const { data:tms, isPending, error} = useFetch(apiURL, refresh)

    const { data:expenseTypes, isPending:workType_isPending, error:workType_error} = useFetch(config.API_URL + 'lists/expense_type/', refresh)
    const { data:authors, isPending:authors_isPending, error:authors_error} = useFetch(config.API_URL + 'user/authors/', refresh)
    const { data:projects, isPending:projects_isPending, error:projects_error} = useFetch(config.API_URL + 'project/for_lists', refresh)


    const SumFooter = (props) => {
        const field = props.field || "";
        const total = process(data, dataState).data.reduce((acc, current) => acc + current[field], 0)
        const formatter = new Intl.NumberFormat('en-US', { useGrouping: true });
        return (
            <td colSpan={props.colSpan} style={props.style} className={"right"}>
                {formatter.format(total)}
            </td>
        );
    };

    const DurationFooter = (props) => {
        const field = props.field || "";
        const total = process(data, dataState).data.reduce((acc, current) => acc + current[field], 0)
        const dataValue = Math.floor(total / 60).toString().padStart(2, '0') + ':' + (total % 60).toString().padStart(2, '0')
        return (
            <td colSpan={props.colSpan} style={props.style} className={"right"}>
                {dataValue}
            </td>
        );
    };

    const CountFooter = (props) => {
        const field = props.field || "";
        const total = process(data, dataState).data.length
        return (
            <td
                style={props.style} // this applies styles that lock the column at a specific position
                className={props.className} // this adds classes needed for locked columns
                colSpan={props.colSpan}
                role={"gridcell"}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
            >
                {total} záznamů
            </td>
        );
    };



    const PriceFooter = (props) => {
        const field = props.field || "";
        const total = process(data, dataState).data.reduce((acc, current) => acc + current[field], 0)
        const formatter = new Intl.NumberFormat('cs-CZ', { useGrouping: true });
        return (
            <td
                style={props.style} // this applies styles that lock the column at a specific position
                className={props.className + " right"} // this adds classes needed for locked columns
                colSpan={props.colSpan}
                role={"gridcell"}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
            >
                {formatter.format(total)}
            </td>
        );
    };

    // useEffect(() => {
    //     switch (dataType) {
    //         case "client":
    //             loadTmsForClient(selectedClient.ID)
    //             break
    //         case "project":
    //             loadTmsForProject(selectedProject.ID)
    //             break
    //         default:
    //             loadTmsAll()
    //     }
    // },[selectedClient, selectedProject])
    //

    //region editors and filters

    const useCustomCell = props => (
        <CustomTextCell
            {...props}
            selected={(dataType === "all") ? selectedTask : null}
        />
    );

    const useCurrencyEditor = props => (
        <DropDownEditor
            {...props}
            listData={currency}
            editable={true}
        />
    );

    const CurrencyFilter = (props) => (
        <DropDownFilter
            {...props}
            data={currency}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );


    const useColorCodedDateEditor1 = props => (
        <ColorCodedDateEditor
            {...props}
            colorPast = "#DD0000"
            colorFuture = ""
        />
    );

    const useProjectEditor = props => (
        <DropDownEditor
            {...props}
            listData={projects}
            // columns={projectColumns}
            textField={"ClientProjectName"}
            editable={true}
            filterable={true}
        />
    );

    const ProjectFilter = (props) => (
        <DropDownFilter
            {...props}
            data={projects}
            textField={"ClientProjectName"}
            filterable={true}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );

    const useTaskStatusEditor = props => (
        <DropDownEditor
            {...props}
            listData={taskStatus}
            // columns={projectColumns}
            textField={"Name"}
            editable={true}
            filterable={true}
        />
    );

    const TaskStatusFilter = (props) => (
        <DropDownFilter
            {...props}
            data={taskStatus}
            textField={"Name"}
            filterable={true}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );


    const useTaskPriorityEditor = props => (
        <DropDownEditor
            {...props}
            listData={priorities}
            // columns={projectColumns}
            textField={"Name"}
            editable={true}
            filterable={true}
        />
    );

    const TaskPriorityFilter = (props) => (
        <DropDownFilter
            {...props}
            data={priorities}
            textField={"Name"}
            filterable={true}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );



    const useWorkTypeEditor = props => (
        <DropDownEditor
            {...props}
            listData={expenseTypes}
            editable={true}
        />
    );



    const WorkTypeFilter = (props) => (
        <DropDownFilter
            {...props}
            data={expenseTypes}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );

    const useAuthorEditor = props => (
        <DropDownEditor
            {...props}
            listData={authors}
        />
    );

    const useDurationEditor = props => (
        <DurationEditor
            {...props}
        />
    );

    const AuthorFilter = (props) => (
        <DropDownFilter
            {...props}
            data={authors}
            filterable={true}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );

    const useDateFilter = (props) => (
        <DateFilter
            {...props}
            defaultItem={{ID: 0, Name: "All"}}
            type={"past"}
            dataState={dataState}
            setDataState={setDataState}
        />
    );




    //endregion


    //region edit functions

    const rowClick = (props) => {

        setSelected(props.dataItem)
        if (isSmallHeight) toggleDisplayParent()

    }


    const MyEditCommandCell = (props) => (
        <CommandCell
            {...props}
            edit={enterEdit}
            copy={enterCopy}
            delete={deleteRow}
            showFiles={false}
            // filesCount={props.dataItem.FilesCount}
        />
    );

    const MyDetailsCell = (props) => (
        <DetailsCell
            {...props}
            showFiles={showFiles}
            filesCount={props.dataItem.FilesCount}
            showNotes={showNotes}
            notesCount={props.dataItem.NotesCount}
        />
    );



    //endregion


    // ********************************* helper data  *********************************

    const [openForm, setOpenForm] = useState(false);
    const [editItem, setEditItem] = useState(null);


    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [deletedItem, setDeletedItem] = useState(null);

    const [showFilesDialog, setShowFilesDialog] = useState(false)
    const [showFilesFor, setShowFilesFor] = useState(null);

    const [showImportDialog, setShowImportDialog] = useState(false)


// ************************************** EDIT *************************************


    const rowDblClick = props => {
        enterEdit(props.dataItem)
    }

    const enterEdit = (dataItem) => {
        console.log("item edit")

        setEditItem(dataItem)
        setOpenForm(true)
    };


    const handleCancelEdit = (dataItem) => {

        setOpenForm(false);

    };



    const handleSubmit = (dataItem) => {
        // debugger
        if (dataItem.ID === undefined || dataItem.ID === null) {
            saveNew(dataItem)
        }
        else {
            saveItem(dataItem)
        }
        setOpenForm(false);
    };


    // ************************************** NEW *************************************

    // const editNew = () => {
    //     const newItem = {};
    //     setEditItem(newItem)
    //     setOpenForm(true)
    // };

    const editNew = () => {
        const newItem = addNew //{};
        setEditItem(newItem)
        setOpenForm(true)
    };

    // ************************************** COPY *************************************


    const enterCopy = (dataItem) => {
        console.log("copy edit")

        const newItem = {
            ...dataItem,
            ID: null,
            UID: null,
            Origin: null,
            ImportID: null
        }

        setEditItem(newItem)
        setOpenForm(true)

        console.log("edit copy ... ", openForm, editItem)
    };



    // ************************************** Delete *************************************

    const deleteRow = (dataItem) => {
        setDeletedItem(dataItem)
        setShowConfirmDelete(true)
    };

    const deleteRowAction = () => {
        deleteItem(deletedItem)
        setShowConfirmDelete(false)
    };

    const cancelDelete = () => {
        setShowConfirmDelete(false)
    };


    // ************************************** Files *************************************

    const showFiles = (dataItem) => {
        // debugger
        setShowFilesFor(dataItem)
        setShowFilesDialog(true)
    };

    const closeFiles = () => {
        refreshData()
        setShowFilesDialog(false)
    };


    // ************************************** Notes *************************************

    const [showNotesFor, setShowNotesFor] = useState(null);
    const showNotes = (dataItem) => {
        // debugger
        setShowNotesFor(dataItem)
    };

    const closeNotes = () => {
        refreshData()
        setShowNotesFor(null)
    };


    // ************************************** Files *************************************

    const showImport = (dataItem) => {
        // debugger
        setShowFilesFor(dataItem)
        setShowImportDialog(true)
    };

    const closeImport= () => {
        refreshData()
        setShowImportDialog(false)
    };




    // ************************************** GRID *************************************




    return(
        <>

            {data && currency && expenseTypes && authors && projects && priorities &&


                <Grid
                //pageable={true}
                sortable={true}
                filterable={true}
                //scrollable={"virtual"} //virtual, scrollable
                resizable={true}
                reorderable={true}
                //rowHeight={45}
                // onItemChange={itemChange}

                editField="inEdit"
                size={"small"}


                style={{
                    height: "100%", //"calc(100% - 55px)", // (refSize.height - 150) / 2,//"500px",
                    minHeight: 20,
                    //maxHeight: 300, // refSize.height - 70,
                    width: "100%", // refSize.width - menuSize,
                }}
                data={process(data, dataState)}{...dataState}

                //onPageChange={pageChange}
                onDataStateChange={(e) => setDataState(e.dataState)}

                onRowClick={rowClick}
                onRowDoubleClick={rowDblClick}

                >

                    <GridToolbar>

                        <button
                            title="New"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={editNew}
                        >
                            New Task
                        </button>

                    </GridToolbar>

                    <Column cell={useCustomCell} field="ID" title="ID" width="80px" filterable={true} editable={false} locked />

                    {/*<Column field="details" minResizableWidth={180}*/}
                    {/*        cell={MyDetailsCell}*/}
                    {/*        filterable={false}*/}
                    {/*        headerCell={ActionCell}*/}
                    {/*        width="100"*/}
                    {/*        resizable={true}*/}
                    {/*        locked*/}
                    {/*/>*/}

                    <Column field="TaskStatusID" minResizableWidth={120} title="Status" cell={useTaskStatusEditor} filterCell={TaskStatusFilter} width={120} locked />

                    <Column field="Subject" minResizableWidth={120} title="Subject" width={200} locked />
                    <Column field="Text" minResizableWidth={120} title="Popis" width={600}/>

                    <Column field="PriorityID" minResizableWidth={120} title="Priority" cell={useTaskPriorityEditor} filterCell={TaskPriorityFilter} width={120}/>


                {/*<Column cell={myID} field="ID" title="ID" width="80px" footerCell={myFooterCell} filterable={true}/>*/}
                {/*<Column cell={myID} field="ID" title="ID" width="80px" filterable={true} locked/>*/}


                    <Column field="AuthorID" minResizableWidth={120} title="Autor" cell={useAuthorEditor}
                            filterCell={AuthorFilter} width={150} editable={true} />

                    <Column field="OwnerID" minResizableWidth={120} title="Owner" cell={useAuthorEditor}
                            filterCell={AuthorFilter} width={150} editable={true} />

                    <Column field="Taken" minResizableWidth={100} title="Datum" width={150}
                            filter="date"
                            format="{0:dd.MM.yyyy}"
                            editor="date"
                            //cell={DateCell}
                            filterCell={useDateFilter}
                    />

                    <Column field="DueDate" minResizableWidth={100} title="Termin" width={150} cell={useColorCodedDateEditor1}
                            filter="date"
                            format="{0:dd.MM.yyyy}"
                            editor="date"
                        //cell={DateCell}
                            filterCell={useDateFilter}
                    />

                    <Column field="Parent" minResizableWidth={120} title="Parent" width={600}/>

                    {/*<Column field="ClientID" minResizableWidth={120} title="Projekt" width={300} locked*/}
                    {/*        cell={useProjectEditor} filterCell={ProjectFilter} footerCell={CountFooter} />*/}

                    {/*<Column field="Parent" minResizableWidth={120} title="Parent" width={300}*/}
                    {/*        cell={useProjectEditor} filterCell={ProjectFilter} footerCell={CountFooter} />*/}

                    {/*<Column field="CashflowID" minResizableWidth={120} title="Projekt" width={300} locked*/}
                    {/*        cell={useProjectEditor} filterCell={ProjectFilter} footerCell={CountFooter} />*/}

                    <Column field="Tags" minResizableWidth={120} title="Tagy" width={columnDefWidth}/>

                    <Column field="details" minResizableWidth={120}
                            cell={MyDetailsCell}
                            filterable={false}
                            headerCell={ActionCell}
                            width="120"
                            resizable={true}
                            locked
                    />

                    <Column field="action" minResizableWidth={100}
                            cell={MyEditCommandCell}
                        // columnMenu={ActionCell}
                            filterable={false}
                            headerCell={ActionCell}
                            width="100"
                            resizable={true}
                            locked
                        // filterCell={ActionCell}
                    />

                </Grid>

            }


            {openForm && <EditTask
                projects={projects}
                authors={authors}
                expenseTypes={expenseTypes}
                currencies={currency}
                taskStatuses={taskStatus}
                priorities={priorities}
                cancelEdit={handleCancelEdit}
                onSubmit={handleSubmit}
                item={editItem}
            />}


            {showConfirmDelete && <Confirm
                action={deleteRowAction}
                cancel={cancelDelete}
                text={"delete item?"}
            />}


            {showFilesDialog && <FileDialog
                close={closeFiles}
                text={"delete item?"}
                filesFor={showFilesFor}
            />}

            {showNotesFor && <NotesInDialog
                close={closeNotes}
                notesFor={showNotesFor}
            />}


            {isLoading && <Waiting />}

                {/*<div className="list-footer"></div>*/}


                {/*{openForm && <EditClient*/}
                {/*    cancelEdit={handleCancelEdit}*/}
                {/*    onSubmit={handleSubmit}*/}
                {/*    item={editItem}*/}
                {/*/>}*/}

        </>
    )
}

export default TaskGrid