import * as React from "react";
import {Dialog, DialogActionsBar, Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import {TextBox, NumericTextBox, TextArea, InputSeparator} from "@progress/kendo-react-inputs";
import {Error, Hint, Label} from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import FormDropDown from "../../formEditComponents/FormDropDown";
import {isNumeric} from "@progress/kendo-data-query/dist/npm/utils";
import {DateInput, DatePicker} from "@progress/kendo-react-dateinputs";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {useState} from "react";


const TextBoxField = fieldRenderProps => {
    const {
        validationMessage,
        visited,
        label,
        id,
        valid,
        ...others
    } = fieldRenderProps;
    return <>
        <Label editorId={id} className={"k-form-label"}>
            {label}
        </Label>
        <div className={"k-form-field-wrap"}>
            <TextBox {...others} />
        </div>
    </>;
};

const PassBoxField = fieldRenderProps => {
    const {
        validationMessage,
        visited,
        label,
        id,
        valid,
        ...others
    } = fieldRenderProps;

    const [viewPass, setViewPass] = useState(false)

    const toggleViewPass = () => {
        setViewPass(!viewPass)
    }

    return <>
        <Label editorId={id} className={"k-form-label"}>
            {label}
        </Label>
        <div className={"k-form-field-wrap"}>
            <TextBox {...others}
                     type={viewPass ? "text" : "password"}
                     suffix={() => (
                         <>

                             <InputSeparator />
                             <Button
                                 onClick={toggleViewPass}
                                 fillMode={"flat"}
                                 icon={"eye"}
                             >
                                 {/*<span className="k-icon k-icon-md k-i-eye"></span>*/}
                             </Button>

                         </>
                     )}
            />
        </div>
    </>;
};



const useProjectEditor = props => (
    <FormDropDown
        {...props}
        textField={"ClientProjectName"}
    />
);


const EditUser = props => {

    const {
        cancelEdit,
        onSubmit,
        item,
        partners,
        ...other
    } = props;

    // item.UnitID = units.find((c) => c.ID === item.UnitID)
    // item.PartnerID = partners.find((c) => c.ID === item.PartnerID)


    const handleSubmit = dataItem => {
        // debugger
        if (!isNumeric(dataItem.PartnerID)) dataItem.PartnerID = dataItem.PartnerID.ID
        dataItem.Groups = null
        onSubmit(dataItem)
    }

    var title = `Edit user: ${item.Name}`
    if (item.ID === null) {
        title = "Copy user"
    }

    if (item.ID === undefined) {
        title = "Create new user"
    }


    // const { data:partners, isPending, error} = useFetch(config.API_URL + 'partner/', 1)

    return <Form initialValues={item} onSubmit={handleSubmit} render={renderProps =>

        <Window title={title} onClose={cancelEdit}
                initialHeight={800}
                initialWidth={600}
                modal={true}
        >
            <div className="editForm">
                <FormElement horizontal={true}>

                    <FieldWrapper><Field name={"Name"} component={TextBoxField} label={"Name"} /></FieldWrapper>
                    <FieldWrapper><Field name={"ShortName"} component={TextBoxField} label={"Short Name"} /></FieldWrapper>
                    <FieldWrapper><Field name={"Nick"} component={TextBoxField} label={"Nick"} /></FieldWrapper>
                    <FieldWrapper><Field name={"Email"} component={TextBoxField} label={"e-mail"} /></FieldWrapper>
                    <FieldWrapper><Field name={"Phone"} component={TextBoxField} label={"phone"} /></FieldWrapper>
                    <FieldWrapper><Field name={"Uname"} component={TextBoxField} label={"Uname"} /></FieldWrapper>
                    <FieldWrapper><Field name={"Password"} component={PassBoxField} label={"Password"} /></FieldWrapper>

                    {partners && <FieldWrapper><Field
                        // id={"PartnerID"}
                        name={"PartnerID"}
                        label={"Partner"}
                        textField={"Name"}
                        dataItemKey={"id"}
                        // selectField={"selected"}
                        // expandField={"expanded"}
                        component={FormDropDown}
                        listData={partners}
                        // validator={requiredValidator}
                    /></FieldWrapper>}

                    <FieldWrapper><Field name={"AllowedUnits"} component={TextBoxField} label={"Units"} /></FieldWrapper>
                    <FieldWrapper><Field name={"AllowedPartners"} component={TextBoxField} label={"Partners"} /></FieldWrapper>
                    <FieldWrapper><Field name={"IntegrationID"} component={TextBoxField} label={"Integration ID"} /></FieldWrapper>



                </FormElement>
            </div>

            <WindowActionsBar layout="start">
                <Button type={"submit"} themeColor={"primary"} disabled={!renderProps.allowSubmit} onClick={renderProps.onSubmit} icon="save" >
                    Update
                </Button>
                <Button onClick={cancelEdit} icon="cancel" >
                    Cancel
                </Button>
            </WindowActionsBar>

        </Window>} {...other} />;
};
export default EditUser;