import * as React from "react";
import {Dialog, DialogActionsBar, Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import {TextBox, NumericTextBox, TextArea} from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {Error, Hint, Label} from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import FormDropDown from "../formEditComponents/FormDropDown";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import {isNumeric} from "@progress/kendo-data-query/dist/npm/utils";
import clients from "../clients/Clients";
import FormTextBoxField from "../formEditComponents/FormTextBoxField";
import FormTextAreaField from "../formEditComponents/FormTextAreaField";
import FormNonNegativeNumericInput from "../formEditComponents/FormNonNegativeNumericInput";
import FormNumBoxField from "../formEditComponents/FormNumBoxField";

const minValueValidator = value => value >= 0 ? "" : "The value must be 0 or higher";

const mandatoryFieldValidator = value => value != null ? "" : "mandatory field"



const useCurrency = props => (
    <FormDropDown
        {...props}
        textField={"Name"}
        defaultItem={{Name:"not set", ID:null}}
    />
);


const EditPrice = props => {

    const {
        cancelEdit,
        onSubmit,
        currency,
        item,
        ...other
    } = props;

    // item.UnitID = units.find((c) => c.ID === item.UnitID)
    // item.PartnerID = partners.find((c) => c.ID === item.PartnerID)


    const handleSubmit = data => {
        //debugger
        if (data.CurrencyInID != null && !isNumeric(data.CurrencyInID)) data.CurrencyInID = data.CurrencyInID.ID
        if (data.CurrencyOutID != null && !isNumeric(data.CurrencyOutID)) data.CurrencyOutID = data.CurrencyOutID.ID
        onSubmit(data)
    }

    var title = `Edit Price: ${item.Name}`


    // const { data:partners, isPending, error} = useFetch(config.API_URL + 'partner/', 1)

    return <Form initialValues={item} onSubmit={handleSubmit} render={renderProps =>

        <Window title={title} onClose={cancelEdit}
                initialHeight={700}
                initialWidth={600}
                modal={true}
        >
            <div className="editForm">

                <FormElement horizontal={true}>

                    <FieldWrapper><Field name={"PriceIn"} component={FormNumBoxField} label={"Price in"}/></FieldWrapper>

                    {currency && <FieldWrapper><Field name={"CurrencyInID"} label={"Currency In"} textField={"Name"}
                                             dataItemKey={"id"} component={useCurrency} listData={currency} /></FieldWrapper>}

                    <FieldWrapper><Field name={"PriceOut"} component={FormNumBoxField} label={"Price out"}/></FieldWrapper>

                    {currency && <FieldWrapper><Field name={"CurrencyOutID"} label={"Currency Out"} textField={"Name"} dataItemKey={"id"}
                                                      component={useCurrency} listData={currency} /></FieldWrapper>}

                </FormElement>

            </div>

        <WindowActionsBar layout="start">
            <Button type={"submit"} themeColor={"primary"} disabled={!renderProps.allowSubmit} onClick={renderProps.onSubmit} icon="save" >
                Update
            </Button>
            <Button onClick={cancelEdit} icon="cancel" >
                Cancel
            </Button>
        </WindowActionsBar>

    </Window>} {...other} />;
};
export default EditPrice;