import * as React from "react";
import {Dialog, DialogActionsBar, Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import {TextBox, NumericTextBox, TextArea} from "@progress/kendo-react-inputs";
import {Error, Hint, Label} from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import FormDropDown from "../formEditComponents/FormDropDown";
import {isNumeric} from "@progress/kendo-data-query/dist/npm/utils";
import {DateInput, DatePicker} from "@progress/kendo-react-dateinputs";
import FormColorField from "../formEditComponents/FormColorField";


const mandatoryFieldValidator = value => value != null ? "" : "mandatory field"

const TextBoxField = fieldRenderProps => {
    const {
        validationMessage,
        visited,
        label,
        id,
        valid,
        ...others
    } = fieldRenderProps;
    return <>
        <Label editorId={id} className={"k-form-label"}>
            {label}
        </Label>
        <div className={"k-form-field-wrap"}>
            <TextBox {...others} />
        </div>
    </>;
};


const EditProduct = props => {

    const {
        cancelEdit,
        onSubmit,
        item,
        units,
        productStatuses,
        ...other
    } = props;

    // item.UnitID = units.find((c) => c.ID === item.UnitID)
    // item.PartnerID = partners.find((c) => c.ID === item.PartnerID)


    const handleSubmit = dataItem => {
        if (!isNumeric(dataItem.UnitID)) dataItem.UnitID = dataItem.UnitID.ID
        if (!isNumeric(dataItem.ProductStatusID)) dataItem.ProductStatusID = dataItem.ProductStatusID.ID
        // debugger
        onSubmit(dataItem)
    }

    var title = `Edit product status: ${item.Name}`
    if (item.ID === null) {
        title = "Copy product status"
    }

    if (item.ID === undefined) {
        title = "Create new product status"
    }


    // const { data:partners, isPending, error} = useFetch(config.API_URL + 'partner/', 1)

    return <Form initialValues={item} onSubmit={handleSubmit} render={renderProps =>

        <Window title={title} onClose={cancelEdit}
                initialHeight={500}
                initialWidth={600}
                modal={true}
        >
            <div className="editForm">
                <FormElement horizontal={true}>

                    <FieldWrapper><Field name={"Name"} component={TextBoxField} label={"Name"} /></FieldWrapper>
                    <FieldWrapper><Field name={"Code"} component={TextBoxField} label={"Code"} /></FieldWrapper>
                    <FieldWrapper><Field
                        name={"ProductStatusID"}
                        label={"Status"}
                        textField={"Name"}
                        dataItemKey={"id"}
                        component={FormDropDown}
                        listData={productStatuses}
                        validator={mandatoryFieldValidator}
                    /></FieldWrapper>
                    <FieldWrapper><Field name={"Category"} component={TextBoxField} label={"Category"} /></FieldWrapper>
                    <FieldWrapper><Field name={"Note"} component={TextBoxField} label={"Note"} /></FieldWrapper>
                    <FieldWrapper><Field name={"Pricing"} component={TextBoxField} label={"Pricing"} /></FieldWrapper>

                    {units && <FieldWrapper><Field
                        // id={"PartnerID"}
                        name={"UnitID"}
                        label={"Stredisko"}
                        textField={"Name"}
                        dataItemKey={"id"}
                        // selectField={"selected"}
                        // expandField={"expanded"}
                        component={FormDropDown}
                        listData={units}
                        // validator={requiredValidator}
                        validator={mandatoryFieldValidator}
                    /></FieldWrapper>}

                </FormElement>
            </div>

            <WindowActionsBar layout="start">
                <Button type={"submit"} themeColor={"primary"} disabled={!renderProps.allowSubmit} onClick={renderProps.onSubmit} icon="save" >
                    Update
                </Button>
                <Button onClick={cancelEdit} icon="cancel" >
                    Cancel
                </Button>
            </WindowActionsBar>

        </Window>} {...other} />;
};
export default EditProduct;