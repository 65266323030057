import {Splitter, StackLayout} from "@progress/kendo-react-layout";
import {useLayoutEffect, useState} from "react";
import {Button, Toolbar, ToolbarItem, ToolbarSpacer} from "@progress/kendo-react-buttons";
import TaskGrid from "./TasksGrid";
import ProjectDetailSelector from "../projects/ProjectDetailSelector";
import ProjectDetail from "../projects/ProjectDetail";
import Price from "../price/Price";
import CashFlowGrid from "../cashflow/CashFlowGrid";
import TmsGrid from "../tms/TmsGrid";
import Expenses from "../expenses/Expenses";
import FileInDetail from "../files/FileInDetail";
import TaskDetailSelector from "./TaskDetailSelector";
import useProjectStore from "../../stores/ProjectStore";
import useTaskStore from "../../stores/TaskStore";
import TaskDetailInfo from "./taskDetailInfo";
import Notes from "../notes/Notes";
import Checklists from "../chcklists/Chcklists";


const Tasks = props => {

    const menuSize = props.menuSize
    const [refSize, setRefSize] = useState({width:0, height:0});

    const [selectedDetail, setSelectedDetail] = useState()
    const [displayGrid, setDisplayGrid] = useState(true)

    const {selectedTask, setSelectedProject} = useTaskStore((state) => ({selectedTask: state.selectedItem, setSelectedTask: state.selectItem}))
    const loadProjects = useProjectStore(state => state.loadAll)



    const toggleDisplayGrid = () => {
        setDisplayGrid(!displayGrid)
    }

    const handleSize = () => {
        // debugger
        let width = window.innerWidth - 10;
        const height = window.innerHeight;
        setRefSize({
            // width: ref.current.offsetWidth,
            // height: ref.current.offsetHeight
            width: width,
            height: height
        })
        console.log("tms size - ", refSize.width, width, window.innerWidth)
    }

    useLayoutEffect(() => {
        handleSize()
        window.addEventListener("resize", handleSize);
        return () => window.removeEventListener("resize", handleSize);
    }, []);

    const [panes, setPanes] = useState([
        {
            //size: "50%",
            min: "0",
            collapsible: true,
        },
        {
            size:"700px",
            min:"400px",
            // min: "50px",
            collapsible: false,
            scrollable: false,
        },
    ]);


    const addNew = () => {
        // const newDataItem = {
        //     inEdit: true,
        // };
        // setData([newDataItem, ...data]);
        // addNewClient()
    };

    // const addNewCashflow = useCashflowStore(state => state.addNew)

    const onChange = (event) => {
        setPanes(event.newState);
    };

    return(
        <>


            <Splitter
                style={{
                    height: "calc(100vh - 55px)",
                    width: refSize.width - menuSize
                }}
                panes={panes}
                orientation={"horizontal"}
                onChange={onChange}
            >

                <StackLayout
                    style={{
                        height: "100%",
                    }}
                    orientation={"vertical"}
                    gap={0}
                >
                    {/*<div className="list-header">*/}

                    {/*    <Toolbar>*/}
                    {/*        <ToolbarItem>*/}
                    {/*            /!*Zobrazeno {0} z {0}*!/*/}
                    {/*        </ToolbarItem>*/}
                    {/*        <ToolbarSpacer />*/}
                    {/*        <ToolbarItem>*/}

                    {/*            <Button onClick={addNewCashflow}*/}
                    {/*                    themeColor={"base"}*/}
                    {/*            >Nový záznam</Button>*/}


                    {/*        </ToolbarItem>*/}
                    {/*        <ToolbarItem>*/}

                    {/*        </ToolbarItem>*/}
                    {/*    </Toolbar>*/}

                    {/*</div>*/}

                    <TaskGrid size={refSize} menuSize={menuSize} dataType={props.dataType}></TaskGrid>

                </StackLayout>


                {/*<StackLayout*/}
                {/*    style={{*/}
                {/*        height: "100%",*/}
                {/*        width: "100%",*/}
                {/*    }}*/}
                {/*    orientation={"vertical"}*/}
                {/*    gap={0}*/}
                {/*>*/}

                <div style={{height: "100%"}}>
                    <div className="list-header">
                        <TaskDetailSelector  task={selectedTask}
                            // clientName={selectedProjectName}
                                                selectedDetail={selectedDetail}
                                                setSelectedDetail={setSelectedDetail}
                                                toggleDisplayParent={toggleDisplayGrid}
                        />
                    </div>
                    <div>

                        {/*{selectedDetail===0 &&*/}
                        {/*    <TaskDetail size={refSize} menuSize={menuSize} selectedClient={selectedTask} />}*/}

                        {selectedDetail===0 &&
                            <TaskDetailInfo size={refSize} menuSize={menuSize} dataType={"project"} orientation={"vertical"} task={selectedTask} />}

                        {selectedDetail===12 &&
                            <FileInDetail size={refSize} menuSize={menuSize} dataType={"project"} orientation={"vertical"} filesFor={selectedTask} />}

                        {selectedDetail===13 &&
                            <Checklists size={refSize} menuSize={menuSize} orientation={"vertical"} forItem={selectedTask} />}

                        {selectedDetail===14 &&
                            <Notes size={refSize} menuSize={menuSize} orientation={"vertical"} notesFor={selectedTask} />}

                    </div>
                </div>
                {/*</StackLayout>*/}


            </Splitter>

                {/*<StackLayout*/}
                {/*    style={{*/}
                {/*        height: "100%",*/}
                {/*    }}*/}
                {/*    orientation={"vertical"}*/}
                {/*    gap={0}*/}
                {/*>*/}

                {/*    <>*/}

                {/*        {chartData && <CashflowGraph data={chartData}/>}*/}

                {/*    </>*/}

                {/*</StackLayout>*/}

            {/*</Splitter>*/}


        </>
    )
}

export default Tasks