import useProductStore from "../../stores/ProductStore";
import useProductClientStore from "../../stores/ProductClientStore";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import {useEffect, useState} from "react";
import useClientStore from "../../stores/ClientStore";
import ProductClientInGrid from "./ProductClientInGrid";
import EditClient from "../clients/EditClient";
import EditProductClient from "./EditProductClient";
import FileDialog from "../files/FileDialog";

const ProductClient = props => {

    const {selectedClient} = useClientStore((state) => ({selectedClient: state.selectedClient}))

    const refSize = props.size
    const menuSize = props.menuSize
    const dataType = props.dataType || "all"

    const data = useProductClientStore(state => state.storeData)
    const addNew = useProductClientStore(state => state.addNew)
    const setData = useProductClientStore(state => state.setStoreData)
    const saveNew = useProductClientStore(state => state.saveNew)
    const saveItem = useProductClientStore(state => state.saveItem)

    const refreshData = useProductClientStore(state => state.refreshData)
    const deleteItem = useProductClientStore(state => state.deleteItem)

    const loadAll = useProductClientStore(state => state.loadAll)
    const loadForClient = useProductClientStore(state => state.loadForClient)

    const { data:productStatus, isPending:productStatus_isPending, error:productStatus_error} = useFetch(config.API_URL + 'lists/product_status/', 1)
    const { data:productClientStatus, isPending:productClientStatus_isPending, error:productClientStatus_error} = useFetch(config.API_URL + 'lists/product_client_status/', 1)
    const { data:units, isPending:units_isPending, error:units_error} = useFetch(config.API_URL + 'lists/unit/', 1)

    const [editPC, setEditPC] = useState(null)
    const [showFilesFor, setShowFilesFor] = useState(null);

    useEffect(() => {
        switch (dataType) {
            case "client":
                //loadForClient(selectedClient.ID)
                loadForClient(selectedClient.ID)
                // setData(data1.filter(project => project.ClientID === selectedClient.ID))
                break
            default:
                loadAll()
        }
    },[selectedClient])


    const edit = pc => {
        // console.log("edit", pc.UN)
        setEditPC(pc)
    }

    const handleCancelEdit = () => {
        setEditPC(null)
    };



    const handleSubmit = (dataItem) => {

        //debugger
        saveItem(dataItem)
        refreshData()

        setEditPC(null)
    };

    // ************************************** Files *************************************

    const showFiles = (dataItem) => {
        debugger
        setShowFilesFor(dataItem)
    };

    const closeFiles = () => {
        refreshData()
        setShowFilesFor(null)
    };

    // ************************************** GRID *************************************

    return (
        <>


            {data && productStatus && productClientStatus &&

                <div className="product-client">

                    {data.map(pc=> (
                        <ProductClientInGrid edit={() => edit(pc)} pc={pc} showFiles={() => showFiles(pc)}/>
                    ))}


                </div>

            }

            {editPC && <EditProductClient
                productClientStatuses={productClientStatus}
                cancelEdit={handleCancelEdit}
                onSubmit={handleSubmit}
                item={editPC}
            />}

            {showFilesFor && <FileDialog
                close={closeFiles}
                filesFor={showFilesFor}
            />}

        </>
    );
}

export default ProductClient
