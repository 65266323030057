import {useEffect, useState} from "react";
import {Button} from "@progress/kendo-react-buttons";
import {Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import * as React from "react";
import {Checkbox} from "@progress/kendo-react-inputs";
import useFetch from "../../../tools/UseFetch";
import config from "../../../config/config.json";
import useUserStore from "../../../stores/UserStore";
import useGroupStore from "../../../stores/GroupStore";


const EditGroups = props => {


    const { data:user_groups, user_groupsIsPending, user_groupsError} = useFetch(config.API_URL + 'group/for_user/' + props.item.ID, 1)
    const [groups, setGroups] = useState(user_groups)
    // const [groupIDs, setGroupIDs] = useState(groups.map(p => ({ id: p.ID, checked: p.IsInGroup })))

    const saveUserInGroup = useGroupStore(state => state.saveUserInGroup)

    useEffect(() => {
        setGroups(user_groups)
    }, [user_groups]);

    function toggleCheckbox(id) {
        const updatedUserGroups = [...groups];
        const index = updatedUserGroups.findIndex(p => p.ID === id);

        if (index !== -1) {
            updatedUserGroups[index].IsInGroup = !updatedUserGroups[index].IsInGroup;
            setGroups(updatedUserGroups);
        }
    }

    const submit = e => {
        saveUserInGroup(props.item.ID, groups)
        props.onSubmit()
    }

    return (

        <>

            <Window
                title={"Edit groups for " + props.item.Name}
                onClose={props.onCancel}
                initialHeight={800}
                initialWidth={500}
                modal={true}
                resizable={true}
            >

                {groups && <div className="groups-edit-main">

                    {groups.map(p => (
                            <div className="invoice-dialog-checkbox">
                                <Checkbox
                                    key={p.ID} id={p.ID} label={p.Name}
                                    checked={p.IsInGroup}
                                    onChange={() => toggleCheckbox(p.ID)}
                                />
                            </div>
                        )
                    )}

                </div>}

                <WindowActionsBar layout="end">

                    <Button themeColor={"primary"} onClick={props.onCancel} >
                        <span className="k-icon k-i-cancel k-icon-md"></span>&nbsp;
                        Cancel
                    </Button>


                    <Button themeColor={"success"} onClick={submit} >
                        <span className="k-icon k-i-save k-icon-md"></span>&nbsp;
                        Save
                    </Button>

                </WindowActionsBar>

            </Window>

        </>

    );
}

export default EditGroups