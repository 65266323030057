import {DropDownList} from "@progress/kendo-react-dropdowns";
import {MaskedTextBox, NumericTextBox} from "@progress/kendo-react-inputs";

function convertTimeStringToMinutes(timeString) {
    // debugger
    var totalMinutes = 0
    var parts = timeString.split(":");
    var hours = parseInt(parts[0], 10);
    var minutes = parseInt(parts[1], 10);
    totalMinutes = (hours * 60) + minutes;
    if (isNaN(totalMinutes)) {totalMinutes = 0;}
    return totalMinutes;
}

const PriceEditor = props => {

    const rules = {
        5: /[0-5]/,
        2: /[0-2]/,
    }

    const listData = props.listData

    //debugger
    const handleChange = (e) => {
        //debugger
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            });
        }
    };



    // debugger
    const { dataItem } = props;
    const field = props.field || "";
    const value = dataItem[field] === null ? 0 : Math.floor(dataItem[field]);
    const formatter = new Intl.NumberFormat('cs-CZ', { useGrouping: true });

    const neutralStyle = props.neutralStyle || ""
    const redStyle = props.redStyle || "red"
    const greenStyle = props.greenStyle || "green"

    // debugger

    const colorClass = (value===0 ? neutralStyle : (value < 0 ? redStyle : greenStyle))

    return(
        <td
            style={props.style} // this applies styles that lock the column at a specific position
            className={props.className + " right " + colorClass} // this adds classes needed for locked columns
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
        >
            {dataItem.inEdit ? (

                <NumericTextBox
                    style={{
                        width: "100%",
                    }}
                    fillMode={"solid"}
                    rounded={"small"}
                    defaultValue={0}
                    value={value}
                    onChange={handleChange}
                    // placeholder="email"
                    //label={'e-mail'} //value={email} handleChange={setEmail}

                />

                // <DropDownList
                //     style={{
                //         width: "100%",
                //     }}
                //     onChange={handleChange}
                //     value={listData.find((c) => c.ID === dataValue)}
                //     data={listData}
                //     textField="Name"
                // />
            ) : (
                formatter.format(value)
            )}
        </td>
    )

}

export default PriceEditor
