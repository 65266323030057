import * as React from "react";
import {Dialog, DialogActionsBar, Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import {TextBox, NumericTextBox, TextArea, Checkbox} from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {Error, Hint, Label} from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import FormDropDown from "../formEditComponents/FormDropDown";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import {isDate, isNumeric} from "@progress/kendo-data-query/dist/npm/utils";
import {DateInput, DatePicker} from "@progress/kendo-react-dateinputs";
import DropDownEditor from "../editComponents/DropDownEditor";
import FormTextBoxField from "../formEditComponents/FormTextBoxField";
import FormTextAreaField from "../formEditComponents/FormTextAreaField";
import FormNumBoxField from "../formEditComponents/FormNumBoxField";
import FormDateField from "../formEditComponents/FormDateField";
import NumBoxField from "../formEditComponents/FormNumBoxField";
import TimeField from "../formEditComponents/FormTimeField";

const minValueValidator = value => value >= 0 ? "" : "The value must be 0 or higher";


const mandatoryFieldValidator = value => value != null ? "" : "mandatory field"


const useProjectEditor = props => (
    <FormDropDown
        {...props}
        textField={"ClientProjectName"}
    />
);


const EditTms = props => {

    const {
        cancelEdit,
        onSubmit,
        projects,
        authors,
        workTypes,
        ...other
    } = props;

    // item.UnitID = units.find((c) => c.ID === item.UnitID)
    // item.PartnerID = partners.find((c) => c.ID === item.PartnerID)

    const item = {}
    const originalItem = item

    item.TimeSpentIn = isDate(item.TimeSpentIn) ? item.TimeSpentIn : new Date(0,0,0, Math.floor(item.TimeSpentIn / 60), item.TimeSpentIn % 60)
    item.TimeSpentOut = isDate(item.TimeSpentOut) ? item.TimeSpentOut : new Date(0,0,0, Math.floor(item.TimeSpentOut / 60), item.TimeSpentOut % 60)

    const handleSubmit = dataItem => {
        // debugger
        if (isDate(dataItem.TimeSpentIn)) dataItem.TimeSpentIn = 60 * dataItem.TimeSpentIn.getHours() + dataItem.TimeSpentIn.getMinutes()
        if (isDate(dataItem.TimeSpentOut)) dataItem.TimeSpentOut = 60 * dataItem.TimeSpentOut.getHours() + dataItem.TimeSpentOut.getMinutes()

        if (!isNumeric(dataItem.ProjectID)) dataItem.ProjectID = dataItem.ProjectID.ID
        if (!isNumeric(dataItem.AuthorID)) dataItem.AuthorID = dataItem.AuthorID.ID
        if (!isNumeric(dataItem.WorkTypeID)) dataItem.WorkTypeID = dataItem.WorkTypeID.ID
        onSubmit(dataItem)
    }

    const handleClose = dataItem => {
        // item.TimeSpentIn = originalItem.TimeSpentIn
        // item.TimeSpentOut = originalItem.TimeSpentOut
        cancelEdit()
    }

    var title = ``
    if (item.ID === null) {
        title = "Copy TMS"
    }
    else if (item.ID === undefined) {
        title = "Create new TMS"
    }
    else {
        title = `Edit TMS: ${item.ID}`
    }


    // const { data:partners, isPending, error} = useFetch(config.API_URL + 'partner/', 1)

    return <Form initialValues={item} onSubmit={handleSubmit} render={renderProps =>

        <Window title={title} onClose={handleClose}
                initialHeight={800}
                initialWidth={600}
                modal={true}
        >
            <div className="editForm">
                <FormElement horizontal={true}>



                    {projects && <FieldWrapper><Field name={"ProjectID"} label={"Project"} textField={"Name"} dataItemKey={"id"} component={useProjectEditor} listData={projects}  /></FieldWrapper>}
                    {authors && <FieldWrapper><Field name={"AuthorID"} label={"Autor"} textField={"Name"} dataItemKey={"id"} component={FormDropDown} listData={authors} /></FieldWrapper>}
                    {workTypes && <FieldWrapper><Field name={"WorkTypeID"} label={"WorkTypeID"} textField={"Name"} dataItemKey={"id"} component={FormDropDown} listData={workTypes} /></FieldWrapper>}


                    <FieldWrapper><Field name={"Code"} component={FormTextBoxField}  label={"Code"} /></FieldWrapper>
                    <FieldWrapper><Field name={"Category"} component={FormTextBoxField}  label={"Category"} /></FieldWrapper>

                    <FieldWrapper><Field name={"Date"} component={FormDateField}  label={"Date"} /></FieldWrapper>

                    <FieldWrapper><Field name={"TimeSpentIn"} component={TimeField}  label={"TimeSpentIn"} /></FieldWrapper>
                    <FieldWrapper><Field name={"TimeSpentOut"} component={TimeField}  label={"TimeSpentOut"} /></FieldWrapper>

                    <FieldWrapper><Field name={"Note"} component={FormTextAreaField}  label={"Poznamka"} /></FieldWrapper>
                    <FieldWrapper><Field name={"Tags"} component={FormTextBoxField}  label={"Tagy"} /></FieldWrapper>

                    <FieldWrapper><Field name={"InvoiceNum"} component={FormTextBoxField}  label={"InvoiceNum"} /></FieldWrapper>




                    {/*<FieldWrapper>*/}
                    {/*    <Field name={"UnitPrice"} component={NonNegativeNumericInput} label={"Price"} validator={minValueValidator} />*/}
                    {/*</FieldWrapper>*/}
                    {/*<FieldWrapper>*/}
                    {/*    <Field name={"UnitsInStock"} component={NonNegativeNumericInput} label={"In stock"} validator={minValueValidator} />*/}
                    {/*</FieldWrapper>*/}
                </FormElement>
            </div>

            <WindowActionsBar layout="start">
                <Button type={"submit"} themeColor={"primary"} disabled={!renderProps.allowSubmit} onClick={renderProps.onSubmit} icon="save" >
                    Update
                </Button>
                <Button onClick={handleClose} icon="cancel" >
                    Cancel
                </Button>
            </WindowActionsBar>

        </Window>} {...other} />;
};
export default EditTms;