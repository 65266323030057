import * as React from "react";
import {Dialog, DialogActionsBar, Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import {TextBox, NumericTextBox, TextArea} from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {Error, Hint, Label} from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import FormDropDown from "../formEditComponents/FormDropDown";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import {isNumeric} from "@progress/kendo-data-query/dist/npm/utils";
import clients from "../clients/Clients";
import FormTextBoxField from "../formEditComponents/FormTextBoxField";
import FormTextAreaField from "../formEditComponents/FormTextAreaField";
import FormNonNegativeNumericInput from "../formEditComponents/FormNonNegativeNumericInput";

const minValueValidator = value => value >= 0 ? "" : "The value must be 0 or higher";

const mandatoryFieldValidator = value => (value != null) && (value !== 0) ? "" : "mandatory field"


const EditProject = props => {

    const {
        cancelEdit,
        onSubmit,
        item,
        partners,
        units,
        clients,
        projectStatuses,
        ...other
    } = props;

    // item.UnitID = units.find((c) => c.ID === item.UnitID)
    // item.PartnerID = partners.find((c) => c.ID === item.PartnerID)


    const handleSubmit = dataItem => {
        // debugger
        // if (!isNumeric(dataItem.PartnerID)) dataItem.PartnerID = dataItem.PartnerID.ID
        // if (!isNumeric(dataItem.UnitID)) dataItem.UnitID = dataItem.UnitID.ID
        if (!isNumeric(dataItem.ClientID)) dataItem.ClientID = dataItem.ClientID.ID
        if (!isNumeric(dataItem.ProjectStatusID)) dataItem.ProjectStatusID = dataItem.ProjectStatusID.ID
        onSubmit(dataItem)
    }

    var title = `Edit project: ${item.Name}`
    // debugger
    if (item.ID === null) {
        title = "Copy project"
    }

    if (item.ID === undefined) {
        title = "Create new project"
    }

    // const { data:partners, isPending, error} = useFetch(config.API_URL + 'partner/', 1)

    return <Form initialValues={item} onSubmit={handleSubmit} render={renderProps =>

        <Window title={title} onClose={cancelEdit}
                initialHeight={800}
                initialWidth={600}
                modal={true}
        >
            <div className="editForm">
                <FormElement horizontal={true}>


                    <FieldWrapper><Field name={"Name"} component={FormTextBoxField}  label={"Name"} validator={mandatoryFieldValidator} /></FieldWrapper>
                    <FieldWrapper><Field name={"OfferNum"} component={FormTextBoxField}  label={"Nabidka"} /></FieldWrapper>
                    {projectStatuses && <FieldWrapper><Field name={"ProjectStatusID"} label={"Status"} textField={"Name"} dataItemKey={"id"} component={FormDropDown} listData={projectStatuses} validator={mandatoryFieldValidator} /></FieldWrapper>}
                    <FieldWrapper><Field name={"Code"} component={FormTextBoxField}  label={"Kod"} /></FieldWrapper>
                    <FieldWrapper><Field name={"Category"} component={FormTextBoxField}  label={"Kategorie"} /></FieldWrapper>
                    <FieldWrapper><Field name={"FileNum"} component={FormTextBoxField}  label={"Slozka"} /></FieldWrapper>
                    <FieldWrapper><Field name={"Note"} component={FormTextAreaField}  label={"Poznamka"} /></FieldWrapper>
                    {/*<FieldWrapper><Field name={"Tags"} component={FormTextBoxField}  label={"Tagy"} /></FieldWrapper>*/}

                    <FieldWrapper><Field name={"ProjectLimit"} component={FormNonNegativeNumericInput}  label={"Limit"} /></FieldWrapper>

                    {/*{partners && <FieldWrapper><Field*/}
                    {/*    // id={"PartnerID"}*/}
                    {/*    name={"PartnerID"}*/}
                    {/*    label={"Partner"}*/}
                    {/*    textField={"Name"}*/}
                    {/*    dataItemKey={"id"}*/}
                    {/*    // selectField={"selected"}*/}
                    {/*    // expandField={"expanded"}*/}
                    {/*    component={FormDropDown}*/}
                    {/*    listData={partners}*/}
                    {/*    // validator={requiredValidator}*/}
                    {/*    validator={mandatoryFieldValidator}*/}
                    {/*/></FieldWrapper>}*/}

                    {/*{units && <FieldWrapper><Field*/}
                    {/*    // id={"PartnerID"}*/}
                    {/*    name={"UnitID"}*/}
                    {/*    label={"Stredisko"}*/}
                    {/*    textField={"Name"}*/}
                    {/*    dataItemKey={"id"}*/}
                    {/*    // selectField={"selected"}*/}
                    {/*    // expandField={"expanded"}*/}
                    {/*    component={FormDropDown}*/}
                    {/*    listData={units}*/}
                    {/*    // validator={requiredValidator}*/}
                    {/*    validator={mandatoryFieldValidator}*/}
                    {/*/></FieldWrapper>}*/}

                    {clients && <FieldWrapper><Field
                        // id={"PartnerID"}
                        name={"ClientID"}
                        label={"Klient"}
                        textField={"Name"}
                        dataItemKey={"id"}
                        // selectField={"selected"}
                        // expandField={"expanded"}
                        component={FormDropDown}
                        listData={clients}
                        // validator={requiredValidator}
                        validator={mandatoryFieldValidator}
                    /></FieldWrapper>}






                    {/*<FieldWrapper>*/}
                    {/*    <Field name={"UnitPrice"} component={NonNegativeNumericInput} label={"Price"} validator={minValueValidator} />*/}
                    {/*</FieldWrapper>*/}
                    {/*<FieldWrapper>*/}
                    {/*    <Field name={"UnitsInStock"} component={NonNegativeNumericInput} label={"In stock"} validator={minValueValidator} />*/}
                    {/*</FieldWrapper>*/}
                </FormElement>
            </div>

        <WindowActionsBar layout="start">
            <Button type={"submit"} themeColor={"primary"} disabled={!renderProps.allowSubmit} onClick={renderProps.onSubmit} icon="save" >
                Update
            </Button>
            <Button onClick={cancelEdit} icon="cancel" >
                Cancel
            </Button>
        </WindowActionsBar>

    </Window>} {...other} />;
};
export default EditProject;