import {
    Chart,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartSeries,
    ChartSeriesItem, ChartSeriesItemTooltip, ChartSeriesLabels
} from "@progress/kendo-react-charts";
import {groupBy} from "@progress/kendo-data-query";
import * as series from "@progress/kendo-data-query/dist/npm/transducers";
import CashflowGraphTooltipRow from "./CashflowGraphTooltipRow";

const CashflowGraph = props => {

    const data = props.data


    // console.log(data.data)
    // console.log(typeof data.data)
    // console.log(Array.isArray(data.data))

    const objectdata = data.data.reduce((acc, item) => {

        // debugger
        // get the value of both the keys
        const maturity = item["Maturity"];
        const price = item["PriceCZKVAT"];
        const text = item["Text"]
        const cislo = item["Number"]

        if(acc[maturity]){
            acc[maturity] = {
                ["Maturity"]: maturity,
                ["PriceCZKVAT"]: acc[maturity].PriceCZKVAT + price,
                ["Text"]: acc[maturity].Text + "<br /> [" + cislo + "]" + text
            }
        }
        else {
            acc[maturity] = {
                ["Maturity"]: maturity,
                ["PriceCZKVAT"]: price,
                ["Text"]: "[" + cislo + "]" + text
            }
        }

        return acc
    }, {})

    const adata = Object.keys(objectdata).map(datum => ({
        Maturity: datum,
        PriceVat: objectdata[datum].PriceCZKVAT,
        Text: objectdata[datum].Text,
    }))

    // console.log(data.data)
    // console.log(typeof adata)
    // console.log(Array.isArray(adata))
    //
    // console.log(adata)

    // debugger

    // const series = groupBy(data, [
    //     {
    //         field: "CashflowStatus",
    //     },
    // ]);

    const pointColor = (point) => {
        if (point.value > 0) {
            return "#AAFFAA";
        } else {
            return "#FFAAAA";
        }
    };

    console.log(series)

    // const tooltipTemplate = (point, id) => {
    //     // console.log('point - ', point)
    //     // console.log('id - ', id)
    //     return (
    //         <div>
    //             <div>Hodnota: {point.point.value}</div>
    //             {/*<div>Projekt: <b>{projects.find(prj => prj.ID === id).Name}</b></div>*/}
    //             <div>Projekt: <b>{point.point.series.name}</b></div>
    //         </div>
    //     );
    // };
    // const mapSeries = (item) => (
    //     <ChartSeriesItem
    //         data={item.items}
    //         name={item.value}
    //         field="Price"
    //         categoryField="Maturity"
    //         type="area"
    //         stack={true}
    //         line={{
    //             style: "smooth",
    //         }}
    //     >
    //         {/*<ChartSeriesItemTooltip visible={true} render={(point) => tooltipTemplate(point, item.value)} />*/}
    //         {/*<ChartSeriesItemTooltip visible={true} />*/}
    //     </ChartSeriesItem>
    // );

    const tooltipTemplate = (point, id) => {
        console.log('point - ', point)
        console.log('adata - ', adata)
        // console.log('id - ', id)
        // debugger
        // const dataPoint = adata.filter(item => {return(item.Maturity === point.point.category.toString())})[0]

        const dataPoints = data.data.filter(item => {
            const itemDate = new Date(item.Maturity);
            const pointDate = new Date(point.point.category);

            // Resetujeme časovou část na půlnoc
            itemDate.setHours(0, 0, 0, 0);
            pointDate.setHours(0, 0, 0, 0);

            return itemDate.getTime() === pointDate.getTime();
        });

        const text = "" //(dataPoint) ? dataPoint.Text : ""
        console.log('datapoint - ', dataPoints)

        const formatter = new Intl.NumberFormat('cs-CZ', { useGrouping: true });

        return (
            <div className={"tooltip"}>
                <div>Value: {formatter.format(point.point.value)}</div>
                <div>Total: {formatter.format(point.point.total)}</div>
                {/*<div>Projekt: <b>{projects.find(prj => prj.ID === id).Name}</b></div>*/}
                <div>Date: <b>{point.point.category.toLocaleDateString('cs-CZ')}</b></div>
                <div>&nbsp;</div>
                {dataPoints.map((item, index) => (<CashflowGraphTooltipRow key={index} item={item} />))}
            </div>
        );
    };

    return(

        <>
            {data &&
                <div className="cashflow-chart">
                    <Chart
                        style={{height:"100%"}}

                    >
                        <ChartCategoryAxis>
                            <ChartCategoryAxisItem type={"date"} maxDivisions={20} >

                            </ChartCategoryAxisItem>
                        </ChartCategoryAxis>
                        {/*<ChartSeries>*/}
                        {/*    {[...new Set(data.map(item => item.ProjectID))].map(id => (*/}
                        {/*        <ChartSeriesItem key={id} type={"area"} stack={true}*/}
                        {/*                         data={data}  //{data.filter(item => item.ProjectID === id)}*/}
                        {/*                         line={{*/}
                        {/*                             style: "normal",*/}
                        {/*                         }}*/}
                        {/*                         field="Value">*/}
                        {/*            <ChartSeriesItemTooltip visible={true} render={(point) => tooltipTemplate(point, id)} />*/}
                        {/*        </ChartSeriesItem>*/}
                        {/*    ))}*/}

                        {/*</ChartSeries>*/}
                        <ChartSeries>
                            {/*{series.map(mapSeries)}*/}

                            <ChartSeriesItem
                                data={adata}
                                name={"cashflow"}
                                field="PriceVat"
                                categoryField="Maturity"

                                // type="waterfall"
                                type={"waterfall"}
                                color={pointColor}
                                // stack={true}
                                line={{
                                    style: "step",
                                    width: "2px",
                                }}

                            >
                                <ChartSeriesLabels format="N0" position="outsideEnd" />
                                <ChartSeriesItemTooltip visible={true} render={(point) => tooltipTemplate(point, 20)} />
                            </ChartSeriesItem>

                        </ChartSeries>
                    </Chart>
                </div>
            }
        </>
    )

}

export default CashflowGraph