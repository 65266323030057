import {
    Button,
    ButtonGroup,
    Toolbar,
    ToolbarItem,
    ToolbarSeparator,
    ToolbarSpacer
} from "@progress/kendo-react-buttons";
import useClientStore from "../../stores/ClientStore";
import useProjectStore from "../../stores/ProjectStore";
import useOwnRightsStore from "../../stores/OwnRightsStore";

const ProjectDetailSelector = (props) => {

    const haveRight = useOwnRightsStore(state => state.haveRight)

    // const project = props.project
    const selectedProject = useProjectStore(state => state.selectedItem)

    // debugger

    const clientName = props.clientName
    const selectedDetail = props.selectedDetail
    const setSelectedDetail = props.setSelectedDetail
    const toggleDisplayParent = props.toggleDisplayParent

    const enabled = selectedProject != null
    const SelectedProjectname = (enabled ? selectedProject.ClientProjectName : "")

    return(
        <>
            <div className="detail-selector-outer">

                <span className="detail-selector-buttons">
                    <Toolbar className={"detail-selector-buttons-project"}>
                        <ToolbarItem style={{minWidth: 0}}>
                            <span className="detail-selector-caption" onClick={toggleDisplayParent}>{SelectedProjectname}</span>
                        </ToolbarItem>

                        <ToolbarSeparator />
                        <ToolbarItem>
                            <ButtonGroup disabled={!enabled}>
                                <Button onClick={() => setSelectedDetail(0)}
                                        themeColor={(selectedDetail === 0) ? "primary" : "base"}
                                >Přehled</Button>
                                <Button onClick={() => setSelectedDetail(12)}
                                        themeColor={(selectedDetail === 12) ? "primary" : "base"}
                                >Dokumenty</Button>
                                <Button onClick={() => setSelectedDetail(13)}
                                        themeColor={(selectedDetail === 13) ? "primary" : "base"}
                                >Tasks</Button>
                            </ButtonGroup>
                        </ToolbarItem>
                        <ToolbarSeparator />
                        <ToolbarItem>
                            <ButtonGroup disabled={!enabled}>

                                <Button onClick={() => setSelectedDetail(1)}
                                        themeColor={(selectedDetail === 1) ? "primary" : "base"}
                                        disabled={!haveRight("Prices_Project_Edit")}
                                >Ceny</Button>

                                <Button onClick={() => setSelectedDetail(3)}
                                        themeColor={(selectedDetail === 3) ? "primary" : "base"}
                                >Výkazy</Button>
                                <Button onClick={() => setSelectedDetail(4)}
                                        themeColor={(selectedDetail === 4) ? "primary" : "base"}
                                >Náklady</Button>
                                <Button onClick={() => setSelectedDetail(5)}
                                        themeColor={(selectedDetail === 5) ? "primary" : "base"}
                                >Cashflow</Button>
                            </ButtonGroup>
                        </ToolbarItem>
                        <ToolbarSpacer />
                        <ToolbarItem>

                            <span className="detail-selector-caption" onClick={toggleDisplayParent}>{clientName}</span>
                        </ToolbarItem>
                    </Toolbar>
                </span>

                {/*<span className="detail-selector-caption">*/}
                {/*    {clientName}*/}
                {/*</span>*/}

            </div>
        </>
    )
}

export default ProjectDetailSelector