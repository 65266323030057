import axios from "axios";
import config from "../config/config.json";
import {create} from "zustand";

const ProductStore = (set, get) => ({

    selectedItem: null,
    storeData: [],
    storeDataOriginal: [],
    isLoading: false,
    error: null,
    inAddMode: false,
    ClientID: 0,
    ProjectID: 0,

    selectItem: (project) => set((state) => ({
        selectedItem: project
    })),

    setStoreData: (newStoreData) => {
        // debugger
        set({storeData: newStoreData})
    },

    loadAll: async () => {
        // debugger
        console.log("load all project Data")
        set({ loading: true, error: null, ClientID: null, inAddMode: false });

        try {
            const response = await axios.get(config.API_URL + 'product/', {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            const newData = data.map(item => ({...item, Date: new Date(item.Date), Maturity: new Date(item.Maturity), LastActivity: (item.LastActivity ? new Date(item.LastActivity) : null)}))
            set({ storeData: newData, loading: false });
            set((state) => ({storeDataOriginal: state.storeData}))

        } catch (error) {
            set({ error: error.message, loading: false });
        }
    },


    addNew: () => {
        var newDataItem = {};
        if (get().ClientID !== 0) {
            newDataItem = {...newDataItem, ClientID: get().ClientID}
        }
        return newDataItem
    },


    saveNew: async (dataItem) => {
        // debugger

        // if (get().inAddMode) {

        try {
            const response = await axios.put(config.API_URL + 'product/', dataItem, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            // set((state) => state.loadCashflowForClient(state.ClientID))
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }

        // }
    },

    saveItem: async (dataItem) => {

        try {
            const response = await axios.post(config.API_URL + 'product/' + dataItem.ID, dataItem, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            //set((state) => state.loadCashflowForClient(state.ClientID))
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },

    deleteItem: async (dataItem) => {

        try {
            const response = await axios.delete(config.API_URL + 'product/' + dataItem.ID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            //set((state) => state.loadCashflowForClient(state.ClientID))
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },

    refreshData: () => {
        set((state) => state.loadAll())
    }

})

const useProductStore = create(ProductStore)

export default useProductStore