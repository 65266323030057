import {isNumeric} from "@progress/kendo-data-query/dist/npm/utils";
import {Field, FieldWrapper, Form, FormElement} from "@progress/kendo-react-form";
import {Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import FormTextBoxField from "../formEditComponents/FormTextBoxField";
import FormDropDown from "../formEditComponents/FormDropDown";
import FormTextAreaField from "../formEditComponents/FormTextAreaField";
import FormNonNegativeNumericInput from "../formEditComponents/FormNonNegativeNumericInput";
import {Button} from "@progress/kendo-react-buttons";
import * as React from "react";
import FormColorField from "../formEditComponents/FormColorField";

const mandatoryFieldValidator = value => value != null ? "" : "mandatory field"

const EditCheckList = props => {

    const {
        cancelEdit,
        onSubmit,
        item,
        partners,
        units,
        clients,
        projectStatuses,
        ...other
    } = props;


    const handleSubmit = dataItem => {
        // debugger
        onSubmit(dataItem)
    }

    var title = `Edit Note: ${item.Subject}`
    if (item.ID === null) {
        title = "Copy Note"
    }

    if (item.ID === undefined) {
        title = "Create new Note"
    }


    return <Form initialValues={item} onSubmit={handleSubmit} render={renderProps =>

        <Window title={title} onClose={cancelEdit}
                initialHeight={800}
                initialWidth={600}
                modal={true}
        >
            <div className="editForm">
                <FormElement horizontal={true}>

                    <FieldWrapper><Field name={"Subject"} component={FormTextBoxField}  label={"Subject"} validator={mandatoryFieldValidator} /></FieldWrapper>
                    <FieldWrapper><Field name={"Text"} component={FormTextAreaField} rows={10} label={"Text"} /></FieldWrapper>

                    {/*<FieldWrapper><Field name={"Color3"} component={FormColorField} label={"Color"} /></FieldWrapper>*/}


                </FormElement>
            </div>

            <WindowActionsBar layout="start">
                <Button type={"submit"} themeColor={"primary"} disabled={!renderProps.allowSubmit} onClick={renderProps.onSubmit} icon="save" >
                    Update
                </Button>
                <Button onClick={cancelEdit} icon="cancel" >
                    Cancel
                </Button>
            </WindowActionsBar>

        </Window>} {...other} />;

}

export default EditCheckList