import * as React from "react";
import {Dialog, DialogActionsBar, Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import {TextBox, NumericTextBox, TextArea} from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {Error, Hint, Label} from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import FormDropDown from "../formEditComponents/FormDropDown";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import {isNumeric} from "@progress/kendo-data-query/dist/npm/utils";
import FormTextBoxField from "../formEditComponents/FormTextBoxField";
import FormTextAreaField from "../formEditComponents/FormTextAreaField";
import axios from "axios";
import {useState} from "react";

const minValueValidator = value => value >= 0 ? "" : "The value must be 0 or higher";

const mandatoryFieldValidator = value => value != null ? "" : "mandatory field"

const EditClient = props => {

    const icons = {
        '.pdf':'k-i-pdf',
        '.doc':'k-i-doc',
        '.docx':'k-i-doc',
        '.xls':'k-i-xls',
        '.xlsx':'k-i-doc',
        '.txt':'k-i-txt',
        '.csv':'k-i-csv',
        '.exe':'k-i-exe',
        '.psd':'k-i-psd',
        '.ppt':'k-i-ppt',
        '.pptx':'k-i-ppt',
        '.avi':'k-i-film',
        '.mpg':'k-i-film',
        '.mp4':'k-i-film',
        '.mpeg':'k-i-film',
        '.jpg':'k-i-image',
        '.jpeg':'k-i-image',
        '.png':'k-i-image',
        '.bmp':'k-i-image',
        '.tiff':'k-i-image',
    }

    const {
        cancelEdit,
        onSubmit,
        item,
        partners,
        units,
        ...other
    } = props;

    // item.UnitID = units.find((c) => c.ID === item.UnitID)
    // item.PartnerID = partners.find((c) => c.ID === item.PartnerID)


    const [refNum, setRefNum] = useState(0)
    const {data:files, isPending, error} = useFetch(config.API_URL + 'file/' + item.UID, refNum)

    const handleSubmit = dataItem => {
        // debugger
        if (!isNumeric(dataItem.PartnerID)) dataItem.PartnerID = dataItem.PartnerID.ID
        if (!isNumeric(dataItem.UnitID)) dataItem.UnitID = dataItem.UnitID.ID
        onSubmit(dataItem)
    }

    var title = `Edit client: ${item.Name}`
    if (item.ID === null) {
        title = "Copy client"
    }

    if (item.ID === undefined) {
        title = "Create new client"
    }

    const  removeFile  = async file => {
        try {
            const response = await axios.delete(config.API_URL + 'file/' + file.ID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            //set((state) => state.loadCashflowForClient(state.ClientID))
            setRefNum(refNum + 1)
        } catch (error) {

        }
    }


    // const { data:partners, isPending, error} = useFetch(config.API_URL + 'partner/', 1)

    return <Form initialValues={item} onSubmit={handleSubmit} render={renderProps =>

        <Window title={title} onClose={cancelEdit}
                initialHeight={800}
                initialWidth={600}
                modal={true}
        >
            <div className="editForm">
                <FormElement horizontal={true}>


                    <FieldWrapper><Field name={"Name"} component={FormTextBoxField} label={"Name"}
                                         validator={mandatoryFieldValidator}/></FieldWrapper>
                    <FieldWrapper><Field name={"ICO"} component={FormTextBoxField} label={"ICO"}/></FieldWrapper>
                    <FieldWrapper><Field name={"DIC"} component={FormTextBoxField} label={"DIC"}/></FieldWrapper>
                    <FieldWrapper><Field name={"Code"} component={FormTextBoxField} label={"Kod"}/></FieldWrapper>
                    <FieldWrapper><Field name={"Category"} component={FormTextBoxField}
                                         label={"Kategorie"}/></FieldWrapper>
                    <FieldWrapper><Field name={"Email"} component={FormTextBoxField} label={"e-mail"}/></FieldWrapper>
                    <FieldWrapper><Field name={"Phone"} component={FormTextBoxField} label={"Telefon"}/></FieldWrapper>
                    <FieldWrapper><Field name={"Address"} component={FormTextAreaField}
                                         label={"Adresa"}/></FieldWrapper>
                    <FieldWrapper><Field name={"Representative"} component={FormTextBoxField}
                                         label={"Zastupce"}/></FieldWrapper>
                    <FieldWrapper><Field name={"Note"} component={FormTextAreaField} label={"Poznamka"}/></FieldWrapper>
                    {/*<FieldWrapper><Field name={"PartnerID"} component={TextBoxField}  label={"Partner"} /></FieldWrapper>*/}
                    {/*<FieldWrapper><Field name={"UnitID"} component={TextBoxField}  label={"Stredisko"} /></FieldWrapper>*/}
                    {/*<FieldWrapper><Field name={"Tags"} component={FormTextBoxField} label={"Tagy"}/></FieldWrapper>*/}

                    {partners && <FieldWrapper><Field
                        // id={"PartnerID"}
                        name={"PartnerID"}
                        label={"Partner"}
                        textField={"Name"}
                        dataItemKey={"id"}
                        // selectField={"selected"}
                        // expandField={"expanded"}
                        component={FormDropDown}
                        listData={partners}
                        // validator={requiredValidator}
                        validator={mandatoryFieldValidator}
                    /></FieldWrapper>}

                    {units && <FieldWrapper><Field
                        // id={"PartnerID"}
                        name={"UnitID"}
                        label={"Stredisko"}
                        textField={"Name"}
                        dataItemKey={"id"}
                        // selectField={"selected"}
                        // expandField={"expanded"}
                        component={FormDropDown}
                        listData={units}
                        // validator={requiredValidator}
                        validator={mandatoryFieldValidator}
                    /></FieldWrapper>}


                    {/*<FieldWrapper>*/}
                    {/*    <Field name={"UnitPrice"} component={NonNegativeNumericInput} label={"Price"} validator={minValueValidator} />*/}
                    {/*</FieldWrapper>*/}
                    {/*<FieldWrapper>*/}
                    {/*    <Field name={"UnitsInStock"} component={NonNegativeNumericInput} label={"In stock"} validator={minValueValidator} />*/}
                    {/*</FieldWrapper>*/}
                </FormElement>
            </div>

            <div className="file-list" style={{height: 'calc(100% - 750px)'}}>
                {files && <>

                    {files.map(f => (
                        <div key={f.ID} className="file-row">
                            <a className="file-row-a" href={config.FILE_URL + f.RelPath} target="_blank"><span
                                className={"k-icon " + (icons[f.Type.toLowerCase()] || "k-i-file")}
                                style={{fontSize: 50 + 'px'}}/>
                                <span className="file-row-name">{f.Name}</span>
                                <span
                                    className="file-row-info">{new Date(f.CreatedAt).toLocaleString('cz-CZ')}, {f.Size} B</span></a>
                            {/*<span className="file-row-remove">*/}

                            {/*    <span className="k-icon k-i-x-circle red" style={{fontSize: 25 + 'px'}}*/}
                            {/*          onClick={() => removeFile(f)} />*/}

                            {/*</span>*/}

                        </div>
                    ))}

                </>}
            </div>

            <WindowActionsBar layout="start">
                <Button type={"submit"} themeColor={"primary"} disabled={!renderProps.allowSubmit}
                        onClick={renderProps.onSubmit} icon="save">
                    Update
                </Button>
                <Button onClick={cancelEdit} icon="cancel">
                    Cancel
                </Button>
            </WindowActionsBar>

        </Window>} {...other} />;
};
export default EditClient;