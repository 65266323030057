import {useLayoutEffect, useState} from "react";
import Price from "./Price";
import {StackLayout} from "@progress/kendo-react-layout";

const BasePriceContainer = props => {

    const [refSize, setRefSize] = useState({width:0, height:0});

    const handleSize = () => {
        // debugger
        let width = window.innerWidth - 10;
        const height = window.innerHeight;
        setRefSize({
            // width: ref.current.offsetWidth,
            // height: ref.current.offsetHeight
            width: width,
            height: height
        })
        console.log(refSize.width, width, window.innerWidth)
    }

    useLayoutEffect(() => {
        handleSize()
        window.addEventListener("resize", handleSize);
        return () => window.removeEventListener("resize", handleSize);
    }, []);

    return(


        <StackLayout
            style={{
                height: "calc(100vh - 55px)",
            }}
            orientation={"vertical"}
            gap={0}
        >

            <Price size={refSize} menuSize={props.menuSize} dataType={"base"} />

        </StackLayout>
    )
}

export default BasePriceContainer