import {create} from "zustand";
import axios from "axios";
import config from "../config/config.json";
import clients from "../components/clients/Clients";
import APICall from "../tools/APICall";

const CurrencyStore = (set, get) => ({

    data: [],
    dataOriginal: [],
    isLoading: false,
    error: null,
    inAddMode: false,

    setData: (newData) => {
        // debugger
        set({data: newData})
    },

    loadData: async () => {
        // debugger
        set({ loading: true, error: null, inAddMode: false });

        try {
            const response = await axios.get(config.API_URL + 'lists/currency/', {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            set({ data: response.data, loading: false });
            set((state) => ({dataOriginal: state.data}))
        } catch (error) {
            set({ error: error.message, loading: false });
        }
    },

    addNew: () => {
        var newDataItem = {};
        return newDataItem
    },

    discardNew: () => {
        // debugger
        if (get().inAddMode) {

            set({inAddMode: false})

            const newData = [...get().data];
            newData.splice(0, 1);

            set({data: newData})

        }
    },

    saveNew: async (dataItem) => {
        try {
            const response = await axios.put(config.API_URL + 'lists/currency/', dataItem, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }
    },

    saveItem: async (dataItem) => {

        try {
            const response = await axios.post(config.API_URL + 'lists/currency/' + dataItem.ID, dataItem, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },

    refreshData: () => {
        set((state) => state.loadData())
    }

})

const useCurrencyStore = create(CurrencyStore)

export default useCurrencyStore