import {Dialog, DialogActionsBar, Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import {ExternalDropZone, Upload} from "@progress/kendo-react-upload";
import {createRef, useState} from "react";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import "../../App.scss"
import {StackLayout} from "@progress/kendo-react-layout";
import {Button} from "@progress/kendo-react-buttons";
import axios from "axios";
import * as React from "react";
import ImportGrid from "./ImportGrid";
import useTmsStore from "../../stores/TmsStore";



const ConfirmImportDialog = props => {
    return(
        <>


            <Dialog title={"Please confirm"} onClose={props.cancel}
                    height={250}
                    width={400}
            >
                <p
                    style={{
                        margin: "25px",
                        textAlign: "center",
                    }}
                >
                    {props.text}
                </p>
                <DialogActionsBar>

                    <Button
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                        onClick={props.cancel}
                        themeColor={"base"}
                        style={{height:"40px"}}
                    >
                        Cancel
                    </Button>

                    <Button
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                        onClick={props.actionImport}
                        themeColor={"warning"}
                        style={{height:"40px"}}
                    >
                        Import
                    </Button>

                    <Button
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                        onClick={props.actionImportAndDelete}
                        themeColor={"error"}
                        style={{height:"40px"}}
                    >
                        Import & delete existing
                    </Button>

                </DialogActionsBar>
            </Dialog>



        </>
    )
}




const ImportDialog = props => {

    const uploadRef = createRef();
    const [refNum, setRefNum] = useState(0)
    const imporForProject = props.forProject
    const close = props.close

    const [importID, setImportID] = useState(null)

    const windowWidth = 1500


    //const {data:files, isPending, error} = useFetch(config.API_URL + 'file/' + props.filesFor.UID, refNum)

    const formatter = new Intl.DateTimeFormat('cs-CZ')

    const tmsConfirmImport = useTmsStore(state => state.confirmImport)
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)

    const confirmDialogCancel = () => {
        setShowConfirmDialog(false)
    }

    const confirmDialogAction = () => {
        tmsConfirmImport(importID, false)
        close()
    }

    const confirmDialogActionDelete = () => {
        tmsConfirmImport(importID, true)
        close()
    }

    const confirmImport = () => {

        setShowConfirmDialog(true)

        //tmsConfirmImport(importID)
        //close()
    }

    const onBeforeUpload = (event) => {
        event.additionalData.description = "File upload";
        event.additionalData.ProjectID = imporForProject.ID;
    };
    const onBeforeRemove = (event) => {
        event.additionalData.description = "File remove";
    };

    // const  removeFile  = async file => {
    //     try {
    //         const response = await axios.delete(config.API_URL + 'file/' + file.ID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
    //         //set((state) => state.loadCashflowForClient(state.ClientID))
    //         setRefNum(refNum + 1)
    //     } catch (error) {
    //
    //     }
    // }

    const onStatusChange = (event) => {
        setRefNum(refNum + 1)
        console.log(event)
        if (event.response.response.importID) setImportID(event.response.response.importID)
    };


    return(
        <>

            <Window title={"Import TMS for Project: " + imporForProject.Name}
                    initialHeight={1000}
                    initialWidth={windowWidth}
                    modal={true}
                    onClose={props.close}
            >


                <StackLayout
                    style={{
                        height: "100%",
                    }}
                    orientation={"vertical"}
                    gap={0}
                >

                    {<div className="file-upload">


                        <Upload
                            ref={uploadRef}
                            batch={false}
                            multiple={true}
                            // defaultFiles={[]}
                            // files={files}
                            onBeforeUpload={onBeforeUpload}
                            onBeforeRemove={onBeforeRemove}
                            onStatusChange={onStatusChange}

                            withCredentials={false}
                            saveUrl={config.API_URL + "tms/import_for_project/"}
                            // removeUrl={"http://localhost:8099/api/file/"}
                            removeMethod={"DELETE"}
                            saveHeaders={{'x-api-key': sessionStorage.getItem("apiKEY")}}
                            removeHeaders={{'x-api-key': sessionStorage.getItem("apiKEY")}}
                            showFileList={false}
                        />

                        <ExternalDropZone uploadRef={uploadRef}/>

                        {/*<div*/}
                        {/*    style={{*/}
                        {/*        height: "50px",*/}
                        {/*    }}*/}
                        {/*/>*/}

                    </div>}


                    <ImportGrid importID={importID} width={windowWidth - 2}/>

                    {showConfirmDialog && <ConfirmImportDialog
                        cancel={confirmDialogCancel}
                        text={"please confirm import and select:"}
                        actionImport={confirmDialogAction}
                        actionImportAndDelete={confirmDialogActionDelete}
                    />}

                </StackLayout>

                <WindowActionsBar layout="end">

                    <Button onClick={props.close} icon="cancel" >
                        Cancel
                    </Button>

                    <Button type={"submit"} themeColor={"primary"} disabled={!importID} onClick={confirmImport} icon="save" >
                        Confirm import
                    </Button>

                </WindowActionsBar>

            </Window>

        </>
    )
}

export default ImportDialog