import {StackLayout} from "@progress/kendo-react-layout";
import PriceGrid from "./PriceGrid";
import MasterPrice from "./MasterPrice";
import {useEffect, useState} from "react";
import useClientStore from "../../stores/ClientStore";
import useProjectStore from "../../stores/ProjectStore";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import usePriceStore from "../../stores/PriceStore";

const Price = props => {


    const [refresh, setRefresh] = useState(Date.now())

    const dataType = props.dataType || "base"

    const {selectedClient} = useClientStore((state) => ({selectedClient: state.selectedClient}))
    const {selectedProject} = useProjectStore((state) => ({selectedProject: state.selectedItem}))

    const { data:currency, isPending:currency_isPending, error:currency_error} = useFetch(config.API_URL + 'lists/currency/', refresh)
    // const { data:projectMasterPrice, isPending:projectMasterPrice_isPending, error:projectMasterPrice_error} = useFetch(config.API_URL + 'project/master_price/' + selectedProject.ID, refresh)

    // const filter = null


    const baseMasterPrice = usePriceStore(state => state.baseMasterPrice)
    const projectMasterPrice = usePriceStore(state => state.projectMasterPrice)
    const clientMasterPrice = usePriceStore(state => state.clientMasterPrice)
    const setData = usePriceStore(state => state.setTms)
    const loadForClient = usePriceStore(state => state.loadForClient)
    const loadForProject = usePriceStore(state => state.loadForProject)
    const loadBase = usePriceStore(state => state.loadBase)


    useEffect(() => {
        switch (dataType) {
            case "client":
                loadForClient(selectedClient.ID)
                break
            case "project":
                loadForProject(selectedProject.ID)
                break
            default:
                loadBase()
        }

        console.log("projectMasterPrice - ", projectMasterPrice)

    },[selectedClient, selectedProject])


    return(
        <>

            <StackLayout
                style={{
                    height: "calc(100% - 50px)",
                    // width: "100%"
                }}
                orientation={"horizontal"}
                gap={0}
            >

                <div className="master-price-outer">
                    {dataType==="base" && <MasterPrice values={baseMasterPrice} formKey={Date.now()} dataType={"base"}/>}
                    {(dataType==="project" || dataType==="client") && <MasterPrice values={clientMasterPrice} formKey={Date.now()} dataType={"client"}/>}
                    {dataType==="project" && <MasterPrice values={projectMasterPrice} formKey={Date.now()} dataType={"project"}/>}
                </div>

                <PriceGrid size={props.size} menuSize={props.menuSize} selectedProject={selectedProject} selectedClient={selectedClient} dataType={dataType} />

            </StackLayout>

        </>
    )
}

export default Price