import {useEffect, useLayoutEffect, useState} from "react";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import {Button, Toolbar, ToolbarItem, ToolbarSpacer} from "@progress/kendo-react-buttons";
import TmsGrid from "../tms/TmsGrid";
import {Splitter, StackLayout} from "@progress/kendo-react-layout";
import ProjectsGrid from "./ProjectsGrid";
import ProjectDetailSelector from "./ProjectDetailSelector";
import ProjectDetail from "./ProjectDetail";
import useClientStore from "../../stores/ClientStore";
import useProjectStore from "../../stores/ProjectStore";
import FileInDetail from "../files/FileInDetail";
import CashFlowGrid from "../cashflow/CashFlowGrid";
import Price from "../price/Price";
import Expenses from "../expenses/Expenses";
import TaskGrid from "../tasks/TasksGrid";
import ExpensesGrid from "../expenses/ExpensesGrid";
import ProjectDetailSection from "./ProjectDetailSection";


const Projects = (props) => {

    console.log(props.size)
    const menuSize = props.menuSize
    console.log("menu size - ", menuSize)

    const {selectedProject, setSelectedProject} = useProjectStore((state) => ({selectedProject: state.selectedItem, setSelectedProject: state.selectItem}))
    const {selectedClient, setSelectedClient} = useClientStore((state) => ({selectedClient: state.selectedClient, setSelectedClient: state.selectClient}))

    const loadProjects = useProjectStore(state => state.loadAll)

    const refresh = props.refresh
    const [refSize, setRefSize] = useState({width:0, height:0});
    // const [selectedProject, setSelectedProject] = useState()
    // const [selectedProjectName, setSelectedProjectName] = useState()
    const [selectedDetail, setSelectedDetail] = useState()
    const [displayProjectsGrid, setDisplayProjectsGrid] = useState(true)

    const [panes, setPanes] = useState([
        {
            size: "50%",
            min: "0",
            collapsible: true,
        },
        {
            min: "50px",
            collapsible: false,
        },
    ]);

    const onChange = (event) => {
        setPanes(event.newState);
    };

    const toggleDisplayProjectsGrid = () => {
        setDisplayProjectsGrid(!displayProjectsGrid)
    }

    const handleSize = () => {
        // debugger
        let width = window.innerWidth - 10;
        const height = window.innerHeight;
        setRefSize({
            // width: ref.current.offsetWidth,
            // height: ref.current.offsetHeight
            width: width,
            height: height
        })
        console.log(refSize.width, width, window.innerWidth)
    }

    useLayoutEffect(() => {
        handleSize()
        window.addEventListener("resize", handleSize);
        return () => window.removeEventListener("resize", handleSize);
    }, []);

    const { data:projects, isPending, error} = useFetch(config.API_URL + 'project/', refresh)
    const [data, setData] = useState(projects)

    useEffect(() => {
        loadProjects()
        setSelectedProject(null)
        setSelectedClient(null)
    },[])

    //
    // useEffect(() => {
    //     setData(projects)
    // },[projects])


    const [editID, setEditID] = useState(null);

    // const [filtered, setFiltered] = useState(0)
    // const [openForm, setOpenForm] = useState(false);
    // const [editItem, setEditItem] = useState({ProductID: 1,});
    // const [data, setData] = useState(users);


    const addNew = () => {
        const newDataItem = {
            inEdit: true,

        };
        setData([newDataItem, ...data]);
    };


    return(
        <>

            {/*<div className="outer-table" ref={ref}>*/}
            {projects && <>

                <Splitter
                    style={{
                        height: "calc(100vh - 55px)",
                    }}
                    panes={panes}
                    orientation={"vertical"}
                    onChange={onChange}


                >

                    <StackLayout
                        style={{
                            height: "100%",
                        }}
                        orientation={"vertical"}
                        gap={0}
                    >

                        <ProjectsGrid projects={projects} data={data} setData={setData} size={refSize} menuSize={menuSize}
                                     selected={selectedProject} setSelected={setSelectedProject}
                                     // setSelectedProjectName={setSelectedProjectName}
                                     toggleDisplayParent={toggleDisplayProjectsGrid}
                        />
                    </StackLayout>



                    <ProjectDetailSection menuSize={menuSize} size={refSize} selectedDetail={selectedDetail} setSelectedDetail={setSelectedDetail} />


                </Splitter>

            </>}

        </>
    )
}

export default Projects