import {create} from "zustand";
import axios from "axios";
import config from "../config/config.json";
import cashflow from "../components/cashflow/Cashflow";

const PriceStore = (set, get) => ({

    selectedItem: null,
    userPrice: [],
    projectMasterPrice: [],
    clientMasterPrice: [],
    baseMasterPrice: [],
    // storeDataOriginal: [],
    isLoading: false,
    error: null,
    inAddMode: false,
    ClientID: 0,
    ProjectID: 0,




    selectItem: (item) => set((state) => ({
        selectedItem: item
    })),

    setStoreData: (newStoreData) => {
        // debugger
        set({userPrice: newStoreData})
    },

    loadBase: async () => {
        // debugger
        set({ loading: true, error: null, clientMasterPrice:[], projectMasterPrice:[], ClientID: null, ProjectID: null, inAddMode: false });

        try {
            const response = await axios.get(config.API_URL + 'price/base/', {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            const newData = data //.map(item => ({...item, Date: new Date(item.Date), Maturity: new Date(item.Maturity)}))
            set({ userPrice: newData, loading: false });
            // set((state) => ({storeDataOriginal: state.userPrice}))

        } catch (error) {
            set({ error: error.message, loading: false });
        }

        try {
            const response = await axios.get(config.API_URL + 'price/base_master/', {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            set({ baseMasterPrice: data, loading: false });
            // set((state) => ({storeDataOriginal: state.userPrice}))

        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },

    loadForProject: async (projectID) => {
        // debugger
        set({ loading: true, error: null, ClientID: null, ProjectID: projectID , inAddMode: false });

        try {
            const response = await axios.get(config.API_URL + 'price/project/' + projectID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            set({ userPrice: data, loading: false });
            // set((state) => ({storeDataOriginal: state.userPrice}))

        } catch (error) {
            set({ error: error.message, loading: false });
        }

        try {
            const response = await axios.get(config.API_URL + 'price/project_master/' + projectID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            set({ projectMasterPrice: data, loading: false });
            // set((state) => ({storeDataOriginal: state.userPrice}))

        } catch (error) {
            set({ error: error.message, loading: false });
        }

        try {
            const response = await axios.get(config.API_URL + 'price/client_master_for_project/' + projectID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            // if (data.ClientID == null) data.ClientID =
            set({ clientMasterPrice: data, loading: false });
            // set((state) => ({storeDataOriginal: state.userPrice}))

        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },

    loadForClient: async (clientID) => {
        //debugger
        set({ loading: true, error: null, projectMasterPrice:[], ClientID: clientID, ProjectID: null , inAddMode: false });

        try {
            const response = await axios.get(config.API_URL + 'price/client/' + clientID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            const newData = data //.map(item => ({...item, Date: new Date(item.Date)}))
            set({ userPrice: newData, loading: false });
            // set((state) => ({storeDataOriginal: state.userPrice}))

        } catch (error) {
            set({ error: error.message, loading: false });
        }

        try {
            const response = await axios.get(config.API_URL + 'price/client_master/' + clientID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            const newData = data //.map(item => ({...item, Date: new Date(item.Date)}))
            set({ clientMasterPrice: newData, loading: false });
            // set((state) => ({storeDataOriginal: state.userPrice}))

        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },


    addNew: () => {
        // if (!get().inAddMode) {
            var newDataItem = {};
            return newDataItem
        // }
    },


    saveNew: async (dataItem) => {
        // debugger

        // if (get().inAddMode) {

            try {
                const response = await axios.put(config.API_URL + 'lists/unit/', dataItem, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
                // set((state) => state.loadCashflowForClient(state.ClientID))
                get().refreshData()
            } catch (error) {
                set({ error: error.message, loading: false });
            }

        // }
    },

    saveOrAddItem: async (dataItem) => {

        try {
            const response = await axios.post(config.API_URL + 'price/', dataItem, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            //set((state) => state.loadCashflowForClient(state.ClientID))
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }
        get().refreshData()

    },

    deleteItem: async (dataItem) => {

        try {
            const response = await axios.delete(config.API_URL + 'lists/unit/' + dataItem.ID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            //set((state) => state.loadCashflowForClient(state.ClientID))
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },

    refreshData: () => {
        if (get().ProjectID > 0) set((state) => state.loadForProject(state.ProjectID))
        else if (get().ClientID > 0) set((state) => state.loadForClient(state.ClientID))
        else set((state) => state.loadBase())
    }

})

const usePriceStore = create(PriceStore)

export default usePriceStore