import DetailSelector from "./DetailSelector";
import ClientDetail from "./ClientDetail";
import Price from "../price/Price";
import ProjectsGrid from "../projects/ProjectsGrid";
import TmsGrid from "../tms/TmsGrid";
import ExpensesGrid from "../expenses/ExpensesGrid";
import CashFlowGrid from "../cashflow/CashFlowGrid";
import FileInDetail from "../files/FileInDetail";
import TaskGrid from "../tasks/TasksGrid";
import {StackLayout} from "@progress/kendo-react-layout";
import {useState} from "react";
import useClientStore from "../../stores/ClientStore";
import Tasks from "../tasks/Tasks";
import ProductClient from "../productClients/ProductClient";

const ClientDetailSection = props => {

    const {selectedClient, setSelectedClient} = useClientStore((state) => ({selectedClient: state.selectedClient, setSelectedClient: state.selectClient}))

    // const [selectedDetail, setSelectedDetail] = useState()
    // const [refSize, setRefSize] = useState({width:0, height:0});

    const menuSize = props.menuSize
    const refSize = props.size

    const selectedDetail = props.selectedDetail
    const setSelectedDetail = props.setSelectedDetail


    const toggleDisplayClientsGrid = () => {

    }

    return(
        <>

            <StackLayout
                style={{
                    height: "100%",
                }}
                orientation={"vertical"}
                gap={0}
            >
                <div className="list-header">
                    <DetailSelector selectedClient={selectedClient}
                                    selectedDetail={selectedDetail}
                                    setSelectedDetail={setSelectedDetail}
                                    toggleDisplayParent={toggleDisplayClientsGrid}
                    />
                </div>
                <>

                    {selectedDetail===0 &&
                        <ClientDetail size={refSize} menuSize={menuSize} />}

                    {selectedDetail===1 &&
                        <Price size={refSize} menuSize={menuSize} dataType={"client"}  />}

                    {selectedDetail===2 &&
                        <ProjectsGrid size={refSize} menuSize={menuSize} dataType={"client"} />}

                    {selectedDetail===3 &&
                        <TmsGrid size={refSize} menuSize={menuSize} dataType={"client"} />}

                    {selectedDetail===4 &&
                        <ExpensesGrid size={refSize} menuSize={menuSize} dataType={"client"} />}

                    {selectedDetail===5 &&
                        <CashFlowGrid size={refSize} menuSize={menuSize} dataType={"client"} />}

                    {selectedDetail===12 &&
                        <FileInDetail size={refSize} menuSize={menuSize} dataType={"client"} filesFor={selectedClient} />}

                    {selectedDetail===13 &&
                        <Tasks size={refSize} menuSize={menuSize} dataType={"client"} filesFor={selectedClient} />}

                    {selectedDetail===21 &&
                        <ProductClient size={refSize} menuSize={menuSize} dataType={"client"} />}



                </>

            </StackLayout>

        </>
    )
}

export default ClientDetailSection