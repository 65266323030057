import {process} from "@progress/kendo-data-query";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {Grid, GridToolbar} from "@progress/kendo-react-grid";
import {useEffect, useState} from "react";
import CommandCell from "../../tableComponents/CommandCell";
import useUserStore from "../../../stores/UserStore";
import DropDownEditor from "../../editComponents/DropDownEditor";
import DropDownFilter from "../../filterComponents/DropDownFilter";
import useFetch from "../../../tools/UseFetch";
import config from "../../../config/config.json";
import EditUser from "./EditUser";
import UserCommandCell from "../../tableComponents/UserCommandCell";
import EditGroups from "./EditGroups";

const EDIT_FIELD = "inEdit";

const ActionCell = (props) => {
    return(
        <>

        </>
    )
}

const initialDataState = {
    // take: 50,
    skip: 0,
    pageSize:100,
};





const UserGrid = (props) => {


    const refSize = props.size
    const menuSize = props.menuSize
    const dataType = props.dataType || "all"

    const exportData = props.setData

    const [dataState, setDataState] = useState(initialDataState);
    const [refresh, setRefresh] = useState(Date.now())


    let columnDefWidth = 100


    const data = useUserStore(state => state.storeData)
    const addNew = useUserStore(state => state.addNew)
    const setData = useUserStore(state => state.setStoreData)
    const saveNew = useUserStore(state => state.saveNew)
    const saveItem = useUserStore(state => state.saveItem)

    const refreshData = useUserStore(state => state.refreshData)
    const deleteItem = useUserStore(state => state.deleteItem)

    const loadAll = useUserStore(state => state.loadAll)


    const [openForm, setOpenForm] = useState(false);
    const [editItem, setEditItem] = useState(null);

    const { data:partners, isPending, error} = useFetch(config.API_URL + 'partner/', 1)
    // const { data:user_groups, user_groupsIsPending, user_groupsError} = useFetch(config.API_URL + 'group/for_user/' + props.actual_user.ID, 1)



    const CountFooter = (props) => {
        const field = props.field || "";
        const total = process(data, dataState).data.length
        return (
            <td
                style={props.style} // this applies styles that lock the column at a specific position
                className={props.className} // this adds classes needed for locked columns
                colSpan={props.colSpan}
                role={"gridcell"}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
            >
                {total} záznamů
            </td>
        );
    };


    // const [data, setData] = useState(cashflow)



    useEffect(() => {
        loadAll()
    },[])



    const MyEditCommandCell = (props) => (
        <UserCommandCell
            {...props}
            edit={enterEdit}
            copy={enterCopy}
            groups={editGroups}
            groupsCount={props.dataItem.GroupsCount}
        />
    );

    const usePartnerEditor = props => (
        <DropDownEditor
            {...props}
            listData={partners}
        />
    );

    const PartnerFilter = (props) => (
        <DropDownFilter
            {...props}
            data={partners}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );


    const itemChange = (event) => {
        const inEditID = event.dataItem.ID;
        const field = event.field || "";
        const newData = data.map((item) =>
            item.ID === inEditID
                ? {
                    ...item,
                    [field]: event.value,
                }
                : item
        );
        setData(newData);
    };



    // *********************************** HELPER DATA **********************************

    const [openGroupsForm, setOpenGroupsForm] = useState(false);


    // *********************************** EDIT GROUPS **********************************

    const editGroups = dataItem => {
        setEditItem(dataItem)
        setOpenGroupsForm(true)
    }

    const cancelEditGroups = e => {
        setOpenGroupsForm(false)
    }

    const submitEditGroups = e => {
        setOpenGroupsForm(false)
        console.log("before data refresh")
        refreshData()
    }


    // ************************************** EDIT *************************************

    const rowDblClick = props => {
        enterEdit(props.dataItem)
    }

    const enterEdit = (dataItem) => {
        console.log("item edit")

        setEditItem(dataItem)
        setOpenForm(true)
    };

    const handleCancelEdit = (dataItem) => {

        setOpenForm(false);

    };



    const handleSubmit = (dataItem) => {
        debugger
        if (dataItem.ID === undefined || dataItem.ID === null) {
            saveNew(dataItem)
        }
        else {
            saveItem(dataItem)
        }
        setOpenForm(false);
    };



    // ************************************** NEW *************************************

    const editNew = () => {
        const newItem = addNew //{};
        setEditItem(newItem)
        setOpenForm(true)
    };


    // ************************************** COPY *************************************


    const enterCopy = (dataItem) => {
        console.log("copy edit")

        const newItem = {
            ...dataItem,
            ID: null,
            UID: null,
        }

        setEditItem(newItem)
        setOpenForm(true)

        console.log("edit copy ... ", openForm, editItem)
    };


    return(
        <>

            {data && partners &&


                <Grid
                    //pageable={true}
                    sortable={true}
                    filterable={true}
                    scrollable={"scrollable"} //virtual
                    resizable={true}
                    reorderable={true}
                    rowHeight={40}
                    onItemChange={itemChange}

                    editField={EDIT_FIELD}

                    style={{
                        height: "100%", //"calc(100% - 55px)", // (refSize.height - 150) / 2,//"500px",
                        minHeight: 20,
                        //maxHeight: 300, // refSize.height - 70,
                        width: refSize.width - menuSize,
                    }}
                    data={process(data, dataState)}{...dataState}

                    //onPageChange={pageChange}
                    onDataStateChange={(e) => setDataState(e.dataState)}
                    onRowDoubleClick={rowDblClick}

                >

                    <GridToolbar>

                        <button
                            title="Export to Excel"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={editNew}
                        >
                            Novy zaznam
                        </button>

                    </GridToolbar>

                    <Column field="ID" title="ID" width="80px" filterable={false} editable={false} locked />


                    <Column field="Name" minResizableWidth={80} title="Name" width={220} editable={true} locked />
                    <Column field="ShortName" minResizableWidth={80} title="Short Name" width={100} editable={true} />
                    <Column field="Nick" minResizableWidth={80} title="Nick" width={100} editable={true} />
                    <Column field="Email" minResizableWidth={80} title="e-mail" width={100} editable={true} />
                    <Column field="Phone" minResizableWidth={80} title="phone" width={100} editable={true} />
                    <Column field="Uname" minResizableWidth={80} title="user name" width={100} editable={true} />

                    <Column field="PartnerID" minResizableWidth={100} title="Partner" cell={usePartnerEditor}
                            width={200} filterCell={PartnerFilter} />

                    <Column field="AllowedUnits" minResizableWidth={80} title="units" width={100} editable={true} />
                    <Column field="AllowedPartners" minResizableWidth={80} title="partners" width={100} editable={true} />
                    <Column field="IntegrationID" minResizableWidth={80} title="IntegrationID" width={100} editable={true} />
                    <Column field="Groups" minResizableWidth={80} title="Groups" width={250} editable={true} />


                    <Column field="action" minResizableWidth={180}
                            cell={MyEditCommandCell}
                        // columnMenu={ActionCell}
                            filterable={false}
                            headerCell={ActionCell}
                            width="180"
                            resizable={true}
                            locked
                        // filterCell={ActionCell}
                    />

                </Grid>

            }



            {openForm && <EditUser
                partners={partners}
                cancelEdit={handleCancelEdit}
                onSubmit={handleSubmit}
                item={editItem}
            />}


            {openGroupsForm && <EditGroups
                partners={partners}
                onCancel={cancelEditGroups}
                onSubmit={submitEditGroups}
                item={editItem}
            />}


        </>
    )
}

export default UserGrid