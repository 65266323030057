import {Field, FieldWrapper, Form, FormElement} from "@progress/kendo-react-form";
import FormTextBoxField from "../formEditComponents/FormTextBoxField";
import FormDateField from "../formEditComponents/FormDateField";
import {Button} from "@progress/kendo-react-buttons";
import * as React from "react";
import {isNumeric} from "@progress/kendo-data-query/dist/npm/utils";
import FormDropDown from "../formEditComponents/FormDropDown";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import {useEffect, useState} from "react";
import useClientStore from "../../stores/ClientStore";
import useProjectStore from "../../stores/ProjectStore";
import useTmsStore from "../../stores/TmsStore";
import usePriceStore from "../../stores/PriceStore";
import NumBoxField from "../formEditComponents/FormNumBoxField";
import FormNumBoxField from "../formEditComponents/FormNumBoxField";

const mandatoryFieldValidator = value => value != null ? "" : "mandatory field"


const useCurrency = props => (
    <FormDropDown
        {...props}
        textField={"Name"}
        defaultItem={{Name:"not set", ID:null}}
    />
);

const MasterPrice = props => {

    const [refresh, setRefresh] = useState(Date.now())

    const dataType = props.dataType || "all"

    const {selectedClient} = useClientStore((state) => ({selectedClient: state.selectedClient}))
    const {selectedProject} = useProjectStore((state) => ({selectedProject: state.selectedItem}))

    const { data:currency, isPending:currency_isPending, error:currency_error} = useFetch(config.API_URL + 'lists/currency/', refresh)
    // const { data:projectMasterPrice, isPending:projectMasterPrice_isPending, error:projectMasterPrice_error} = useFetch(config.API_URL + 'project/master_price/' + selectedProject.ID, refresh)

    // const filter = null

    const masterPrice = props.values

    var caption = "" // dataType === "client" ? "Client master price" : "Project master price"
    var captionColor = "" // dataType === "client" ? "#FFCCCC" : "#CCFFCC"

    switch (dataType) {
        case "base":
            caption = "base master price"
            captionColor = "#FFCCCC"
            break
        case "client":
            caption = "client master price"
            captionColor = "#CCCCFF"
            break
        case "project":
            caption = "project master price"
            captionColor = "#CCFFCC"
            break
    }

    const saveOrAdd = usePriceStore(state => state.saveOrAddItem)


    // const projectMasterPrice = usePriceStore(state => state.projectMasterPrice)
    // const setData = usePriceStore(state => state.setTms)
    // const loadForClient = usePriceStore(state => state.loadForClient)
    // const loadForProject = usePriceStore(state => state.loadForProject)
    // const loadBase = usePriceStore(state => state.loadBase)



    const {
        cancelEdit,
        onSubmit,
        item,
        cfTypes,
        cfStatuses,
        counterParties,
        // projects,
        currencies,
        units,
        ...other
    } = props;

    // useEffect(() => {
    //     switch (dataType) {
    //         case "client":
    //             loadForClient(selectedClient.ID)
    //             break
    //         case "project":
    //             loadForProject(selectedProject.ID)
    //             break
    //         default:
    //             loadBase()
    //     }
    //
    //     console.log("projectMasterPrice - ", projectMasterPrice)
    //
    // },[selectedClient, selectedProject])


    const handleSubmit = data => {
        // debugger

        // if (newFilter.ProjectID != null && !isNumeric(newFilter.ProjectID)) newFilter.ProjectID = newFilter.ProjectID.ID
        // if (newFilter.ClientID != null && !isNumeric(newFilter.ClientID)) newFilter.ClientID = newFilter.ClientID.ID
        // if (newFilter.Author != null && !isNumeric(newFilter.Author)) newFilter.Author = newFilter.Author.ID
        // if (newFilter.Invoiced != null && !isNumeric(newFilter.Invoiced)) newFilter.Invoiced = newFilter.Invoiced.ID
        if (data.CurrencyInID != null && !isNumeric(data.CurrencyInID)) data.CurrencyInID = data.CurrencyInID.ID
        if (data.CurrencyOutID != null && !isNumeric(data.CurrencyOutID)) data.CurrencyOutID = data.CurrencyOutID.ID
        if (data.UserID != null && data.UserID === 0) data.UserID = null
        if (data.ProjectID != null && data.ProjectID === 0) data.ProjectID = null
        if (data.ClientID != null && data.ClientID === 0) data.ClientID = null

        // setFilter(newFilter)
        console.log("data - ", data)
        saveOrAdd(data)
    }

    console.log("values = ", masterPrice)

    return(
        <>

            <div className="master-price">


                <div className="master-price-section">

                    <div className="master-price-section-caption" style={{backgroundColor: captionColor}}>{caption}</div>


                    {masterPrice && <Form key={props.formKey} initialValues={masterPrice} onSubmit={handleSubmit} render={renderProps =>

                        <>
                            <FormElement horizontal={true}>

                                <FieldWrapper><Field name={"PriceIn"} component={FormNumBoxField} label={"Price in"}/></FieldWrapper>
                                {currency &&
                                    <FieldWrapper><Field name={"CurrencyInID"} label={"Currency In"} textField={"Name"}
                                                         dataItemKey={"id"} component={useCurrency} listData={currency} /></FieldWrapper>}
                                <FieldWrapper><Field name={"PriceOut"} component={FormNumBoxField}
                                                     label={"Price out"}/></FieldWrapper>
                                {currency && <FieldWrapper><Field name={"CurrencyOutID"} label={"Currency Out"}
                                                                  textField={"Name"} dataItemKey={"id"}
                                                                  component={useCurrency} listData={currency} /></FieldWrapper>}

                            </FormElement>

                            <div className="master-price-section-buttons">
                                <Button type={"submit"} themeColor={"primary"} disabled={!renderProps.allowSubmit}
                                        onClick={renderProps.onSubmit} icon="save">
                                    Update
                                </Button>
                            </div>

                        </>

                    } {...other} />}


                </div>

            </div>

        </>
    )
}

export default MasterPrice