import ProjectDetailSelector from "./ProjectDetailSelector";
import ProjectDetail from "./ProjectDetail";
import Price from "../price/Price";
import CashFlowGrid from "../cashflow/CashFlowGrid";
import TmsGrid from "../tms/TmsGrid";
import ExpensesGrid from "../expenses/ExpensesGrid";
import FileInDetail from "../files/FileInDetail";
import TaskGrid from "../tasks/TasksGrid";
import {StackLayout} from "@progress/kendo-react-layout";
import useProjectStore from "../../stores/ProjectStore";
import {useState} from "react";
import Tasks from "../tasks/Tasks";

const ProjectDetailSection = props => {

    const menuSize = props.menuSize
    const refSize = props.size

    const selectedDetail = props.selectedDetail
    const setSelectedDetail = props.setSelectedDetail

    const {selectedProject, setSelectedProject} = useProjectStore((state) => ({selectedProject: state.selectedItem, setSelectedProject: state.selectItem}))
    //const [selectedDetail, setSelectedDetail] = useState()
    // const [refSize, setRefSize] = useState({width:0, height:0});

    const toggleDisplayProjectsGrid = () => {

    }


    return(
        <>

            <StackLayout
                style={{
                    height: "100%",
                }}
                orientation={"vertical"}
                gap={0}
            >
                <div className="list-header">
                    <ProjectDetailSelector  project={selectedProject}
                        // clientName={selectedProjectName}
                                            selectedDetail={selectedDetail}
                                            setSelectedDetail={setSelectedDetail}
                                            toggleDisplayParent={toggleDisplayProjectsGrid}
                    />
                </div>
                <>

                    {selectedDetail===0 &&
                        <ProjectDetail size={refSize} menuSize={menuSize} selectedClient={selectedProject} />}

                    {selectedDetail===1 &&
                        <Price size={refSize} menuSize={menuSize} dataType={"project"} />}

                    {selectedDetail===5 &&
                        <CashFlowGrid size={refSize} menuSize={menuSize} dataType={"project"} />}

                    {selectedDetail===3 &&
                        <TmsGrid size={refSize} menuSize={menuSize} dataType={"project"} />}

                    {selectedDetail===4 &&
                        <ExpensesGrid size={refSize} menuSize={menuSize} dataType={"project"} />}

                    {selectedDetail===12 &&
                        <FileInDetail size={refSize} menuSize={menuSize} dataType={"project"} filesFor={selectedProject} />}

                    {selectedDetail===13 &&
                        <Tasks size={refSize} menuSize={menuSize} dataType={"project"} filesFor={selectedProject} />}

                </>

            </StackLayout>

        </>
    )
}

export default ProjectDetailSection