import {Dialog, DialogActionsBar, Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import {ExternalDropZone, Upload} from "@progress/kendo-react-upload";
import {createRef, useState} from "react";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import "../../App.scss"
import {StackLayout} from "@progress/kendo-react-layout";
import {Button} from "@progress/kendo-react-buttons";
import axios from "axios";


const FileDialog = props => {

    const uploadRef = createRef();
    const [refNum, setRefNum] = useState(0)


    const {data:files, isPending, error} = useFetch(config.API_URL + 'file/' + props.filesFor.UID, refNum)

    const formatter = new Intl.DateTimeFormat('cs-CZ')

    const onBeforeUpload = (event) => {
        event.additionalData.description = "File upload";
        event.additionalData.forUID = props.filesFor.UID;
    };
    const onBeforeRemove = (event) => {
        event.additionalData.description = "File remove";
    };

    const  removeFile  = async file => {
        try {
            const response = await axios.delete(config.API_URL + 'file/' + file.ID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            //set((state) => state.loadCashflowForClient(state.ClientID))
            setRefNum(refNum + 1)
        } catch (error) {

        }
    }

    const onStatusChange = (event) => {
        setRefNum(refNum + 1)
        console.log(event)
    };


    const icons = {
        '.pdf':'k-i-pdf',
        '.doc':'k-i-doc',
        '.docx':'k-i-doc',
        '.xls':'k-i-xls',
        '.xlsx':'k-i-doc',
        '.txt':'k-i-txt',
        '.csv':'k-i-csv',
        '.exe':'k-i-exe',
        '.psd':'k-i-psd',
        '.ppt':'k-i-ppt',
        '.pptx':'k-i-ppt',
        '.avi':'k-i-film',
        '.mpg':'k-i-film',
        '.mp4':'k-i-film',
        '.mpeg':'k-i-film',
        '.jpg':'k-i-image',
        '.jpeg':'k-i-image',
        '.png':'k-i-image',
        '.bmp':'k-i-image',
        '.tiff':'k-i-image',
    }

    return(
        <>

            <Window title="Files"
                    initialHeight={1000}
                    initialWidth={600}
                    modal={true}
                    onClose={props.close}
            >


                <StackLayout
                    style={{
                        height: "100%",
                    }}
                    orientation={"vertical"}
                    gap={0}
                >

                    {<div className="file-upload">


                        <Upload
                            ref={uploadRef}
                            batch={true}
                            multiple={true}
                            // defaultFiles={[]}
                            // files={files}
                            onBeforeUpload={onBeforeUpload}
                            onBeforeRemove={onBeforeRemove}
                            onStatusChange={onStatusChange}
                            withCredentials={false}
                            saveUrl={config.API_URL + "file/"}
                            // removeUrl={"http://localhost:8099/api/file/"}
                            removeMethod={"DELETE"}
                            saveHeaders={{'x-api-key': sessionStorage.getItem("apiKEY")}}
                            removeHeaders={{'x-api-key': sessionStorage.getItem("apiKEY")}}
                            showFileList={false}
                        />

                        <ExternalDropZone uploadRef={uploadRef}/>

                        {/*<div*/}
                        {/*    style={{*/}
                        {/*        height: "50px",*/}
                        {/*    }}*/}
                        {/*/>*/}

                    </div>}


                    <div className="file-list">
                        {files && <>

                            {files.map(f => (
                                <div key={f.ID} className="file-row">
                                    <a className="file-row-a" href={config.FILE_URL + f.RelPath} target="_blank"><span className={"k-icon " + (icons[f.Type.toLowerCase()] || "k-i-file")} style={{fontSize: 50 + 'px'}} />
                                    <span className="file-row-name">{f.Name}</span>
                                    <span className="file-row-info">{new Date(f.CreatedAt).toLocaleString('cz-CZ')}, {f.Size} B</span></a>
                                    <span className="file-row-remove">

                                        <span className="k-icon k-i-x-circle red" style={{fontSize: 25 + 'px'}} onClick={() => removeFile(f)} />

                                    </span>
                                </div>
                            ))}

                        </>}
                    </div>

                </StackLayout>

            </Window>

        </>
    )
}

export default FileDialog