import {DropDownList} from "@progress/kendo-react-dropdowns";
import {filterBy} from "@progress/kendo-data-query";
import {useRef, useState} from "react";
import {Label} from "@progress/kendo-react-labels";
import {FieldWrapper} from "@progress/kendo-react-form";
import {isNumeric} from "@progress/kendo-data-query/dist/npm/utils";

const FormDropDown = props => {

    const {
        listData,
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        wrapperStyle,
        ...others
    } = props;

    const editorRef = useRef(null);
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";

    //console.log("others", others)

    // const listData = props.listData
    // const textField = props.textField || "Name"
    // const filterable = props.filterable || false

    // const { dataItem } = props;
    // const field = props.field || "";
    // const dataValue = dataItem[field] === null ? "" : dataItem[field];
    // const selectedValue=listData.find((c) => c.ID === dataValue)

    //

    var value

    if (isNumeric(props.value)) {
        value = listData.find((c) => c.ID === props.value)
    }
    else {
        value = props.value
    }


    //debugger

    const [data, setData] = useState(listData.slice());
    const filterData = (filter) => {
        const data = listData.slice();
        return filterBy(data, filter);
    };

    const filterChange = (event) => {
        setData(filterData(event.filter));
    };

    //debugger

    return(

        <>

            {/*<FieldWrapper style={wrapperStyle}>*/}
            <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
            >
                {label}
            </Label>

            <div className={"k-form-field-wrap"}>
                <DropDownList
                    ariaLabelledBy={labelId}
                    ariaDescribedBy={`${hintId} ${errorId}`}
                    ref={editorRef}
                    data={data}
                    id={id}
                    onChange={props.onChange}
                    // value={listData.find((c) => c.ID === dataValue)}
                    // data={listData}
                    textField={(props.textField || "Name")}
                    filterable={true}
                    onFilterChange={filterChange}
                    value={value}
                    disabled={props.disabled || false}
                    // style={{width:75 + '%'}}
                    defaultItem={props.defaultItem}
                />
            </div>
            {/*</FieldWrapper>*/}

        </>

    )
}

export default FormDropDown