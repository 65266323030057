import {useEffect, useState} from "react";
import {Button} from "@progress/kendo-react-buttons";
import {Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import * as React from "react";
import {Checkbox} from "@progress/kendo-react-inputs";
import useFetch from "../../../tools/UseFetch";
import config from "../../../config/config.json";
import useUserStore from "../../../stores/UserStore";
import useGroupStore from "../../../stores/GroupStore";


const EditRights = props => {


    const { data:group_rights, group_rightsIsPending, group_rightsError} = useFetch(config.API_URL + 'group/get_rights_for_group/' + props.item.ID, 1)
    const [rights, setRights] = useState(group_rights)
    // const [groupIDs, setGroupIDs] = useState(groups.map(p => ({ id: p.ID, checked: p.IsInGroup })))

    const saveRightsInGroup = useGroupStore(state => state.saveRightsInGroup)

    useEffect(() => {
        setRights(group_rights)
    }, [group_rights]);

    function toggleCheckbox(id) {
        const updatedRights = [...rights];
        const index = updatedRights.findIndex(p => p.ID === id);

        if (index !== -1) {
            updatedRights[index].HaveRight = !updatedRights[index].HaveRight;
            setRights(updatedRights);
        }
    }

    const submit = e => {
        saveRightsInGroup(props.item.ID, rights)
        props.onSubmit()
    }

    return (

        <>

            <Window
                title={"Edit rights for " + props.item.Name}
                onClose={props.onCancel}
                initialHeight={800}
                initialWidth={500}
                modal={true}
                resizable={true}
            >

                {rights && <div className="groups-edit-main">

                    {rights.map(p => (
                            <div className="invoice-dialog-checkbox">
                                <Checkbox
                                    key={p.ID} id={p.ID} label={p.Name}
                                    checked={p.HaveRight}
                                    onChange={() => toggleCheckbox(p.ID)}
                                />
                            </div>
                        )
                    )}

                </div>}

                <WindowActionsBar layout="end">

                    <Button themeColor={"primary"} onClick={props.onCancel} >
                        <span className="k-icon k-i-cancel k-icon-md"></span>&nbsp;
                        Cancel
                    </Button>


                    <Button themeColor={"success"} onClick={submit} >
                        <span className="k-icon k-i-save k-icon-md"></span>&nbsp;
                        Save
                    </Button>

                </WindowActionsBar>

            </Window>

        </>

    );
}

export default EditRights