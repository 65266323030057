import {create} from "zustand";
import axios from "axios";
import config from "../config/config.json";
import clients from "../components/clients/Clients";
import APICall from "../tools/APICall";

const ClientStore = (set, get) => ({

    selectedClient: null,
    clients: [],
    clientsOriginal: [],
    isLoading: false,
    error: null,
    inAddMode: false,

    selectClient: (client) => set((state) => ({
        selectedClient: client
    })),

    setClients: (newClients) => {
        // debugger
        set({clients: newClients})
    },

    loadClients: async () => {
        // debugger
        set({ loading: true, error: null, inAddMode: false });

        try {
            const response = await axios.get(config.API_URL + 'client/', {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            set({ clients: response.data, loading: false });
            set((state) => ({clientsOriginal: state.clients}))
        } catch (error) {
            set({ error: error.message, loading: false });
        }
    },

    addNew: () => {
        // if (!get().inAddMode) {
            const newDataItem = {
                inEdit: true,
            };
            // set((state) => ({clients: [newDataItem, ...state.clients]}))
            // set({inAddMode: true})
            return newDataItem
        // }
    },

    // discardNew: () => {
    //     // debugger
    //     if (get().inAddMode) {
    //
    //         set({inAddMode: false})
    //
    //         // const newData = [...get().clients];
    //         // newData.splice(0, 1);
    //         //
    //         // set({clients: newData})
    //
    //     }
    // },

    saveNew: async (dataItem) => {
        //debugger

        // if (get().inAddMode) {

            try {
                const response = await axios.put(config.API_URL + 'client/', dataItem, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
                set((state) => state.loadClients())
            } catch (error) {
                set({ error: error.message, loading: false });
            }

        // }
    },

    saveItem: async (dataItem) => {

        try {
            const response = await axios.post(config.API_URL + 'client/' + dataItem.ID, dataItem, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            set((state) => state.loadClients())
        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },


    deleteItem: async (dataItem) => {

        try {
            const response = await axios.delete(config.API_URL + 'client/' + dataItem.ID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            //set((state) => state.loadCashflowForClient(state.ClientID))
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },

    refreshData: () => {
        // if (get().ProjectID > 0) set((state) => state.loadCashflowForClient(state.ClientID)) //TODO> udelat pro projekt
        // else if (get().ClientID > 0) set((state) => state.loadCashflowForClient(state.ClientID))
        // else
        set((state) => state.loadClients())
    }

})

const useClientStore = create(ClientStore)

export default useClientStore