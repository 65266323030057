import {Checkbox, TextBox} from "@progress/kendo-react-inputs";
import {useState} from "react";

const InvoiceProjects = props => {

    const projects = props.projects

    const projectIDs = props.projectIDs
    const setProjectIDs = props.setProjectIDs

    //const [checkboxes, setCheckboxes] = useState(projects.map(p => ({ id: p.ID, checked: false })));

    const toggleAll = (e) => {
        console.log(e.target.checked)
        setProjectIDs(projects.map(p => ({ id: p.ID, checked: e.target.checked })));
    }

    const toggleCheckbox = (id) => {
        setProjectIDs(prevCheckboxes =>
            prevCheckboxes.map(checkbox =>
                checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
            )
        );
    };

    return(
        <>

            <div className="invoice-dialog-checkbox-all">
                <Checkbox label={" vybrat všechny"} onClick={toggleAll} />
            </div>



            {projects.map(p => (
                <div className="invoice-dialog-checkbox">
                    <Checkbox
                        key={p.ID} id={p.ID} label={p.Name}
                        checked={projectIDs.find(c => c.id === p.ID).checked}
                        onChange={() => toggleCheckbox(p.ID)}
                    />
                </div>
                )
            )}

        </>
    )
}

export default InvoiceProjects