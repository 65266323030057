import {Button} from "@progress/kendo-react-buttons";
import useTmsStore from "../../stores/TmsStore";
import {useState} from "react";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import EditTms from "../tms/EditTms";
import useOwnRightsStore from "../../stores/OwnRightsStore";

const QuickLinks = props => {

    const [refresh, setRefresh] = useState(Date.now())

    const haveRight = useOwnRightsStore(state => state.haveRight)

    const tms = useTmsStore(state => state.tmsOriginal)
    const addNew = useTmsStore(state => state.addNew)
    const discardNew = useTmsStore(state => state.discardNew)
    const saveNew = useTmsStore(state => state.saveNew)
    const saveItem = useTmsStore(state => state.saveItem)

    const [openForm, setOpenForm] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const { data:actual_user, isPending:actual_user_isPending, error:actual_user_error} = useFetch(config.API_URL + 'user/get_own_user', 1)

    const { data:currency, isPending:currency_isPending, error:currency_error} = useFetch(config.API_URL + 'lists/currency/', refresh)
    const { data:workTypes, isPending:workType_isPending, error:workType_error} = useFetch(config.API_URL + 'lists/worktype/', refresh)
    const { data:authors, isPending:authors_isPending, error:authors_error} = useFetch(config.API_URL + 'user/authors/', refresh)
    const { data:projects, isPending:projects_isPending, error:projects_error} = useFetch(config.API_URL + 'project/for_lists', refresh)





    // ************************************** NEW *************************************

    const editNew = () => {
        const newItem = addNew() // {};

        //debugger

        const now = new Date()
        newItem.Date = new Date(now.getFullYear(), now.getMonth(), now.getDate())

        const newItem2 = {
            ...newItem,
            TimeSpentIn: 0,
            TimeSpentOut: 0,
            AuthorID: actual_user.ID,
            // ImportID: null
        }

        setEditItem(newItem2)
        setOpenForm(true)
    };

    const handleCancelEdit = (dataItem) => {

        // debugger
        // refreshData()
        setOpenForm(false);

    };

    const handleSubmit = (dataItem) => {
        // debugger
        if (dataItem.ID === undefined || dataItem.ID === null) {
            saveNew(dataItem)
        }
        else {
            saveItem(dataItem)
        }
        setOpenForm(false);
    };


    return(

        <>

            <div className="home-quick-links">
                <Button themeColor={"success"} size={"large"} onClick={editNew}>New Record</Button>
                {haveRight("invoice_delete") && <Button themeColor={"error"} size={"large"} onClick={editNew}>Smazat fakturu</Button>}
            </div>

            {openForm && <EditTms
                projects={projects}
                authors={authors}
                workTypes={workTypes}
                currencies={currency}
                cancelEdit={handleCancelEdit}
                onSubmit={handleSubmit}
                item={editItem}
            />}

        </>

    )

}

export default QuickLinks