import {Stepper} from "@progress/kendo-react-layout";
import {useEffect, useState} from "react";
import {Button} from "@progress/kendo-react-buttons";
import InvoiceBasicParams from "./InvoiceBasicParams";
import InvoiceProjects from "./InvoiceProjects";
import InvoiceItems from "./IvoiceItems";
import InvoiceRecap from "./InvoiceRecap";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import useClientStore from "../../stores/ClientStore";
import useProjectStore from "../../stores/ProjectStore";
import {Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import * as React from "react";
import axios from "axios";


async function doInvoice(data) {
    try {
        console.log("before data set")
        const response = await axios.post(config.API_URL + 'invoice/create_invoice', data, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
        const newData = response.data
        const newData2 = newData.map(item => ({...item, Date: new Date(item.Date)}))
        // setData(newData2);
        console.log("data set")
    } catch (error) {
        //set({ error: error.message, isLoading: false });
        console.log("data set error", error)
    }
}



const yesNoValues = [
    {
        text: "Yes",
        id: 1,
    },
    {
        text: "No",
        id: 2,
    },
];


const nonBillableValues = [
    {
        text: "Nezahrnovat",
        id: 1,
    },
    {
        text: "Zahrnout s nulou",
        id: 2,
    },
    {
        text: "Zahrnout",
        id: 3,
    },
];

const reportTypeValues = [
    {
        text: "Zahrnout cenu",
        id: 1,
    },
    {
        text: "Jen součet",
        id: 2,
    },
    {
        text: "Ani součet",
        id: 3,
    },
];

const includeValues = [
    {
        text: "Jen nefakturované",
        id: 1,
    },
    {
        text: "Vše",
        id: 2,
    },
];

const periodItems = [
    {
        text: "Vše",
        id: 1,
    },
    {
        text: "Tento měsíc",
        id: 2,
    },
    {
        text: "Minulý měsíc",
        id: 3,
    },
    {
        text: "Vše do minuleho měsíce",
        id: 4,
    },
    {
        text: "Vlastní ...",
        id: 5,
    },
];


const items = [
    {
        label: "Záklaní parametry",
        icon: "k-i-info",
    },
    {
        label: "Projekty",
        icon: "k-i-folder",
    },
    // {
    //     label: "Položky",
    //     icon: "k-i-grid-layout",
    // },
    {
        label: "Dokončení",
        icon: "k-i-checkbox-checked",
    },

];

const Invoice = props => {

    const selectedClient = useClientStore(state => state.selectedClient)
    const selectedProject = useProjectStore(state => state.selectedItem)

    const [stepperValue, setStepperValue] = useState(0);

    const [tags, setTags] = useState("")
    const [invoiceNum, setInvoiceNum] = useState("")
    const [includeNoNB, setIncludeNoNB] = useState(nonBillableValues[0])
    const [include, setInclude] = useState(includeValues[0])
    const [setAsInvoiced, setSetAsInvoiced] = useState(yesNoValues[0])
    const [createCashFlow, setCreateCashFlow] = useState(yesNoValues[1])
    const [reportType, setReportType] = useState(reportTypeValues[0])
    const [period, setPeriod] = useState(periodItems[3])
    const [selectedAuthor, setSelectedAuthor] = useState(null)

    const [periodFrom, setPeriodFrom] = useState(null)
    const [periodTo, setPeriodTo] = useState(null)

    const [invNumError, setInvNumError] = useState(false)

    console.log("create Cashflow", createCashFlow)

    // const {data:projects, isPending, error} = useFetch(config.API_URL + 'project/forClient/' + selectedClient.ID, 0)

    const projects = useProjectStore(state => state.storeData)
    const loadProjectsForClient = useProjectStore(state => state.loadForClient)

    const [projectIDs, setProjectIDs] = useState()
    const [pdfDownloaded, setPdfDownloaded] = useState(false)
    const [invoiceTMSIds, setInvoiceTMSIds] = useState("")
    const [invoiceExpensesIds, setInvoiceExpensesIds] = useState("")

    const { data:authors, isPending:authors_isPending, error:authors_error} = useFetch(config.API_URL + 'user/authors/', 0)


    useEffect(() => {
        loadProjectsForClient(selectedClient.ID)
    }, [selectedClient]);


    useEffect(() => {
        setProjectIDs(projects.map(p => ({ id: p.ID, checked: false })))
    }, [projects]);

    const handleChange = (e) => {
        console.log("handle stepper change")
        setStepperValue(e.value);
        setPdfDownloaded(false)
    };

    const handleNext = async (e) => {

        console.log("handle stepper next", stepperValue)

        if (stepperValue === 0) {
            let invoiceNumExists = false;
            if (invoiceNum !== "") {
                try {
                    const response = await axios.get(config.API_URL + 'invoice/check_invoice_num/' + invoiceNum);
                    const data = response.data;
                    console.log(data);
                    invoiceNumExists = data.exist
                } catch (error) {
                    console.error('Error fetching data: ', error);
                }
                if (invoiceNumExists) {
                    // alert("Invoice number already exists!");
                    setInvNumError(true)
                    return;
                }
            }
        }

        if (stepperValue <= 3) setStepperValue(stepperValue + 1);
    };


    const handleBack = (e) => {
        console.log("handle stepper back")
        if (stepperValue > 0) setStepperValue(stepperValue - 1)
        setPdfDownloaded(false)
    }

    const handleSubmit = (e) => {
        // alert(invoiceTMSIds)
        // alert(invoiceExpensesIds)
        const data = {
            tags: tags,
            invoiceNum: invoiceNum,
            setAsInvoiced: setAsInvoiced.id,
            createCashFlow: createCashFlow.id,
            tmsItems: invoiceTMSIds,
            expensesItems: invoiceExpensesIds,
        }

        doInvoice(data)
        props.toggleDialog()

    }

    const setInvoiceItemsIDs = (tms, expenses) => {
        setInvoiceTMSIds(tms)
        setInvoiceExpensesIds(expenses)
    }


    return (

        <>

            <Window
                title={"Fakturovat " + selectedClient.Name}
                onClose={props.toggleDialog}
                initialHeight={800}
                initialWidth={1100}
                modal={true}
                resizable={true}
            >

                <div className="invoice-dialog">

                    <div className="invoice-dialog-stepper">
                        <Stepper
                            value={stepperValue}
                            onChange={handleChange}
                            items={items}
                            linear={true}
                            orientation={"vertical"}
                        />
                    </div>

                    <div className="invoice-dialog-main">

                        {stepperValue===0 && authors &&
                            <InvoiceBasicParams
                                tags={tags} setTags={setTags}
                                invoiceNum={invoiceNum} setInvoiceNum={setInvoiceNum}
                                includeNoNB={includeNoNB} setIncludeNoNB={setIncludeNoNB} nonBillableValues={nonBillableValues}
                                include={include} setInclude={setInclude} includeValues={includeValues}
                                setAsInvoiced={setAsInvoiced} setSetAsInvoiced={setSetAsInvoiced} yesNoValues={yesNoValues}
                                createCashFlow={createCashFlow} setCreateCashFlow={setCreateCashFlow}
                                period={period} setPeriod={setPeriod} periodItems={periodItems}
                                reportType={reportType} setReportType={setReportType} reportTypeValues={reportTypeValues}
                                invNumError={invNumError} setInvNumError={setInvNumError}
                                periodFrom={periodFrom} periodTo={periodTo} setPeriodFrom={setPeriodFrom} setPeriodTo={setPeriodTo}
                                authors={authors} selectedAuthor={selectedAuthor} setSelectedAuthor={setSelectedAuthor}
                            />
                        }

                        {stepperValue===1 && projects && <InvoiceProjects projects={projects} projectIDs={projectIDs} setProjectIDs={setProjectIDs} />}
                        {stepperValue===2 && <InvoiceRecap invoiceNum={invoiceNum} includeInvoiced={include}
                                                           projectIDs={projectIDs} includeNoNB={includeNoNB}
                                                           setAsInvoiced={setAsInvoiced}
                                                           createCashFlow={createCashFlow} period={period}
                                                           setPdfDownloaded={setPdfDownloaded}
                                                           setInvoiceIds={setInvoiceItemsIDs}
                                                           reportType={reportType}
                                                           periodFrom={periodFrom} periodTo={periodTo}
                                                           selectedAuthor={selectedAuthor}
                                                           selectedClient={selectedClient}
                        />}
                    </div>

                </div>

                <WindowActionsBar layout="end">
                    <Button themeColor={"primary"} onClick={handleBack} disabled={stepperValue <= 0}>
                        <span className="k-icon k-i-rewind k-icon-md"></span>&nbsp;
                        Back
                    </Button>
                    <Button themeColor={"primary"} onClick={handleNext} disabled={stepperValue >= 2} >
                        Next&nbsp;
                        <span className="k-icon k-i-forward k-icon-md"></span>
                    </Button>

                    <Button themeColor={"success"} onClick={handleSubmit} disabled={(stepperValue !== 2) || !pdfDownloaded || invoiceNum === ""} >
                        <span className="k-icon k-i-forward k-icon-md"></span>&nbsp;
                        Finish
                    </Button>

                </WindowActionsBar>

            </Window>

        </>

    );
}

export default Invoice