import ListValue from "../displayComponents/ListValue";

const ProjectDetailInfo = props => {

    debugger

    const project = props.project

    const lastActivity = (project.LastActivity ? project.LastActivity.toLocaleDateString('cs-CZ') : "never")

    const minutes = (project.TotalActivity % 60).toString().padStart(2, '0')
    const hours = Math.floor(project.TotalActivity / 60)

    const TimeStr = (minutes === 0) ? hours + " h" : hours + ":" + minutes


    const getTimeString = time => {
        return (time ? Math.floor(time / 60).toString().padStart(2, '0') + ':' + (time % 60).toString().padStart(2, '0') : 0)
    }

    const getPriceString = price => {
        const formatter = new Intl.NumberFormat('cs-CZ', { useGrouping: true });
        return formatter.format(Math.floor(price))
    }

    return (
        <>

            <div className="client-detail-outter">
                <div className="client-detail">
                    <div className="client-name">
                        <h2>{project.ClientProjectName}</h2>
                    </div>

                    <div className="client-info">Název: {project.Name}</div>
                    <div className="client-info">Klient: {project.ClientName}</div>
                    <div className="client-info">Limit: {getPriceString(project.ProjectLimit)}</div>
                    <div className="client-info">Bilance: {getPriceString(project.CashFlow)}</div>
                    <div className="client-info">Bilance In: {getPriceString(project.CashFlowWithTMSIn)}</div>
                    <div className="client-info">Bilance out: {getPriceString(project.CashFlowWithTMSOut)}</div><br />
                    <div className="client-info">Last Activity: {lastActivity}</div>
                    <div className="client-info">Total Activity: {TimeStr}</div>
                    {/*<div className="client-info">Klient: <ListValue listData={props.statuses} dataID={project.StatusID} /></div>*/}


                </div>
            </div>

        </>
    )
}

export default ProjectDetailInfo