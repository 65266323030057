const ProjectSums = (props) => {

    const invoices = props.invoices
    const reportType = props.reportType
    const showPrice = props.showPrice;
    const showHours = props.showHours;

    console.log("invoice sums - ", invoices)

    let sums = {};
    invoices.forEach(item => {
        if (!sums[item.Currency]) {
            sums[item.Currency] = 0;
        }
        sums[item.Currency] += item.Price;
    });

    let totalMinutes = 0;
    invoices.forEach(item => {
        totalMinutes += item.TimeSpent;
    });

    let hours = Math.floor(totalMinutes / 60);
    let minutes = (totalMinutes % 60).toString().padStart(2, '0');


    return (
        <>
            <div className="project-sum">
                <span className="project-sum-title">Project total</span>
                <span className="project-sum-values">
                    {showHours !== "no" && <div>Hours: {hours + ':' + minutes}</div>}
                    {(reportType.id !== 3 || showPrice === "yes") && <>
                        {Object.entries(sums).map(([currency, sum]) => (
                            <div key={currency}>{`${currency}: ${sum.toLocaleString()}`}</div>
                        ))}
                    </>}
                </span>
            </div>
        </>
    );
}

export default ProjectSums;