import * as React from "react";
import {Dialog, DialogActionsBar, Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import {TextBox, NumericTextBox, TextArea} from "@progress/kendo-react-inputs";
import {Error, Hint, Label} from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import FormDropDown from "../../formEditComponents/FormDropDown";
import {isNumeric} from "@progress/kendo-data-query/dist/npm/utils";
import {DateInput, DatePicker} from "@progress/kendo-react-dateinputs";
import FormColorField from "../../formEditComponents/FormColorField";
import TextBoxField from "../../formEditComponents/FormTextBoxField";
import NumBoxField from "../../formEditComponents/FormNumBoxField";


const minValueValidator = value => value >= 0 ? "" : "The value must be 0 or higher";
const NonNegativeNumericInput = fieldRenderProps => {
    const {
        validationMessage,
        visited,
        id,
        valid,
        label,
        ...others
    } = fieldRenderProps;
    return <>
        <Label editorId={id} editorValid={valid} className={"k-form-label"}>
            {label}
        </Label>
        <div className={"k-form-field-wrap"}>
            <NumericTextBox {...others} />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    </>;
};
// const TextBoxField = fieldRenderProps => {
//     const {
//         validationMessage,
//         visited,
//         label,
//         id,
//         valid,
//         ...others
//     } = fieldRenderProps;
//     return <>
//         <Label editorId={id} className={"k-form-label"}>
//             {label}
//         </Label>
//         <div className={"k-form-field-wrap"}>
//             <TextBox {...others} />
//         </div>
//     </>;
// };

// const NumBoxField = fieldRenderProps => {
//     const {
//         validationMessage,
//         visited,
//         label,
//         id,
//         valid,
//         ...others
//     } = fieldRenderProps;
//     return <>
//         <Label editorId={id} className={"k-form-label"}>
//             {label}
//         </Label>
//         <div className={"k-form-field-wrap"}>
//             <NumericTextBox {...others} />
//         </div>
//     </>;
// };

const DateField = fieldRenderProps => {
    const {
        validationMessage,
        visited,
        label,
        id,
        valid,
        ...others
    } = fieldRenderProps;
    return <>
        <Label editorId={id} className={"k-form-label"}>
            {label}
        </Label>
        <div className={"k-form-field-wrap"}>
            <DatePicker {...others} />
        </div>
    </>;
};


const TextAreaField = fieldRenderProps => {
    const {
        validationMessage,
        visited,
        label,
        id,
        valid,
        ...others
    } = fieldRenderProps;
    return <>
        <Label editorId={id} className={"k-form-label"}>
            {label}
        </Label>
        <div className={"k-form-field-wrap"}>
            <TextArea {...others} />
        </div>
    </>;
};


const useProjectEditor = props => (
    <FormDropDown
        {...props}
        textField={"ClientProjectName"}
    />
);


const EditTaskPriority = props => {

    const {
        cancelEdit,
        onSubmit,
        item,
        ...other
    } = props;

    // item.UnitID = units.find((c) => c.ID === item.UnitID)
    // item.PartnerID = partners.find((c) => c.ID === item.PartnerID)


    const handleSubmit = dataItem => {
        // debugger
        onSubmit(dataItem)
    }

    var title = `Edit cashflow type: ${item.Name}`
    if (item.ID === null) {
        title = "Copy cashflow type"
    }

    if (item.ID === undefined) {
        title = "Create new cashflow type"
    }


    // const { data:partners, isPending, error} = useFetch(config.API_URL + 'partner/', 1)

    return <Form initialValues={item} onSubmit={handleSubmit} render={renderProps =>

        <Window title={title} onClose={cancelEdit}
                initialHeight={500}
                initialWidth={600}
                modal={true}
        >
            <div className="editForm">
                <FormElement horizontal={true}>

                    <FieldWrapper><Field name={"Name"} component={TextBoxField} label={"Name"} /></FieldWrapper>
                    <FieldWrapper><Field name={"Color"} component={FormColorField} label={"Color"} /></FieldWrapper>

                </FormElement>
            </div>

            <WindowActionsBar layout="start">
                <Button type={"submit"} themeColor={"primary"} disabled={!renderProps.allowSubmit} onClick={renderProps.onSubmit} icon="save" >
                    Update
                </Button>
                <Button onClick={cancelEdit} icon="cancel" >
                    Cancel
                </Button>
            </WindowActionsBar>

        </Window>} {...other} />;
};
export default EditTaskPriority;