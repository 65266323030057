import * as React from "react";
import {Dialog, DialogActionsBar, Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import {TextBox, NumericTextBox, TextArea} from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {Error, Hint, Label} from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import FormDropDown from "../formEditComponents/FormDropDown";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import {isNumeric} from "@progress/kendo-data-query/dist/npm/utils";
import clients from "../clients/Clients";
import FormTextBoxField from "../formEditComponents/FormTextBoxField";
import FormTextAreaField from "../formEditComponents/FormTextAreaField";
import FormNonNegativeNumericInput from "../formEditComponents/FormNonNegativeNumericInput";
import {useState} from "react";

const minValueValidator = value => value >= 0 ? "" : "The value must be 0 or higher";

const mandatoryFieldValidator = value => value != null ? "" : "mandatory field"

const icons = {
    '.pdf':'k-i-pdf',
    '.doc':'k-i-doc',
    '.docx':'k-i-doc',
    '.xls':'k-i-xls',
    '.xlsx':'k-i-doc',
    '.txt':'k-i-txt',
    '.csv':'k-i-csv',
    '.exe':'k-i-exe',
    '.psd':'k-i-psd',
    '.ppt':'k-i-ppt',
    '.pptx':'k-i-ppt',
    '.avi':'k-i-film',
    '.mpg':'k-i-film',
    '.mp4':'k-i-film',
    '.mpeg':'k-i-film',
    '.jpg':'k-i-image',
    '.jpeg':'k-i-image',
    '.png':'k-i-image',
    '.bmp':'k-i-image',
    '.tiff':'k-i-image',
}

const EditProject = props => {

    const {
        cancelEdit,
        onSubmit,
        item,
        productClientStatuses,
        ...other
    } = props;

    // item.UnitID = units.find((c) => c.ID === item.UnitID)
    // item.PartnerID = partners.find((c) => c.ID === item.PartnerID)


    const [refNum, setRefNum] = useState(0)
    const {data:files, isPending, error} = useFetch(config.API_URL + 'file/' + item.UID, refNum)

    const handleSubmit = dataItem => {
        // debugger
        if (!isNumeric(dataItem.ProductClientStatusID)) dataItem.ProductClientStatusID = dataItem.ProductClientStatusID.ID
        onSubmit(dataItem)
    }

    var title = `Edit Product: ${item.Name}`
    if (item.ID === null) {
        title = "Copy project"
    }

    if (item.ID === undefined) {
        title = "Create new project"
    }

    // const { data:partners, isPending, error} = useFetch(config.API_URL + 'partner/', 1)

    return <Form initialValues={item} onSubmit={handleSubmit} render={renderProps =>

        <Window title={title} onClose={cancelEdit}
                initialHeight={800}
                initialWidth={600}
                modal={true}
        >
            <div className="editForm">
                <FormElement horizontal={true}>

                    {productClientStatuses &&
                        <FieldWrapper><Field name={"ProductClientStatusID"} label={"Status"} textField={"Name"}
                                             dataItemKey={"id"} component={FormDropDown}
                                             listData={productClientStatuses}
                                             validator={mandatoryFieldValidator}/></FieldWrapper>}
                    <FieldWrapper><Field name={"Note"} component={FormTextAreaField} label={"Note"}/></FieldWrapper>
                    <FieldWrapper><Field name={"Pricing"} component={FormTextAreaField}
                                         label={"Princing"}/></FieldWrapper>


                </FormElement>
            </div>

            <div className="file-list" style={{height: 'calc(100% - 750px)'}}>
                {files && <>

                    {files.map(f => (
                        <div key={f.ID} className="file-row">
                            <a className="file-row-a" href={config.FILE_URL + f.RelPath} target="_blank"><span
                                className={"k-icon " + (icons[f.Type.toLowerCase()] || "k-i-file")}
                                style={{fontSize: 50 + 'px'}}/>
                                <span className="file-row-name">{f.Name}</span>
                                <span
                                    className="file-row-info">{new Date(f.CreatedAt).toLocaleString('cz-CZ')}, {f.Size} B</span></a>
                            {/*<span className="file-row-remove">*/}

                            {/*    <span className="k-icon k-i-x-circle red" style={{fontSize: 25 + 'px'}}*/}
                            {/*          onClick={() => removeFile(f)} />*/}

                            {/*</span>*/}

                        </div>
                    ))}

                </>}
            </div>

            <WindowActionsBar layout="start">
                <Button type={"submit"} themeColor={"primary"} disabled={!renderProps.allowSubmit}
                        onClick={renderProps.onSubmit} icon="save">
                    Update
                </Button>
                <Button onClick={cancelEdit} icon="cancel">
                    Cancel
                </Button>
            </WindowActionsBar>

        </Window>} {...other} />;
};
export default EditProject;