import {create} from "zustand";
import axios from "axios";
import config from "../config/config.json";

const ExpenseStore = (set, get) => ({

    expense: [],
    expenseOriginal: [],
    isLoading: false,
    error: null,
    inAddMode: false,
    ClientID: 0,
    ProjectID: 0,

    filter: {
        Author: null,
        DateRange: null,
        DateFrom: null,
        DateTo: null,
        ClientID: 5,
        ProjectID: null,
        Invoiced: null,
        InvoiceNum: null,
    },

    setExpense: (newExpense) => {
        // debugger
        set({expense: newExpense})
        console.log(newExpense)
    },

    setFilter: (newFilter) => {
        set({filter: newFilter})
    },

    loadExpenseAll: async () => {
        // debugger

        if (get().ClientID != null || get().ProjectID != null) set({expense:[]})
        set({ isLoading: true, error: null, ClientID: null, ProjectID: null, inAddMode: false });

        try {
            const response = await axios.get(config.API_URL + 'expense/', {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            const newData = data.map(item => ({...item, Date: new Date(item.Date)}))
            set({ expense: newData, isLoading: false });
            set((state) => ({expenseOriginal: state.expense}))

        } catch (error) {
            set({ error: error.message, isLoading: false });
        }
    },


    loadExpenseForClient: async (clientID) => {
        // debugger

        if (get().ClientID !== clientID) set({expense:[]})
        set({ isLoading: true, error: null, ClientID: clientID, ProjectID: null, inAddMode: false });

        try {
            const response = await axios.get(config.API_URL + 'expense/forClient/' + clientID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            const newData = data.map(item => ({...item, Date: new Date(item.Date)}))
            set({ expense: newData, isLoading: false });
            set((state) => ({expenseOriginal: state.expense}))

        } catch (error) {
            set({ error: error.message, isLoading: false });
        }
    },


    loadExpenseForProject: async (projectID) => {
        // debugger

        if (get().ProjectID !== projectID) set({expense:[]})
        set({ isLoading: true, error: null, ClientID: null, ProjectID: projectID , inAddMode: false });

        try {
            const response = await axios.get(config.API_URL + 'expense/forProject/' + projectID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            const newData = data.map(item => ({...item, Date: new Date(item.Date)}))
            set({ expense: newData, isLoading: false });
            set((state) => ({expenseOriginal: state.expense}))

        } catch (error) {
            set({ error: error.message, isLoading: false });
        }
    },


    addNew: () => {
        var newDataItem = {};
        if (get().ProjectID !== 0) {
            newDataItem = {...newDataItem, ProjectID: get().ProjectID}
        }
        return newDataItem
    },

    discardNew: () => {
        // debugger
        if (get().inAddMode) {

            set({inAddMode: false})

            const newData = [...get().expense];
            newData.splice(0, 1);

            set({tms: newData})

        }
    },

    saveNew: async (dataItem) => {
        // debugger

            try {
                const response = await axios.put(config.API_URL + 'expense/', dataItem, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
                get().refreshData()
            } catch (error) {
                set({ error: error.message, loading: false });
            }

    },

    saveItem: async (dataItem) => {

        try {
            const response = await axios.post(config.API_URL + 'expense/' + dataItem.ID, dataItem, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },

    deleteItem: async (dataItem) => {

        try {
            const response = await axios.delete(config.API_URL + 'expense/' + dataItem.ID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            //set((state) => state.loadCashflowForClient(state.ClientID))
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },


    refreshData: () => {
        if (get().ProjectID > 0) set((state) => state.loadExpenseForProject(state.ProjectID))
        else if (get().ClientID > 0) set((state) => state.loadExpenseForClient(state.ClientID))
        else set((state) => state.loadExpenseAll())
    }

})

const useExpenseStore = create(ExpenseStore)

export default useExpenseStore