const TaskDetailInfo = props => {

    const task = props.task

    return (
        <>

            <div className="task-detail-outter">
                <div className="task-detail">
                    <div className="client-name">
                        <h2>{task.Subject}</h2>
                    </div>
                    <div className="client-info">Subject: {task.Subject}</div>
                    <div className="client-info">Status: {task.TaskStatusID}</div>
                    <div className="client-info">Popis: {task.Text}</div>

                </div>
            </div>

        </>
    )
}

export default TaskDetailInfo