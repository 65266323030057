import {process} from "@progress/kendo-data-query";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {Grid, GridToolbar} from "@progress/kendo-react-grid";
import config from "../../config/config.json";
import {cloneElement, useEffect, useState} from "react";
import useFetch from "../../tools/UseFetch";
import DropDownFilter from "../filterComponents/DropDownFilter";
import ClientEditor from "../editComponents/ClientEditor";
import useClientStore from "../../stores/ClientStore";
import useProjectStore from "../../stores/ProjectStore";
import DropDownEditor from "../editComponents/DropDownEditor";
import CustomTextCell from "../editComponents/CustomTextCell";
import PriceEditor from "../editComponents/PriceEditor";
import useOwnRightsStore from "../../stores/OwnRightsStore";
import {ToolbarItem, ToolbarSeparator} from "@progress/kendo-react-buttons";
import FileDialog from "../files/FileDialog";
import NotesInDialog from "../notes/NotesInDialog";
import DetailsCell from "../tableComponents/DetailsCell";


const ActionCell = (props) => {
    return(
        <>
            {/*<Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"*/}
            {/*        size="small"*/}
            {/*        rounded="small"*/}
            {/*        onClick={() => props.enterEdit(props.dataItem)}*/}
            {/*>Novy klient</Button>*/}
            {/*<span className={"table-header-action"}>Nový klient</span>*/}
            {/*<span className={"table-header-action"}>aaa</span>*/}
        </>
    )
}

const initialDataState = {
    take: 100,
    skip: 0,
    // pageSize:50,
};

const ProjectsGrid = (props) => {

    const {selectedClient} = useClientStore((state) => ({selectedClient: state.selectedClient}))

    const {selectedProject, setSelectedProject} = useProjectStore((state) => ({selectedProject: state.selectedItem, setSelectedProject: state.selectItem}))

    const refSize = props.size  || {"width" : window.innerWidth - 10, "height" : window.innerHeight}
    const menuSize = props.menuSize
    const dataType = props.dataType || "all"

    const haveRight = useOwnRightsStore(state => state.haveRight)

    const clientID = props.clientID || 1

    const setSelectedClient = useClientStore(state => state.selectClient)


    const Data = useProjectStore(state => state.storeData)
    const projectData = Data.filter(project => project.ClientID === clientID)

    console.log("TableProjectDetail", clientID, Data, projectData)

    // const addNew = useProjectStore(state => state.addNew)
    // // const setData = useProjectStore(state => state.setStoreData)
    // // const projects = useProjectStore(state => state.clientsOriginal)
    // // const discardNew = useProjectStore(state => state.discardNew)
    // const saveNew = useProjectStore(state => state.saveNew)
    // const saveItem = useProjectStore(state => state.saveItem)
    // // const selectedProject = useProjectStore(state => state.selectedItem)
    const setSelected = useProjectStore(state => state.selectItem)
    // // const addNewClient = useProjectStore(state => state.addNew)
    // const refreshData = useProjectStore(state => state.refreshData)
    const refreshData = useProjectStore(state => state.refreshData)
    // const deleteItem = useProjectStore(state => state.deleteItem)
    //
    const loadForClient = useProjectStore(state => state.loadForClient)
    const loadAll = useProjectStore(state => state.loadAll)



    const { data:partners, isPending, error} = useFetch(config.API_URL + 'partner/', 1)
    const { data:units, isPending:units_isPending, error:units_error} = useFetch(config.API_URL + 'lists/unit/', 1)
    const { data:projectStatus, isPending:projectStatus_isPending, error:cprojectStatus_error} = useFetch(config.API_URL + 'lists/project_status/', 1)

    // const { data:projects, projects_isPending, projects_error} = useFetch(config.API_URL + 'project/client/' + selectedClient.ID, 1)

    const clients = useClientStore(state => state.clients)


    const isSmallHeight = window.innerHeight < 600

    // const projects = props.projects

    // const selected = props.selected
    // const setSelected = props.setSelected
    // const setSelectedProjectName = props.setSelectedProjectName
    const toggleDisplayParent = props.toggleDisplayParent || null

    const [dataState, setDataState] = useState(initialDataState);

    let columnDefWidth = refSize.width * 0.1
    if (columnDefWidth < 120) columnDefWidth = 120


    useEffect(() => {
        switch (dataType) {
            case "client":
                loadForClient(selectedClient.ID)
                break
            default:
                loadAll()
        }
    },[])


    const useCustomCell = props => (
        <CustomTextCell
            {...props}
            selected={(dataType == "all") ? selectedProject : null}
        />
    );


    const useClientEditor = props => (
        <ClientEditor
            {...props}
            clients={clients}
        />
    );

    const useLimitEditor = props => (
        <PriceEditor
            {...props}
            neutralStyle={""}
            redStyle={" "}
            greenStyle={" "}
        />
    );


    const usePartnerEditor = props => (
        <DropDownEditor
            {...props}
            listData={partners}
        />
    );

    const PartnerFilter = (props) => (
        <DropDownFilter
            {...props}
            data={partners}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );

    const useUnitEditor = props => (
        <DropDownEditor
            {...props}
            listData={units}
            editable={true}
        />
    );

    const UnitFilter = (props) => (
        <DropDownFilter
            {...props}
            data={units}
            filterable={true}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );

    const ClientFilter = (props) => (
        <DropDownFilter
            {...props}
            data={clients}
            filterable={true}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );


    const useProjectStatusEditor = props => (
        <DropDownEditor
            {...props}
            listData={projectStatus}
            editable={true}
        />
    );

    const StatusFilter = (props) => {
        console.log("datastate ... ", dataState)
        return(
            <DropDownFilter
                {...props}
                data={projectStatus}
                filterable={false}
                defaultItem={{ID: 0, Name: "All"}}
            />
        )};

    const rowClick = (props) => {
        console.log("project row click - " + props.dataItem.ID)
        console.log(props.dataItem)
        // debugger
        setSelected(props.dataItem)
        setSelectedClient(null)
        // setSelectedProjectName(props.dataItem.Name)
        if (isSmallHeight) toggleDisplayParent()
        console.log(selectedProject)

        // this.setState({
        //     selectedRowId: props.dataItem.id
        // });

    }

    // useEffect(() => {
    //     setSelectedCount(process(projectData, dataState).projectData.length)
    // },[dataState])

    // const MyEditCommandCell = (props) => (
    //     <CommandCell
    //         {...props}
    //         edit={enterEdit}
    //         copy={enterCopy}
    //         delete={deleteRow}
    //         showFiles={showFiles}
    //         showDelete={haveRight("Projects_Del")}
    //         showCopy={haveRight("Projects_Edit")}
    //         filesCount={props.dataItem.FilesCount}
    //     />
    // );


    const MyDetailsCell = (props) => (
        <DetailsCell
            {...props}
            showFiles={showFiles}
            filesCount={props.dataItem.FilesCount}
            showNotes={showNotes}
            notesCount={props.dataItem.NotesCount}
        />
    );

    // ********************************* helper projectData  *********************************

    const [openForm, setOpenForm] = useState(false);
    const [editItem, setEditItem] = useState(null);


    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [deletedItem, setDeletedItem] = useState(null);

    const [showFilesDialog, setShowFilesDialog] = useState(false)
    const [showFilesFor, setShowFilesFor] = useState(null);



    // ************************************** Files *************************************

    const showFiles = (dataItem) => {
        // debugger
        setShowFilesFor(dataItem)
        setShowFilesDialog(true)
    };

    const closeFiles = () => {
        refreshData()
        setShowFilesDialog(false)
    };

    // ************************************** Notes *************************************

    const [showNotesFor, setShowNotesFor] = useState(null);
    const showNotes = (dataItem) => {
        // debugger
        setShowNotesFor(dataItem)
    };

    const closeNotes = () => {
        refreshData()
        setShowNotesFor(null)
    };


    // ************************************** GRID *************************************



    // ************************************** GRID *************************************

    // const [page, setPage] = useState(initialDataState);
    const [pageSizeValue, setPageSizeValue] = useState(10);

    return(
        <>
            {

                console.log(menuSize)

            }
            {partners && clients && units && projectStatus && <Grid
                // pageable={true}
                sortable={true}
                filterable={false}
                //scrollable={"virtual"}
                resizable={true}
                reorderable={true}
                //rowHeight={50}
                // onItemChange={itemChange}
                // rowRender={rowRender}

                editField="inEdit"
                size={"small"}

                // style={{
                //     height: "100%", // (refSize.height - 150) / 2,//"500px",
                //     minHeight: 20,
                //     maxHeight: refSize.height - 70,
                //     // width: refSize.width - menuSize,
                // }}
                data={process(projectData, dataState)}{...dataState}

                total={process(projectData, dataState).length}
                // pageable={{
                //     buttonCount: 4,
                //     pageSizes: [10, 50, 100, 500],
                //     pageSizeValue: pageSizeValue,
                // }}
                // onPageChange={pageChange}

                onDataStateChange={(e) => setDataState(e.dataState)}
                onRowClick={rowClick}
                // onRowDoubleClick={rowDblClick}
            >

                {/*<GridToolbar>*/}

                {/*    {haveRight("Projects_Edit") && <button*/}
                {/*        title="Export to Excel"*/}
                {/*        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"*/}
                {/*        onClick={editNew}*/}
                {/*    >*/}
                {/*        Novy projekt*/}
                {/*    </button>}*/}

                {/*    <ToolbarSeparator />*/}
                {/*    <ToolbarItem>zobrazeno: {selectedCount} z celkem {projectData.length}</ToolbarItem>*/}

                {/*</GridToolbar>*/}

                <Column cell={useCustomCell} field="ID" title="ID" width="60px" filterable={false} editable={false} />


                {/*<Column cell={myID} field="ID" title="ID" width="80px" footerCell={myFooterCell} filterable={true}/>*/}
                {/*<Column cell={myID} field="ID" title="ID" width="80px" filterable={true} locked/>*/}

                {/*<Column field="ClientID" minResizableWidth={120} title="Klient" cell={useClientEditor}*/}
                {/*        width={columnDefWidth} filterCell={ClientFilter} locked />*/}

                <Column field="Name" minResizableWidth={200} title="Název" width={columnDefWidth} />
                <Column field="OfferNum" minResizableWidth={130} title="Nabidka" width={130} />
                <Column field="ProjectStatusID" minResizableWidth={80} title="Status" cell={useProjectStatusEditor} width={150} filterCell={StatusFilter} />


                <Column field="FileNum" minResizableWidth={200} title="Spis" width={120}/>


                <Column field="PartnerID" minResizableWidth={100} title="Partner" cell={usePartnerEditor}
                        width={200} filterCell={PartnerFilter} />
                <Column field="UnitID" minResizableWidth={100} title="Středisko" cell={useUnitEditor}
                        width={200} filterCell={UnitFilter} />


                {/*<Column field="PartnerID" minResizableWidth={120} title="Partner" cell={usePartnerEditor}*/}
                {/*        width={columnDefWidth} filterCell={PartnerFilter} />*/}

                <Column field="LastMonth" minResizableWidth={40} title="Minulý měsíc" cell={PriceEditor} width={100} editable={false} />
                <Column field="ToInvoice" minResizableWidth={40} title="k fakturaci" cell={PriceEditor} width={100} editable={false} filter={"numeric"} />


                <Column field="CashFlow" minResizableWidth={40} cell={PriceEditor} title="bilance CZK" width={120} editable={false} filter={"numeric"} />
                {haveRight("View_In_Values") && <Column field="CashFlowWithTMSIn" minResizableWidth={40} cell={PriceEditor} title="bilance CZK In"
                         width={120} editable={false} filter={"numeric"}/>}
                {haveRight("View_Out_Values") && <Column field="CashFlowWithTMSOut" minResizableWidth={40} cell={PriceEditor} title="bilance CZK Out"
                         width={120} editable={false} filter={"numeric"}/>}

                <Column field="ProjectLimit" minResizableWidth={40} cell={useLimitEditor} title="Limit" width={120} editable={false} filter={"numeric"} />

                <Column field="Code" minResizableWidth={120} title="Kód" width={120}/>
                <Column field="Category" minResizableWidth={120} title="Kategorie" width={120}/>
                <Column field="Note" minResizableWidth={100} title="Poznámka" width={300}/>
                {/*<Column field="Tags" minResizableWidth={120} title="Tagy" width={columnDefWidth}/>*/}

                <Column field="details" minResizableWidth={120}
                        cell={MyDetailsCell}
                        filterable={false}
                        headerCell={ActionCell}
                        width="120"
                        resizable={true}
                        locked
                />

                {/*<Column field="action" minResizableWidth={180}*/}
                {/*        cell={MyEditCommandCell}*/}
                {/*    // columnMenu={ActionCell}*/}
                {/*        filterable={false}*/}
                {/*        headerCell={ActionCell}*/}
                {/*        width="180"*/}
                {/*        resizable={true}*/}
                {/*        locked*/}
                {/*    // filterCell={ActionCell}*/}
                {/*/>*/}

            </Grid>}

            {showFilesDialog && <FileDialog
                close={closeFiles}
                text={"delete item?"}
                filesFor={showFilesFor}
            />}

            {showNotesFor && <NotesInDialog
                close={closeNotes}
                notesFor={showNotesFor}
            />}

            {/*{openForm && haveRight("Projects_Edit") && <EditProject*/}
            {/*    partners={partners}*/}
            {/*    units={units}*/}
            {/*    clients={clients}*/}
            {/*    projectStatuses={projectStatus}*/}
            {/*    cancelEdit={handleCancelEdit}*/}
            {/*    onSubmit={handleSubmit}*/}
            {/*    item={editItem}*/}
            {/*/>}*/}

            {/*{showConfirmDelete && <Confirm*/}
            {/*    action={deleteRowAction}*/}
            {/*    cancel={cancelDelete}*/}
            {/*    text={"delete item?"}*/}
            {/*/>}*/}


            {/*{showFilesDialog && <FileDialog*/}
            {/*    close={closeFiles}*/}
            {/*    text={"delete item?"}*/}
            {/*    filesFor={showFilesFor}*/}
            {/*/>}*/}

        </>
    )
}

export default ProjectsGrid