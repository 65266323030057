import {useTableKeyboardNavigation} from "@progress/kendo-react-data-tools";
import {GRID_COL_INDEX_ATTRIBUTE} from "@progress/kendo-react-grid";
import {Button} from "@progress/kendo-react-buttons";

const UserCommandCell = (props) => {
    debugger
    const { dataItem } = props;
    const navigationAttributes = useTableKeyboardNavigation(props.id);

    let styles = {...props.style}
    // if (props.selected && props.dataItem.ID === props.selected.ID) {styles = {...props.style, border: "1px solid red", backgroundColor: "rgb(243,221,239)"}}

    const groupsCount = (props.groupsCount > 0) ? props.groupsCount : ""
    const showGroups = props.showFiles !== true
    const showDelete = props.showDelete || false
    const showCopy = props.showCopy !== false


    return (
        <td
            style={styles} // this applies styles that lock the column at a specific position
            className={props.className + " right"} // this adds classes needed for locked columns
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            {...{
                [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
            }}
            {...navigationAttributes}
            align={"right"}
        >

            {/*<Button themeColor={"base"} className1="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command" onClick={() => props.edit(dataItem)}>*/}
            {/*    <span className="k-icon k-i-edit" />*/}
            {/*</Button>*/}
            {/*&nbsp;*/}
            {showGroups && <Button themeColor={"base"}
                     className1="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
                     style={{width: 60 + 'px'}} onClick={() => props.groups(dataItem)}>
                <span className="k-icon k-i-myspace"/> <span className={"red"}
                                                          style={{fontWeight: "bold"}}>{groupsCount}</span>
            </Button>}
            &nbsp;
            {showCopy && <Button themeColor={"base"}
                     className1="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
                     onClick={() => props.copy(dataItem)}>
                <span className="k-icon k-i-plus-circle"/>
            </Button>}
            &nbsp;
            {showDelete && <Button themeColor={"error"}
                     className1="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-grid-edit-command"
                     onClick={() => props.delete(dataItem)}>
                <span className="k-icon k-i-delete"/>
            </Button>}

        </td>
    );
};

export default UserCommandCell