import {DropDownList} from "@progress/kendo-react-dropdowns";
import {filterBy} from "@progress/kendo-data-query";
import {useRef, useState} from "react";
import {Label} from "@progress/kendo-react-labels";
import {FieldWrapper} from "@progress/kendo-react-form";
import {isNumeric} from "@progress/kendo-data-query/dist/npm/utils";
import {ColorPicker} from "@progress/kendo-react-inputs";

const FormColor= props => {

    const {
        listData,
        validationMessage,
        touched,
        label,
        id,
        valid,
        disabled,
        hint,
        wrapperStyle,
        ...others
    } = props;

    const editorRef = useRef(null);
    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hintId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";
    const labelId = label ? `${id}_label` : "";

    //console.log("others", others)

    // const listData = props.listData
    // const textField = props.textField || "Name"
    // const filterable = props.filterable || false

    // const { dataItem } = props;
    // const field = props.field || "";
    // const dataValue = dataItem[field] === null ? "" : dataItem[field];
    // const selectedValue=listData.find((c) => c.ID === dataValue)

    //


    //debugger


    //debugger

    return(

        <>

            {/*<FieldWrapper style={wrapperStyle}>*/}
            <Label
                id={labelId}
                editorRef={editorRef}
                editorId={id}
                editorValid={valid}
                editorDisabled={disabled}
            >
                {label}
            </Label>

            <div className={"k-form-field-wrap"}>
                <ColorPicker
                    {...others}
                    view="gradient"
                />
            </div>
            {/*</FieldWrapper>*/}

        </>

    )
}

export default FormColor