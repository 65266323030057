import {TileLayout} from "@progress/kendo-react-layout";
import {useState} from "react";
import ClientDetailInfo from "./ClientDetailInfo";
import ClientDetailGraph from "./ClientDetailGraph";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import ClientDetailSummary from "./ClientDetailSummary";
import useClientStore from "../../stores/ClientStore";

const styles = {
    fontSize: 24,
    textAlign: "center",
    margin: "auto",
    userSelect: "none",
};



const ClientDetail = props => {

    const {selectedClient} = useClientStore((state) => ({selectedClient: state.selectedClient}))

    const { data:summaryData, isPending:summaryData_IsPending, error:summaryData_error} = useFetch(config.API_URL + 'tms/summary/' + selectedClient.ID, 0)
    const { data:chartData, isPending, error} = useFetch(config.API_URL + 'tms/graph/' + selectedClient.ID, 0)
    const { data:projects, isPending:projects_IsPending, error:projects_error} = useFetch(config.API_URL + 'project/forClient/' + selectedClient.ID, 0)

    const tiles = [
        {
            header: "Detail klienta",
            body: <ClientDetailInfo cliet={selectedClient} />,
            resizable: false,
            reorderable: false,
        },

        {
            header: "Přehled",
            body: <>{summaryData && <ClientDetailSummary data={summaryData}/>}</>,
            resizable: false,
            reorderable: false,
        },
        {
            header: "graf",
            body: <>{chartData && <ClientDetailGraph data={chartData} projects={projects}/>}</>,
            resizable: false,
            reorderable: false,
        },

    ];

    const [data, setData] = useState([
        {
            col: 1,
            colSpan: 2,
            rowSpan: 10,
        },
        {
            col: 3,
            colSpan: 2,
            rowSpan: 10,
        },
        {
            col: 5,
            colSpan: 6,
            rowSpan: 10,
        },

    ]);
    const handleReposition = (e) => {
        setData(e.value);
        console.log(e.value);
    };

    return(
        <>

            <div className="client-detail">

                {<TileLayout
                    columns={10}
                    // rows={10}
                    items={tiles}
                    positions={data}
                    onReposition={handleReposition}
                />}

            </div>

        </>
    )
}

export default ClientDetail