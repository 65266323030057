import * as React from "react";
import {Dialog, DialogActionsBar, Window, WindowActionsBar} from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import {TextBox, NumericTextBox, TextArea} from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {Error, Hint, Label} from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import FormDropDown from "../formEditComponents/FormDropDown";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import {isNumeric} from "@progress/kendo-data-query/dist/npm/utils";
import {DateInput, DatePicker} from "@progress/kendo-react-dateinputs";
import DropDownEditor from "../editComponents/DropDownEditor";
import FormTextBoxField from "../formEditComponents/FormTextBoxField";
import FormTextAreaField from "../formEditComponents/FormTextAreaField";
import FormNumBoxField from "../formEditComponents/FormNumBoxField";
import FormDateField from "../formEditComponents/FormDateField";
import NumBoxField from "../formEditComponents/FormNumBoxField";

const minValueValidator = value => value >= 0 ? "" : "The value must be 0 or higher";


const mandatoryFieldValidator = value => value != null ? "" : "mandatory field"


const useProjectEditor = props => (
    <FormDropDown
        {...props}
        textField={"ClientProjectName"}
    />
);


const EditTask = props => {

    const {
        cancelEdit,
        onSubmit,
        item,
        projects,
        clients,
        tasks,
        authors,
        priorities,
        taskStatuses,
        currencies,
        ...other
    } = props;

    // item.UnitID = units.find((c) => c.ID === item.UnitID)
    // item.PartnerID = partners.find((c) => c.ID === item.PartnerID)


    const handleSubmit = dataItem => {
        // debugger
        // if (!isNumeric(dataItem.ProjectID)) dataItem.ProjectID = dataItem.ProjectID.ID
        // if (!isNumeric(dataItem.AuthorID)) dataItem.AuthorID = dataItem.AuthorID.ID
        // if (!isNumeric(dataItem.ExpenseTypeID)) dataItem.ExpenseTypeID = dataItem.ExpenseTypeID.ID
        if (!isNumeric(dataItem.TaskStatusID)) dataItem.TaskStatusID = dataItem.TaskStatusID.ID
        if (!isNumeric(dataItem.PriorityID)) dataItem.PriorityID = dataItem.PriorityID.ID
        if (!isNumeric(dataItem.AuthorID)) dataItem.AuthorID = dataItem.AuthorID.ID
        if (!isNumeric(dataItem.OwnerID)) dataItem.OwnerID = dataItem.OwnerID.ID
        onSubmit(dataItem)
    }

    var title = ``
    if (item.ID === null) {
        title = "Copy Expense"
    }
    else if (item.ID === undefined) {
        title = "Create new Expense"
    }
    else {
        title = `Edit Expense: ${item.ID}`
    }


    // const { data:partners, isPending, error} = useFetch(config.API_URL + 'partner/', 1)

    return <Form initialValues={item} onSubmit={handleSubmit} render={renderProps =>

        <Window title={title} onClose={cancelEdit}
                initialHeight={800}
                initialWidth={600}
                modal={true}
        >
            <div className="editForm">
                <FormElement horizontal={true}>



                    {/*{projects && <FieldWrapper><Field name={"ProjectID"} label={"Project"} textField={"Name"} dataItemKey={"id"} component={useProjectEditor} listData={projects} /></FieldWrapper>}*/}
                    {/*{authors && <FieldWrapper><Field name={"AuthorID"} label={"Autor"} textField={"Name"} dataItemKey={"id"} component={FormDropDown} listData={authors} validator={mandatoryFieldValidator} /></FieldWrapper>}*/}
                    {/*{expenseTypes && <FieldWrapper><Field name={"ExpenseTypeID"} label={"WorkTypeID"} textField={"Name"} dataItemKey={"id"} component={FormDropDown} listData={expenseTypes} validator={mandatoryFieldValidator} /></FieldWrapper>}*/}
                    {taskStatuses && <FieldWrapper><Field name={"TaskStatusID"} label={"Status"} textField={"Name"} dataItemKey={"id"} component={FormDropDown} listData={taskStatuses} validator={mandatoryFieldValidator} /></FieldWrapper>}

                    <FieldWrapper><Field name={"Subject"} component={FormTextBoxField}  label={"Subject"} /></FieldWrapper>
                    <FieldWrapper><Field name={"Text"} component={FormTextAreaField} rows={10} label={"Text"} /></FieldWrapper>

                    {priorities && <FieldWrapper><Field name={"PriorityID"} label={"Priority"} textField={"Name"} dataItemKey={"id"} component={FormDropDown} listData={priorities} validator={mandatoryFieldValidator} /></FieldWrapper>}

                    <FieldWrapper><Field name={"Taken"} component={FormDateField}  label={"Date"} validator={mandatoryFieldValidator} /></FieldWrapper>
                    <FieldWrapper><Field name={"DueDate"} component={FormDateField}  label={"Due"} validator={mandatoryFieldValidator} /></FieldWrapper>

                    {authors && <FieldWrapper><Field name={"AuthorID"} label={"Autor"} textField={"Name"} dataItemKey={"id"} component={FormDropDown} listData={authors} validator={mandatoryFieldValidator} /></FieldWrapper>}
                    {authors && <FieldWrapper><Field name={"OwnerID"} label={"Vlastnik"} textField={"Name"} dataItemKey={"id"} component={FormDropDown} listData={authors} validator={mandatoryFieldValidator} /></FieldWrapper>}

                    {/*<FieldWrapper><Field name={"Desc"} component={FormTextAreaField}  label={"Popis"} /></FieldWrapper>*/}


                    <FieldWrapper><Field name={"Tags"} component={FormTextBoxField}  label={"Tagy"} /></FieldWrapper>

                    {/*<FieldWrapper>*/}
                    {/*    <Field name={"UnitPrice"} component={NonNegativeNumericInput} label={"Price"} validator={minValueValidator} />*/}
                    {/*</FieldWrapper>*/}
                    {/*<FieldWrapper>*/}
                    {/*    <Field name={"UnitsInStock"} component={NonNegativeNumericInput} label={"In stock"} validator={minValueValidator} />*/}
                    {/*</FieldWrapper>*/}
                </FormElement>
            </div>

            <WindowActionsBar layout="start">
                <Button type={"submit"} themeColor={"primary"} disabled={!renderProps.allowSubmit} onClick={renderProps.onSubmit} icon="save" >
                    Update
                </Button>
                <Button onClick={cancelEdit} icon="cancel" >
                    Cancel
                </Button>
            </WindowActionsBar>

        </Window>} {...other} />;
};
export default EditTask;