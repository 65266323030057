import {DropDownList} from "@progress/kendo-react-dropdowns";
import {TextBox} from "@progress/kendo-react-inputs";
import {useState} from "react";
import {GridCell, GridEditCell} from "@progress/kendo-react-grid";

const CustomTextCell = props => {

    const dataItem = props.dataItem

    let styles = {...props.style}
    if (props.selected && props.dataItem.ID === props.selected.ID) {styles = {...props.style, border: "1px solid red", backgroundColor: "rgb(243,221,239)"}}


    console.log('custom cell - ', props.selected)

    //debugger
    const handleChange = (e) => {
        //debugger
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            });
        }
    };

    const field = props.field || "";
    const dataValue = dataItem[field] === null ? "" : dataItem[field];

    const [myValue, setMyValue] = useState(dataValue)

    return(
        <td style={styles} className={props.className}>
            {dataItem.inEdit ? (

                    // <GridEditCell dataItem={props.dataItem} dataIndex={props.dataIndex} id={props.id}
                    //               isSelected={props.isSelected} ariaColumnIndex={props.ariaColumnIndex}
                    //               style={{ background: 'your-color-here' }}>
                    //     <TextBox
                    //         className="k-textbox"
                    //         value={props.dataItem[props.field]}
                    //         onChange={(e) => {
                    //             props.onChange({ dataItem: props.dataItem, field: props.field, syntheticEvent: e });
                    //         }}
                    //     />
                    // </GridEditCell>


                // <GridEditCell {...props}
                //               style={{ background: 'your-color-here', width: '100%' }}>

                // <TextBox
                //     className="k-textbox"
                //     style={{
                //         width: "100%",
                //     }}
                //     onChange={e => setMyValue(e.target.value)}
                //     value={myValue}
                //     onBlur={handleChange}
                // />

                // </GridEditCell>
                dataValue
            ) : (
                dataValue
            )}
        </td>


    )
}

export default CustomTextCell