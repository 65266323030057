import {filterBy, process} from "@progress/kendo-data-query";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {Grid, GridToolbar} from "@progress/kendo-react-grid";
import {useEffect, useState} from "react";
import useFetch from "../../tools/UseFetch";
import config from "../../config/config.json";
import Waiting from "../waiting/Waiting";
import EditCommandCell from "../editComponents/EditCommandCell";
import APICall from "../../tools/APICall";
import CurrencyEditor from "../editComponents/CurrencyEditor";
import DropDownFilter from "../filterComponents/DropDownFilter";
import DropDownEditor from "../editComponents/DropDownEditor";
import DurationEditor from "../editComponents/DurationEditor";
import useClientStore from "../../stores/ClientStore";
import DateFilter from "../filterComponents/DateFilter";
import useCashflowStore from "../../stores/CashflowStore";
import useTmsStore from "../../stores/TmsStore";
import useCurrencyStore from "../../stores/CurrencyStore";
import useProjectStore from "../../stores/ProjectStore";
import CommandCell from "../tableComponents/CommandCell";
import Confirm from "../general/Confirm";
import FileDialog from "../files/FileDialog";
import EditExpense from "./EditExpense";
import useExpenseStore from "../../stores/ExpenseStore";
import {ToolbarItem, ToolbarSeparator} from "@progress/kendo-react-buttons";
import useOwnRightsStore from "../../stores/OwnRightsStore";

const comparisonDate = new Date().getDate() > 5 ? new Date(new Date().getFullYear(), new Date().getMonth(), 1) : new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);

const ActionCell = (props) => {
    return(
        <>
            {/*<Button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"*/}
            {/*        size="small"*/}
            {/*        rounded="small"*/}
            {/*        onClick={() => props.enterEdit(props.dataItem)}*/}
            {/*>Novy klient</Button>*/}
            {/*<span className={"table-header-action"}>Nový klient</span>*/}
            {/*<span className={"table-header-action"}>aaa</span>*/}
        </>
    )
}

const initialDataState = {
    take: 50,
    skip: 0,
    //pageSize:100,
    sort:[
        {dir: "desc", field: "Date" }
    ],
};


const ExpenseGrid = (props) => {

    const isSmallHeight = window.innerHeight < 600

    // const refresh = props.refresh
    const {selectedClient} = useClientStore((state) => ({selectedClient: state.selectedClient}))
    const {selectedProject} = useProjectStore((state) => ({selectedProject: state.selectedItem}))

    const refSize = props.size
    const menuSize = props.menuSize
    const dataType = props.dataType || "all"

    console.log("expense grid datatype - ", props)


    const [dataState, setDataState] = useState(initialDataState);
    const [refresh, setRefresh] = useState(Date.now())


    let columnDefWidth = 100
    // let columnDefWidth = refSize.width * 0.1
    // if (columnDefWidth < 120) columnDefWidth = 120


    useEffect(() => {
        // debugger
        switch (dataType) {
            case "client":
                loadForClient(selectedClient.ID)
                break
            case "project":
                loadForProject(selectedProject.ID)
                break
            default:
                loadAll()
        }
    },[selectedClient, selectedProject])

    //
    // let apiURL, apiProjectURL;
    // switch (dataType) {
    //     case "client":
    //         apiURL = config.API_URL + 'tms/forClient/' + selectedClient.ID
    //         apiProjectURL = config.API_URL + 'project/forClient/' + selectedClient.ID
    //         break
    //     case "project":
    //         apiURL = config.API_URL + 'tms/forProject/' + selectedProject.ID
    //         apiProjectURL = config.API_URL + 'project/forProject/' + selectedProject.ID
    //         break
    //     default:
    //         apiURL = config.API_URL + 'tms/'
    //         apiProjectURL = config.API_URL + 'project/'
    // }


    const data = useExpenseStore(state => state.expense)
    const isLoading = useExpenseStore(state => state.isLoading)
    const setData = useExpenseStore(state => state.setExpense)
    const loadForClient = useExpenseStore(state => state.loadExpenseForClient)
    const loadForProject = useExpenseStore(state => state.loadExpenseForProject)
    const loadAll = useExpenseStore(state => state.loadExpenseAll)

    const addNew = useExpenseStore(state => state.addNew)
    const saveNew = useExpenseStore(state => state.saveNew)
    const saveItem = useExpenseStore(state => state.saveItem)
    const deleteItem = useExpenseStore(state => state.deleteItem)
    const refreshData = useExpenseStore(state => state.refreshData)

    const { data:currency, isPending:currency_isPending, error:currency_error} = useFetch(config.API_URL + 'lists/currency/', refresh)


    // const [selectedCount, setSelectedCount] = useState(0)
    //
    // useEffect(() => {
    //     setSelectedCount(process(data, dataState).data.length)
    // },[dataState])

    // const { data:tms, isPending, error} = useFetch(apiURL, refresh)

    const { data:expenseTypes, isPending:workType_isPending, error:workType_error} = useFetch(config.API_URL + 'lists/expense_type/', refresh)
    const { data:authors, isPending:authors_isPending, error:authors_error} = useFetch(config.API_URL + 'user/authors/', refresh)
    const { data:projects, isPending:projects_isPending, error:projects_error} = useFetch(config.API_URL + 'project/for_lists', refresh)
    const { data:actual_user, isPending:actual_user_isPending, error:actual_user_error} = useFetch(config.API_URL + 'user/get_own_user', 1)

    const SumFooter = (props) => {
        const field = props.field || "";

        const filteredData = dataState.filter ? filterBy(data, dataState.filter) : data;
        const total = filteredData.reduce((acc, current) => acc + current[field], 0);

        const formatter = new Intl.NumberFormat('en-US', { useGrouping: true });
        return (
            <td colSpan={props.colSpan} style={props.style} className={"right"}>
                {formatter.format(total)}
            </td>
        );
    };

    const DurationFooter = (props) => {
        const field = props.field || "";
        const total = process(data, dataState).data.reduce((acc, current) => acc + current[field], 0)
        const dataValue = Math.floor(total / 60).toString().padStart(2, '0') + ':' + (total % 60).toString().padStart(2, '0')
        return (
            <td colSpan={props.colSpan} style={props.style} className={"right"}>
                {dataValue}
            </td>
        );
    };

    const CountFooter = (props) => {
        const field = props.field || "";

        const filteredData = dataState.filter ? filterBy(data, dataState.filter) : data;
        const total = filteredData.length

        return (
            <td
                style={props.style} // this applies styles that lock the column at a specific position
                className={props.className} // this adds classes needed for locked columns
                colSpan={props.colSpan}
                role={"gridcell"}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
            >
                {total} záznamů
            </td>
        );
    };



    const PriceFooter = (props) => {
        const field = props.field || "";
        const total = process(data, dataState).data.reduce((acc, current) => acc + current[field], 0)
        const formatter = new Intl.NumberFormat('cs-CZ', { useGrouping: true });
        return (
            <td
                style={props.style} // this applies styles that lock the column at a specific position
                className={props.className + " right"} // this adds classes needed for locked columns
                colSpan={props.colSpan}
                role={"gridcell"}
                aria-colindex={props.ariaColumnIndex}
                aria-selected={props.isSelected}
            >
                {formatter.format(total)}
            </td>
        );
    };

    // useEffect(() => {
    //     switch (dataType) {
    //         case "client":
    //             loadTmsForClient(selectedClient.ID)
    //             break
    //         case "project":
    //             loadTmsForProject(selectedProject.ID)
    //             break
    //         default:
    //             loadTmsAll()
    //     }
    // },[selectedClient, selectedProject])
    //

    //region editors and filters



    const useCurrencyEditor = props => (
        <DropDownEditor
            {...props}
            listData={currency}
            editable={true}
        />
    );

    const CurrencyFilter = (props) => (
        <DropDownFilter
            {...props}
            data={currency}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );


    const useProjectEditor = props => (
        <DropDownEditor
            {...props}
            listData={projects}
            // columns={projectColumns}
            textField={"ClientProjectName"}
            editable={true}
            filterable={true}
        />
    );

    const ProjectFilter = (props) => (
        <DropDownFilter
            {...props}
            data={projects}
            textField={"ClientProjectName"}
            filterable={true}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );

    const useWorkTypeEditor = props => (
        <DropDownEditor
            {...props}
            listData={expenseTypes}
            editable={true}
        />
    );



    const WorkTypeFilter = (props) => (
        <DropDownFilter
            {...props}
            data={expenseTypes}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );

    const useAuthorEditor = props => (
        <DropDownEditor
            {...props}
            listData={authors}
        />
    );

    const useDurationEditor = props => (
        <DurationEditor
            {...props}
        />
    );

    const AuthorFilter = (props) => (
        <DropDownFilter
            {...props}
            data={authors}
            filterable={true}
            defaultItem={{ID: 0, Name: "All"}}
        />
    );

    const useDateFilter = (props) => (
        <DateFilter
            {...props}
            defaultItem={{ID: 0, Name: "All"}}
            type={"past"}
            dataState={dataState}
            setDataState={setDataState}
        />
    );




    //endregion
    const haveRight = useOwnRightsStore(state => state.haveRight)

    //region edit functions


    const MyEditCommandCell = (props) => (
        <CommandCell
            {...props}
            edit={enterEdit}
            copy={enterCopy}
            delete={deleteRow}
            showFiles={showFiles}
            filesCount={props.dataItem.FilesCount}
            disableDelete={(props.dataItem.Date < comparisonDate && !haveRight("TMS_Edit_Locked")) || (props.dataItem.AuthorID !== actual_user.ID && !haveRight("TMS_Fill_For_Others"))}
        />
    );



    //endregion


    // ********************************* helper data  *********************************

    const [openForm, setOpenForm] = useState(false);
    const [editItem, setEditItem] = useState(null);


    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [deletedItem, setDeletedItem] = useState(null);

    const [showFilesDialog, setShowFilesDialog] = useState(false)
    const [showFilesFor, setShowFilesFor] = useState(null);

    const [showImportDialog, setShowImportDialog] = useState(false)


// ************************************** EDIT *************************************


    const rowDblClick = props => {
        enterEdit(props.dataItem)
    }

    const enterEdit = (dataItem) => {
        console.log("item edit")

        setEditItem(dataItem)
        setOpenForm(true)
    };


    const handleCancelEdit = (dataItem) => {

        setOpenForm(false);

    };



    const handleSubmit = (dataItem) => {
        //debugger
        if (dataItem.ID === undefined || dataItem.ID === null) {
            saveNew(dataItem)
        }
        else {
            saveItem(dataItem)
        }
        setOpenForm(false);
    };


    // ************************************** NEW *************************************

    // const editNew = () => {
    //     const newItem = {};
    //     setEditItem(newItem)
    //     setOpenForm(true)
    // };

    const editNew = () => {
        const newItem = addNew() //{};

        const now = new Date()
        newItem.Date = new Date(now.getFullYear(), now.getMonth(), now.getDate())

        const newItem2 = {
            ...newItem,
            AuthorID: actual_user.ID,
            // ImportID: null
        }

        setEditItem(newItem2)
        setOpenForm(true)
    };

    // ************************************** COPY *************************************


    const enterCopy = (dataItem) => {
        console.log("copy edit")

        const newItem = {
            ...dataItem,
            ID: null,
            UID: null,
            Origin: null,
            ImportID: null
        }

        setEditItem(newItem)
        setOpenForm(true)

        console.log("edit copy ... ", openForm, editItem)
    };



    // ************************************** Delete *************************************

    const deleteRow = (dataItem) => {
        setDeletedItem(dataItem)
        setShowConfirmDelete(true)
    };

    const deleteRowAction = () => {
        deleteItem(deletedItem)
        setShowConfirmDelete(false)
    };

    const cancelDelete = () => {
        setShowConfirmDelete(false)
    };


    // ************************************** Files *************************************

    const showFiles = (dataItem) => {
        // debugger
        setShowFilesFor(dataItem)
        setShowFilesDialog(true)
    };

    const closeFiles = () => {
        refreshData()
        setShowFilesDialog(false)
    };


    // ************************************** Files *************************************

    const showImport = (dataItem) => {
        // debugger
        setShowFilesFor(dataItem)
        setShowImportDialog(true)
    };

    const closeImport= () => {
        refreshData()
        setShowImportDialog(false)
    };




    // ************************************** GRID *************************************


    // const [page, setPage] = useState(initialDataState);
    const [pageSizeValue, setPageSizeValue] = useState(50);

    const pageChange = (event) => {

        // const targetEvent = event.syntheticEvent;
        // const take = targetEvent.value === "All" ? process(data, dataState).length : event.page.take;
        //
        // if (targetEvent.value) {
        //     setPageSizeValue(targetEvent.value);
        // }

        setPageSizeValue(event.page.take)

        setDataState({
            ...dataState,
            skip: event.page.skip,
            take: event.page.take
        });
    };

    return(
        <>

            {data && currency && expenseTypes && authors && projects &&


                <Grid
                //pageable={true}
                sortable={true}
                filterable={true}
                //scrollable={"virtual"} //virtual, scrollable
                resizable={true}
                reorderable={true}
                //rowHeight={45}
                // onItemChange={itemChange}

                editField="inEdit"
                size={"small"}


                style={{
                    height: "100%", //"calc(100% - 55px)", // (refSize.height - 150) / 2,//"500px",
                    minHeight: 20,
                    //maxHeight: 300, // refSize.height - 70,
                    width: refSize.width - menuSize,
                }}
                data={process(data, dataState)}{...dataState}

                total={process(data, dataState).length}
                pageable={{
                    buttonCount: 4,
                    pageSizes: [10, 50, 100, 500],
                    pageSizeValue: pageSizeValue,
                }}
                onPageChange={pageChange}

                //onPageChange={pageChange}
                onDataStateChange={(e) => setDataState(e.dataState)}

                onRowDoubleClick={rowDblClick}

                >

                    <GridToolbar>

                        <button
                            title="New"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={editNew}
                        >
                            Novy Vydaj
                        </button>

                        {/*<ToolbarSeparator />*/}
                        {/*<ToolbarItem>zobrazeno: {selectedCount} z celkem {data.length}</ToolbarItem>*/}

                    </GridToolbar>

                    <Column field="ID" title="ID" width="80px" filterable={true} editable={false} />

                {/*<Column cell={myID} field="ID" title="ID" width="80px" footerCell={myFooterCell} filterable={true}/>*/}
                {/*<Column cell={myID} field="ID" title="ID" width="80px" filterable={true} locked/>*/}
                    <Column field="ProjectID" minResizableWidth={120} title="Projekt" width={300} locked
                            cell={useProjectEditor} filterCell={ProjectFilter} footerCell={CountFooter} />
                    <Column field="AuthorID" minResizableWidth={120} title="Autor" cell={useAuthorEditor}
                            filterCell={AuthorFilter} width={200} editable={true} locked />



                    <Column field="Date" minResizableWidth={100} title="Datum" width={150}
                            filter="date"
                            format="{0:dd.MM.yyyy}"
                            editor="date"
                            //cell={DateCell}
                            filterCell={useDateFilter}
                    />



                    <Column field="ExpenseTypeID" minResizableWidth={120} title="Druh" cell={useWorkTypeEditor} filterCell={WorkTypeFilter} width={120}/>

                    <Column field="Desc" minResizableWidth={120} title="Popis" width={450}/>

                    {/*<Column title={"In"}>*/}
                        <Column field="Price" minResizableWidth={120} title="Cena In" width={120} editor={"numeric"} editable={false} filter={"numeric"} className={"red right"}  footerCell={SumFooter} />
                        <Column field="CurrencyID" minResizableWidth={120} title="Měna In" cell={useCurrencyEditor} filterCell={CurrencyFilter} width={120}/>
                    {/*</Column>*/}

                    <Column field="Invoiced" minResizableWidth={120} title="Fakturovano" width={120} />
                    <Column field="InvoiceNum" minResizableWidth={120} title="Faktura" width={120}/>
                    {/*<Column field="Tags" minResizableWidth={120} title="Tagy" width={120}/>*/}

                    <Column field="action" minResizableWidth={180}
                            cell={MyEditCommandCell}
                        // columnMenu={ActionCell}
                            filterable={false}
                            headerCell={ActionCell}
                            width="180"
                            resizable={true}
                            locked
                        // filterCell={ActionCell}
                    />

                </Grid>

            }


            {openForm && <EditExpense
                projects={projects}
                authors={authors}
                expenseTypes={expenseTypes}
                currencies={currency}
                editFor={dataType}
                selectedClient={selectedClient}
                selectedProject={selectedProject}
                actualUser={actual_user}
                cancelEdit={handleCancelEdit}
                onSubmit={handleSubmit}
                item={editItem}
            />}


            {showConfirmDelete && <Confirm
                action={deleteRowAction}
                cancel={cancelDelete}
                text={"delete item?"}
            />}


            {showFilesDialog && <FileDialog
                close={closeFiles}
                text={"delete item?"}
                filesFor={showFilesFor}
            />}



            {isLoading && <Waiting />}

                {/*<div className="list-footer"></div>*/}


                {/*{openForm && <EditClient*/}
                {/*    cancelEdit={handleCancelEdit}*/}
                {/*    onSubmit={handleSubmit}*/}
                {/*    item={editItem}*/}
                {/*/>}*/}

        </>
    )
}

export default ExpenseGrid