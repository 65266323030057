import {create} from "zustand";
import axios from "axios";
import config from "../config/config.json";
import cashflow from "../components/cashflow/Cashflow";

const CashflowStore = (set, get) => ({

    cashflow: [],
    cashflowOriginal: [],
    isLoading: false,
    error: null,
    inAddMode: false,
    ClientID: 0,
    ProjectID: 0,

    setCashflow: (newCashflow) => {
        // debugger
        set({cashflow: newCashflow})
    },

    loadCashflowAll: async () => {

        if (get().ClientID != null || get().ProjectID != null) set({cashflow:[]})
        set({ isLoading: true, error: null, ClientID: null, ProjectID: null, inAddMode: false });

        try {
            const response = await axios.get(config.API_URL + 'cashflow/', {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            const newData = data.map(item => ({...item, Date: new Date(item.Date), Maturity: new Date(item.Maturity)}))
            set({ cashflow: newData, isLoading: false });
            set((state) => ({cashflowOriginal: state.cashflow}))

        } catch (error) {
            set({ error: error.message, isLoading: false });
        }
    },

    loadCashflowForClient: async (clientID) => {

        if (get().ClientID !== clientID) set({cashflow:[]})
        set({ isLoading: true, error: null, ClientID: clientID, ProjectID: null, inAddMode: false });

        try {
            const response = await axios.get(config.API_URL + 'cashflow/client/' + clientID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            const newData = data.map(item => ({...item, Date: new Date(item.Date), Maturity: new Date(item.Maturity)}))
            set({ cashflow: newData, isLoading: false });
            set((state) => ({cashflowOriginal: state.cashflow}))

        } catch (error) {
            set({ error: error.message, isLoading: false });
        }
    },

    loadCashflowForProject: async (projectID) => {

        if (get().ProjectID !== projectID) set({cashflow:[]})
        set({ isLoading: true, error: null, ProjectID: projectID, ClientID: null, inAddMode: false });

        try {
            const response = await axios.get(config.API_URL + 'cashflow/project/' + projectID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            const newData = data.map(item => ({...item, Date: new Date(item.Date), Maturity: new Date(item.Maturity)}))
            set({ cashflow: newData, isLoading: false });
            set((state) => ({cashflowOriginal: state.cashflow}))

        } catch (error) {
            set({ error: error.message, isLoading: false });
        }
    },

    addNew: () => {
        // if (!get().inAddMode) {
            var newDataItem = {
                // inEdit: true,
            };
            if (get().ClientID !== 0) {
                newDataItem = {...newDataItem, CounterPartyID: get().ClientID}
            }
            if (get().ProjectID !== 0) {
                newDataItem = {...newDataItem, ProjectID: get().ProjectID}
            }
            // set((state) => ({cashflow: [newDataItem, ...state.cashflow]}))
            // set({inAddMode: true})
            return newDataItem
        // }
    },

    // discardNew: () => {
    //     // debugger
    //     if (get().inAddMode) {
    //
    //         set({inAddMode: false})
    //
    //         const newData = [...get().cashflow];
    //         newData.splice(0, 1);
    //
    //         set({cashflow: newData})
    //
    //     }
    // },

    saveNew: async (dataItem) => {
        // debugger

        // if (get().inAddMode) {

            try {
                const response = await axios.put(config.API_URL + 'cashflow/', dataItem, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
                // set((state) => state.loadCashflowForClient(state.ClientID))
                get().refreshData()
            } catch (error) {
                set({ error: error.message, loading: false });
            }

        // }
    },

    saveItem: async (dataItem) => {

        try {
            const response = await axios.post(config.API_URL + 'cashflow/' + dataItem.ID, dataItem, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            //set((state) => state.loadCashflowForClient(state.ClientID))
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },

    deleteItem: async (dataItem) => {

        try {
            const response = await axios.delete(config.API_URL + 'cashflow/' + dataItem.ID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            //set((state) => state.loadCashflowForClient(state.ClientID))
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },

    refreshData: () => {
        console.log("refresh CF Data")
        if (get().ProjectID > 0) set((state) => state.loadCashflowForProject(state.ProjectID)) //TODO> udelat pro projekt
        else if (get().ClientID > 0) set((state) => state.loadCashflowForClient(state.ClientID))
        else set((state) => state.loadCashflowAll())
    }

})

const useCashflowStore = create(CashflowStore)

export default useCashflowStore