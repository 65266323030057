import {Upload} from "@progress/kendo-react-upload";
import config from "../../config/config.json";
import {createRef, useState} from "react";
import useCashflowStore from "../../stores/CashflowStore";
// import * as console from "console";

const DummyUpload = props => {

    const [refNum, setRefNum] = useState(0)
    const uploadRef = createRef();

    const addNew = useCashflowStore(state => state.addNew)
    const newItem = addNew()

    const onBeforeUpload = (event) => {
        event.additionalData.description = "File upload";
        event.additionalData.ClientID = newItem.CounterPartyID || 0;
        event.additionalData.ProjectID = newItem.ProjectID || 0;
        console.log("onBeforeUpload", event)
    };
    const onBeforeRemove = (event) => {
        event.additionalData.description = "File remove";
    };

    const onStatusChange = (event) => {
        setRefNum(refNum + 1)
        console.log("upload on status change", event)
        props.refreshData()
    };

    // const onAdd = (event) => {
    //     console.log("upload on add", event);
    //     props.refreshData();
    // };

    return (
        <div className={"cashflow-dummyupload"}>

            <Upload
                className={"cashflow-dummyupload-upload"}
                ref={uploadRef}
                batch={false}
                multiple={true}
                // defaultFiles={[]}
                // files={files}
                onBeforeUpload={onBeforeUpload}
                onBeforeRemove={onBeforeRemove}
                onStatusChange={onStatusChange}
                // onAdd={onAdd}
                withCredentials={false}
                saveUrl={config.API_URL + "cashflow/dummyupload"}
                // removeUrl={"http://localhost:8099/api/file/"}
                removeMethod={"DELETE"}
                saveHeaders={{'x-api-key': sessionStorage.getItem("apiKEY")}}
                removeHeaders={{'x-api-key': sessionStorage.getItem("apiKEY")}}
                showFileList={false}

            />

        </div>
    );
}

export default DummyUpload