import {Error, Label} from "@progress/kendo-react-labels";
import {TextArea} from "@progress/kendo-react-inputs";
import * as React from "react";

const TextAreaField = fieldRenderProps => {
    const {
        validationMessage,
        visited,
        label,
        id,
        valid,
        ...others
    } = fieldRenderProps;
    return <>
        <Label editorId={id} className={"k-form-label"}>
            {label}
        </Label>
        <div className={"k-form-field-wrap"}>
            <TextArea {...others} />
            {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
    </>;
};

export default TextAreaField