import * as React from "react";
export const CellEditCellRender = (props) => {
    const dataItem = props.originalProps.dataItem;
    const cellField = props.originalProps.field;
    const inEditField = dataItem[props.editField || ""];
    const additionalProps =
        cellField && (cellField === inEditField)
            ? {
                ref: (td) => {
                    const input = td && td.querySelector("input");
                    const activeElement = document.activeElement;
                    if (
                        !input ||
                        !activeElement ||
                        input === activeElement ||
                        !activeElement.contains(input)
                    ) {
                        return;
                    }
                    input.select();
                },
                onKeyDown: (event) => {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                        props.exitEdit(props.originalProps.dataItem);
                        // console.log(event.key)
                    }
                },
            }
            : {
                onDoubleClick: () => {
                    // debugger
                    props.enterEdit(dataItem, cellField);
                },
            };
    const clonedProps = {
        ...props.td.props,
        ...additionalProps,
    };
    return React.cloneElement(props.td, clonedProps, props.td.props.children);
};


export const CellEditRowRender = (props) => {
    const trProps = {
        ...props.tr.props,
        onBlur: () => {
            // props.exitEdit(props.originalProps.dataItem);
        },
    };
    return React.cloneElement(
        props.tr,
        {
            ...trProps,
        },
        props.tr.props.children
    );
};