import * as React from 'react';
import { Drawer, DrawerContent } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import withRouter from "./withRouter";
import Header from "./Header";
import {useEffect, useRef, useState} from "react";
import useClientStore from "../stores/ClientStore";
import useOwnRightsStore from "../stores/OwnRightsStore";







const DrawerRouterContainer = props => {

    const haveRight = useOwnRightsStore(state => state.haveRight)
    const loadRights = useOwnRightsStore(state => state.loadAll)

    useEffect(() => {
        loadRights()
    },[])

    const items = [
        {
            text: 'Home',
            icon: 'k-i-grid',
            selected: true,
            route: '/',
            show: true
        },
        {
            separator: true,
            show: true
        },
        {
            text: 'Klienti',
            icon: 'k-i-tell-a-friend',
            route: '/clients',
            show: haveRight("Clients_Read")
        },
        {
            text: 'Projekty',
            icon: 'k-i-folder-open',
            route: '/projects',
            show: haveRight("Projects_Read")
        },
        {
            text: 'Výkazy',
            icon: 'k-i-clock',
            route: '/timesheet',
            show: true
        },
        {
            text: 'Naklady',
            icon: 'k-i-clock',
            route: '/expenses',
            show: true
        },
        {
            text: 'Cashflow',
            icon: 'k-i-js',
            route: '/cashflow',
            show: haveRight("CF_Read")
        },
        {
            text: 'Tasks',
            icon: 'k-i-checkbox-checked',
            route: '/tasks',
            show: true
        },
        {
            separator: true,
            show: true
        },
        {
            text: 'Audit trail',
            icon: 'k-i-reset',
            route: '/audittrail',
            show: false
        },
        {
            separator: true,
            show: true
        },
        {
            text: 'Uživatelé',
            icon: 'k-i-user',
            route: '/users',
            show: haveRight("Admin_Users")
        },
        {
            text: 'Skupiny',
            icon: 'k-i-myspace',
            route: '/groups',
            show: haveRight("Admin_Groups")
        },
        {
            text: 'Ceny',
            icon: 'k-i-delicious-box',
            route: '/baseprices',
            show: haveRight("Prices_Master_Edit")
        },
        {
            text: 'Práva',
            icon: 'k-i-lock',
            route: '/rights',
            show: false
        },
        {
            text: 'Nastavení',
            icon: 'k-i-gear',
            route: '/settings',
            show: false
        },
        {
            separator: true,
            show: true
        },
        {
            separator: true,
            show: true
        },
        {
            text: 'Partner',
            icon: 'k-i-link-horizontal',
            route: '/partners',
            show: haveRight("Admin_Partners")
        },
        {
            text: 'Unit',
            icon: 'k-i-front-element',
            route: '/units',
            show: haveRight("Admin_Units")
        },
        {
            text: 'Currency',
            icon: 'k-i-dollar',
            route: '/currency',
            show: haveRight("Admin_Currency")
        },
        {
            text: 'CF Status',
            icon: 'k-i-check-circle',
            route: '/cfstatus',
            show: haveRight("Admin_CFStatus")
        },
        {
            text: 'CF Type',
            icon: 'k-i-thumbnails-right',
            route: '/cftype',
            show: haveRight("Admin_CFType")
        },
        {
            text: 'Work Type',
            icon: 'k-i-thumbnails-right',
            route: '/work_type',
            show: haveRight("Admin_WorkType")
        },
        {
            text: 'Expense Type',
            icon: 'k-i-thumbnails-right',
            route: '/expense_type',
            show: haveRight("Admin_ExpenseType")
        },
        {
            text: 'Task Status',
            icon: 'k-i-checkbox-checked',
            route: '/task_status',
            show: haveRight("Admin_ExpenseType")
        },
        {
            text: 'Task Priority',
            icon: 'k-i-checkbox-checked',
            route: '/task_priority',
            show: haveRight("Admin_ExpenseType")
        },
        {
            text: 'Project Status',
            icon: 'k-i-check-circle',
            route: '/project_status',
            show: haveRight("Admin_ProjectStatus")
        },
        {
            separator: true,
            show: true
        },
        {
            text: 'Products',
            icon: 'k-i-inbox',
            route: '/products',
            show: haveRight("Admin_ProjectStatus")
        },
        {
            text: 'Product Status',
            icon: 'k-i-inbox',
            route: '/product_status',
            show: haveRight("Admin_ProjectStatus")
        },
        {
            text: 'Product Client Status',
            icon: 'k-i-inbox',
            route: '/product_client_status',
            show: haveRight("Admin_ProjectStatus")
        },
        {
            separator: true,
            show: true
        },
        {
            text: 'Login history',
            icon: 'k-i-clock',
            route: '/login_history',
            show: haveRight("Admin_ProjectStatus")
        }
    ].filter(item => item.show !== false);

    if (haveRight("Admin_Users")) items.push({})


    const isSmallScreen = window.innerWidth < 768
    const menu = useRef(null);

    const menuSize = props.menuSize
    const setMenuSize = props.setMenuSize

    const [expanded, setExpanded] = React.useState(true);
    const [section, setSection] = useState("")
    // const [menuSize, setMenuSize] = useState(0)

    useEffect(() => {
        if (isSmallScreen) setMenuSize(0)
        else if (expanded) setMenuSize(240)
            else setMenuSize(50)

        console.log(menuSize)

    },[expanded, isSmallScreen])

    const loadClients = useClientStore(state => state.loadClients)


    useEffect(() => {
        loadClients()
    },[])


    const handleClick = () => {
        setExpanded(!expanded);
    };
    const onSelect = e => {
        setSection(e.itemTarget.props.text)
        props.navigate(e.itemTarget.props.route);
        if (isSmallScreen) setExpanded(!expanded);
    };

    //TODO nefunguje refresh pro nejake stranky
    const setSelectedItem = pathName => {
        let currentPath = items.find(item => item.route === pathName);
        if (currentPath.text) {
            return currentPath.text;
        }
    };
    let selected = setSelectedItem(props.location.pathname);

    return <div>

        <Header handleClick={handleClick} section={section} />

        <Drawer ref={menu}
            expanded={expanded}
            position={'start'}
            // mode={'overlay'}
            //mini={true}
            width={240}
            mode={isSmallScreen ? 'overlay' : 'push'}
            mini={!isSmallScreen}

            items={items.map(item => ({
            ...item,
            selected: item.text === selected
        }))} onSelect={onSelect}>
            <DrawerContent size={menuSize}>
                {props.children}
            </DrawerContent>
        </Drawer>
    </div>;
};
export default withRouter(DrawerRouterContainer);