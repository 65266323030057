import {DropDownList} from "@progress/kendo-react-dropdowns";
import {filterBy} from "@progress/kendo-data-query";
import {useState} from "react";


const DropDownEditor = props => {

    const listData = props.listData
    const textField = props.textField || "Name"
    const filterable = props.filterable || false

    // console.log("dropdown editor: ", props)

    // debugger
    const handleChange = (e) => {
        //debugger
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value.ID,
            });
        }
    };
    // debugger
    const { dataItem } = props;
    const field = props.field || "";
    const dataValue = dataItem[field] === null ? "" : dataItem[field];
    const selectedValue=listData.find((c) => c.ID === dataValue)
    const color = selectedValue ? selectedValue.Color : ""

    const colorStyle = (color !== undefined) ? "; background-color:" + color + "!important; " : ""


    const [data, setData] = useState(listData.slice());
    const filterData = (filter) => {
        const data = listData.slice();
        return filterBy(data, filter);
    };
    const filterChange = (event) => {
        setData(filterData(event.filter));
    };

    return(
        <td
            style={{...props.style, backgroundColor:color }} // this applies styles that lock the column at a specific position
            className={props.className} // this adds classes needed for locked columns
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
        >
            {dataItem.inEdit && props.editable ? (
                <DropDownList
                    style={{
                        width: "100%",
                    }}
                    data={data}
                    onChange={handleChange}
                    value={listData.find((c) => c.ID === dataValue)}
                    // data={listData}
                    textField={textField}
                    filterable={filterable}
                    onFilterChange={filterChange}
                />
            ) : (
                selectedValue ? selectedValue[textField] : ""
            )}
        </td>
    )
}

export default DropDownEditor