import axios from "axios";
import config from "../config/config.json";
import {create} from "zustand";

const ProductStatusStore = (set, get) => ({

    selectedItem: null,
    storeData: [],
    storeDataOriginal: [],
    isLoading: false,
    error: null,
    inAddMode: false,


    selectItem: (item) => set((state) => ({
        selectedItem: item
    })),

    setStoreData: (newStoreData) => {
        // debugger
        set({storeData: newStoreData})
    },

    loadAll: async () => {
        // debugger
        set({ loading: true, error: null, ClientID: null, inAddMode: false });

        try {
            const response = await axios.get(config.API_URL + 'lists/product_status/', {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});

            const data = response.data
            const newData = data //.map(item => ({...item, Date: new Date(item.Date), Maturity: new Date(item.Maturity)}))
            set({ storeData: newData, loading: false });
            set((state) => ({storeDataOriginal: state.storeData}))

        } catch (error) {
            set({ error: error.message, loading: false });
        }
    },


    addNew: () => {
        // if (!get().inAddMode) {
        var newDataItem = {
            // inEdit: true,
        };
        // if (get().ClientID !== 0) {
        //     newDataItem = {...newDataItem, ClientID: get().ClientID}
        // }
        // set((state) => ({cashflow: [newDataItem, ...state.cashflow]}))
        // set({inAddMode: true})
        return newDataItem
        // }
    },


    saveNew: async (dataItem) => {
        // debugger

        // if (get().inAddMode) {

        try {
            const response = await axios.put(config.API_URL + 'lists/product_status/', dataItem, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            // set((state) => state.loadCashflowForClient(state.ClientID))
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }

        // }
    },

    saveItem: async (dataItem) => {

        try {
            const response = await axios.post(config.API_URL + 'lists/product_status/' + dataItem.ID, dataItem, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            //set((state) => state.loadCashflowForClient(state.ClientID))
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },

    deleteItem: async (dataItem) => {

        try {
            const response = await axios.delete(config.API_URL + 'lists/product_status/' + dataItem.ID, {headers: {'x-api-key': sessionStorage.getItem("apiKEY")}});
            //set((state) => state.loadCashflowForClient(state.ClientID))
            get().refreshData()
        } catch (error) {
            set({ error: error.message, loading: false });
        }

    },

    refreshData: () => {
        set((state) => state.loadAll())
    }

})

const useProductStatusStore = create(ProductStatusStore)

export default useProductStatusStore