const ClientDetailInfo = props => {

    const client = props.cliet

    return (
        <>

            <div className="client-detail-outter">
                <div className="client-detail">
                    <div className="client-name">
                        <h2>{client.Name}</h2>
                    </div>
                    <div className="client-info">Název: {client.Name}</div>
                    <div className="client-info">ICO: {client.ICO}</div>
                    <div className="client-info">DIC: {client.DIC}</div>
                    <div className="client-info">Email: {client.Email}</div>
                    <div className="client-info">Phone: {client.Phone}</div>
                    <div className="client-info">Adresa: {client.Address}</div>
                    <div className="client-info">Kontakt: {client.Representative}</div>
                    <div className="client-info">Partner: {client.PartnerName}</div>
                    <div className="client-info">Poznamka: {client.Note}</div>
                </div>
            </div>

        </>
    )
}

export default ClientDetailInfo